import React, { Fragment, useEffect, useState } from 'react'
import "./AllCoursesProvided.scss"
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import MyservicesCard from '../../../components/LandingpageComponents/MyServicesCard/MyservicesCard';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { HiArrowLongRight } from 'react-icons/hi2';
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function AllCoursesProvided(props) {
  let navigate = useNavigate()
  let [services, setServices] = useState('')
  let [mentor, setMentor] = useState('')
  let [itemsToShow, setItemsToShow] = useState(15)
  let [isLoading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getCourseData()

    if (isDesktop) { setItemsToShow(15); }
    else if (isTablet) { setItemsToShow(6); }
    else if (isMobile) { setItemsToShow(3); }
  }, [])

  console.log('this is all courses');

  function cickedOnShowMore() {
    let adOn = 15;
    if (isDesktop) { adOn = 15; }
    else if (isTablet) { adOn = 6; }
    else if (isMobile) { adOn = 3; }
    let count = itemsToShow
    count = count + adOn
    setItemsToShow(count)
  }

  async function getCourseData() {

    try {
      setLoading(true)
      let services = await apiservice.apiCall('/ServiceList')
      console.log(services);
      if (services.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (services.data) {
        setServices(services.data)
        setLoading(false)

      }
      else {
        console.log(services.error);
        setLoading(false)

      }
    } catch (error) {
      console.log(error);
      setLoading(false)

    }
  }


  function getCoursesList() {
    let list = [];
    let count = 0
    for (let i = 0; i < services.length; i++) {
      let item = services[i]
      console.log(item);
      if (itemsToShow >= i + 1) {

        list.push(
          <div key={i + 1} className="col-lg-4 col-sm-12 col-md-6 p-1" onClick={async () => { await apiservice.setLocalStorage('serviceData', item); navigate(`/servicedetail/${item.Service_id}`) }}>
            <MyservicesCard
              courseTitle={item.Service_Name}
              courseImg={item.ThumbnailImage}
              mentorName={mentor.name}
              ratting={item.averageRating}
              serviceType={item.MentorshipType}
              courseDuration={`${item.Hour}h ${item.Minutes}m`}
              timeZone={item.timezone}
              webinarDate={item.start_date}
              webinarTime={item.start_time}
              courseCost={item.Price}
              currencySymbol={item.CurrencySymbol} />
          </div>
        );
      }
      if (count === 5) {
        count = 0;
      } else {
        count++;
      }
    }

    return list;
  }


  return (
    <Fragment>
      {isLoading ? <Loader /> :
        <div className='p-3'>
          <div className='container-fluid'>
            <div className='fs-3 fw-bold'>All Services</div>
            <hr />
          </div>
          <section style={{ backgroundColor: "#f1efe7" }}>
            <article className='container '>

              <div className="row">
                {getCoursesList()}
              </div>
              <div className='w-100 text-center py-4 my-4'>
                {
                  services.length >= itemsToShow ?

                    <button
                      type="button"
                      onClick={cickedOnShowMore}
                      className="btn btn-primary text-center px-4 py-2 border-0 btnM"
                      style={{ backgroundColor: "#E57A4D" }}
                    >
                      Show More
                    </button> :
                    ''
                }
              </div>
            </article>
          </section>
        </div>
      }

    </Fragment>
  )
}

export default AllCoursesProvided
