import React from 'react'
import { Fragment } from 'react'
import StarRatings from "react-star-ratings";
import noImg from "../../../assets/no-image.png"
import "./MyServicesCard.scss"
import moment from 'moment';
import apiservice from '../../../apiservice/ApiService';

function MyservicesCard({ courseTitle, mentorName, courseDuration, courseImg, courseCost, serviceType, ratting, webinarDate, webinarTime, timeZone, currencySymbol }) {
  return (
    <Fragment>
      <div className="card bg-transparent border-0 mb-4" style={{ width: "100%", cursor: 'pointer' }}>
        <img className="card-img-top  rounded-3 imgwidthHeight d-none d-lg-block" height={260} src={courseImg !== null ? `${apiservice.apiImageShow()}/${courseImg}` : noImg} alt="Card image cap"  />
        <img className="card-img-top  rounded-3 imgwidthHeight d-lg-none d-block" height={160} src={courseImg !== null ? `${apiservice.apiImageShow()}/${courseImg}` : noImg} alt="Card image cap" />
        <div className="card-body p-0 m-0">
          <h3 className="myServicesHeadText pt-2">{courseTitle}</h3>
          <div className="p-0 m-0 d-block d-lg-flex d-md-flex w-100"><p className='p-0 m-0'>{mentorName}</p></div>
          <div>
            <div
              className=" badge px-2 py-1 text-dark text-center rounded-2"
              style={{ backgroundColor: "#ABEFC6" }}
            >
              {" "}
              {serviceType}
            </div>
            {serviceType === 'Webinar Session' ?
                          <span className='badge text-dark mb-2 ms-2' style={{ backgroundColor: '#c7cce5' }}>{webinarDate} {webinarTime}</span> : ''}
          </div>
          <div className="py-1 m-0">
            <StarRatings
              rating={Number(ratting)}
              starDimension='20px'
              starRatedColor="#FDB022"
              numberOfStars={5}
            /> <span className="ps-2">{ratting === 0 ? 0 : Number(ratting).toFixed(1)}</span> </div>
          <p className="py-1 m-0" ><b>Total time:</b> {courseDuration} </p>
          {/* <div>
            {serviceType === 'Webinar Session' ?
              webinarDate === null ? '' :
                <div>
                  <div className=''><b>Date  :</b> {webinarDate}</div>
                  <div className=''><b> Time :</b> {webinarTime} ({timeZone})</div>
                </div>
              :
              ''}
          </div> */}
          <p className="py-1 m-0 fw-bold">{currencySymbol} {Number(courseCost).toFixed(2)}</p>
        </div>
      </div>
    </Fragment>
  )
}

export default MyservicesCard
