
import icon1 from '../../../assets/IndustryIcon/Icon1.png'
import icon2 from '../../../assets/IndustryIcon/Icon2.png'
import icon3 from '../../../assets/IndustryIcon/Icon3.png'
import icon4 from '../../../assets/IndustryIcon/Icon4.png'
import icon5 from '../../../assets/IndustryIcon/Icon5.png'
import icon6 from '../../../assets/IndustryIcon/Icon6.png'
import icon7 from '../../../assets/IndustryIcon/Icon7.png'
import icon8 from '../../../assets/IndustryIcon/Icon8.png'
import icon9 from '../../../assets/IndustryIcon/Icon9.png'
import icon10 from '../../../assets/IndustryIcon/Icon10.png'
import icon11 from '../../../assets/IndustryIcon/Icon11.png'
import icon12 from '../../../assets/IndustryIcon/Icon12.png'
import icon13 from '../../../assets/IndustryIcon/Icon13.png'
import icon14 from '../../../assets/IndustryIcon/Icon14.png'
import icon15 from '../../../assets/IndustryIcon/Icon15.png'
import icon16 from '../../../assets/IndustryIcon/Icon16.png'
import icon17 from '../../../assets/IndustryIcon/Icon17.png'
import icon18 from '../../../assets/IndustryIcon/Icon18.png'
import icon19 from '../../../assets/IndustryIcon/Icon19.png'
import icon20 from '../../../assets/IndustryIcon/Icon20.png'
import icon21 from '../../../assets/IndustryIcon/Icon21.png'
import icon22 from '../../../assets/IndustryIcon/Icon22.png'
import icon23 from '../../../assets/IndustryIcon/Icon23.png'
import icon24 from '../../../assets/IndustryIcon/Icon24.png'
import icon25 from '../../../assets/IndustryIcon/Icon25.png'
import icon26 from '../../../assets/IndustryIcon/Icon26.png'
import icon27 from '../../../assets/IndustryIcon/Icon27.png'
import icon28 from '../../../assets/IndustryIcon/Icon28.png'
import icon29 from '../../../assets/IndustryIcon/Icon29.png'
import icon30 from '../../../assets/IndustryIcon/Icon30.png'
import icon31 from '../../../assets/IndustryIcon/Icon31.png'
import icon32 from '../../../assets/IndustryIcon/Icon32.png'
import icon33 from '../../../assets/IndustryIcon/Icon33.png'
import icon34 from '../../../assets/IndustryIcon/Icon34.png'

function IndustryArray(industry) {
    return industry = [
        { icon: icon1, name: 'Accounting' },
        { icon: icon2, name: 'Audit & Assurance' },
        { icon: icon3, name: 'Asset Management' },
        { icon: icon4, name: 'Banking' },
        { icon: icon5, name: 'Big Tech' },
        { icon: icon6, name: 'Consulting' },
        { icon: icon7, name: 'Content Creation' },
        { icon: icon8, name: 'Crypto/Web3' },
        { icon: icon9, name: 'Education' },
        { icon: icon10, name: 'Engineering' },
        { icon: icon11, name: 'Entrepreneurship' },
        { icon: icon12, name: 'Investment Banking' },
        { icon: icon13, name: 'Law' },
        { icon: icon14, name: 'Insurance' },
        { icon: icon15, name: 'Immigration Consulting' },
        { icon: icon16, name: 'Operations' },
        { icon: icon17, name: 'Private Banking' },
        { icon: icon18, name: 'Private Equity' },
        { icon: icon19, name: 'Research' },
        { icon: icon20, name: 'Wellness' },
        { icon: icon21, name: 'Human Resources' },
        { icon: icon22, name: 'Hedge Funds' },
        { icon: icon23, name: 'Marketing and Media' },
        { icon: icon24, name: 'Medicine & Healthcare' },
        { icon: icon25, name: 'Sales' },
        { icon: icon26, name: 'Sales & Trading' },
        { icon: icon27, name: 'Sports' },
        { icon: icon28, name: 'Software Development' },
        { icon: icon29, name: 'Venture Capital' },
        { icon: icon30, name: 'Risk Management' },
        { icon: icon31, name: 'Real Estate' },
        { icon: icon32, name: 'Technology' },
        { icon: icon33, name: 'Product Management' },
        { icon: icon34, name: 'General' },

    ]
}
 
export default IndustryArray
