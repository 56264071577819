import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import apiservice from '../../../apiservice/ApiService';

function MeetingLeave() {

    const [isCompleted, setIsCompleted] = useState(1)
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const onChangeHandle = (e) => {
        const value = e.target.value;
        setIsCompleted(Number(value));
    };
    const onclickSubmit = async () => {
        setIsLoading(true)
        const urlParams = new URLSearchParams(window.location.search);
        const role = urlParams.get('role');
        const booking_id = urlParams.get('booking_id');
        const session_number = urlParams.get('session_number');
        let dataObject = {
            role: role,
            booking_id: booking_id,
            session_number: session_number,
            attend_status: isCompleted
        }
        console.log(dataObject);
        await apiservice.apiCall('/SessionCompleted', '', dataObject, 'POST').then(res => {
            if (res.data) {
                console.log(res.data);
                setIsLoading(false)
                setSuccessMessage(res.data)
            } else {
                setIsLoading(false)
                setSuccessMessage(res.error)
                console.log(res.error);
            }
        })
    }

    const onClickOk = () => {
        const fullUrl = window.location.href;
        console.log(fullUrl);
        window.close()
    }
    return (
        <>
            <Modal show={true}>
                <ModalBody>
                    <div className='p-2'>
                        {successMessage === '' ?
                            <div className='p-4 border rounded'>
                                <div className='mb-3 fw-bold text-center'>Have you completed your session?</div>
                                <div className='d-flex justify-content-center'>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input" onChange={onChangeHandle}
                                                value={1} checked={isCompleted === 1 ? true : false} />
                                            YES
                                        </label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input" onChange={onChangeHandle}
                                                value={0} checked={isCompleted === 0 ? true : false} />
                                            NO
                                        </label>
                                    </div>
                                </div>
                                <div className='mt-3 text-center'>
                                    <button className='btn btn-orange' onClick={onclickSubmit} disabled={isLoading ? true : false}>{isLoading ? 'Loading..' : 'Submit'}</button>
                                </div>
                            </div>
                            :
                            <div className='p-4 border rounded text-center'>
                                <div className=''>{successMessage}</div>
                                <div className='mt-3'>
                                    <button className='btn btn-orange' onClick={onClickOk}>OK</button>
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MeetingLeave
