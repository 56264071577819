
export default function validation() {
  let mobileVal = /^\d{6,15}$/
  let emailVal = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let passwordVal = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
  let onlyStringVal = /^[a-zA-Z_ ]*$/
  let onlyNumberVal = /^[0-9]+$/
  let numberWithDecimal = /^\d+(\.\d+)?$/
  let bankACNumber = /^\d+$/
  let IFSCCodeVal = /^[A-Z]{4}0\d{6}$/
  let isValidUrl = /^(https?|ftp):[^\s$.?#].[^\s]*$/
  let htmlRegex = /\\<.*?\\>/
  let stringWithSpecialCharacter = /^[^\d]+$/

  return { mobileVal, emailVal, passwordVal, onlyNumberVal, onlyStringVal, numberWithDecimal, bankACNumber, IFSCCodeVal, isValidUrl, htmlRegex, stringWithSpecialCharacter }
}
