import React from 'react'
import topImage from '../../../assets/supportImage.png'
function SupportPage() {

    return (
        <>
            <div className='p-3'>
                <div className='container-fluid'>
                    <div className='fw-bold Title d-none d-lg-block'>Support</div>
                    <div className='fw-bold Title text-center d-lg-none d-block'>Support</div>
                </div>
                <div className='text-center mt-5'>
                    <img src={topImage} alt='..' className='img-fluid' />
                </div>
                <div className='mt-5'>
                    <div className="form-group">
                        <p type="text" className="form-control pb-lg-5 px-lg-4">
                        Your feedback matters! Whether you're seeking guidance on anything from navigating courses to seeking clarification on any course-related matter, our team is here to help! Please email us at <span className='text-decoration-none' ><a href="mailto:kavish@xperthrivr.com?subject=&body=">kavish@xperthrivr.com</a></span> or explore our FAQs for immediate solutions.  
                        </p>
                    </div>
                </div>
                <div className='mt-3 text-center'>
                    <button className='btn btn-light py-3' onClick={() => window.location = "mailto:kavish@xperthrivr.com?subject=&body="} style={{ cursor: 'pointer' }}>Get in touch</button>
                </div>
            </div>
        </>
    )
}

export default SupportPage
