import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
function Loader() {
    return (
        <div className='position-relative' style={{ width: '100%', height: '500px' }}>
            <div className='position-absolute top-0 bottom-0 start-0 end-0 m-auto text-center' style={{ width: '200px', height: '100px',color:'#E57A4D' }}>
                <FontAwesomeIcon className='fa-2x' icon={faSpinner} spin /> <span className='fs-3 ms-4 fw-bold'>Loading...</span>
            </div>
        </div>
    )
}

export default Loader
