import React, { useEffect, useState } from 'react'
import apiservice from '../../../apiservice/ApiService'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader'
import weblogo from '../../../assets/Web_Logo.png'
function SuccessScheduleMeeting() {
    let navigate = useNavigate()
    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        successSchedule()
    }, [])
    const successSchedule = async () => {
        // alert('api calling')
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        let currScheduleId = localStorage.getItem('currScheduleId')
        let currServiceId = localStorage.getItem('currServiceId')
        // alert(currScheduleId, 'currScheduleId')
        // alert(currServiceId, 'currServiceId')
        console.log(currScheduleId, 'currScheduleId');
        console.log(currServiceId, 'currServiceId');
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        let apiEndPoints = currScheduleId === null || currScheduleId === '' || currScheduleId === undefined ? '/ScheduleWebinarLink' : '/ScheduleServiceLink'
        let dataObject = {
            ScheduleId: currScheduleId === null || currScheduleId === '' || currScheduleId === undefined ? currServiceId : currScheduleId,
            google_auth_token: code
        }
        await apiservice.apiCall(apiEndPoints, uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setErrorMsg('')
                setSuccessMsg(res.data)

            }
            else {
                setIsLoading(false)
                setSuccessMsg('')
                setErrorMsg(res.error)
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const onClickOK = () => {
        window.close()
    }
    return (
        <>{isLoading ? <Loader /> :
            <div className='container-fluid p-0'>
                    <div className=' pt-5 text-center'>
                        <img src={weblogo} alt='...' width={172} height={40} />
                    </div>
                <div className='container'>
                    <div className='text-center mt-5 p-5 border border-grey fw-bold rounded col-6 offset-3'>
                        {successMsg === '' ? '' : <div className='text-success'>{successMsg} </div>}
                        {errorMsg === '' ? '' : <div className='text-danger'>{errorMsg}</div>}

                        <div>
                            <button className='btn btn-orange mt-3' onClick={onClickOK}>OK</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default SuccessScheduleMeeting
