import React, { useEffect, useRef, useState } from 'react'
import logo from '../../../assets/logo_white.png'
import prefix from '../../../assets/prefix-icon.png'
import downarrow from '../../../assets/down-arrow.png'
import { toPng } from 'html-to-image';
import apiservice from '../../../apiservice/ApiService'
import noimage from '../../../assets/noProfileImg.png'
import './AnnounceMyPage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';
import SEOScript from '../../../scoscript/SEOScript';
import ShareLink from 'react-linkedin-share-link'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { Modal, ModalBody } from 'react-bootstrap';
import AnnouceMyService from './AnnouceMyService';
function AnnounceMyPage(props) {
    let navigate = useNavigate()
    const [servicesName, setServicesName] = useState([])
    const [userData, setUserData] = useState([])
    const [imageUrlEncode, setImageUrlEncode] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [imageUrlFromApi, setImageUrlFromApi] = useState('')
    useEffect(() => {
        getServicesNames()
        getImageCode()
        setTimeout(() => {
            LoadimageForView()
        }, 2000);
    }, [])
    const getImageCode = async () => {

        let usrData = await apiservice.getLocalStorage('userData')
        await apiservice.apiCall('/mentor_profile_string', usrData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);
                setImageUrlEncode(res.data)
            }
            else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const getServicesNames = async () => {
        let usrData = await apiservice.getLocalStorage('userData')
        console.log(usrData);
        let imageUrl = usrData.ProfileImage === null ? usrData.SocialImage === null ? noimage : usrData.SocialImage : `${apiservice.apiImageShow()}/${usrData.ProfileImage}`
        console.log(imageUrl, '----------- image url --------------');
        setUserData(usrData)
        await apiservice.apiCall('/TopServiceList', usrData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);
                setServicesName(res.data)
            }
            else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }


    const elementRef = useRef(null);
    const htmlToImageConvert = async () => {
        setIsLoading(false)
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = imagePreview;
        link.click();
       
    };




    const listOfServicesName = () => {
        let list = []
        let dataArr = servicesName

        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            list.push(
                <div className='d-flex mt-3 '>
                    <div className='ms-5  prefix_icon'>
                        <img src={prefix} alt='' />
                    </div>
                    <div className='ms-3' style={{ fontSize: '18px' }}>{element.Service_Name}</div>
                </div>

            )
        }

        return list
    }




    const LoadimageForView = async () => {
        setIsLoading(true);

        try {
            const dataUrl = await toPng(elementRef.current);
            console.log(dataUrl);
            setImagePreview(dataUrl);
            generateImageUrlFromBase64(dataUrl)
            setIsImageLoading(false)
        } catch (err) {
            console.error('Error converting to image:', err);
        } finally {
            setIsLoading(false);
        }
    };
    const generateImageUrlFromBase64 = async (imgString) => {
        let uData = await apiservice.getLocalStorage('userData')
        console.log(imgString);
        await apiservice.apiCall('/profile_link_from_base64_string', uData.Token, { image_string: imgString }, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);
                setImageUrlFromApi(res.data)
            }
            else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }





    const onClickLoginLinkedIn = () => {

        try {
            linkedInLogin();
        } catch (error) {
            console.log(error);
        }
    };

    const [linkedinPostPopup, setLinkedinPostPopup] = useState(false)
    const [postSuccess, setPostSuccess] = useState('')
    const [postError, setPostError] = useState('')
    const [linkedinPostLoading, setlinkedinPostLoading] = useState(false)
    
    const { linkedInLogin } = useLinkedIn({
        clientId: "865mis3s3mhtef",
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: (code) => {
            console.log(code);
            linkedinCallBack(code)
        },
        scope: "email openid profile r_learningdata w_member_social",
        onError: (error) => {
            console.log(error);

        },
    })


    const linkedinCallBack = async (code) => {
        setlinkedinPostLoading(true)
        setLinkedinPostPopup(true)
        let uData = await apiservice.getLocalStorage('userData')
        let dataObject = {
            access_token: code,
            image: imageUrlFromApi
        }
        await apiservice.apiCall('/linkedin/share', uData.Token, dataObject, 'POST').then(res => {
            if (res.data) {
                console.log(res.data);
                setPostSuccess(res.data)
                setlinkedinPostLoading(false)
                setPostError('')
            } else {
                setPostError(res.error)
                setlinkedinPostLoading(false)
                setPostSuccess('')
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    const onClickLinkedinPostOk = () => {
        if (postSuccess !== '') {
            window.open('https://www.linkedin.com/');
            setLinkedinPostPopup(false)
        } else {
            setLinkedinPostPopup(false)
        }
    }
    const onClickLinkedinPostClose = () => {
        setLinkedinPostPopup(false)
    }

    return (
        <>
            <Modal show={linkedinPostPopup}>
                <ModalBody>
                    {linkedinPostLoading ?
                        <div className='p-3 text-center'>
                            <FontAwesomeIcon icon={faSpinner} spin /> Loading...

                        </div>
                        : <div className='p-3 text-center'>
                            <div className='my-3'>
                                {postSuccess === '' ? '' : <div className='text-success'>{postSuccess}</div>}
                                {postError === '' ? '' : <div className='text-danger'>{postError}</div>}
                            </div>
                            <div className='mt-3'>
                                {postSuccess === '' ? '' : <button className='btn btn-orange' onClick={onClickLinkedinPostOk}>View Post</button>}
                                <button className='btn btn-secondary ms-3' onClick={onClickLinkedinPostClose}>Close</button>
                            </div>
                        </div>}

                </ModalBody>
            </Modal>
            <div className='overflow-hidden'>
                {isImageLoading ? '' :
                    <div className=''>
                        <div className='row py-3'>
                        <div className='fs-3 fw-bold'>Profile</div>
                            <div className='col-lg-6'>
                                <img src={imagePreview} width={'100%'} alt='...' />
                            </div>
                            <div className='col-lg-6'>
                                <div className='my-3 text-center'>
                                    {isLoading ? <button className='btn btn-orange' >
                                        Loading...
                                    </button> :
                                        <div>
                                            <button className='btn btn-orange btnM me-2 rounded-5' onClick={htmlToImageConvert}>
                                                <FontAwesomeIcon className='fa-1x' icon={faDownload} />
                                            </button>
                                           

                                            <button className='btn btn-primary' onClick={onClickLoginLinkedIn}>
                                                <FontAwesomeIcon className=' ' icon={faLinkedin} /> Share on LinkedIn
                                            </button>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row py-3'>
                        <div className='fs-3 fw-bold'>Services</div>
                           <AnnouceMyService />
                        </div>
                    </div>
                }
                {isImageLoading ?
                    <div className='position-relative ' >


                        <div className='cover_loader'>
                            <div className='position-relative top-0 start-0 end-0 bottom-0'>
                                <div className='my-5'>
                                    <div className='container'>
                                        <div className='col-md-6 col-lg-12 col-4 text-center'>
                                            <div className='text-center' style={{ color: '#E57A4D' }}>
                                                <FontAwesomeIcon className='fa-2x' icon={faSpinner} spin /> <span className='fs-2 ms-4 fw-bold'>Loading...</span>
                                            </div>
                                            <div className=' mt-3 text-success  '>
                                                We are in the process of generating your personalized page. Please allow some time for this to complete.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='main-social-container p-2 ' ref={elementRef}>
                            <div className='conatiner'>
                                <div className=' col-12 offset-md-0 overflow-hidden text-container'>
                                    <div className=''>
                                        <div className='row'>
                                            <div className='col-3 bg-dark'>
                                                <div className=' text-center mt-5'>
                                                    <img src={logo} className='company_logo' alt='...' />
                                                </div>

                                                <div className='mt-3 position-relative'>
                                                    <img src={downarrow} alt='...' className='arrow-img' />
                                                    <div className='rounded-circle main-iamge text-center overflow-hidden'>
                                                        <img src={`data:image/png;base64,${imageUrlEncode}`} alt="" style={{ borderRadius: '50%' }} width="100%" height="100%" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-9'>
                                                <div className=' mt-3 ms-4 '>
                                                    <div className='fs-3 ms-5 fw-bold'>Let's Connect 1:1!</div>
                                                    <div className=''>
                                                        {listOfServicesName()}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 text-light bg-dark'>
                                                <div className=' py-4'>
                                                    <div className='ms-5 my-3 fs-1 fw-bold user_name'>{userData.name} {userData.lname}</div>
                                                </div>

                                                <div className='border-bar mt-5' >
                                                    <div className='row'>
                                                        <div className='col-10 offset-2 text-dark'>
                                                            <div className='p-4 over-lap-content' >
                                                                <div className='fixed_text' style={{ fontSize: '18px' }}>Reach out to me on the XperThrivr.</div>
                                                                <div className='fw-bold mt-3 profile_link text-break' style={{ fontSize: '18px' }}><a>{`${apiservice.webOriginUrl()}/contentexpert/${userData.UserName}`}</a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}


            </div>
        </>
    )
}

export default AnnounceMyPage
