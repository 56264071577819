import React, { useEffect } from 'react'
import "./LearnerListForSchedule.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import { Pagination } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function HistoryOfLearner(props) {
    let navigate = useNavigate()
    useEffect(() => {
        getAlldata()
    }, [])
    const [myLearners, setMyLearners] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    async function getAlldata() {
        setIsLoading(true)
        let id = props.id
        let uData = await apiservice.getLocalStorage('userData')
        try {
            let myLearnersData = await apiservice.apiCall('/BookServiceCompletedLearnerList', uData.Token, { service_id: id }, 'POST')
            if (myLearnersData.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (myLearnersData.data) {
                setIsLoading(false)
                setMyLearners(myLearnersData.data)
            }
            else {
                setIsLoading(false)
                console.log(myLearnersData.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }
    const onClickLearnerCard = async (data) => {
        let learnerId = data.users.id
        let sid = props.id
        localStorage.setItem('scheduleServiceid', sid)
        localStorage.setItem('isSchedulePage',true)
        await apiservice.setLocalStorage('learnerId', learnerId)
        console.log(learnerId);
        navigate(`/contetexpert-home/learnerprofile/${learnerId}`)

    }

    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(myLearners.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = myLearners.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);


    const onClickHistory = (data) => {
        console.log(data);
        let dataObject = {
            service_id: props.id,
            book_services_id: data.Book_Service_Id,
            learner_id: data.users.id,
        }
        console.log(dataObject);
        let dataObj = JSON.stringify(dataObject)

        navigate(`/contetexpert-home/historylist/${dataObj}`)
    }

    const learnerList = () => {
        let array = itemsToDisplay
        console.log(array);
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let item = array[index]

            list.push(
                <div className='col-md-4 '
                    key={index}
                >
                    <div className='col-12 learnerCardMentors my-2'>
                        <div className="card" >
                            <div className={`cardBg${count}`}>
                            </div>
                            <div className="card-body p-2">
                                <div className="  learner_icon " >
                                    <div className='d-flex justify-content-center'>
                                        <div className=' rounded-circle overflow-hidden ms-2' style={{ width: '70px', height: '70px' }}>
                                            <img src={item.users.ProfileImage === null ? item.users.SocialImage === null ? item.users.profile_photo_url : item.users.SocialImage : `${apiservice.apiImageShow()}/${item.users.ProfileImage}`} className='' alt="" width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div className='card-text  m-2 text-center'>
                                        <div className=' fw-bold'>{item.users.name ? `${item.users.name} ${item.users.lname}` : 'Learner'} </div>
                                    </div>
                                    <div className='text-center px-3'>
                                        <div className=''>
                                            <div className='w-100 btn btn-orange-border btnM' onClick={() => onClickLearnerCard(item)}>View Details</div>
                                        </div>
                                        <div className='mt-3'>
                                            <div className='w-100 btn btn-orange btnM' onClick={() => onClickHistory(item)}>History</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

            if (count === 5) {
                count = 0
            } else {
                count++
            }
        }
        return list
    }
    return (
        <>
            {isLoading ? <Loader /> :
                <div>

                    {myLearners.length > 0 ?
                        <div className='mt-3'>
                            <div className=' row'>
                                {learnerList()}
                            </div>
                            <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination>
                                    <Pagination.Prev
                                        className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1 ? true : false}
                                    >
                                        <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                    </Pagination.Prev>
                                    {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                        <Pagination.Item
                                            key={pageRangeStart + offset}
                                            active={pageRangeStart + offset === currentPage}
                                            onClick={() => handlePageChange(pageRangeStart + offset)}
                                        >
                                            {pageRangeStart + offset}
                                        </Pagination.Item>
                                    ))}
                                    {pageRangeEnd < totalPages && (
                                        <Pagination.Ellipsis disabled />
                                    )}
                                    <Pagination.Next
                                        className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages ? true : false}
                                    >
                                        <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </div> :
                        <div>
                            <div className='fw-bold text-center mt-5 text-danger'>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
                        </div>

                    }
                </div>
            }
        </>
    )
}

export default HistoryOfLearner
