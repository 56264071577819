import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './ProgressReport.scss'
import StarRatings from 'react-star-ratings';


import logo from '../../../assets/LOGO.png'
import Rborder from '../../../assets/reportimage/ReportBorder.png'
import Rborder2 from '../../../assets/reportimage/ReportBorder2.png'
import Rimg from '../../../assets/reportimage/ReportImg.png'
import Rimg1 from '../../../assets/reportimage/ReportImg1.png'
import Rimg2 from '../../../assets/reportimage/ReportImg2.png'
import Rimg3 from '../../../assets/reportimage/ReportImg3.png'
import courseImg from "../../../assets/reportimage/cardImage.png"
import Mail from '../../../assets/reportimage/mail.png'
import Phone from '../../../assets/reportimage/phone-call.png'
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment';
import Loader from '../../../components/Loader';
import ProgressReportView from './ProgressReportView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function ProgressReport(props) {
    let navigate = useNavigate()
    const reportTemplateRef = useRef(null);
    const [userDatas, setUserDatas] = useState([])
    const [servicesData, setServicesData] = useState([])
    const [reviewsData, setReviewsData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [imageString, setImageString] = useState('')



    const handleGeneratePdf = () => {
        console.log(imageString)
        const pdf = new jsPDF('p', 'mm', 'a4');
        const padding = 30;
        const width = 210.0015555555555 - (2 * padding);
        const height = 297.0000833333333 - (2 * padding);
    
        const x = padding;
        const y = padding;
        pdf.addImage(imageString, 'PNG', x, 0, width, height);
        pdf.save('XperThrivr-Progress-Report.pdf');
    };
    const generateImage = () => {
        const input = reportTemplateRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setImageString(imgData)

            setIsLoading(false)
        });
    };

    useEffect(() => {
        getReportData()
    }, [])



    const getReportData = async () => {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        await apiservice.apiCall('/learner_progress_report', uData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);
                let datas = res.data
                setUserDatas(datas.user_details)
                setServicesData(datas.service_list)
                setReviewsData(datas.reviews)
                setTimeout(() => {
                    generateImage()
                }, 3000);
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }



    const listOfServices = () => {
        let list = []
        let dataArr = servicesData
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            list.push(
                <div className='col-4 p-0' key={index}>
                    <div className='p-3'>
                        <div className='border rounded'>
                            <div className=''>
                                <div className=''>
                                    <div className='m-2'>
                                        <img src={`data:image/png;base64,${element.ThumbnailImageString}`} alt='...' className='w-100' />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='p-2'>
                                        <div className='fw-bold '>{element.Service_Name}</div>
                                        <div>{element.content_expert.name} {element.content_expert.lname} </div>
                                        <div className='badge text-dark' style={{ backgroundColor: ' #abefc6' }}>{element.MentorshipType}</div>
                                        <div className=''><b>Total Time: </b>{element.Hour}h : {element.Minutes}m <div>
                                            {element.MentorshipType === 'Webinar Session' ?
                                                <div>

                                                    <div className=''>  <b>Date :</b> {element.start_date} </div>
                                                    <div className=''>  <b>Time :</b> {element.start_time} ({element.timezone})</div>
                                                </div>
                                                : ''}
                                            <StarRatings
                                                rating={Number(element.averageRating)}
                                                starDimension={'20px'}
                                                starRatedColor="#FDB022"
                                                numberOfStars={5}
                                            />
                                            <span className='me-4 ms-3 my-1'>{Number(element.averageRating).toFixed(1)}</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        }

        return list
    }


    const listOfReviews = () => {

        let list = []
        let dataArr = reviewsData
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            console.log(element);

            list.push(
                <div className='col-12'>
                    <div className='border rounded mb-3'>
                        <div className='row'>
                            <div className='col-1'>
                                <div className='text-center py-3 '>
                                    <div className='rounded-circle overflow-hidden text-center ms-3 ' style={{ width: '50px', height: '50px' }}>
                                        <img src={`data:image/png;base64,${element.content_expert.profile_string}`} alt='...' width={'100%'} height={'100%'} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-11'>
                                <div className=' py-3'>
                                    <div className='fw-bold '>{element.content_expert.name} {element.content_expert.lname}</div>
                                    <div>
                                        <StarRatings
                                            rating={Number(element.Ratting)}
                                            starDimension={'20px'}
                                            starRatedColor="#FDB022"
                                            numberOfStars={5}
                                        />
                                    </div>
                                    <div className='pe-5'>{element.comment}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )

        }

        return list

    }
    return (
        <>
            {isLoading ?
                <div className=''>
                    <div className='position-relative mb-3 overflow-hidden'>
                        <div className=' p-3' ref={reportTemplateRef} style={{ minWidth: '950px' }} >
                            <div className='container-md container-fluid' >
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className='text-start m-5'>
                                            <img src={logo} alt='...' width={170} height={40} />
                                        </div>
                                    </div>
                                    <div className='col-8'>
                                        <div className='ReportTitle'>
                                            <div className='text-center p-5'>
                                                Progress Report
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src={Rborder} alt='...' style={{ width: "100%" }} />
                                </div>
                                <div className='row'>
                                    <div className=' col-12'>
                                        <div className='row'>
                                            <div className='col-1  text-center ' >
                                                <div className='rounded-circle overflow-hidden my-5' style={{ width: '70px', height: '70px' }}>
                                                    <img src={`data:image/png;base64,${userDatas.profile_string}`} alt='...' width={'100%'} height={'100%'} />
                                                </div>
                                            </div>
                                            <div className='col-11'>

                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <div className='EmpInfo ms-5'>
                                                            <div className=' mt-5'>
                                                                <div className='row mb-3'>
                                                                    <div className='col-3 fw-bold '>Name :</div>
                                                                    <div className='col-9 ps-2'>{userDatas.name} {userDatas.lname}</div>
                                                                </div>
                                                                <div className='row mb-3'>
                                                                    <div className='col-3 fw-bold '>Mobile :</div>
                                                                    <div className='col-9 ps-2'>{userDatas.mobile}</div>
                                                                </div>
                                                                <div className='row mb-3'>
                                                                    <div className='col-3 fw-bold'>E-mail :</div>
                                                                    <div className='col-9 ps-2'>{userDatas.email}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='mt-5 text-end'>Date : {moment(new Date().toLocaleDateString()).format('DD/MM/YYYY')}</div>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr style={{ stroke: 'rgba(20, 19, 18, 0.10))', height: '2px', marginBottom: '40px' }} />
                                <div className='fw-bold fs-3 mb-3'>
                                    My Learning Progress
                                </div>
                                <div className='row mb-3'>

                                    {listOfServices()}
                                </div>
                                <div className='fw-bold fs-3 mb-3'>
                                    My Reviews
                                </div>
                                <div className='row'>
                                    {listOfReviews()}
                                </div>

                                <div className='mb-4'>
                                    <img src={Rborder2} alt='...' style={{ width: "100%" }} />
                                </div>
                                <div className='mb-5'>
                                    <div className='row'>
                                        <div className='col-6 text-center text-md-end'>
                                            <img src={Phone} alt='...' width={30} height={30} />
                                            <span className='fs-5 ms-3'>+44 7743 460591</span>
                                        </div>
                                        <div className='col-6 text-md-start text-center'>
                                            <img src={Mail} alt='...' width={30} height={30} />
                                            <span className='fs-5  ms-3'>kavish@xperthrivr.com</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='position-absolute top-0 start-0 bg-light ' style={{ width: '950px', height: '100%' }}>
                            
                        </div>
                        <div className='position-absolute top-0 start-0 bg-light '>
                            <div className='my-5'>
                                <div className='container'>
                                    <div className=' col-12 '>
                                        <div className='text-center' style={{ color: '#E57A4D' }}>
                                            <FontAwesomeIcon className='fa-2x' icon={faSpinner} spin /> <span className='fs-3 ms-4 fw-bold'>Loading...</span>
                        
                                        </div>
                                           <div className='mt-3 ms-lg-5 text-center text-success'> We are in the process of generating your personalized page. Please allow some time for this to complete.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''}
            {/* ---- only for view --- */}
            {isLoading ? '' :
                <div>

                    <div className='position-relative top-0 start-0' >
                        <img src={imageString} alt='...' width={'100%'} height={'100%'} />

                    </div>
                    <div className='my-5 text-center'>
                        <button className="btn btn-orange" onClick={handleGeneratePdf}>
                            Download
                        </button>
                    </div>
                </div>}



        </>
    )
}

export default ProgressReport
