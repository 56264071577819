import { React, Fragment } from 'react'
import "./MentorsCard.scss";
function MentorCard({ mentorsName, mentorsDescription, mentorsImage, sideBgColor = 'cardBg' }) {
  return (
    <Fragment>
      <div className='mentorsCard my-2'>
        <div className="card" style={{ width: '100%' }}>
          <div className={sideBgColor}>
          </div>
          <div className="card-body d-flex">
            <div>
              <div className='rounded-circle overflow-hidden' style={{ width: '70px', height: '70px' }}>
                <img src={mentorsImage} alt="" width={'100%'} height={'100%'} />
              </div>
            </div>
            <div className='card-text ms-4  m-lg-2'>
              <div className=' fw-bold'>{mentorsName} </div>
              <p className="" style={{ fontSize: '12px' }}>{mentorsDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MentorCard
