import React, { useState } from 'react'
import twitter from '../../../assets/twitter.png'
import facebook from '../../../assets/facebook.png'
import instagram from '../../../assets/instagram.png'
import linkedin from '../../../assets/linkedin.png'
import brandLogo from "../../../assets/Logo1.png"
import './Footer.Styles.scss'
import sms from "../../../assets/sms.png"
import validation from '../../Validations'
import apiservice from '../../../apiservice/ApiService'
import { Button, Modal, ModalHeader } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'



export default function Footersection(props) {
  let navigate = useNavigate
  const [email, setEmail] = useState('')
  let [showModal, setshowModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  let [modalMessage, setModalMsg] = useState('')

  const facebookUrl = 'https://www.facebook.com/people/XperThrivr/61553156529287';
  const twitterUrl = 'https://twitter.com/XperThrivr';
  const instagramUrl = 'https://www.instagram.com/xperthrivr';
  const linkedinUrl = 'https://www.linkedin.com/company/xperthrivr'
  const [isLoading, setIsLoading] = useState(false)
  const redirectToSocialMedia = (url) => {
    window.open(url, '_blank', 'noreferrer')
  };



  async function clickOnSubmitNewsLetter() {
    if (email === '') {
      setErrorMsg('Email is required!')

    } else if (!email.match(validation().emailVal)) {
      setErrorMsg('Email is not valid!')
    } else {

      setIsLoading(true)
      let reqData = {
        email: email
      }

      try {
        let subsToNewsLetter = await apiservice.apiCall('/Neweletter', null, reqData, 'POST')
        if (subsToNewsLetter.data) {
          setIsLoading(false)
          console.log(subsToNewsLetter.data);
          setSuccessMsg(subsToNewsLetter.data)
          setEmail('')
          setTimeout(() => {
            setSuccessMsg()
          }, 2000);
          setModalMsg('Subscribed Succesfully')
        }
        else {
          setIsLoading(false)
          setErrorMsg(subsToNewsLetter.error)
          setEmail('')
          setTimeout(() => {
            setErrorMsg()
          }, 2000);
          console.log(subsToNewsLetter.error);
          setModalMsg(subsToNewsLetter.error)
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error);
      }
    }

  }


  return (
    <div className='mt-5 footer-sec'>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>
          We will contact you shortly.
          <Button variant="primary" onClick={() => setshowModal(false)}>Ok</Button>
        </ModalHeader>
      </Modal>
      <div className='container'>
        <img src={brandLogo} className='ms-2 mt-3' onClick={() => props.navigate('/')} width={170} height={40} />
        <div className='row m-0'>
          <div className='col-lg-4'>
            <div className='my-4'>
             <p>At XperThrivr, we empower individuals to extract meaningful learnings from their journey (career, business, life path, specific project, etc.) and turn those learnings into valuable 1:1 sessions. Enabling individuals to share their knowledge and helping others achieve success. </p>
             <p>Together, let’s empower others! </p>
            </div>


          </div>
          <div className='col-lg-3'>
            <div className='my-lg-2 text-lg-center text-start fs-5 fw-bold mb-3'>Quick Links</div>
            <div className='ms-lg-5 text-lg-start text-start'>
              <div className='d-flex ms-2' onClick={() => props.navigate('/aboutus')} ><div className='ms-xl-5 me-2 mb-2' style={{ cursor: 'pointer' }} >&#x25CF;</div><div style={{ cursor: 'pointer' }}>About Us</div></div>
              <div className='d-flex ms-2' onClick={() => props.navigate('/privacy')}><div className='ms-xl-5 me-2 mb-2' style={{ cursor: 'pointer' }} >&#x25CF;</div><div style={{ cursor: 'pointer' }}>Privacy Policy</div></div>
              <div className='d-flex ms-2' onClick={() => props.navigate('/contactus')}><div className='ms-xl-5 me-2 mb-2' style={{ cursor: 'pointer' }}>&#x25CF;</div><div style={{ cursor: 'pointer' }}>Contact Us</div></div>
              <div className='d-flex ms-2' onClick={() => props.navigate('/termsandcondition')}><div className='ms-xl-5 me-2 mb-2' style={{ cursor: 'pointer' }}>&#x25CF;</div><div style={{ cursor: 'pointer' }}>Terms & Conditions</div></div>
            </div>
          </div>
          <div className='col-lg-5 pe-5'>
            <div className='my-2 fs-5 fw-bold ms-lg-5 ms-0'>Newsletter</div>
            <div className=' ms-lg-5 ms-0'>
              Subscribe to get the latest updates on our news, services, and access to exclusive insider information and resources
            </div>

            <div className="input-group mb-3 mt-3 border rounded bg-dark p-1 ms-lg-5 ms-0">
              <img src={sms} alt="" width={20} height={20} className='m-2' />
              <input type="email" value={email} onChange={(e) => { setEmail(e.target.value); setErrorMsg(''); }} className="form-control border-0 text-light bg-dark" placeholder="Enter your email" aria-label="Enter your email" aria-describedby="button-addon2" />
              <button className="btn btn-light border-0 rounded btnM" onClick={clickOnSubmitNewsLetter} type="button" id="button-addon2" disabled={isLoading ? true : false}>{isLoading ? 'Loading...' : 'Submit'}</button>
            </div>
            <div className=" p-1 ms-lg-5 ms-0">

              <small className='text-danger'>{errorMsg}</small>
              <small className='text-success'>{successMsg}</small>
            </div>
            <div className='pb-2 ms-lg-5 d-none d-lg-block'>
              <img src={twitter} className='me-2' target='_blank' width={48} height={48} onClick={() => redirectToSocialMedia(twitterUrl)} type='button' />
              <img src={facebook} className='me-2' target='_blank' width={48} height={48} onClick={() => redirectToSocialMedia(facebookUrl)} type='button' />
              <img src={instagram} className='me-2' target='_blank' width={48} height={48} onClick={() => redirectToSocialMedia(instagramUrl)} type='button' />
              <img src={linkedin} className='me-2' target='_blank' width={48} height={48} onClick={() => redirectToSocialMedia(linkedinUrl)} type='button' />
            </div>
            <div className='pb-2 ms-lg-5 d-lg-none d-block'>
              <img src={twitter} className='me-2' target='_blank' width={32} height={32} onClick={() => redirectToSocialMedia(twitterUrl)} type='button' />
              <img src={facebook} className='me-2' target='_blank' width={32} height={32} onClick={() => redirectToSocialMedia(facebookUrl)} type='button' />
              <img src={instagram} className='me-2' target='_blank' width={32} height={32} onClick={() => redirectToSocialMedia(instagramUrl)} type='button' />
              <img src={linkedin} className='me-2' target='_blank' width={32} height={32} onClick={() => redirectToSocialMedia(linkedinUrl)} type='button' />
            </div>
          </div>

          <div className='my-lg-5 my-0'>
            <hr style={{
              height: "2px",
              backgroundColor: "#000",
              stroke: "#000"
            }} />
            <div className='text-center mb-3'><p>Copyright @2024 XperThrivr. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
