import React from 'react'
import topImage from '../../../assets/supportImage.png'
import './SupportPage.scss'
function SupportPage() {
    return (
        <>
            <div className='p-3 support_container'>
                <div className='container-fluid'>
                    <div className='fw-bold Title d-none d-lg-block'>Support</div>
                    <div className='fw-bold Title d-lg-none d-block text-center'>Support</div>
                </div>
                <div className='text-center mt-5'>
                    <img src={topImage} className='img-fluid' alt='..' />
                </div>
                <div className='mt-5'>
                    <div className="form-group">
                        <p type="text" className="form-control pb-lg-5 text-support">
                            Your feedback matters! Whether you're seeking guidance on anything from navigating courses to seeking clarification on any course-related matter, our team is here to help! Please email us at  <span className='text-decoration-none' ><a href="mailto:kavish@xperthrivr.com?subject=&body=">kavish@xperthrivr.com</a></span>  or explore our FAQs for immediate solutions.
                        </p>

                    </div>
                </div>
                <div className='mt-3 text-center'>
                    <button className='btn btn-light py-2' onClick={() => window.location = "mailto:kavish@xperthrivr.com?subject=&body="} style={{ cursor: 'pointer' }}>Get in touch</button>
                </div>
            </div>
        </>
    )
}

export default SupportPage
