import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import Slider from 'react-slick'
import "./MyReviewPage.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { useState } from 'react';
import '../../../components/MentorsComponents/MentorCard/MentorsCard.scss';
import StarRatings from 'react-star-ratings';

import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'

import { Pagination } from 'react-bootstrap';
import IndustryArray from './../../../components/MentorsComponents/IndustryArray/IndustryArray';
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';

function MyReviewPage(props) {
    let navigate = useNavigate()
    const initialData = {
        industryType: '',
        searchValue: ''
    }
    const [settings, setSettings] = useState({})
    const [activeIndustry, setActiveIndustry] = useState('Accounting')
    let [myreviews, setMyreviews] = useState([])

    const [data, setData] = useState(initialData)
    const [searchResult, setSearchResult] = useState([])
    const [isSearch, setIsSearch] = useState(false)

    let [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setActiveIndustry('Accounting')
        if (isMobile) {

            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            )
        }
        if (isTablet) {

            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            )
        }
        if (isDesktop) {

            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            )
        }
    }, [])
    const onChangeHandle = async (e) => {
        const { name, value } = e.target
        setIsSearch(false)
        setData({ ...data, [name]: value })
    }

    useEffect(() => {
        getReviews('Accounting')
    }, [])

    async function getReviews(data) {
        console.log(data);
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userData');
        console.log(udata);
        await apiservice.apiCall(`/LearnerReviewShow`, udata.Token, { industry: data }, "POST").then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setMyreviews(res.data)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const onClickIndustryMenu = (data) => {
        // alert(data.name)
        getReviews(data.name)
        setActiveIndustry(data.name)
    }



    const industryListing = () => {
        let array = IndustryArray()
        let list = []

        for (let index = 0; index < array.length; index++) {
            let data = array[index]
            list.push(
                <div className={activeIndustry === data.name ? 'text-center border-bottom border-dark pointer' : 'text-center pointer'} key={index} onClick={() => onClickIndustryMenu(data)}>
                    <div className='py-3 text-center'>
                        <div className='m-auto text-center industry_list_icon'>
                            <img src={data.icon} className='' alt='...'  />
                        </div>
                        <div className='text-center text-break mt-3' style={{ fontSize: '11px' }}> {data.name}</div>
                    </div>
                </div>
            )

        }
        return list
    }


    const sampleData = isSearch ? searchResult : myreviews
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(sampleData.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = sampleData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);




    const listOfReviews = () => {
        let array = itemsToDisplay
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let data = array[index]
            console.log(data);
            list.push(
                <div className={count === 0 ? 'col-md-6 col-12 card_bortder border-end' : count === 1 ? 'col-md-6 col-12 card_bortder ' : count === 2 ? 'col-md-6 col-12 card_bortder border-top' : 'col-md-6 col-12 card_bortder border-top border-start'}
                    key={index}>
                    <div className='mt-3 p-3'>
                        <div className='d-flex'>
                            <div className=' '>
                                <div className='rounded-circle overflow-hidden' style={{ width: '70px', height: '70px' }}>
                                    <img src={data.learner.ProfileImage === null ? data.learner.SocialImage === null ? data.learner.profile_photo_url : data.learner.SocialImage : `${apiservice.apiImageShow()}/${data.learner.ProfileImage}`} width={'100%'} height={'100%'} alt='' />
                                </div>
                            </div>
                            <div className='mt-3 ms-3'>
                                <div className='fw-bold'>{data.learner.name} {data.learner.lname}</div>
                                <div>
                                    <StarRatings
                                        rating={Number(data.Ratting)}
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <p>{data.comment}</p>
                        </div>
                    </div>
                </div>
            )
            if (count === 3) {
                count = 0
            } else {
                count++
            }

        }
        return list
    }

    const onClickSearch = async () => {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        let dataObject = {
            value: data.searchValue
        }
        await apiservice.apiCall('/LearnerReviewShow', uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setSearchResult(res.data)
                setIsSearch(true)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        })
    }
    return (
        <>

            <div>
                <div className='p-3'>
                    <div className='fw-bold Title'>My Reviews</div>
                    <div className='mt-3'>
                        <div className="col-lg-8 col-12">
                            <div className="input-group mb-3 border rounded-3 border-dark ">
                                <input type="text" onChange={onChangeHandle}
                                    className="form-control border-0 bg-transparent" name='searchValue' value={data.searchValue} placeholder='Search Learner' />
                                <span className="input-group-text border-0 bg-transparent"> <button className='btn btn-search-btn-blue' onClick={onClickSearch}><FontAwesomeIcon icon={faSearch}  /></button></span>
                            </div>
                        </div>
                    </div>
                    {isSearch ? '' :
                        <div className='mt-3 px-3 border-bottom'>
                            <Slider className='px-2'  {...settings}>

                                {industryListing()}

                            </Slider>
                        </div>}
                    {isLoading ? <Loader /> :
                        <div className=''>

                            <div>
                                {sampleData.length > 0 ?
                                    <div className='my-3'>
                                        <div className=' row'>

                                            {listOfReviews()}
                                        </div>
                                        <div className="pagination-container mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Pagination>
                                                <Pagination.Prev
                                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                                    onClick={handlePreviousPage}
                                                    disabled={currentPage === 1 ? true : false}
                                                >
                                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                                </Pagination.Prev>
                                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                                    <Pagination.Item
                                                        key={pageRangeStart + offset}
                                                        active={pageRangeStart + offset === currentPage}
                                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                                    >
                                                        {pageRangeStart + offset}
                                                    </Pagination.Item>
                                                ))}
                                                {pageRangeEnd < totalPages && (
                                                    <Pagination.Ellipsis disabled />
                                                )}
                                                <Pagination.Next
                                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages ? true : false}
                                                >
                                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                                </Pagination.Next>
                                            </Pagination>
                                        </div>
                                    </div>
                                    :
                                    <div className='mt-5 text-center text-danger'> Every session deserves a happy clap! The more positive feedback you collect, the more learners you'll attract!</div>
                                }
                            </div>
                        </div>}
                </div>
            </div>
        </>
    )
}

export default MyReviewPage
