import React, { Fragment, useEffect } from "react";
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from "../../../components/LandingpageComponents/Footer/Footer";
import star from "../../../assets/smallStar.png";
import waterSplash from "../../../assets/waterSplash.png";
import heroImg from "../../../assets/heroImg.png";
import totalStudImg from "../../../assets/totalStudImg.png";
import mentorImg from "../../../assets/mentorImg.png";
import noImg from "../../../assets/no-image.png"
import { HiArrowLongRight } from "react-icons/hi2";
import testimonialImg from "../../../assets/testimonialImg.png";
import MentorCard from "../../../components/LandingpageComponents/MentorsCard/MentorCard";
import "./LandingPage.Styles.scss";
import StarRatings from "react-star-ratings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { isMobile, isDesktop, isTablet } from "react-device-detect";
import deliveryImg1 from '../../../assets/deliveryWaysImg1.png'
import deliveryImg2 from '../../../assets/deliveryWaysImg2.png'
import deliveryImg3 from '../../../assets/deliveryWaysImg3.png'
import olCourseBuilder from "../../../assets/onlCourseBuilder.png"
import oneOnOneSessionImg from '../../../assets/oneOnOneSec.png'
import hostLiveWebinar from '../../../assets/HostLiveWebinar.png'
import StartHollow from "../../../assets/startHollow.png"
import careerProf from '../../../assets/careerProfessionals.png'
import contentCreator from '../../../assets/contentCreators.png'
import OnlineBiz from '../../../assets/onlineBusiness.png'
import bluebg from '../../../assets/rectangleDiagonal.png'
import apiservice from "../../../apiservice/ApiService";
import { useNavigate } from "react-router-dom";
// import ScrollAnimation from "react-animate-on-scroll";

function LandingPage(props) {
  let navigate = useNavigate()
  const [questionNumber, setQuestionNumber] = useState(0);
  let [mentors, setMentors] = useState([])
  let [searchItem, setSearchItem] = useState('')
  let [testimonialData, setTestiData] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const onChageMenu = (e) => {
    localStorage.setItem('curMenu', e)
    console.log(e);
    navigate(e)
    // setData({ ...data, 'currentMenu': localStorage.getItem('curMenu') })
  }

  async function getMentors() {
    setIsLoading(true)
    try {
      let allmentors = await apiservice.apiCall('/ContentExpertListFavorite')
      if (allmentors.data) {
        setIsLoading(false)
        console.log(allmentors.data);
        setMentors(allmentors.data)
      }
      else {
        setIsLoading(false)
        console.log(allmentors.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  function updateSlides() {
    if (window.innerWidth >= 992) {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      });
    } else if (window.innerWidth >= 576) {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
      });
    } else {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }
  }



  useEffect(() => {
    const handleResize = () => {
      updateSlides();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])

  useEffect(() => {
    getMentors()
    localStorage.setItem('curMenu', 'xt')
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')
  }, [])


  async function showMentorData(item) {
    console.log(item, '---------------------');
    await apiservice.setLocalStorage('mentorData', item)
    // navigate('/contentexpert')
    navigate(`/contentexpert/${item.content_experts_id}`)
  }
  // ----------Slider function--------------
  const [settings, setSettings] = useState({});

  async function getTestimonialData() {
    setIsLoading(true)
    try {
      let testiData = await apiservice.apiCall('/TestimonalList')
      console.log('Testimonials', testiData);
      if (testiData.data) {
        setIsLoading(false)
        setTestiData(testiData.data)
      }
      else {
        setIsLoading(false)
        console.log(testiData.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      });
    } else if (window.innerWidth >= 576) {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
      });
    } else {
      setSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }
    getTestimonialData()

  }, []);

  const onClickQuetions = (e) => {
    setQuestionNumber(e);
  };


  function getCard() {
    let list = [];
    let count = 0;
    let noOfCards = 0
    for (let i = 0; i < mentors.length; i++) {
      let item = mentors[i]
      console.log(item);
      if (item.is_admin_verified === 1 && item.isVerified === 1 && i < 5) {
        list.push(
          <MentorCard
            key={i}
            onClick={() => showMentorData(item)}
            mentorsName={`${item.name} ${item.lname}`}
            sideBgColor={`cardBg${count}`}
            mentorsImage={item.ProfileImage === null ? item.SocialImage === null ? item.profile_photo_url : item.SocialImage : `${apiservice.apiImageShow()}/${item.ProfileImage}`}
            mentorsDescription={item.shortDescription}
            mentorDesignation = {`${item.lastDesignation} @ ${item.company}`}
          />
        );
      }
      if (count === 5) {
        count = 0;
      } else {
        count++;
      }
    }

    return list;
  }
  const colors = ["#ADD6DA", "#FDDCAB", "#ABEFC6", "#BDB4FE", "#53B1FD"];
  const testimonialsCard = () => {
    let list = [];

    for (let index = 0; index < testimonialData.length; index++) {
      let item = testimonialData[index]
      console.log(item);
      if (item !== null) {


        list.push(
          <div key={index} className="col-lg-4 col-sm-12 col-md-6 p-2 h-100 testimonialAtLanding">
            <div
              className="card p-4 align-self-center"
              style={{
                width: "auto",
                backgroundColor:
                  colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              <div className="card-body rounded-1 p-22 bg-white " >
                <div className="d-lg-flex d-block text-lg-start text-center py-3">
                  <img src={item.ProfileImage === null || item.ProfileImage === '' || item.ProfileImage === undefined ? noImg : `${apiservice.apiImageShow()}/${item.ProfileImage}`} className=" rounded-circle" width={80} height={80} alt="" />
                  <div className=" align-self-center ps-3">
                    <p
                      className="p-0 m-0 smallSubTitle text-break"
                    >
                      {item.Name}
                    </p>
                    <StarRatings
                      rating={Number(item.Ratting)}
                      starDimension="20px"
                      starRatedColor="#FDB022"
                      numberOfStars={5}
                    />
                  </div>
                </div>
                <p className=""  >

                  {item.Description}
                </p>
              </div>
            </div>
          </div>
        );
      }
    }
    return list;
  };
  const withoutSlidertestimonialsCard = () => {
    let list = [];

    for (let index = 0; index < testimonialData.length; index++) {
      let item = testimonialData[index]
      console.log(item);
      if (item !== null) {
        if (index < 3) {

          list.push(
            <div key={index} className="col-lg-4 col-sm-12 col-md-6 p-2 h-100 testimonialAtLanding">
              <div
                className="card p-3 align-self-center"
                style={{
                  width: "auto",
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                }}
              >
                <div className="card-body rounded-1 p-22 bg-white " >
                  <div className="d-lg-flex d-block text-center py-3">
                    <img src={item.ProfileImage === null || item.ProfileImage === '' || item.ProfileImage === undefined ? noImg : `${apiservice.apiImageShow()}/${item.ProfileImage}`} className=" rounded-circle" width={80} height={80} alt="" />
                    <div className=" align-self-center ps-3">
                      <div
                        className="p-0 m-0  text-break"
                        style={{ fontSize: "16px" }}
                      >
                        {item.Name}
                      </div>
                      <StarRatings
                        rating={Number(item.Ratting)}
                        starDimension="20px"
                        starRatedColor="#FDB022"
                        numberOfStars={5}
                      />
                    </div>
                  </div>
                  <p className="card-text" >

                    {item.Description}
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    return list;
  };
  const onCLickExplore = () => {
    localStorage.setItem('isFilter', 'inactive');
    localStorage.setItem('indusrtyType', 'Accounting')
    navigate("/services")
  }




  return (
    <Fragment>
      <Header navigate={navigate} setSearchItem={setSearchItem} />

      {/* ------main Section--------- */}
      <section className=" main-page-container"  >
        <div className="position-relative">
          <div className="forBgDigonalRect d-lg-block d-none">
            <img src={bluebg} className="w-100" alt="..." />
          </div>
          <article className="container">
            <div className="row m-0 " >
              <div className="col-lg-6 col-12 align-self-center">
                <div className="mt-4">
                  <img
                    src={star}
                    height={32}
                    width={32}
                    className="my-2 m-0 starAnimatin"
                    alt=""
                  />{" "}
                  <span className="mx-1 my-2 m-0">Welcome</span>
                </div>
                <div className=" position-relative">
                  <img
                    src={waterSplash}
                    width={88}
                    height={91}
                    className="splash-img d-lg-block d-none"
                    alt=""
                  />
                </div>
                <div className="headTextLarge mb-4">
                Empowering Individuals. Together.
                </div>

                {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}

                <div className="smallText ">
                  <div className="d-flex align-items-center mb-2"> <img src={careerProf} className="me-3" width={62} height={46} alt="" />  <p><span className="fw-medium pointer" onClick={() => onChageMenu('/xtForcareer')}>Career professional</span> - Extract meaningful learnings from your career journey and turn them into 1:1 mentorship sessions for others to benefit from. </p> </div>

                  <div className="d-flex align-items-center mb-2"> <img src={contentCreator} className="me-3" width={62} height={46} alt="" />  <p><span className="fw-medium pointer" onClick={() => onChageMenu('/forContentCreator')}>Content creator </span>- Turn your passion into self-paced courses, 1:1 coaching sessions, or live workshops.</p> </div>

                  <div className="d-flex align-items-center mb-3"> <img src={OnlineBiz} className="me-3" width={62} height={46} alt="" />  <p><span className="fw-medium pointer" onClick={() => onChageMenu('/xtforbuizz')}>Online business</span> - Monetise the full aspect of your expertise. Schedule, host and get paid for your 1:1 consultation sessions and online classes.  </p> </div>
                </div>
                {/* </ScrollAnimation> */}
                <button
                  type="button"
                  onClick={onCLickExplore}
                  className="btn btn-primary border-0 px-4 py-2 mb-2  btnM pointer"
                  style={{ backgroundColor: "#E57A4D" }}
                >
                  Explore
                </button>
                {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}
                <div className=" my-4" >
                  <img src={totalStudImg} className="d-none d-lg-block" alt="" />
                  <img src={totalStudImg} className="d-lg-none d-block" alt="" width={'40%'} />
                </div>
                {/* </ScrollAnimation> */}
              </div>
              <div className="col-lg-6 col-12 z-1 p-0 m-0 align-self-center h-100 ">
                {/* <ScrollAnimation animateIn='animate__fadeInRight' > */}
                <img src={heroImg} className=" w-100" alt="" />
                {/* </ScrollAnimation> */}
              </div>
            </div>
          </article>


          {/* ------------Ways to deliver the product--------------- */}

          {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
          <article className="container sliderItem ">
            <div className="mb-3 pt-3"><h4 className="fw-semibold text-center">Three ways we deliver</h4></div>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators me-5">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active roundedBtn " aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className=" roundedBtn  " aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className=" roundedBtn " aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card border-3 w-100 bg-transparent border-white " >
                    <div className="row m-0 py-3 px-lg- ">
                      <div className="col-lg-6 text-center">
                        <img src={deliveryImg1} className="rounded-1 deliveryWays " alt="" />
                      </div>
                      <div className="col-lg-6 col-12 px-lg-5 px-4 d-flex flex-column justify-content-center">
                        <div className="d-flex py-3 align-items-center">
                          <img src={olCourseBuilder} width={58} height={54} alt="" /> <h2 className="px-3" >Online course builder</h2>
                        </div>
                        <p>
                          From facilitating 1:1 technical interview prep to delivering fitness classes, our course builder lets you schedule structured sessions with flexible durations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="card border-3 w-100 bg-transparent border-white " >
                    <div className="row m-0 py-3 px-lg- ">
                      <div className="col-lg-6 text-center">
                        <img src={deliveryImg2} className="rounded-1 deliveryWays " alt="" />
                      </div>
                      <div className="col-lg-6 col-12 px-lg-5 px-4 d-flex flex-column justify-content-center">
                        <div className="d-flex py-3 align-items-center">
                          <img src={oneOnOneSessionImg} width={58} height={54} alt="" /> <h2 className="px-3" >1:1 single and multi-sessions</h2>
                        </div>
                        <p>
                          We offer single and multi-sessions options, including one-on-one sessions and online sessions, to deliver the best possible learning experience for you.                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="carousel-item">
                  <div className="card border-3 w-100 bg-transparent border-white " >
                    <div className="row m-0 py-3 px-lg- ">
                      <div className="col-lg-6 text-center">
                        <img src={deliveryImg3} className="rounded-1 deliveryWays " alt="" />
                      </div>
                      <div className="col-lg-6 col-12 px-lg-5 px-4 d-flex flex-column justify-content-center">
                        <div className="d-flex py-3 align-items-center">
                          <img src={hostLiveWebinar} width={58} height={54} alt="" /> <h2 className="px-3" >Host live webinar sessions</h2>
                        </div>
                        <p>
                          Host webinars: Deliver talks, lectures, lead discussions with a wider audience. Offer customised sessions in a collaborative and engaging group setting.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          {/* </ScrollAnimation> */}


          {/* ------------our Mentor's section--------------- */}

          {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
          <article className="container py-5 ourMentorSection ">
            <div className="row m-0" style={{ height: 'max-content' }}>
              <div className="col-lg-4 align-self-center">
                {/* <p className="smallSubTitle m-0 p-0">Our Content Expert</p> */}
                <h4 class="mdTitleText mb-4">Greatest Content Expert Inspire</h4>
                <div className="d-flex">
                  <div>
                    <button
                      onClick={onCLickExplore}
                      type="button"
                      className="btn btn-primary px-4 py-2 border-0 mb-3 btnM"
                      style={{ backgroundColor: "#E57A4D", width: 'max-content' }}
                    >
                      Explore All Content Experts
                    </button>
                  </div>
                  <div className=" ms-xl-5 " style={{ marginTop: '-80px' }}>
                    <img src={StartHollow} width={80} height={80} className="d-lg-block d-none start_splash_img" />
                  </div>
                </div>
              </div>
              {getCard()}
            </div>
          </article>
          {/* </ScrollAnimation> */}

          {/* <*---------Testimonials Section---------*> */}
          <article className="container-fluid bg-white p-4 ">
            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
            <article className=" container">
              <div className="py-3">
                <p className="mdTitleText  text-center p-0 m-0">Testimonial</p>
                {/* <h4 className="fw-bold fs-3 mdTitleText text-center">
                  People Enjoy Using XperThrivr
                </h4> */}
              </div>
              {testimonialData.length > 2 ?
                <Slider {...settings}>
                  {testimonialsCard()}
                </Slider> :
                <div className="row m-0">
                  {withoutSlidertestimonialsCard()}
                </div>}

            </article>

            {/* </ScrollAnimation> */}
          </article>

          {/* <*--------- FAQs Section---------*> */}
          <article className="container">
            <div className="row m-0 my-lg-5 py-lg-5">
              <div className="col-lg-3 col-12">
                {/* <p className="smallSubTitle">Top Questions</p> */}

              </div>
              {/* <ScrollAnimation animateIn='animate__zoomIn' > */}


              <div className="col-lg-12 col-12 ">
                <div>
                  <div className="mb-2">
                    <h4 className="fw-bold fs-3 mdTitleText mt-5 text-center">
                      Frequently Asked Questions
                    </h4>
                  </div>


                  <div className="mt-5">
                    <div
                      className="accordion "
                      // style={{--bs-body-bg:"transperent"}}
                      id="accordionExample"
                    >
                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingOne"
                        >
                          <button
                            onClick={() => onClickQuetions(1)}
                            className="accordion-button collapsed pe-5 "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 1
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  1
                                </div>
                                <div className="col-10  pt-1 acTitle">What is XperThrivr raison d'être?</div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body bg-transparent">
                            <p><b>For content experts:</b> XperThrivr enables you to monetise your knowledge and expertise;
                              and to offer coaching and mentorship through 1:1 sessions.
                              Set up your profile and access your dashboard - add your sessions, generate your profile link;
                              and share it with your network of learners and followers.
                            </p>
                            <p><b>For Learners:</b> XperThrivr provides you with personalised access to a vast
                              network of content experts and industry leaders who are keen on helping you to thrive in your relevant field.
                              Tap into their wealth of experience and obtain hands-on skill in a quasi-familial relationship through 1:1 sessions.
                            </p>

                          </div>
                        </div>
                      </div>


                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingTwo"
                        >
                          <button
                            onClick={() => onClickQuetions(2)}
                            className="accordion-button collapsed pe-5"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 2
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  2
                                </div>
                                <div className="col-10  pt-1 acTitle">
                                  Do I have to pay for the expert sessions?
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p> We enable both free and paid sessions. How much the content experts will charge really depends on the perceived value and how much are the learners and followers willing to pay.
                            </p>
                          </div>
                        </div>
                      </div>



                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingSeven"
                        >
                          <button
                            onClick={() => onClickQuetions(3)}
                            className="accordion-button collapsed pe-5"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 3
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  3
                                </div>
                                <div className="col-10  pt-1 acTitle">
                                  How does XperThrivr offer a personalised learning experience for its learners?
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p><b>Your learning journey begins as follows:</b></p>
                            <p><b>a.</b> Once you book your session, you will be connected to the content expert from your
                              dashboard.</p>
                            <p><b>b.</b> Our in-built chat feature will be enabled on your dashboard, through which our content
                              expert will reach out directly to you to schedule the session at your convenient time and
                              date.</p>
                            <p><b>c.</b> Use our chat feature to discuss your learning objective and the content of the session
                              beforehand with the content expert. Dive in the session with confidence!</p>
                            <p><b>d.</b> We automatically send the calendar invite and email notifications to both of you, making
                              scheduling seamless and effortless.</p>

                          </div>
                        </div>
                      </div>


                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingSix"
                        >
                          <button
                            onClick={() => onClickQuetions(4)}
                            className="accordion-button collapsed pe-5"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 4
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  4
                                </div>
                                <div className="col-10  pt-1 acTitle">
                                  How sessions can be structured?
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p><b>The sessions can be formatted as follows:</b></p>
                            <p><b>Single session:</b> Single 1:1 session is ideal for receiving focused support and guidance on a particular area without the commitment of multiple sessions.</p>
                            <p><b>Multi-session:</b> Multiple session allows learners to master intricate skills and to dive deep into complex topics that requires more than one session.</p>
                            <p><b>Webinar session:</b> Webinar is available for content experts that allows them to reach a wider audience. They can set the number of participants, select the time and date; and XperThrivr will generate the webinar link that can be shared with the participants ahead of the webinar.</p>

                          </div>
                        </div>
                      </div>

                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingFive"
                        >
                          <button
                            onClick={() => onClickQuetions(5)}
                            className="accordion-button collapsed pe-5"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 5
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  5
                                </div>
                                <div className="col-10  pt-1 acTitle">
                                  How do I enroll on XperThrivr as a learner?
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p><b>As a learner, you can access various content expert’s sessions by following these steps:</b></p>
                            <div className="ms-3">
                              <p>
                                <b> a.</b> Sign up for an XperThrivr account to access your learning dashboard.
                              </p>
                              <p>
                                <b> b.</b> Search by your category of industry or browse the various content experts to view their service offerings.
                              </p>
                              <p>
                                <b>c.</b> Enrol in 1:1 sessions with content experts that aligns with your learning objectives.
                              </p>
                              <p>
                                <b> d.</b> Generate your personalised progress report that captures all your completed sessions and accomplishment highlights from content experts.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="accordion-item bg-transparent">
                        <h2
                          className="accordion-header bg-transparent"
                          id="headingThree"
                        >
                          <button
                            onClick={() => onClickQuetions(6)}
                            className="accordion-button collapsed pe-5"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className={
                                    questionNumber === 6
                                      ? "col-2 rounded-circle pt-2 text-center active-circle"
                                      : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                  }
                                  style={{ width: "35px", height: "35px" }}
                                >
                                  6
                                </div>
                                <div className="col-10  pt-1 acTitle">
                                  For content experts, is XperThrivr free to use?
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>Yes, Xperthrivr is free to use and we only charge a very small commission of 5% on your earnings to cover the operational cost of the website. For example, we partner with Paypal for secure payment that charges around 3% of transaction amount.
                            </p>
                          </div>
                        </div>
                      </div>



                    </div>







                  </div>
                </div>
              </div>
              {/* </ScrollAnimation> */}
            </div>
          </article>
          <Footersection navigate={navigate} />
        </div>
      </section>
    </Fragment>
  );
}

export default LandingPage;
