import React, { useEffect, useState } from 'react'
import './Homepage.scss'

import Header from './../../../components/MentorsComponents/Header/Header';
import Sidebar from './../../../components/MentorsComponents/Side-bar/Sidebar';
import LogoutPage from './../LogoutPage/LogoutPage';
import SupportPage from './../SupportPage/SupportPage';
import PaymentsPage from './../PaymentsPage/PaymentsPage';
import MyReviewPage from './../MyReviewPage.js/MyReviewPage';
import WriteReviewPage from './../WriteReview/WriteReviewPage';
import LearnerProfilePage from './../LearnerProfilePage/LearnerProfilePage';
import MyLearnersPage from './../MyLearnersPage/MyLearnersPage';
import MyServicesPage from '../MyBookingsPage/MyBookingsPage';
import ProfilePage from './../Profile/ProfilePage';
import MessagePage from './../Message/MessagePage';
import PreviewPage from './../PreviewPage/PreviewPage';
import CreateANewServices from './../CreateANewServices/CreateANewServices';
import NotificationPage from './../NotificationPage/NotificationPage';
import DashboardPage from '../DashboardPage/DashboardPage';
import Error404Page from '../../../components/MentorsComponents/Error404Page/Error404Page';
import ServicesList from '../MyServices/MyServices';
import LearnerListForSchedule from '../LearnerListForSchedule/LearnerListForSchedule';
import HistoryList from '../LearnerListForSchedule/HistoryList';
import JoinMeeting from '../JoinMeeting/JoinMeeting';
import AnnounceMyPage from '../AnnounceMyPage/AnnounceMyPage';
import routeProtect from '../../../components/routerProtect';
import { Route, Routes, useNavigate } from 'react-router-dom';



export default function HomePage(props) {
  let navigate = useNavigate()
  const [currentPathName, setCurrentPathName] = useState('')
  useEffect(() => {
    let pathName = window.location.pathname
    console.log(pathName);
    setCurrentPathName(pathName)
  })

  return (
    <>
      {!routeProtect() ? navigate('/') :
        <div className='mainpage-container'>
          <div className='container-xl container-fluid'>
            <div className='row'>
              <div className='col-3 d-xl-block d-none'>
                <Sidebar navigate={navigate} currentPath={currentPathName} />
              </div>
              <div className='col-12 d-xl-none d-block p-0'>
                <Header navigate={navigate} currentPath={currentPathName} />
              </div>
              <div className='col-xl-9 col-12 main-container'>
                <Routes>
                  <Route path='/joinmeet/:id' element={<JoinMeeting />} />
                  <Route path='/logout' element={<LogoutPage />} />
                  <Route path='/supports' element={<SupportPage />} />
                  <Route path='/payments' element={<PaymentsPage />} />
                  <Route path='/myreviews' element={<MyReviewPage />} />
                  <Route path='/writereview/:id' element={<WriteReviewPage />} />
                  <Route path='/learnerprofile/:id' element={<LearnerProfilePage />} />
                  <Route path='/historylist/:id' element={<HistoryList />} />
                  <Route path='/learnerlistforschedule/:id' element={<LearnerListForSchedule />} />
                  <Route path='/mylearners' element={<MyLearnersPage />} />
                  <Route path='/myservices' element={<MyServicesPage />} />
                  <Route path='/servicelist' element={<ServicesList />} />
                  <Route path='/announcemypage' element={<AnnounceMyPage />} />
                  <Route path='/profile' element={<ProfilePage />} />
                  <Route path='/message' element={<MessagePage />} />
                  <Route path='/previewservice' element={<PreviewPage />} />
                  <Route path='/createnewservices' element={<CreateANewServices />} />
                  <Route path='/notification' element={<NotificationPage />} />
                  {/* <Route path='/contetexpert-home' element={<DashboardPage />} /> */}
                  <Route path='/' element={<DashboardPage />} />
                  {/* <Route component={Error404Page} /> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
