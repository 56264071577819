import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import './DashboardPage.scss'
import Alt_Arrow_Down from '../../../assets/Alt_Arrow_Down.png'
import bell_icon from '../../../assets/bell.png'
import ServicesCard from '../../../components/LearnersComponents/ServicesCard.js/ServicesCard'
import card_top_img from '../../../assets/card-top-image.png'
import MentorCard from '../../../components/LearnersComponents/MentorCard/MentorCard'

import noImg from '../../../assets/no-image.png'
import coverImage from "../../../assets/Learner's dashboard cover image 1.png"
import apiservice from '../../../apiservice/ApiService'
import IndustryArray from '../../../components/MentorsComponents/IndustryArray/IndustryArray'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'

export default function DashboardPage(props) {
  let navigate = useNavigate()
  const [activeMenu, setActiveMenu] = useState('All')
  let [services, setServices] = useState([])
  let [mentorsList, setMentorsList] = useState([])
  const [industryArr, setIndustryArr] = useState([])
  const [searchType, setSearchType] = useState('Accounting')
  const [searchinp, setSearchInp] = useState('')

  const [completedProfile, setcompletedProfile] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [notificationCount, setNotificationCount] = useState(0)

  const [isServiceLoading, setIsServiceLoading] = useState(false)

  async function getAllServices() {
    setIsServiceLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    let allServices = await apiservice.apiCall('/ServiceList', uData.Token)
    console.log(allServices);
    try {
      if (allServices.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (allServices.data) {
        setIsServiceLoading(false)
        console.log(allServices.data);
        setServices(allServices.data)
      }
      else {
        setIsServiceLoading(false)
        console.log(allServices.error);
      }
    } catch (error) {
      setIsServiceLoading(false)
      console.log(error);
    }
  }


  async function getAllMentors() {
    setIsLoading(true)
    try {
      let uData = await apiservice.getLocalStorage('userData')
      let allmentors = await apiservice.apiCall('/Learner/ContentExpertList', uData.Token)
      console.log(allmentors);
      if (allmentors.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (allmentors.data) {
        setIsLoading(false)
        console.log(allmentors.data);
        setMentorsList(allmentors.data)
      }
      else {
        setIsLoading(false)
        console.log(allmentors.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }


  useEffect(() => {
    getAllServices()
    getAllMentors()
    getUserDataFromLocalStorage()
    getNotificationCount()
    let industryArray = IndustryArray()
    setIndustryArr(industryArray)
    console.log(services);
  }, [])

  const getUserDataFromLocalStorage = async () => {
    let uData = await apiservice.getLocalStorage('userData')
    console.log(uData);
    if (uData.AboutSelf === null ||
      uData.email === null ||
      uData.mobile === null ||
      uData.Birthdate === null
    ) {
      setcompletedProfile(true)
    } else {
      setcompletedProfile(false)
    }
  }

  const getNotificationCount = async () => {
    let userData = await apiservice.getLocalStorage('userData')
    await apiservice.apiCall('/learner_notifications_count', userData.Token)
      .then(res => {
        if (res.unAuthenticated) {
          navigate('/')
          localStorage.removeItem('userData')
        } else if (res.data) {
          console.log(res.data);
          setNotificationCount(res.data.notificationCount)
        } else {
          console.log(res);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }


  const onClickManu = (e) => {
    setActiveMenu(e)
  }

  function clickedOnAllCourses() {
    navigate('/learner-home/allcourses')
  }

  function clickedOnAllMentors() {
    navigate('/services')
  }

  function clickedOnSingleService(item) {
    navigate(`/servicedetail/${item.Service_id}`)
  }

  async function clickedOnSingleMentors(item) {
    await apiservice.setLocalStorage('mentorData', item)
    // navigate('/contentexpert')
    navigate(`/contentexpert/${item.content_experts_id}`)
  }


  const myServiceList = () => {
    let list = [];
    let count = 0;
    for (let index = 0; index < services.length; index++) {
      let item = services[index]
      if (activeMenu === '1:1 Single Session' && count < 3) {
        if (item.MentorshipType === '1:1 Single Session') {
          count = count + 1;
          console.log('Items in 1:1 single session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-6 col-lg-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                content_expert_name={`${item.content_expert.name} ${item.content_expert.lname}`}
                serviceType={item.MentorshipType}
                rating={item.averageRating}
                duration={` ${item.Hour}h ${item.Minutes}m  `}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                timeZone={item.timezone}
                pricing={item.Price}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === '1:1 Multi-Session' && count < 3) {
        if (item.MentorshipType === '1:1 Multi-Session') {
          count = count + 1;
          console.log('Items in 1:1 Multi-Session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-6 col-lg-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                content_expert_name={`${item.content_expert.name} ${item.content_expert.lname}`}
                serviceType={item.MentorshipType}
                rating={item.averageRating}
                duration={`${item.Hour}h ${item.Minutes}m  `}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                pricing={item.Price}
                timeZone={item.timezone}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === 'Webinar Session' && count < 3) {
        if (item.MentorshipType === 'Webinar Session') {
          count = count + 1;
          console.log('Items in 1:1 Webinar Session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-6 col-lg-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                content_expert_name={`${item.content_expert.name} ${item.content_expert.lname}`}
                serviceType={item.MentorshipType}
                rating={item.averageRating}
                duration={`${item.Hour}h ${item.Minutes}m `}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                pricing={item.Price}
                timeZone={item.timezone}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === 'All' && count < 3) {
        count = count + 1;

        list.push(
          <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-6 col-lg-4 col-12 p-3'>
            <ServicesCard
              card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
              title={item.Service_Name}
              content_expert_name={`${item.content_expert.name} ${item.content_expert.lname}`}
              serviceType={item.MentorshipType}
              rating={item.averageRating}
              duration={`${item.Hour}h ${item.Minutes}m `}
              webinarDate={item.start_date}
              webinarTime={item.start_time}
              pricing={item.Price}
              timeZone={item.timezone}
              currencySymbol={item.CurrencySymbol}
            />

          </div>
        )

      }
    }
    return list
  }


  const learnerList = () => {
    let array = mentorsList
    let list = []
    console.log(array);
    let count = 0
    for (let index = 0; index < array.length; index++) {
      let data = array[index]
      console.log(data);
      if (index < 3) {
        list.push(
          <div className='col-lg-4 ' onClick={() => clickedOnSingleMentors(data)} style={{ cursor: 'pointer' }}
            key={index}>
            <MentorCard
              mentorsName={`${data.name} ${data.lname}`}
              mentorsDescription={data.shortDescription}
              mentorsImage={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`}
              sideBgColor={`cardBg${count}`}
              mentorDesignation = {`${data.lastDesignation} @ ${data.company}`}
            />
          </div>
        )
        if (count === 5) {
          count = 0
        } else {
          count++
        }
      }
    }
    return list
  }
  async function changeIndustry(e) {
    let selectValue = e.target.value
    setSearchType(selectValue)
    localStorage.setItem('industryType', e.target.value)
  }
  const onChangeHandle = (e) => {
    let inputValue = e.target.value
    setSearchInp(inputValue)
    localStorage.setItem('searchInput', inputValue)
  }
  async function clickedOnSearch() {
    setIsServiceLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    let dataObject = {
      industry: searchType,
      search: searchinp
    }

    console.log(dataObject);
    await apiservice.apiCall('/Learner/ServiceList', uData.Token, dataObject, 'POST').then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsServiceLoading(false)
        console.log(res.data);
        setServices(res.data)
      }
      else {
        setIsServiceLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsServiceLoading(false)
      console.log(err);
    })

  }
  return (
    <>
      {isLoading ? <Loader /> :
        <div className='dashboard-mainpage mt-3'>
          <div className='container-fluid'>
            <div className='d-none d-lg-block'>
              <div className='row'>

                {completedProfile ?
                  <div className='col-lg-4 col-12 text-center mb-2'>
                    <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => navigate('/learner-home/profile')}>Complete Profile </button>
                  </div> : ''}

                <div className='col-lg-4 col-12  text-center rounded-2'>
                  <button className='btn btn-orange w-100  rounded-2 btnM' onClick={() => navigate('/learner-home/progressreport')}>Progress Report</button>

                </div>

              </div>
            </div>
            <div className='d-lg-none d-block'>
              <div className='d-flex '>
                {completedProfile ?
                  <div className='text-center mb-2 me-3'>
                    <button className='btn btn-orange rounded-2 btnM' onClick={() => navigate('/learner-home/profile')}>Complete Profile  </button>
                  </div> : ''}
                <div className='text-center rounded-2'>
                  <button className='btn btn-orange rounded-2 btnM' onClick={() => navigate('/learner-home/progressreport')}>Progress Report</button>
                </div>

              </div>
            </div>
            <div className='mt-3'>
              <div className='row'>
                <div className='col-lg-10 col-12 d-none d-lg-block'>
                  <div className="input-group mb-3 border rounded-3 border-dark ">
                    <span className="input-group-text border-0  bg-transparent">
                      <div className="dropdown border-end border-dark" style={{ width: '250px', }}>
                        <div className="form-group" style={{ outline: 'none' }}>
                          <select className="form-select border-0" value={searchType} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
                            {
                              industryArr.map((item, i) => {
                                return (
                                  <option key={i} value={item.name} className="border-0" >{item.name}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </span>

                    <input type="text" className="form-control border-0 bg-transparent" value={searchinp} onChange={onChangeHandle} placeholder='Search by session' />
                    <span className="input-group-text border-0 bg-transparent d-lg-block d-none">
                      <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} className='' /></button>
                    </span>
                    <span className="input-group-text border-0 bg-transparent d-lg-none d-block">
                      <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} /></button>
                    </span>
                  </div>
                </div>
                <div className='col-lg-10 col-12 d-lg-none d-block'>
                  <div className='row serachInput'>
                    <div className="input-group mb-3 border rounded-3 border-dark ">
                      <div className='col-6 col-12'>
                        <span className="input-group-text border-0  bg-transparent">
                          <div className="dropdown  border-dark">
                            <div className="form-group" style={{ outline: 'none' }}>
                              <select className="form-select w-100" value={searchType} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
                                {
                                  industryArr.map((item, i) => {
                                    return (
                                      <option key={i} value={item.name} className="border-0" >{item.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div className='col-6 col-12'>
                        <div className='d-flex ms-3'>
                          <input type="text" className="form-control border-0 border-bottom bg-transparent mb-2" value={searchinp} onChange={onChangeHandle} placeholder='Search by session' />
                          <span className="input-group-text border-0 bg-transparent">
                            <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} /></button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
                <div className='col-lg-2 col-12'>
                  <div className='notification-icon-conntainer text-center pt-2 d-lg-block d-none position-relative pointer' onClick={() => navigate('/learner-home/notification')}>
                    <div className='position-absolute top-0 end-0 me-2 '><div className='badge p-1 bg-danger' style={{ fontSize: '10px' }}>{notificationCount}</div></div>
                    <img src={bell_icon} alt='...' className='mt-1' />
                  </div>
                </div>
              </div>

            </div>

            <div className='mt-3'>
              <img src={coverImage} alt='...' className='w-100' />
            </div>
            <div className='mt-3'>
              <div className='fw-bold Title'>Services</div>

              <div className='mt-3'>
                {services.length > 0 ?
                  <div className=''>
                    <div className='d-lg-flex justify-content-between'>
                      <div className='d-lg-block d-none'>
                        <div className='d-flex'>
                          <div className={activeMenu === 'All' ? 'px-3 py-2 border rounded-2 text-light bg-dark border-dark' : 'px-3 py-2 border rounded-2 border-dark'} onClick={() => onClickManu('All')} style={{ cursor: 'pointer' }} >All</div>
                          <div className={activeMenu === '1:1 Single Session' ? 'px-3 py-2 mx-2 border rounded-2 text-light bg-dark border-dark' : 'px-3 py-2 mx-2 border rounded-2 border-dark'} onClick={() => onClickManu('1:1 Single Session')} style={{ cursor: 'pointer' }}>1:1 Single Session</div>
                          <div className={activeMenu === '1:1 Multi-Session' ? 'px-3 py-2 me-2 border rounded-2 text-light bg-dark border-dark' : 'px-3 py-2 me-2 border rounded-2 border-dark'} onClick={() => onClickManu('1:1 Multi-Session')} style={{ cursor: 'pointer' }}>1:1 Multi-Session</div>
                          <div className={activeMenu === 'Webinar Session' ? 'px-3 py-2 border rounded-2 text-light bg-dark border-dark' : 'px-3 py-2 border rounded-2 border-dark'} onClick={() => onClickManu('Webinar Session')} style={{ cursor: 'pointer' }}>Webinar Session</div>
                        </div>
                      </div>
                      <div className='d-md-none d-block'>
                        <div className='row'>
                          <div className='p-1  col-6'>
                            <div className={activeMenu === 'All' ? 'col-12 px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-3 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('All')} >All</div>
                          </div>
                          <div className='p-1  col-6'>
                            <div className={activeMenu === '1:1 Single Session' ? 'col-12 px-2 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-2 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Single Session')} >1:1 Single Session</div>
                          </div>
                          <div className='p-1  col-6'>
                            <div className={activeMenu === '1:1 Multi-Session' ? 'col-12 px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-3 py-2  border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Multi-Session')} >1:1 Multi-Session</div>
                          </div>
                          <div className='p-1  col-6'>
                            <div className={activeMenu === 'Webinar Session' ? 'col-12 px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-3 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('Webinar Session')} >Webinar </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3 fw-bold text-end' onClick={clickedOnAllCourses} style={{ cursor: 'pointer' }}>Explore <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></div>
                    </div>

                    <div className='mt-3'>
                      {isServiceLoading ? <div>Loading...</div> :
                        <div className='row'>
                          {myServiceList()}
                        </div>}
                    </div>
                  </div> :
                  <div className='my-5 text-danger'>
                    We regret to inform you that the service you are seeking has not been added.
                  </div>}


                <div className='mt-3'>
                  <div className='d-flex justify-content-between'>
                    <div className='fw-bold Title'>Top Content Expert</div>
                    <div className='mt-2 mt-lg-3 fw-bold d-flex'><span className='d-none d-lg-block' style={{ cursor: 'pointer' }} onClick={clickedOnAllMentors}>Explore </span>  <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></div>
                  </div>
                  <div className='mt-3'>
                    <div className='row'>
                      {learnerList()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
