import React, { useEffect } from 'react'
import { Pagination } from 'react-bootstrap';
import './Notificationpage.scss'
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function NotificationPage(props) {
  let navigate = useNavigate()
  const [notificationArr, setNotification] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const getNotification = async () => {
    setIsLoading(true)
    let udata = await apiservice.getLocalStorage('userData')

    await apiservice.apiCall('/mentor_get_all_notifications', udata.Token).then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setNotification(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }
  useEffect(() => {
    getNotification()
  }, [])


  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(notificationArr.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = notificationArr.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageRangeStart = Math.max(1, currentPage - 2);
  const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

  const notificationList = () => {
    let array = itemsToDisplay
    let list = []
    for (let index = 0; index < array.length; index++) {
      let data = array[index]
      if (data.message !== null) {

        list.push(
          <div className='p-3 row border-bottom'>
            <div className='col-lg-9 col-12 pt-2 text-break'>
              {data.message}
            </div>
            <div className='col-lg-3 mt-lg-0 mt-3 col-12 pt-2'>{moment(new Date(data.created_at)).startOf('minite').fromNow()}  </div>
          </div>

        )
      }
    }
    return list
  }

  return (
    <>
      {isLoading ? <Loader /> :
        <div className='p-3'>
          <div className='container-fluid'>
            <div className='fs-3 fw-bold'>Notification</div>
          </div>
          <div className='mt-3'>
            <div>
              {notificationList()}
            </div>
            <div className="pagination-container mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination>
                <Pagination.Prev
                  className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1 ? true : false}
                >
                  <img src={currentPage === 1 ? leftLight : left} alt='...' />
                </Pagination.Prev>
                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                  <Pagination.Item
                    key={pageRangeStart + offset}
                    active={pageRangeStart + offset === currentPage}
                    onClick={() => handlePageChange(pageRangeStart + offset)}
                  >
                    {pageRangeStart + offset}
                  </Pagination.Item>
                ))}
                {pageRangeEnd < totalPages && (
                  <Pagination.Ellipsis disabled />
                )}
                <Pagination.Next
                  className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages ? true : false}
                >
                  <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                </Pagination.Next>
              </Pagination>
            </div>
          </div>
        </div>
      }
    </>

  );
}

export default NotificationPage
