import { React, Fragment } from 'react'
import "./MentorsCard.scss";
import noImg from '../../../assets/no-image.png'
function MentorCard({ mentorsName, mentorsDescription, mentorsImage = noImg, sideBgColor, onClick, mentorDesignation }) {
  console.log(mentorDesignation);
  return (
    <Fragment>
      <div className='col-lg-4 col-md-6 col-12 mentorsCardLanding my-2 ' onClick={onClick} >
        <div className="card bg-transparent " style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
          <div className={sideBgColor}></div>
          <div className="card-body d-flex  px-2 py-4">
            <img src={mentorsImage} alt="" width={90} height={90} className=' rounded-circle ms-4 z-2 d-none d-lg-block' />
            <img src={mentorsImage} alt="" width={60} height={60} className=' rounded-circle ms-4 z-2 d-lg-none d-block' />
            <div className='card-text ms-4'>
              <div className='fs-4 fw-bold cardTitle'><h5>{mentorsName} </h5></div>
              <p className=''>{mentorDesignation} </p>
              <p className="p-1" style={{ fontSize: '14px' }}>{mentorsDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MentorCard
