import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import MentorCard from '../../../components/LandingpageComponents/MentorsCard/MentorCard';
import { HiArrowLongRight } from 'react-icons/hi2';
import Marquee from "react-fast-marquee";
import numCard0 from "../../../assets/numCard1.png"
import numCard1 from "../../../assets/numCard2.png"
import numCard2 from "../../../assets/numCard3.png"
import numCard3 from "../../../assets/numCard4.png"
import numCard4 from "../../../assets/numCard5.png"
import card1 from '../../../assets/Marqee/card1.png'
import card2 from '../../../assets/Marqee/card2.png'
import card3 from '../../../assets/Marqee/card3.png'
import card4 from '../../../assets/Marqee/card4.png'
import card5 from '../../../assets/Marqee/card5.png'
import card6 from '../../../assets/Marqee/card6.png'
import card7 from '../../../assets/Marqee/card7.png'
import card8 from '../../../assets/Marqee/card8.png'
import card9 from '../../../assets/Marqee/card9.png'
import card10 from '../../../assets/Marqee/card10.png'
import card11 from '../../../assets/Marqee/card11.png'
import card12 from '../../../assets/Marqee/card12.png'
import card13 from '../../../assets/Marqee/card13.png'
import card14 from '../../../assets/Marqee/card14.png'
import card15 from '../../../assets/Marqee/card15.png'
import card16 from '../../../assets/Marqee/card16.png'
import card17 from '../../../assets/Marqee/card17.png'
import card18 from '../../../assets/Marqee/card18.png'
import card19 from '../../../assets/Marqee/card19.png'
import card20 from '../../../assets/Marqee/card20.png'
import card21 from '../../../assets/Marqee/card21.png'
import card22 from '../../../assets/Marqee/card22.png'
import group from "../../../assets/Group.png"
import group1 from "../../../assets/Group1.png"
import group2 from "../../../assets/Group2.png"
import bottomCardImg1 from '../../../assets/icForCareerBottomCardImg1.png'
import bottomCardImg3 from '../../../assets/icForCareerBottomCardImg2.png'
import bottomCardImg2 from '../../../assets/icForCareerBottomCardImg3.png'
import startHollow from "../../../assets/startHollow.png"
import careerTransition from "../../../assets/careerTransitioner.png"
import CardToElevateJourney from '../../../components/LandingpageComponents/CardToElevateJourney/CardToElevateJourney';
import apiservice from '../../../apiservice/ApiService';
import './InterConnectForCareer.module.scss';
import { useNavigate } from 'react-router-dom';
// import ScrollAnimation from 'react-animate-on-scroll';




function InterConnectForCareer(props) {
    let navigate = useNavigate()
    let [isLoggedIn, setLoggedIn] = useState(false)

    useEffect(() => {
        async function getLoginStatus() {
            let logStatus = await apiservice.getLocalStorage('userData')
            if (logStatus) {
                setLoggedIn(true)
            }
            else {
                setLoggedIn(false)
            }
        }
        getLoginStatus()
        window.scrollTo(0, 0)
        localStorage.removeItem('isFilter')

    }, [])

    let dataArr = [
        { icon: card1, title: 'Applications Preparation' },
        { icon: card2, title: 'Assessment Centres' },
        { icon: card3, title: 'Branding' },
        { icon: card4, title: 'Case Study Preparation' },
        { icon: card5, title: 'Career Transitioning' },
        { icon: card6, title: 'Changing roles' },
        { icon: card7, title: 'Experienced Hires' },
        { icon: card8, title: 'Tailoring of CVs' },
        { icon: card9, title: 'Tailoring of Cover Letters' },
        { icon: card10, title: 'Industry Insights' },
        { icon: card11, title: 'Mock Interview Prep' },
        { icon: card12, title: 'Interview Role Play' },
        { icon: card13, title: 'Early Career Guidance' },
        { icon: card14, title: 'Graduate Schemes' },
        { icon: card15, title: 'Salary Negotiations' },
        { icon: card16, title: 'Spring Weeks' },
        { icon: card17, title: 'Coaching for Career Promotion' },
        { icon: card18, title: 'Networking Facilitator' },
        { icon: card19, title: 'Internships' },
        { icon: card20, title: 'General Coaching' },
        { icon: card21, title: 'Targeted Technical Training Session' },
        { icon: card22, title: 'General Advice' },
    ]

    const cardListing = () => {
        let list = []

        for (let index = 0; index < dataArr.length; index++) {
            const data = dataArr[index];
            list.push(
                <div key={index} className='p-3  me-2 text-center p-3'>
                    <div>
                        <img src={data.icon} alt='...' />
                    </div>
                    <div className='mt-3'>{data.title}</div>
                </div>
            )
        }
        return list
    }
    const [tabName, setTabName] = useState('Tab1')

    const onClickTabs = (e) => {
        setTabName(e)
    }



    const numImages = [numCard0, numCard1, numCard2, numCard3, numCard4]
    const description = ['Get access to an interactive dashboard | Book 1:1 sessions or attend webinars in your desired areas.', "Ability to DM mentors after booking | Request personalised mentoring plans.", "Choose between single or multi-session mentorship program, whichever fits your needs.", 'Gain deep industry knowledge and invaluable insights | Technical coaching | and many more.', 'Calendar integration | Booking reminder notification | In-built chat function']
    function getCard() {
        let list = [];
        let count = 0;


        for (let i = 0; i < 5; i++) {
            list.push(

                <CardToElevateJourney
                    key={i}
                    mentorsName={''}
                    sideBgColor={`cardBg${count}`}
                    mentorsImage={numImages[i]}
                    mentorsDescription={description[i]}
                />

            );
            if (count === 5) {
                count = 0;
            } else {
                count++;
            }
        }

        return list;
    }
    return (
        <Fragment>
            <Header navigate={navigate} />
            <section style={{ backgroundColor: "#f1efe7" }}>
                <article className="container pt-lg-5 pt-4">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt; Careers</div>
                            <h2 className='py-2 fw-semibold mdTitleText'>Learn from other’s experiences.</h2>
                            <p>Get personalised 1:1 mentorship from experienced industry professionals who want to give back.</p>
                        </div>
                        <div className='d-lg-block d-none'><img className='start_splash_img' src={startHollow} alt="" width={80} height={80} /></div>
                    </div>
                </article>
                <article className="container py-lg-2 my-lg-2">
                    {/* <ScrollAnimation animateIn='animate__zoomIn' > */}

                    <div className="row" style={{ height: 'max-content !important' }}>
                        <div className="col-lg-4 align-self-center">
                            <h4 className="mdTitleText">Ways in which XT elevates your career journey?</h4>
                        </div>
                        {getCard()}
                    </div>
                    {/* </ScrollAnimation> */}


                    {/* <ScrollAnimation animateIn='animate__zoomIn' > */}


                    <div className='container py-4 my-md-4 mt-0 d-md-block d-none'>
                        <h3 className='text-center fw-semibold mdTitleText'>XperThrivr for :</h3>
                        <div className='m-lg-3'>
                            <div className='row m-0'>
                                <div className='col-lg-4 col-12 p-0'>
                                    <div className='text-center fw-bold rounded-top-2 py-3 ' onClick={() => onClickTabs('Tab1')} style={{ backgroundColor: '#B9E6FE', cursor: 'pointer' }}>Graduates and Career Starters</div>
                                </div>
                                <div className='col-lg-4 col-12 px-0 px-lg-2 '>
                                    <div className='text-center fw-bold rounded-top-2 py-3' onClick={() => onClickTabs('Tab2')} style={{ backgroundColor: '#FDDCAB', cursor: 'pointer' }}>Career Climbers</div>
                                </div>
                                <div className='col-lg-4 col-12 p-0'>
                                    <div className='text-center fw-bold rounded-top-2 py-3' onClick={() => onClickTabs('Tab3')} style={{ backgroundColor: '#ABEFC6', cursor: 'pointer' }}>Career Transitioners</div>
                                </div>
                            </div>
                            <div>
                                {tabName === 'Tab1' ?
                                    <div className='px-lg-5' style={{ backgroundColor: '#B9E6FE' }}>
                                        <div className='row m-0'>
                                            <div className='col-lg-9 col-12'>
                                                <div className=' mt-3 pt-md-4 pt-0'>
                                                    Connect with a multitude of seasoned, highly experienced professionals from your desired field to gain invaluable sector insights, technical coaching, and broaden your extensive network. Establish connections with these seasoned experts, who can offer you deep industry knowledge and mentorship to help you advance in your career.                                        </div>

                                            </div>
                                            <div className='col-lg-3 col-12 text-center'>
                                                <img src={group} alt='...' className='w-75 mt-3' />
                                            </div>
                                        </div>
                                    </div> : tabName === 'Tab2' ?
                                        <div className='px-lg-5 ' style={{ backgroundColor: '#FDDCAB' }}>
                                            <div className='row m-0'>
                                                <div className='col-lg-9 col-12'>
                                                    <div className=' mt-3 pt-md-4 pt-0'>
                                                        Increase your chance of getting promoted by with more experienced professionals who are already operating at that level. They will help you to start preparing for the next role early and accelerate your career through their own network.
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-12 text-center'>
                                                    <img src={group1} alt='...' className='w-100 mt-3' />
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='px-lg-5' style={{ backgroundColor: '#ABEFC6' }}>
                                            <div className='row m-0'>
                                                <div className='col-lg-9 col-12'>
                                                    <div className=' mt-3 pt-md-4 pt-0'>
                                                        Those seeking to transition their career into a different function, sector, or industry can connect with senior professionals who will equip them with the necessary tools and knowledge. Pair up with experienced mentors who can guide you on your journey toward a successful career switch and provide valuable insights into your new field.
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-12 text-center'>
                                                    <img src={careerTransition} alt='...' className='w-100 mt-3' />
                                                </div>
                                            </div>
                                        </div>}
                            </div>
                        </div>
                    </div>



                    <div className='container py-4 my-md-4 mt-0 d-md-none d-block'>
                        <h3 className='text-center fw-semibold mdTitleText'>XperThrivr for :</h3>
                        <div className='m-lg-3'>
                            <div className='row m-0'>
                                <div className='col-lg-4 col-12 p-0'>
                                    <div className='text-center fw-bold rounded-top-2 py-3 ' onClick={() => onClickTabs('Tab1')} style={{ backgroundColor: '#B9E6FE', cursor: 'pointer' }}>Graduates and Career Starters</div>
                                </div>
                                {tabName === 'Tab1' ?
                                    <div className='px-lg-5' style={{ backgroundColor: '#B9E6FE' }}>
                                        <div className='row m-0'>
                                            <div className='col-lg-9 col-12'>
                                                <div className=' mt-3 pt-md-4 pt-0'>
                                                    Connect with a multitude of seasoned, highly experienced professionals from your desired field to gain invaluable sector insights, technical coaching, and broaden your extensive network. Establish connections with these seasoned experts, who can offer you deep industry knowledge and mentorship to help you advance in your career.                                        </div>

                                            </div>
                                            <div className='col-lg-3 col-12 text-center'>
                                                <img src={group} alt='...' className='w-75 mt-3' />
                                            </div>
                                        </div>
                                    </div> : ''}
                                <div className='col-lg-4 col-12 px-0 px-lg-2 '>
                                    <div className='text-center fw-bold rounded-top-2 py-3' onClick={() => onClickTabs('Tab2')} style={{ backgroundColor: '#FDDCAB', cursor: 'pointer' }}>Career Climbers</div>
                                </div>
                                {tabName === 'Tab2' ?
                                    <div className='px-lg-5 ' style={{ backgroundColor: '#FDDCAB' }}>
                                        <div className='row m-0'>
                                            <div className='col-lg-9 col-12'>
                                                <div className=' mt-3 pt-md-4 pt-0'>
                                                    Increase your chance of getting promoted by with more experienced professionals who are already operating at that level. They will help you to start preparing for the next role early and accelerate your career through their own network.
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-12 text-center'>
                                                <img src={group1} alt='...' className='w-100 mt-3' />
                                            </div>
                                        </div>
                                    </div> : ''}
                                <div className='col-lg-4 col-12 p-0'>
                                    <div className='text-center fw-bold rounded-top-2 py-3' onClick={() => onClickTabs('Tab3')} style={{ backgroundColor: '#ABEFC6', cursor: 'pointer' }}>Career Transitioners</div>
                                </div>
                                {tabName === 'Tab3' ?
                                    <div className='px-lg-5' style={{ backgroundColor: '#ABEFC6' }}>
                                        <div className='row m-0'>
                                            <div className='col-lg-9 col-12'>
                                                <div className=' mt-3 pt-md-4 pt-0'>
                                                    Those seeking to transition their career into a different function, sector, or industry can connect with senior professionals who will equip them with the necessary tools and knowledge. Pair up with experienced mentors who can guide you on your journey toward a successful career switch and provide valuable insights into your new field.
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-12 text-center'>
                                                <img src={careerTransition} alt='...' className='w-100 mt-3' />
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                            </div>
                            <div>



                            </div>
                        </div>
                    </div>
                    {/* </ScrollAnimation> */}
                </article>


                <div>
                    <div className='mt-md-3 mt-0 mb-3 fw-semibold text-center mdTitleText'>Areas our experts can guide you through:</div>
                    <Marquee speed={100} pauseOnHover={false} autoFill={true}>{cardListing()} </Marquee>
                </div>
                {
                    isLoggedIn === true ? ''
                        :
                        <article className='claimSec my-5  py-2'>
                            <div className="container row py-3 text-end">

                                <div className="col-lg-4 col-12  offset-lg-4 offset-0"> <div className='headTextAtContCreator'>  <h3 className=' text-lg-start text-center d-none d-lg-block '>Set up your <br /> profile now!</h3>
                                    <h3 className=' text-lg-start text-center d-lg-none d-block '>Set up your profile now!</h3></div>
                                </div>
                                <div className="col-lg-4 col-12 align-self-center">
                                    <div className='w-100 text-center align-self-center'>
                                        {
                                            isLoggedIn === true ? ''
                                                :

                                                <button
                                                    type="button"
                                                    onClick={() => navigate('/signup')}
                                                    className="btn btn-primary border-0 text-center px-4 py-2 btnM"
                                                    style={{ backgroundColor: "#E57A4D" }}
                                                >
                                                    Launch your Personalised Profile
                                                </button>

                                        }
                                    </div>
                                </div>
                            </div>
                        </article>
                }



                <article className='container mt-5'>
                    {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                    <div className="row" style={{ backgroundColor: '#FDDCAB' }}>
                        <div className="col-lg-4 p-0 m-0 col-12">
                            <img src={bottomCardImg1} className='w-100 ' alt="" />
                        </div>
                        <div className="col-lg-8 align-self-center col-12 p-xl-5 p-3 p-0">
                            <div className='fw-semibold py-xl-4 mdTitleText mb-2'>Give yourself the  XperThrivr Edge.</div>
                            <p >
                                <div className='d-flex'>
                                    <div className='me-3'>&#8226;</div>
                                    <div className=''>XT platform is combined with a LMS (learning management system) to enables you to schedule structured sessions through content modules, enhancing your 1:1 sessions and webinars experience.</div>
                                </div>
                                <div className='d-flex mt-3'>
                                    <div className='me-3'>&#8226;</div>
                                    <div className=''>XT provides you with a personalised dashboard that gives you access to a range of areas where our mentors can help. Track your learning progress and showcase your accomplishments by generating reports of completed courses and sessions with a single click!</div>
                                </div>
                            </p>
                        </div>
                    </div>
                    {/* </ScrollAnimation> */}
                </article>


                <article className='container py-lg-4 mt-3'>
                    {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                    <h2 className="text-center fw-semibold py-md-4 py-0 mdTitleText">Why should you use XperThrivr?</h2>
                    <div className="row">
                        <div className="col-lg-6 col-12 p-3">
                        <img src={bottomCardImg2} className='w-100 mb-4 rounded-2 d-md-none d-block' alt="" />
                            <div className="card w-100 p-2 rounded-2 " style={{ backgroundColor: '#ABEFC6' }}>
                                <p>Whatever your career stage and your specialism, our community of mentors can help you find new opportunities and connect you with potential employers. You will be more likely to be aware of new opportunities and to be considered for them.</p>
                            </div>
                            <img src={bottomCardImg2} className='w-100 mt-4 rounded-2 d-md-block d-none' alt="" />
                        </div>
                        <div className="col-lg-6 col-12 p-3" >
                            <img src={bottomCardImg3} className='w-100 mb-4 rounded-2' alt="" style={{ height: '290px' }} />
                            <div className="card w-100 p-2 rounded-2" style={{ backgroundColor: '#C7CCE5', }}>
                                <p>We believe industry professionals who have attained a certain level of mastery in their fields, are always very keen to share their specialised knowledge and to help others achieve success in their careers.</p>
                            </div>
                        </div>
                    </div>
                    {/* </ScrollAnimation> */}
                </article>

                <Footersection navigate={navigate} />
            </section>
        </Fragment>
    )
}

export default InterConnectForCareer
