import React from 'react'
import card1 from '../../../assets/InterConnecCard/card1.png'
import card2 from '../../../assets/InterConnecCard/card2.png'
import card3 from '../../../assets/InterConnecCard/card3.png'
import card4 from '../../../assets/InterConnecCard/card4.png'
import card5 from '../../../assets/InterConnecCard/card5.png'
import card6 from '../../../assets/InterConnecCard/card6.png'
import card7 from '../../../assets/InterConnecCard/card7.png'
import card8 from '../../../assets/InterConnecCard/card8.png'
import card9 from '../../../assets/InterConnecCard/card9.png'
import card10 from '../../../assets/InterConnecCard/card10.png'
import card11 from '../../../assets/InterConnecCard/card11.png'
import './InterConnecCard.scss'
// import ScrollAnimation from 'react-animate-on-scroll'
function InterConnecCard() {
    return (
        <>
            <div className='container interConnect-container'>
                <div className='my-lg-3'>
                    {/* <ScrollAnimation animateIn='animate__fadeInRight' > */}
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card bg-transparent ">
                                    <img className="card-img-top p-3" src={card1} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Personal Development</h4>
                                        <p className="card-text">Provide your followers with a more personalized, supportive, and flexible learning experience via our 1:1 and webinar platform. #personaldevelopment #motivation #mindset</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card2} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Mental Health</h4>
                                        <p className="card-text">Share your deep insights to your followers and promote mental health awareness via 1:1 or webinars sessions. #mentalhealth #stressmanagement</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card3} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Recruitment Professionals</h4>
                                        <p className="card-text">Help your followers stand out in interviews. Conduct 1:1 and webinar sessions to provide invaluable information about the recruitment process, interview stages and job market. #careers #aceinterviews #interviewtips</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </ScrollAnimation> */}
                    {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}

                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card4} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Brand Building</h4>
                                        <p className="card-text">Use 1:1 session to help your followers develop their brand identity or brand marketing plan. #brandidentity #branddesigner</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card5} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Content Marketing</h4>
                                        <p className="card-text">Deliver a webinar or 1:1 to your followers on how to create and distribute high-quality content. #contentmarketing #marketingstrategy</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card6} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Career Coaching</h4>
                                        <p className="card-text">With our LMS features, you can easily include modules and various sub-topics to enhance the 1:1 and webinar experience. #careerguidance #careercoaching #careeradvice</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </ScrollAnimation> */}
                    {/* <ScrollAnimation animateIn='animate__fadeInRight' > */}
                        <div className='row'>

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card7} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Thought Leadership</h4>
                                        <p className="card-text">Monetise your thought leadership expertise through meaningful sessions and deliver them via 1:1 or webinar sessions. #leadership #vision</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card8} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Influencers</h4>
                                        <p className="card-text">Share their knowledge and experience with their followers and to help them achieve their goals. #fitness #beautytips #travel</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card9} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Product Managers</h4>
                                        <p className="card-text">Share your insights in product management, your interview guide, deliver workshop, and many more. #productmanagement #strategy #productdesign</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </ScrollAnimation> */}
                    {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}
                        <div className='row'>

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card10} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Aspiring lawyers</h4>
                                        <p className="card-text">Share your valuable experience of transitioning from passing bar to becoming a lawyer, help your followers getting ahead of what comes next. #barexam #legalcareers</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className="card">
                                    <img className="card-img-top p-3" src={card11} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Finance</h4>
                                        <p className="card-text">Are you passionate about finance and looking for a platform to connect and exchange views with your followers? #corporate finance #modelling #assetmanagement #capital markets</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    {/* </ScrollAnimation> */}

                </div>
            </div >
        </>
    )
}

export default InterConnecCard
