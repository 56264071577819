import React, { useEffect, useState } from 'react'
import './MessagePage.scss'
import attachfileicon from '../../../assets/message_attachment_icon.png'
import sendmessageicon from '../../../assets/send_message_arrow.png'
import ScrollToBottom from 'react-scroll-to-bottom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faDownload, faFileAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import apiservice from '../../../apiservice/ApiService'
import moment from 'moment'
import parse from 'html-react-parser';
import Pusher from 'pusher-js';
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'
function MessagePage(props) {

  let navigate = useNavigate()
  const initialStateData = {
    messageInput: '',
    attchFile: '',
    file: '',
  }

  const [data, setData] = useState(initialStateData)
  const [mobileChatRoom, setMobileChatRoom] = useState(false)
  const [chatFeedArr, setChatFeedArr] = useState([])
  const [messageDatas, setMessageDatas] = useState([])
  const [currentChatFeedData, setCurrentChatFeedData] = useState('')
  const [loginUserData, setLoginUserData] = useState('')
  const inputRef = React.useRef();
  const [attachFile, setAttachFile] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchFeedArr, setSearchFeedArr] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isFromProfile = localStorage.getItem('chatfeedfromLearnerprofile')
    if (isFromProfile === 'true') {
      let chatfeedData = JSON.parse(localStorage.getItem('chatFeedValue'))
      console.log(chatfeedData);
      setCurrentChatFeedData(chatfeedData)
      setMobileChatRoom(true);
      console.log(chatfeedData);
      getMessage(chatfeedData.id)
    }
    getChatFeedData();
    Pusher.logToConsole = true;
    console.log('pusher events1');
    const pusher = new Pusher('3567be6f9b4623340f5e', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('msg-channel');
    channel.bind('msg-event', (receivedData) => {
      getMessage();
      console.log('pusher events2');
      console.log(receivedData);
    });
    return () => {
      channel.unbind();
      pusher.unsubscribe('msg-channel');
      pusher.disconnect();
    };
  }, []);

  async function getChatFeedData() {
    setPageLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    setLoginUserData(uData)
    await apiservice.apiCall('/mentor_chat/get_all_learners', uData.Token).then(res => {
      console.log(res);
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setPageLoading(false)
        console.log(res.data);
        setChatFeedArr(res.data)
      } else {
        setPageLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setPageLoading(false)
      console.log(err);
    })

  }

  const onChangeHandle = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const listOfChatFeed = () => {
    let list = []
    let sortArrayData = isSearch === true ? searchFeedArr : chatFeedArr
    let dataArr = sortArrayData.sort((a, b) => b.unread_count - a.unread_count);
    if (dataArr.length > 0) {

      for (let index = 0; index < dataArr.length; index++) {
        const element = dataArr[index];
        list.push(
          <div key={index} onClick={() => onClickChatFeedUser(element)}>
            <div className={element.id === currentChatFeedData.id ? 'active_chat_feed  py-3 ps-2 rounded  border-bottom' : ' py-3 ps-2  border-bottom'}>

              <div className='row m-0' style={{ cursor: 'pointer' }}>
                <div className='col-xl-2 col-1'>
                  <div className='rounded-circle overflow-hidden shadow border' style={{ height: '30px', width: '30px' }}>
                    <img src={element.ProfileImage === null || element.ProfileImage === '' || element.ProfileImage === undefined ? element.SocialImage === null || element.SocialImage === '' || element.SocialImage === undefined ? element.profile_photo_url : element.SocialImage : `${apiservice.apiImageShow()}/${element.ProfileImage}`} alt="" width={'100%'} height={'100%'} />
                  </div> </div>
                <div className='col-8 '>
                  <div className='ms-2' style={{ fontSize: '14px' }}>{element.name} {element.lname}</div>
                </div>
                <div className='col-xl-2 col-1 '>
                  {element.unread_count === 0 ? '' :
                    <div className='badge' style={{ backgroundColor: 'gray' }}>{element.unread_count}</div>}
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      list.push(
        <div>Data not Found!</div>
      )
    }
    return list
  }

  const onClickChatFeedUser = (data) => {
    localStorage.setItem('chatfeedfromLearnerprofile', false)
    setMobileChatRoom(true);
    setCurrentChatFeedData(data);
    localStorage.setItem('currentFeed', data.id)
    getMessage();
  };

  const onClickBackToChatFeedUser = () => {
    setMobileChatRoom(false);
  };

  async function getMessage() {
    let isFromProfile = localStorage.getItem('chatfeedfromLearnerprofile')
    let currUserData = isFromProfile === 'true' ? JSON.parse(localStorage.getItem('chatFeedValue')) : ''
    let uData = await apiservice.getLocalStorage('userData');
    let id = isFromProfile === 'true' ? currUserData.id : localStorage.getItem('currentFeed');
    console.log(id);
    console.log(uData);
    await apiservice.apiCall(`/mentor_chat/get_messages/${id}`, uData.Token).then(res => {
      console.log(res);
      if (res.unAuthenticated) {
        navigate('/');
        localStorage.removeItem('userData');
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setMessageDatas(res.data);
        // getChatFeedData()
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    });
  }
  const onClickDownload = (filename) => {
    console.log(`${apiservice.apiFileDownloadUrl()}/${filename}`);
    const fileUrl = `${apiservice.apiFileDownloadUrl()}/${filename}`
    window.open(fileUrl, '_blank', 'noreferrer')

  }

  const listOfMessages = () => {
    let list = []


    let dataArr = messageDatas

    let currentDate = null; // Track the current date for grouping
    for (let index = 0; index < dataArr.length; index++) {
      const element = dataArr[index];
      // Check if a new date has started
      const messageDate = new Date(element.created_at).toDateString();
      if (messageDate !== currentDate) {
        currentDate = messageDate;
        console.log(messageDate);
        console.log(new Date().toDateString());
        // Render date separator
        list.push(
          <div key={`dateSeparator-${index}`} className='text-center mb-3'>
            <div className='py-3 time_line d-flex justify-content-center ' >
              <div className='center_text'>

                {
                  // moment(new Date(messageDate)).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ?
                  new Date(messageDate).toDateString() === new Date().toDateString() ?
                    <span className=' '>
                      {'Today'}
                    </span>
                    :
                    new Date(new Date(messageDate).setDate(new Date(messageDate).getDate() + 1)).toDateString() === new Date().toDateString() ?
                      <span className=' '>
                        {'Yesterday '}
                      </span>
                      :
                      <span className=' '>
                        {new Date(messageDate).toDateString()}
                      </span>
                }
              </div>
            </div>
          </div>
        );
      }

      if (element.sender_id !== loginUserData.content_experts_id) {
        list.push(
          <div key={index} className='d-flex mt-3 justify-content-start'>
            <div className='pe-2'>
              <div className='rounded-circle overflow-hidden shadow border' style={{ height: '30px', width: '30px' }}>
                <img src={currentChatFeedData.ProfileImage === null ? currentChatFeedData.profile_photo_url : `${apiservice.apiImageShow()}/${currentChatFeedData.ProfileImage}`} alt="" width={'100%'} height={'100%'} />
              </div>
            </div>
            <div className='p-3 bg-light'>

              <div style={{ fontSize: 10 }} className='fw-bold '>{currentChatFeedData.name} {currentChatFeedData.lname}</div>
              {element.file_name === null || element.file_name === '' || element.file_name === undefined ? '' :
                <div className='d-flex justify-content-between text-primary text-break '>
                  <div className='mt-2'>
                    <FontAwesomeIcon className='me-2' icon={faFileAlt} />  {element.file_name}
                  </div>
                  <div className='mt-2'>
                    <FontAwesomeIcon className='ms-5' icon={faDownload} onClick={() => onClickDownload(element.file_name)} />
                  </div>
                </div>
              }
              <div className='mt-2 text-break'>

                {parse(`<div>${element.message}</div>`)}
              </div>
            </div>
            <div className='pe-md-3 pe-0   ps-1 align-self-end' style={{ fontSize: 10 }}>{moment(element.created_at).format('hh:mm A')} </div>
          </div>
        )
      } else {
        list.push(
          <div key={index} className='d-flex mt-3 justify-content-end'>
            <div className='pe-md-3 pe-1  ps-md-5 ps-0 align-self-end' style={{ fontSize: 10 }}>{moment(element.created_at).format('hh:mm A')}</div>
            <div className='p-3 bg-light'>
              <div style={{ fontSize: 10 }} className='fw-bold'>You</div>
              {element.file_name === null || element.file_name === '' || element.file_name === undefined ? '' :
                <div className='d-flex justify-content-between text-primary text-break'>
                  <div className='mt-2'>
                    <FontAwesomeIcon className='me-2 ' icon={faFileAlt} />  {element.file_name}
                  </div>
                  <div className='mt-2'>
                    <FontAwesomeIcon className='ms-5' icon={faDownload} onClick={() => onClickDownload(element.file_name)} />
                  </div>
                </div>
              }
              <div className='mt-2 text-break'>
                {parse(`<div>${element.message}</div>`)}
              </div>
            </div>
            <div className='ps-2'>
              <div className='rounded-circle overflow-hidden shadow border' style={{ height: '30px', width: '30px' }}>
                <img src={loginUserData.ProfileImage === null ? loginUserData.SocialImage === null ? loginUserData.profile_photo_url : loginUserData.SocialImage : `${apiservice.apiImageShow()}/${loginUserData.ProfileImage}`} alt="" width={'100%'} height={'100%'} />
              </div>
            </div>
          </div>
        )
      }

    }
    return list
  }


  async function onClickSendButton() {

    let uData = await apiservice.getLocalStorage('userData')
    console.log(currentChatFeedData);
    let formdata = new FormData()
    formdata.append('message', data.messageInput)
    formdata.append('file', attachFile)

    console.log(formdata);
    await apiservice.apiCallFormData(`/mentor_chat/send_messages/${currentChatFeedData.id}`, uData.Token, formdata, "POST").then(async res => {
      console.log(res);
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setAttachFile('')
        console.log(res.data);
        setData(initialStateData)
      } else {
        console.log(res);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const selectFile = (e) => {
    let file = e.target.files[0]
    console.log(file);
    setAttachFile(file === undefined ? '' : file)
  }

  const removeAttachment = () => {
    setAttachFile('')
  }


  const onChangeSearchValue = (e) => {
    const { name, value } = e.target;
    setSearchValue(value);
    if (value === '' && isSearch === false) {
      getChatFeedData();
    } else {
      const lowerCaseValue = value.toLowerCase(); // Convert search value to lowercase

      const newArr = chatFeedArr.filter((ele, index) => {
        const lowerCaseName = ele.name.toLowerCase(); // Convert array element to lowercase
        const lowerCaseLName = ele.lname.toLowerCase(); // Convert array element to lowercase

        return lowerCaseName.includes(lowerCaseValue) || lowerCaseLName.includes(lowerCaseValue);
      });
      setIsSearch(true)
      console.log(newArr);
      setSearchFeedArr(newArr)
    }
  }

  return (
    <>
      {pageLoading ? <Loader /> :
        <div>
          {chatFeedArr.length > 0 ?
            <div className='p-3'>
              <div className='container-fluid'>
                <div className='fw-bold Title'>Message</div>
              </div>

              {/* ------------------------------------------ Desktop view ------------------------------------------ */}

              <div className='d-lg-block d-none  mt-3'>
                <div className='row'>
                  <div className='col-lg-4 col-12'>
                    <div className='border rounded-4 border-dark chatFeed'>
                      <div className='p-3  fw-bold'>Chats</div>
                      <hr className='m-0 ' />
                      <div className='chatFeed_subContainer rounded-bottom-4 p-3'>
                        <div className='pb-3'>
                          <div className="form-group">
                            <input type="search" onChange={onChangeSearchValue}
                              className="form-control" name="searchValue" value={searchValue} placeholder="Search Here" />
                          </div>
                        </div>
                        {listOfChatFeed()}
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-8 col-12 '>
                    <div className=' border rounded-4 border-dark chatRoom position-relative overflow-hidden'>
                      {isLoading ? <Loader /> :
                        <div>
                          {currentChatFeedData === '' ? '' :
                            <div>

                              <div className='d-flex p-3'>
                                <div className='rounded-circle overflow-hidden shadow border' style={{ height: '40px', width: '40px', cursor: 'pointer' }}>
                                  <img src={currentChatFeedData.ProfileImage === null || currentChatFeedData.ProfileImage === '' || currentChatFeedData.ProfileImage === undefined ? currentChatFeedData.SocialImage === null || currentChatFeedData.SocialImage === '' || currentChatFeedData.SocialImage === undefined ? currentChatFeedData.profile_photo_url : currentChatFeedData.SocialImage : `${apiservice.apiImageShow()}/${currentChatFeedData.ProfileImage}`} alt="" width={'100%'} height={'100%'} />
                                </div>
                                <div className='ms-3'>
                                  <div className='fw-bold mt-2'>{currentChatFeedData.name} {currentChatFeedData.lname}</div>
                                </div>
                              </div>
                              <div className='px-3'>
                                <hr className=' m-0' />
                              </div>
                            </div>}
                          <ScrollToBottom mode="bottom" className={attachFile === '' ? 'message_container ' : 'message_container_with_attechment'}>
                            <div className='p-3'>
                              {listOfMessages()}
                            </div >
                          </ScrollToBottom>


                          {currentChatFeedData === '' ? '' :
                            <div className=' p-3 msg_input_container position-absolute bottom-0 w-100 rounded-bottom-2'>
                              <div className="input-group rounded-2">
                                <div className=' w-100'>
                                  {attachFile === '' ? '' :
                                    <div className='p-2'>
                                      <div className='d-flex justify-content-between'>
                                        <div>{attachFile.name}</div>
                                        <div><FontAwesomeIcon className='text-danger' onClick={removeAttachment} icon={faTrashAlt} /></div>
                                      </div>
                                    </div>}
                                </div>
                                <input
                                  type="file"
                                  accept='application/pdf, application/msword, application/vnd.ms-excel, text/plain, .jpg, .jpeg, .png'
                                  style={{ display: 'none' }}
                                  ref={(instance) => {
                                    inputRef.current = instance; // We also need it to manipulate the elemnent
                                  }}
                                  onChange={selectFile} />
                                <span className="input-group-text p-0 border-0 bg-transparent" onClick={() => inputRef.current?.click()}><img src={attachfileicon} alt='' /></span>
                                <input type="text" onChange={onChangeHandle} onKeyUp={(e) => { e.key === "Enter" && (attachFile !== '' || data.messageInput.trim() !== '') && onClickSendButton() }}
                                  className="form-control mx-3 rounded-3" name="messageInput" value={data.messageInput} placeholder='Type Here' />
                                <span className="input-group-text p-0 border-0 bg-transparent" >
                                  <button onClick={onClickSendButton} className='btn p-0 border-0' disabled={data.messageInput.trim() !== '' || attachFile !== '' ? false : true}><img src={sendmessageicon} alt='' /></button>
                                </span>
                              </div>
                            </div>}
                        </div>}
                    </div>
                  </div>


                </div>
              </div>

              {/* ------------------------------------------ Mobile view ------------------------------------------ */}

              <div className='d-lg-none d-block  mt-3'>
                <div className='row'>
                  {mobileChatRoom === false ?
                    <div className='col-12'>
                      <div className='border rounded-4 border-dark chatFeed'>
                        <div className='p-3  fw-bold'>Chats</div>
                        <hr className='m-0 ' />
                        <div className='chatFeed_subContainer rounded-bottom-4 p-3'>
                          <div className='pb-3'>
                            <div className="form-group">
                              <input type="text" onChange={onChangeSearchValue}
                                className="form-control" name="searchValue" value={data.searchValue} placeholder="Search Here" />
                            </div>
                          </div>
                          {listOfChatFeed()}
                        </div>
                      </div>
                    </div>
                    :
                    <div className='col-12 '>
                      <div className=' border rounded-4 border-dark chatRoom position-relative overflow-hidden'>
                        {isLoading ? <Loader /> :
                          <div>


                            <div className='d-flex p-3'>
                              <div className='px-3 py-2' onClick={onClickBackToChatFeedUser}><FontAwesomeIcon icon={faArrowLeft} /></div>
                              <div className='rounded-circle overflow-hidden shadow border' style={{ height: '40px', width: '40px' }}>
                                <img src={currentChatFeedData.ProfileImage === null || currentChatFeedData.ProfileImage === '' || currentChatFeedData.ProfileImage === undefined ? currentChatFeedData.SocialImage === null || currentChatFeedData.SocialImage === '' || currentChatFeedData.SocialImage === undefined ? currentChatFeedData.profile_photo_url : currentChatFeedData.SocialImage : `${apiservice.apiImageShow()}/${currentChatFeedData.ProfileImage}`} alt="" width={'100%'} height={'100%'} />
                              </div>
                              <div className='ms-3 '>
                                <div className='fw-bold mt-2'>{currentChatFeedData.name} {currentChatFeedData.lname}</div>
                              </div>
                            </div>
                            <div className='px-3'>
                              <hr className=' m-0' />
                            </div>
                            <ScrollToBottom className='message_container'>
                              <div className='p-3'>
                                {listOfMessages()}
                              </div>

                            </ScrollToBottom>


                            {currentChatFeedData === '' ? '' :
                              <div className='p-3 msg_input_container position-absolute bottom-0 w-100 rounded-bottom-2'>
                                <div className="input-group rounded-2">
                                  <div className=' w-100'>
                                    {attachFile === '' ? '' :
                                      <div className='px-2 pb-2'>
                                        <div className='d-flex justify-content-between'>
                                          <div>{attachFile.name}</div>
                                          <div><FontAwesomeIcon className='text-danger' onClick={removeAttachment} icon={faTrashAlt} /></div>
                                        </div>
                                      </div>}
                                  </div>
                                  <input
                                    type="file"
                                    accept='application/pdf, application/msword, application/vnd.ms-excel, text/plain, .jpg, .jpeg, .png'
                                    style={{ display: 'none' }}
                                    ref={(instance) => {
                                      inputRef.current = instance; // We also need it to manipulate the elemnent
                                    }}
                                    onChange={selectFile} />
                                  <span className="input-group-text p-0 border-0 bg-transparent" onClick={() => inputRef.current?.click()}><img src={attachfileicon} alt='' /></span>

                                  <input type="text" onChange={onChangeHandle} onKeyUp={(e) => { e.key === "Enter" && (attachFile !== '' || data.messageInput.trim() !== '') && onClickSendButton() }}
                                    className="form-control mx-3 rounded-3" name="messageInput" value={data.messageInput} placeholder='Type Here' />
                                  <span className="input-group-text p-0 border-0 bg-transparent" >
                                    <button onClick={onClickSendButton} className='btn p-0 border-0' disabled={data.messageInput.trim() !== '' || attachFile !== '' ? false : true}><img src={sendmessageicon} alt='' /></button>
                                  </span>
                                </div>
                              </div>}
                          </div>}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div> :
            <div>
              <div className='mt-5 text-center text-danger fw-bold'>
                At present, there are no learners available for chat sessions. We encourage you to showcase your services and prompt your audience to book your sessions. By doing so, you can actively engage with them directly to discuss their learning objectives; and the content of the booked session beforehand.
                You can then schedule the session at your perfect time and date.
              </div>
            </div>
          }
        </div>}
    </>
  )
}

export default MessagePage
