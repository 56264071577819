import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import StarRatings from 'react-star-ratings'
import apiservice from '../../../apiservice/ApiService'
import Loader from '../../../components/Loader'
import { useNavigate, useParams } from 'react-router-dom'

function WriteReviewPage(props) {
    let navigate = useNavigate()
    const params = useParams();
    const initialDataState = {
        ratings: 0,
        comments: ''
    }

    const [data, setData] = useState(initialDataState)
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const onChangeInputHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        setErrorMsg('')
        console.log(data);
    }

    const changeRating = (newRating, name) => {
        console.log(newRating, name);
        setErrorMsg('')
        setData({ ...data, 'ratings': newRating })

    }

    const onClickBack = () => {
        let id = params.id
        navigate(`/contetexpert-home/learnerprofile/${id}`)
    }
    async function submitReview() {
        if (data.ratings === 0) {
            setErrorMsg('Please select a rating and proceed to submit your review!')
        }
        else if (data.comments === '') {
            setErrorMsg('Write your message in few words!')
        }
        else {
            setIsLoading(true)

            let lernerId = params.id
            // alert(lernerId)
            let uData = await apiservice.getLocalStorage('userData')
            let reqData = {
                learnerId: lernerId,
                Ratting: data.ratings,
                comment: data.comments
            }
            try {
                let postRating = await apiservice.apiCall('/LearnerReview', uData.Token, reqData, 'POST')

                if (postRating.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (postRating.data) {
                    setSuccessMsg(true)
                    setIsLoading(false)
                    console.log(postRating.data);
                    setTimeout(() => {
                        setIsLoading(false)
                        setSuccessMsg(false)
                        onClickBack()
                    }, 3000);
                }
                else {
                    setIsLoading(false)
                    console.log(postRating.error);
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
    }



    return (
        <>
            {isLoading ? <Loader /> :
                <div>

                    {successMsg ?
                        <div className='mt-3 text-center'>
                            <div className='text-success py-3'>Review Submitted Successfully!</div>
                        </div>
                        :
                        <div className='p-3'>
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <button className='btn btn-orange px-5' onClick={onClickBack}>Back</button>
                                </div>
                            </div>
                            <div className='border px-3 py-5 rounded-2 mt-3'>
                                <div className='fs-3 fw-bold'>Write a Review</div>
                                <div>We value your feedback! Share your review with us and contribute to enhancing your experience.</div>
                                <div className='text-danger mt-3'>{errorMsg}</div>
                                <div className='mt-3'>
                                    <div>
                                        <StarRatings
                                            rating={Number(data.ratings)}
                                            starRatedColor="#FDB022"
                                            starHoverColor="#FDB022"
                                            numberOfStars={5}
                                            changeRating={changeRating}
                                            name='ratings'
                                        />
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className="form-group">
                                        <textarea type="text" onChange={onChangeInputHandle}
                                            className="form-control bg-light" name="comments" value={data.comments} rows={10} aria-describedby="helpId" placeholder="Comment"></textarea>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <button className='btn border border border-dark px-5' onClick={submitReview} disabled={isLoading ? true : false}>{isLoading ? 'Loading...' : `Submit Now `}<FontAwesomeIcon className='ms-2' icon={faArrowRight} /> </button>
                                </div>
                            </div>
                        </div>}
                </div>}
        </>
    )
}

export default WriteReviewPage
