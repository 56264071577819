import React, { useEffect } from 'react'
import "./LearnerListForSchedule.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import { Pagination } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import calendaricon from '../../../assets/calendarIcon.png'
import stopwatchicon from '../../../assets/stopwatchicon.png'
import Loader from '../../../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
function HistoryList(props) {
    let navigate = useNavigate()
    const params = useParams();
    const [historyData, setHistoryData] = useState([])
    const [serviceId, setServiceId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        getHistoryData()
    }, [])
    const getHistoryData = async () => {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        let data = params.id
        let dataObject = JSON.parse(data)
        console.log(dataObject);
        setServiceId(dataObject.service_id)
        await apiservice.apiCall('/BookServiceCompletedSessionList', uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setHistoryData(res.data)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }

        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }

    let sampleArr = [
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
        { name: 'JavaScript Fundamentals – Part 1', date: '6 December, 2023', time: '02:45 PM' },
    ]
    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(historyData.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = historyData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const learnerList = () => {
        let array = itemsToDisplay
        console.log(array);
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let item = array[index]

            list.push(
                <div className='col-12' key={index} >
                    <div className='py-3 border-bottom '>
                        <div className='d-flex justify-content-between'>
                            <div>
                                {item.chapterTitle}
                            </div>
                            <div className='d-flex'>
                                <div><img className='me-2' src={calendaricon} alt='...' /> {item.completed_date}</div>
                                <div><img className='mx-2' src={stopwatchicon} alt='...' /> {item.completed_time}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )

            if (count === 5) {
                count = 0
            } else {
                count++
            }
        }
        return list
    }
    return (
        <>{isLoading ? <Loader /> :
            <div>
                <div className='p-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='fs-3 fw-bold'>History</div>
                        <div className='fs-3 fw-bold'>
                            <button className='btn btn-orange' onClick={() => navigate(`/contetexpert-home/learnerlistforschedule/${serviceId}`)}>Back</button>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-3'>
                        <b> The Complete JavaScript Course 2023: From Zero to Expert!</b>
                    </div>

                    <div>
                        <div className='mt-3'>
                            <div className=' row'>
                                {learnerList()}
                            </div>
                            <div className="pagination-container mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination>
                                    <Pagination.Prev
                                        className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1 ? true : false}
                                    >
                                        <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                    </Pagination.Prev>
                                    {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                        <Pagination.Item
                                            key={pageRangeStart + offset}
                                            active={pageRangeStart + offset === currentPage}
                                            onClick={() => handlePageChange(pageRangeStart + offset)}
                                        >
                                            {pageRangeStart + offset}
                                        </Pagination.Item>
                                    ))}
                                    {pageRangeEnd < totalPages && (
                                        <Pagination.Ellipsis disabled />
                                    )}
                                    <Pagination.Next
                                        className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages ? true : false}
                                    >
                                        <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default HistoryList
