import React, { Fragment, useEffect, useState } from 'react'
import "./CoursesProvided.scss"
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import MyservicesCard from '../../../components/LandingpageComponents/MyServicesCard/MyservicesCard';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { HiArrowLongRight } from 'react-icons/hi2';
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function CoursesProvided(props) {
  let navigate = useNavigate()
  let [services, setServices] = useState('')
  let [mentor, setMentor] = useState('')
  let [itemsToShow, setItemsToShow] = useState(15)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getCourseData()
    localStorage.removeItem('isFilter')

    if (isDesktop) { setItemsToShow(15); }
    else if (isTablet) { setItemsToShow(6); }
    else if (isMobile) { setItemsToShow(3); }
  }, [])


  function cickedOnShowMore() {
    let adOn = 15;
    if (isDesktop) { adOn = 15; }
    else if (isTablet) { adOn = 6; }
    else if (isMobile) { adOn = 3; }
    let count = itemsToShow
    count = count + adOn
    setItemsToShow(count)
  }

  async function getCourseData() {
    let mdata = await apiservice.getLocalStorage('mentorData')
    console.log(mdata);
    setIsLoading(true)
    setMentor(mdata)
    try {
      let services = await apiservice.apiCall(`/ContentExpertService/${mdata.content_experts_id}`)
      console.log(services);
      if (services.data) {
        setServices(services.data)
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
        console.log(services.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }


  function getCoursesList() {
    let list = [];
    let count = 0
    if (services.length > 0) {

      for (let i = 0; i < services.length; i++) {
        let item = services[i]
        console.log(item);
        if (itemsToShow >= i + 1) {

          list.push(
            <div key={i + 1} className="col-lg-4 col-sm-12 col-md-6 p-3" onClick={async () => { await apiservice.setLocalStorage('serviceData', item); navigate(`/servicedetail/${item.Service_id}`) }}>
              <MyservicesCard
                courseTitle={item.Service_Name}
                courseImg={item.ThumbnailImage}
                serviceType={item.MentorshipType}
                mentorName={`${mentor.name} ${mentor.lname}`}
                ratting={item.averageRating}
                courseDuration={`${item.Hour === null ? 0 : item.Hour}h ${item.Minutes === null ? 0 : item.Minutes}m `}
                timeZone={item.timezone}
                  webinarDate={item.start_date}
                  webinarTime={item.start_time}
                courseCost={item.Price} 
                currencySymbol={item.CurrencySymbol}
                />
                

            </div>
          );

        }

        if (count === 5) {
          count = 0;
        } else {
          count++;
        }
      }
    } else {
      list.push(
        <div className='text-center text-danger fw-bold my-5 '>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
      )
    }

    return list;
  }


  return (
    <Fragment>
      <Header navigate={navigate} />
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: "#f1efe7" }}>


          <article className='container'>
            <div className="row">
              {getCoursesList()}
            </div>
            {
              services.length >= itemsToShow ?
                <div className='w-100 text-center py-4 my-4'>

                  <button
                    type="button"
                    onClick={cickedOnShowMore}
                    className="btn btn-primary text-center px-4 py-2 border-0"
                    style={{ backgroundColor: "#E57A4D" }}
                  >
                    Show More  
                  </button>
                </div> :
                ''}
          </article>
          <Footersection navigate={navigate} />
        </section>}
    </Fragment>
  )
}

export default CoursesProvided
