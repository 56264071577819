import './App.scss';
import SignupPage from './pages/LandingpagePages/SignUpPage/SignupPage';
import OTPverification from './pages/LandingpagePages/OTPverification/OTPverification';
import Loginpage from './pages/LandingpagePages/Loginpage/Loginpage';
import ForgotPass from './pages/LandingpagePages/ForgotPass/ForgotPass';
import Resetpass from './pages/LandingpagePages/Resetpassword/Resetpass';
import Services from './pages/LandingpagePages/Servicespage/Services';
import MentorDetails from './pages/LandingpagePages/MentorsDetails/MentorDetails';
import CoursesProvided from './pages/LandingpagePages/Coursesprovided/CoursesProvided';
import PreviewPage from './pages/LandingpagePages/PreviewPage/PreviewPage';
import ServiceMentorDetail from './pages/LandingpagePages/ServiceMentorDetail/ServiceMentorDetail';
import SelectSchedule from './pages/LandingpagePages/SelectSchedule/SelectSchedule';
import ExploreAllMentors from './pages/LandingpagePages/ExploreAllMentors/ExploreAllMentors';
import MentorAtExpolreAllMentors from './pages/LandingpagePages/MentorAtExpolreAllMentors/MentorAtExpolreAllMentors';
import SearchMentors from './pages/LandingpagePages/SearchMentors/SearchMentors';
import Tearmsandcondition from './pages/LandingpagePages/TermsAndConditions/Termsandconditions';
import CheckoutSection from './pages/LandingpagePages/CheckoutSection/CheckoutSection';
import CheckoutPayment from './pages/LandingpagePages/CheckoutPayment/CheckoutPayment';
import AboutUs from './pages/LandingpagePages/AboutUs/AboutUs';
import ContactUs from './pages/LandingpagePages/ContactUs/ContactUs';
import InterConnectForContentCreator from './pages/LandingpagePages/InterConnectForContentCreator/InterConnectForContentCreator';
import InterConnectForBussiness from './pages/LandingpagePages/InterConnectForBussiness/InterConnectForBussiness';
import InterConnectForCareer from './pages/LandingpagePages/InterConnectForCareer/InterConnectForCareer';
import PrivacyPolicy from './pages/LandingpagePages/PrivacyPolicy/PrivacyPolicy';
import MobileVerification from './pages/LandingpagePages/MobileVerification/MobileVerification';
import LandingPage from './pages/LandingpagePages/Landingpage/LandingPage';
import { Fragment, useEffect, useState, Component } from 'react';

import HomePage from './pages/MentorsPages/HomePage/HomePage';
import HomePageLearner from './pages/LearnersPages/HomePage/HomePage';
import apiservice from './apiservice/ApiService';
import Header from './components/LandingpageComponents/Header/Header';
import Footersection from './components/LandingpageComponents/Footer/Footer';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import ConfirmationPage from './pages/LearnersPages/ConfirmationPage/ConfirmationPage';
import MeetingLeave from './components/MentorsComponents/MeetingLeave/MeetingLeave';
import { Route, Routes } from 'react-router-dom';
import Unsubscribe from './components/Unsubscribe';
import SuccessScheduleMeeting from './pages/MentorsPages/LearnerListForSchedule/SuccessScheduleMeeting';

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uData, setUData] = useState({})

  useEffect(() => {
    const getData = async () => {
      // let localdata= await localStorage.getItem('uData')
      let localdata = await apiservice.getLocalStorage('userData')
      setUData({ ...localdata })
      if (localdata) {
        setIsLoggedIn(true)
      }
      else {
        setIsLoggedIn(false)
      }
    };

    getData()
  }, [isLoggedIn]);


  const myRoute1 = [
    { path: '/successschedulemeeting', component: SuccessScheduleMeeting },
    { path: '/unsubscribe', component: Unsubscribe },
    { path: '/leavemeeting', component: MeetingLeave },
    { path: '/confirmation', component: ConfirmationPage },
    { path: '/linkedin', component: LinkedInCallback },
    { path: '/login', component: Loginpage },
    { path: '/signup', component: SignupPage },
    { path: '/verify/:id', component: OTPverification },
    { path: '/forgotpass', component: ForgotPass },
    { path: '/resetPassword', component: Resetpass },
    { path: '/services', component: Services },
    { path: '/courses', component: CoursesProvided },
    { path: '/contentexpert/:id', component: MentorDetails },
    { path: '/servicedetail/:id', component: PreviewPage },
    { path: '/servicedetail-mentorDetails', component: ServiceMentorDetail },
    { path: '/selectschedule', component: SelectSchedule },
    { path: '/exploreAllMentors', component: ExploreAllMentors },
    { path: '/SingleMentorAtexploreAllMentors', component: MentorAtExpolreAllMentors },
    { path: '/termsandcondition', component: Tearmsandcondition },
    { path: '/searchMentors', component: SearchMentors },
    { path: '/privacy', component: PrivacyPolicy },
    { path: '/checkout', component: CheckoutSection },
    { path: '/checkoutPayment', component: CheckoutPayment },
    { path: '/aboutus', component: AboutUs },
    { path: '/contactus', component: ContactUs },
    { path: '/forcontentcreator', component: InterConnectForContentCreator },
    { path: '/xtforbuizz', component: InterConnectForBussiness },
    { path: '/xtForcareer', component: InterConnectForCareer },
    { path: '/mobileverify', component: MobileVerification },
    { path: '/learner-home', component: HomePageLearner },
    { path: "/contetexpert-home", component: HomePage },
    { path: '/', component: LandingPage },
  ]

  const myRoute2 = [
    { path: '/successschedulemeeting', component: SuccessScheduleMeeting },
    { path: '/unsubscribe', component: Unsubscribe },
    { path: '/leavemeeting', component: MeetingLeave },
    { path: '/confirmation', component: ConfirmationPage },
    { path: '/linkedin', component: LinkedInCallback },
    { path: '/login', component: Loginpage },
    { path: '/signup', component: SignupPage },
    { path: '/verify/:id', component: OTPverification },
    { path: '/forgotpass', component: ForgotPass },
    { path: '/resetPassword', component: Resetpass },
    { path: '/services', component: Services },
    { path: '/courses', component: CoursesProvided },
    { path: '/contentexpert/:id', component: MentorDetails },
    { path: '/servicedetail/:id', component: PreviewPage },
    { path: '/servicedetail-mentorDetails', component: ServiceMentorDetail },
    { path: '/selectschedule', component: SelectSchedule },
    { path: '/exploreAllMentors', component: ExploreAllMentors },
    { path: '/SingleMentorAtexploreAllMentors', component: MentorAtExpolreAllMentors },
    { path: '/termsandcondition', component: Tearmsandcondition },
    { path: '/searchMentors', component: SearchMentors },
    { path: '/privacy', component: PrivacyPolicy },
    { path: '/checkout', component: CheckoutSection },
    { path: '/checkoutPayment', component: CheckoutPayment },
    { path: '/aboutus', component: AboutUs },
    { path: '/contactus', component: ContactUs },
    { path: '/forcontentcreator', component: InterConnectForContentCreator },
    { path: '/xtforbuizz', component: InterConnectForBussiness },
    { path: '/xtForcareer', component: InterConnectForCareer },
    { path: '/mobileverify', component: MobileVerification },
    { path: "/contetexpert-home/*", component: HomePage },
    { path: '/learner-home/*', component: HomePageLearner },
    { path: '/xt', component: LandingPage },
    { path: '/', component: LandingPage },
  ]



  return (
    <Fragment>
      {
        uData.user_type === '1' ?
          <Routes>
            {myRoute1.map((item, index) => {
              if (!isLoggedIn) {
                if (item.path === '/contetexpert-home' || item.path === '/learner-home') {

                  <Route key={index} path={item.path} element={<LandingPage />} />;
                }
                else {
                  return <Route key={index} path={item.path} element={<item.component />} />;
                }
              }
              else if (isLoggedIn) {
                if (uData.user_type === "1" && item.path === '/contetexpert-home') {

                  return <Route key={index} path='/' element={<LandingPage />} />;
                }
                else if (uData.user_type === "2" && item.path === '/learner-home') {

                  return <Route key={index} path='/' element={<LandingPage />} />;
                }
                else {
                  return <Route key={index} path={item.path} element={<item.component />} />;
                }
              }

            })}
          </Routes> :

          <Routes>

            {myRoute2.map((item, index) => {
              if (!isLoggedIn) {
                if (item.path === '/contetexpert-home' || item.path === '/learner-home') {
                  <Route key={index} path={item.path} element={<LandingPage />} />;
                }
                else {
                  return <Route key={index} path={item.path} element={<item.component />} />;
                }
              }
              else if (isLoggedIn) {
                if (uData.user_type === "1" && item.path === '/contetexpert-home') {
                  return <Route key={index} path='/' element={<LandingPage />} />;
                }
                else if (uData.user_type === "2" && item.path === '/learner-home') {
                  return <Route key={index} path='/' element={<LandingPage />} />;
                }
                else {
                  return <Route key={index} path={item.path} element={<item.component />} />;
                }
              }

            })}
          </Routes>
      }
    </Fragment>
  );
}

export default App;
