import React, { Fragment, useEffect, useState } from 'react'
import Header from "../../../components/LandingpageComponents/Header/Header";
import hollowStar from "../../../assets/startHollow.png";
import mentorImgLrg from "../../../assets/mentorAtServiceDetail.png";
import groupOfPeople from "../../../assets/groupOfPeople.png";
import services from "../../../assets/onlieEducation.png";
import education from "../../../assets/university.png";
import nextBtn from "../../../assets/nextBtnCircle.png";
import prevBtn from "../../../assets/prevBtnIcon.png"
import mentorImg from "../../../assets/mentorImg.png";
import StarRatings from "react-star-ratings";
import { HiArrowLongRight } from "react-icons/hi2";
import MyservicesCard from "../../../components/LandingpageComponents/MyServicesCard/MyservicesCard";
import Footersection from "../../../components/LandingpageComponents/Footer/Footer";
import { useNavigate } from 'react-router-dom';


function ServiceMentorDetail(props) {
let navigate = useNavigate()
  useEffect(()=>{
    window. scrollTo(0,0)
    localStorage.removeItem('isFilter')

  },[])

  
  return (
    <Fragment>
        <Header navigate={navigate}/>
      <section
        className="mentorsDetailsSection"
        style={{ backgroundColor: "#f1efe7"}}
      >
        {/* -----------Top section--------------- */}
        <article className="container py-3">
          <div className="row">
            <div className="col-lg-8 col-10">
              <p>Crypto/Web3 &gt;  Content Expert &gt; Service  &gt; Content Expert Details  </p>
              <h3 className="py-2" style={{ fontWeight: 600 }}>
              Greatest Content Expert Inspire
              </h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an.
              </p>
            </div>
            <div className="col-lg-2  d-sm-none d-md-none"></div>
            <div className="col-lg-2 col-2">
              {" "}
              <img src={hollowStar} width={104} height={104} alt="" />{" "}
            </div>
          </div>
        </article>

        <article className="Gmentor">
          <div className="container">
            <div className="border p-3">
              <div className="row">
                <div className="col-lg-3">
                  <img src={mentorImgLrg} alt="" className="mentorsDetailImg" />
                </div>
                <div className="col-lg-9">
                  <div className="m-2">
                    <div className="mb-1 title">Leslie Alexander</div>
                    <div className="mb-1">
                      Web Developer and Designer
                    </div>
                    <div className="d-flex mb-1">
                      {/* <div><img src={Rating} className='me-3' />4.3</div> */}
                      <div>
                        <img
                          src={groupOfPeople}
                          alt=""
                          width={24}
                          height={24}
                          className="me-3 ms-3"
                        />
                        1122 Learners
                      </div>
                      <div>
                        <img
                        alt=""
                          src={services}
                          width={20}
                          height={20}
                          className="me-3 ms-3"
                        />
                        12 Services
                      </div>
                    </div>
                    <div className="mb-1 title">Education</div>
                    <div className="mb-1">
                      Master in Web Developer & Designer and Mentorship
                    </div>
                    <div className="d-flex mb-1">
                      <div>
                        <img
                          src={education}
                          alt=""
                          className="me-3"
                          width={24}
                          height={24}
                        />
                      </div>
                      <div>United Nations University</div>
                    </div>
                    <div className="mb-1 title">Experience</div>
                    <div className="mb-1">Total Experience - 6.5 Years</div>
                    <div className="mb-1 title">About me</div>
                    <div className="mb-1 pe-5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="container py-5">
          <div className="d-flex justify-content-between">
            <h2 className="testimonialHeading">Testimonial</h2>
            <div>
              <img
                src={prevBtn}
                width={40}
                height={40}
                alt=""
              />
              <img src={nextBtn} width={40} height={40} alt="" />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 mb-3">
            <img src={mentorImg} width={100} height={100} alt="" />
            <div className="px-3">
              <span className="reviewerName">Jenny Wilson</span>
              <br />
              <StarRatings
                rating={4.3}
                starDimension='20px'
                starRatedColor="#FDB022"
                numberOfStars={5}
              />
            </div>
          </div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>

          <div className="myServices">
            <div className="d-flex justify-content-between align-items-center" >
              <h4 className="testimonialHeading">My Services</h4>
              <div>
                Explore All Services
              </div>
            </div>
          </div>

          <div className="row  bg-transparent">
            <div className="col-lg-4 col-md-6 col-sm-12" onClick={()=>navigate('/servicedetail')}><MyservicesCard courseTitle="The Complete JavaScript Course 2023: From Zero to Expert!"  mentorName="Esther Howard" courseDuration='68.5 total hours' courseCost="$3,199" /></div>
            <div className="col-lg-4 col-md-6 col-sm-12" onClick={()=>navigate('/servicedetail')}><MyservicesCard courseTitle="The Complete JavaScript Course 2023: From Zero to Expert!" mentorName="Esther Howard" courseDuration='68.5 total hours' courseCost="$3,199" /></div>
            <div className="col-lg-4 col-md-6 col-sm-12" onClick={()=>navigate('/servicedetail')}><MyservicesCard courseTitle="The Complete JavaScript Course 2023: From Zero to Expert!" mentorName="Esther Howard" courseDuration='68.5 total hours' courseCost="$3,199" /></div>
          </div>

        </article>
        <Footersection navigate={navigate}/>
      </section>
    </Fragment>
  )
}

export default ServiceMentorDetail
