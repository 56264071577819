import { React, Fragment } from 'react'
import "./MentorsCard.scss";
function MentorCard({ mentorsName, mentorsDescription, mentorsImage, sideBgColor = 'cardBg0', onClick = null }) {
  return (
    <Fragment>
      <div className=' col-12 mentorsCardMentors my-2'>
        <div className="card" style={{ width: '100%',cursor:'pointer' }}>
          <div className={sideBgColor}>
          </div>
          <div className="card-body p-2 d-flex">
            <div className='rounded-circle overflow-hidden ms-2' style={{ width: '70px', height: '70px' }}>
              <img src={mentorsImage} className='' alt="" width={'100%'} height={'100%'} />
            </div>
            <div className='card-text ms-4 m-2'>
              <div className=' fw-bold'>{mentorsName} </div>
              <p className=" vertical-three-line-text" style={{ fontSize: '12px' }}>{mentorsDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MentorCard
