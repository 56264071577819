
import React, { useEffect } from 'react'
import { useState } from 'react'
import './PreviewPage.scss'
import StarRatings from 'react-star-ratings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import apiservice from '../../../apiservice/ApiService'
import ReactPlayer from 'react-player'
import { Button, Modal, ModalHeader } from 'react-bootstrap'
import moment from 'moment'
import { HiArrowLongRight } from 'react-icons/hi2'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'




function PreviewPage(props) {
    let navigate = useNavigate()
    const initialDataState = {
        industryFocus: '',
        otherIndustryFocus: '',
        serviceName: '',
        serviceDescription: '',
        hours: '',
        minutes: '',
        typeOfService: '',
        webinarDate: '',
        webinarTime: '',
        price: '',
        mentorshipType: '',
        sessionsNo: '',
        participantsNo: '',
        timezone: '',
        imageFile: '',
        imageFileforName: '',
        videoFile: '',
        imageURL: '',
        videoUrl: '',
        youtubeVideoFile: '',

        overview: '',
        contentDescription: '',
        chapterTitle: '',
        sessionDuration: '',
        resourcesFile: [],
        chapterDetailsArr: [],


        question: '',
        answer: '',
        queAnsArr: [],
        editQAIndex: '',

        fromHours: '',
        toHours: '',
        Content_Description: ''
    };
    const [serviceData, setServiceData] = useState(initialDataState);
    const [userData, setUserdata] = useState({})
    const [currentStep, setCurrentStep] = useState('step1')
    const [questionNumber, setQuestionNumber] = useState(null)
    const [reviewListArr, setReviewListArr] = useState([])
    const [fromService, setFromService] = useState(false)
    let [showModal, setshowModal] = useState(false)
    let [loadingCreateservice, setLoadingCreateservice] = useState(false)
    let [currServiceForedit, setCurrServiceForEdit] = useState()
    const [viewShowCount, setViewShowCount] = useState(3)
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const getUserData = async () => {
        let udata = await apiservice.getLocalStorage('userData')
        setUserdata(udata)
        getdata(udata)
        let getCurrentService = await apiservice.getLocalStorage('currentService');
        setCurrServiceForEdit(getCurrentService);
    }

    async function getdata(udata) {
        let userData = udata

        setIsLoading(true)
        function isYouTubeLink(link) {
            let youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).*/;
            return youtubeRegex.test(link);
        }

        if (!props.showPreview) {

            setFromService(false)
            let previewId = await apiservice.getLocalStorage('serviceData'); //this will get serviceId
            let previewdata;
            try {

                let previewApi = await apiservice.apiCall(`/SingleServiceShow/${previewId}`, userData.Token)
                if (previewApi.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (previewApi.data) {
                    setIsLoading(false)
                    previewdata = previewApi.data;
                    setReviewListArr(previewdata.ReviewsList)

                    isYouTubeLink(previewdata.UploadVideo)
                    console.log(previewdata.UploadVideo, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
                    // alert(previewdata.start_date)
                    setServiceData({
                        ...previewApi.data,
                        QueAns: JSON.parse(previewApi.data.QueAns),
                        chapterDetailsArr: JSON.parse(previewdata.Chapter),
                        industryFocus: previewdata.Industry,
                        otherIndustryFocus: previewdata.Other_Industry_Name,
                        serviceName: previewdata.Service_Name,
                        serviceDescription: previewdata.Service_Description,
                        hours: previewdata.Hour,
                        minutes: previewdata.Minutes,
                        webinarDate: previewdata.start_date,
                        webinarTime: previewdata.start_time,
                        timezone: previewdata.timezone,
                        typeOfService: previewdata.Price === 0 ? 'Free' : 'Paid',
                        price: previewdata.Price,
                        mentorshipType: previewdata.MentorshipType,
                        imageFile: previewdata.ThumbnailImage,
                        imageFileforName:previewdata.ThumbnailImage,
                        imageURL: `${apiservice.apiImageShow()}/${previewdata.ThumbnailImage}`,

                        videoUrl: previewdata.UploadVideo === null ? null : isYouTubeLink() ? previewdata.UploadVideo : `${apiservice.apiImageShow()}/${previewdata.UploadVideo}`,
                        videoFile: previewdata.UploadVideo === null ? '' : previewdata.UploadVideo,
                        youtubeVideoFile: previewdata.UploadVideo === null ? null : isYouTubeLink() ? previewdata.UploadVideo : `${apiservice.apiImageShow()}/${previewdata.UploadVideo}`,

                        resourcesFile: previewdata.Resources,
                        overview: previewdata.Overview,
                        contentDescription: previewdata.Content_Description,
                        queAnsArr: JSON.parse(previewdata.QueAns),
                    })


                }
                else {

                    setIsLoading(false)
                    console.log(previewApi.error);
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
            // console.log(serviceData);
        }

        else {

            const blobUrlPattern = /^blob:/;

            function isBlobURL(url) {
                return blobUrlPattern.test(url);
            }

            function isValidBase64(str) {
                const imageDataUrlRegex = /^data:image\/png;base64,([A-Za-z0-9+/]+={0,2})$/;

                return imageDataUrlRegex.test(str);
            }
            setIsLoading(false)

            setFromService(true)
            let previewdata = props.prevData;
            console.log(previewdata);
            let mergedDateTime = null
            console.log(previewdata.videoUrl, '++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
            if (previewdata.mentorshipType === 'Webinar Session') {

                const [hours, minutes] = previewdata.webinarTime.split(':');
                mergedDateTime = new Date(previewdata.webinarDate);
                mergedDateTime.setHours(parseInt(hours, 10));
                mergedDateTime.setMinutes(parseInt(minutes, 10));

                console.log('Merged Date and Time:', mergedDateTime);
            }

            setServiceData({
                ...previewdata,
                industryFocus: previewdata.industryFocus,
                otherIndustryFocus: previewdata.otherIndustryFocus,
                serviceName: previewdata.serviceName,
                serviceDescription: previewdata.serviceDescription,
                hours: previewdata.hours,
                minutes: previewdata.minutes,
                webinarDate: mergedDateTime,
                typeOfService: '',
                price: previewdata.price,
                mentorshipType: previewdata.mentorshipType,
                sessionsNo: previewdata.sessionsNo,
                participantsNo: previewdata.participantsNo,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
                imageFile: previewdata.imageFile,
                imageFileforName:previewdata.ThumbnailImage,
                imageURL: isBlobURL(previewdata.imageURL) === false ? `${apiservice.apiImageShow()}/${previewdata.imageURL}` : previewdata.imageURL,
                videoFile: previewdata.youtubeVideoFile === null ? previewdata.videoFile : '',
                videoUrl: previewdata.youtubeVideoFile === null && isBlobURL(previewdata.videoUrl) === false ? previewdata.videoUrl === null ? null : `${apiservice.apiImageShow()}/${previewdata.videoUrl}` : previewdata.videoUrl,
                youtubeVideoFile: previewdata.youtubeVideoFile === '' ? '' : previewdata.youtubeVideoFile,

                overview: previewdata.overview,
                contentDescription: previewdata.contentDescription,
                resourcesFile: previewdata.resourcesFile,
                chapterDetailsArr: previewdata.chapterDetailsArr,


                question: previewdata.question,
                answer: previewdata.answer,
                queAnsArr: previewdata.queAnsArr,
                editQAIndex: previewdata.editQAIndex,

                fromHours: previewdata.editQAIndex,
                toHours: previewdata.toHours,
            })
            console.log(serviceData);

        }

    }
    useEffect(() => {
        getUserData()

    }, [])

    const formSteps = (e) => {
        setCurrentStep(e)
    }


    const onClickQuetions = (e) => {
        setQuestionNumber(e)
        console.log(e);
    }

    // const onSelectResourcesFile = (file) => {
    //     console.log(file);
    //     setServiceData({ ...serviceData, 'resourcesFile': file });
    // }



    function loadQA() {
        let list = [];

        let element = serviceData.queAnsArr || [];

        for (let i = 0; i < element.length; i++) {
            let items = element[i]

            list.push(
                <div key={i} className="accordion-item bg-transparent">
                    <h2 className="accordion-header bg-transparent" id={"headingOne" + i}>
                        <button onClick={() => onClickQuetions(i)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + i} aria-expanded="true" aria-controls={"collapseOne" + i}>
                            <div className='p-lg-2 col-1'>
                                <div className={questionNumber === i ? 'rounded-circle  text-center active-circle  px-2 pt-2 mt-2' : 'quetionbtn rounded-circle px-2 pt-2 mt-2 text-center '} style={{ width: '35px', height: '35px' }}>
                                    {i + 1}
                                </div>
                            </div>
                            <div className='p-2 col-10 '>
                                <div className='mx-3 pt-2'>{items.question}</div>

                            </div>

                        </button>
                    </h2>
                    <div id={"collapseOne" + i} className="accordion-collapse collapse bg-transparent " aria-labelledby={"headingOne" + i} data-bs-parent="#accordionExample">
                       
                        <div className='accordion-body bg-transparent desc ' dangerouslySetInnerHTML={{ __html: items.answer }} />
                    </div>
                </div>
            )
        }
        return list
    }
    const onClickDownload = (filename) => {
        console.log(filename);

        const fileUrl = `${apiservice.backendOrigin()}/uploads/${filename}`

        const link = document.createElement('a')
        link.href = fileUrl
        link.target = '_blank'
        link.download = { filename }
        document.body.appendChild(link)
        link.click();
        link.parentNode.removeChild(link)
    }
    const loadResources = () => {
        let list = []

        let items = serviceData.resourcesFile || [];

        if (!Array.isArray(items)) {

            for (let value in items) {

                list.push(
                    <div key={value} className='p-2 mt-2 bg-light rounded-1'>
                        <div className='d-flex justify-content-between'>
                            <div className='overflow-hidden'>{items[value].file_path}</div>
                            {/* <div>{items[index].sessionDuration} min </div> */}
                            <div className="ms-3" onClick={() => onClickDownload(items[value].file_path)}  ><FontAwesomeIcon icon={faDownload} /></div>
                        </div>
                    </div>
                )
            }
        }
        else {
            for (let i = 0; i < items.length; i++) {
                let value = items[i]

                list.push(
                    <div key={i} className='p-2 mt-2 bg-light rounded-1'>
                        <div className='d-flex justify-content-between'>
                            <div className=' overflow-hidden'>{value}</div>
                            {/* <div>{items[index].sessionDuration} min </div> */}
                            <div className="ms-3" onClick={() => onClickDownload(value)}><FontAwesomeIcon icon={faDownload} /></div>
                        </div>
                    </div>
                )
            }
        }

        return list
    }
    const clickedOnEditService = async () => {

        if (fromService === true) {
            apiservice.setLocalStorage('currentService', serviceData);
            navigate('/contetexpert-home/createnewservices');
            props.setShowPreview(false)
            props.setCurrentStep('step1')
        }
        else {
            apiservice.setLocalStorage('currentService', serviceData)
            navigate('/contetexpert-home/createnewservices')

            // let reqData = {

            //     Industry: props.prevData.industryFocus || "",
            //     Other_Industry_Name: props.prevData.otherIndustryFocus || "",
            //     Service_Name: props.prevData.serviceName || "",
            //     Service_Description: props.prevData.serviceDescription || "",

            //     Hour: props.prevData.hours || "",
            //     Minutes: props.prevData.minutes || "",
            //     Price: props.prevData.price || "",

            //     MentorshipType: props.prevData.mentorshipType || "",
            //     NumberOfSession: props.prevData.sessionsNo || "",
            //     NumberOfParticipant: props.prevData.participantsNo || "",

            //     UploadVideo: props.prevData.videoFile || "",
            //     ThumbnailImage: props.prevData.imageFile || "",

            //     Overview: props.prevData.overview || "",
            //     Content_Description: props.prevData.contentDescription || "",
            //     Chapter: props.prevData.chapterDetailsArr || "",
            //     Resources: props.prevData.resourcesFile || "",
            //     QueAns: props.prevData.queAnsArr || "",
            // }

        }

    }

    async function createService() {
        setLoadingCreateservice(true);
        // setIsLoading(true)
        console.log(props.prevData)
        let requiredData = {
            Industry: props.prevData.industryFocus || "",
            Other_Industry_Name: props.prevData.otherIndustryFocus || "",
            Service_Name: props.prevData.serviceName || "",
            Service_Description: props.prevData.serviceDescription || "",

            Hour: props.prevData.hours || "",
            Minutes: props.prevData.minutes || "",
            Price: props.prevData.price,

            MentorshipType: props.prevData.mentorshipType || "",
            NumberOfSession: props.prevData.mentorshipType === '1:1 Single Session' || props.prevData.mentorshipType === 'Webinar Session' ? 1 : props.prevData.sessionsNo,
            NumberOfParticipant: props.prevData.mentorshipType === '1:1 Single Session' || props.prevData.mentorshipType === '1:1 Multi-Session' ? 1 : props.prevData.participantsNo || "",
            started_date: props.prevData.webinarDate || "",
            started_time: props.prevData.webinarTime || "",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",

            UploadVideo: props.prevData.videoFile === null || props.prevData.videoFile === '' ? props.prevData.youtubeVideoFile : props.prevData.videoFile,
            ThumbnailImage: props.prevData.imageFile || "",

            Overview: props.prevData.overview || "",
            Content_Description: props.prevData.contentDescription || "",
            Chapter: JSON.stringify(props.prevData.chapterDetailsArr) || "",
            Resources: props.prevData.resourcesFile.toLocaleString() || "",
            QueAns: JSON.stringify(props.prevData.queAnsArr) || "",
        }
        console.log(requiredData);


        let formData = new FormData();
        for (let key in requiredData) {
            formData.append(key, requiredData[key]);
        }
        try {

            let getData = await apiservice.getLocalStorage('userData')
            let res = await apiservice.apiCallFormData("/Service_Add", getData.Token, formData, 'POST')
            console.log(res);
            setshowModal(true)
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setLoadingCreateservice(false)
                setshowModal(true)
                setSuccessMessage(res.data)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
                setLoadingCreateservice(false)
                setErrorMessage(res.error)
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
            setLoadingCreateservice(false)
        }

    }

    async function updateService() {
        setLoadingCreateservice(true)
        setIsLoading(true)
        console.log('props.prevData.videoFile:-', props.prevData.videoFile, 'props.prevData.youtubeVideoFile :-', props.prevData.youtubeVideoFile, 'props.prevData.videoUrl :-', props.prevData.videoUrl);
        let requiredData = {

            Industry: props.prevData.industryFocus || "",
            Other_Industry_Name: props.prevData.otherIndustryFocus || "",
            Service_Name: props.prevData.serviceName || "",
            Service_Description: props.prevData.serviceDescription || "",

            Hour: props.prevData.hours,
            Minutes: props.prevData.minutes,
            Price: props.prevData.price,

            MentorshipType: props.prevData.mentorshipType || "",
            NumberOfSession: props.prevData.mentorshipType === '1:1 Single Session' || props.prevData.mentorshipType === 'Webinar Session' ? 1 : props.prevData.sessionsNo,
            NumberOfParticipant: props.prevData.mentorshipType === '1:1 Single Session' || props.prevData.mentorshipType === '1:1 Multi-Session' ? 1 : props.prevData.participantsNo,

            // UploadVideo: props.prevData.videoFile || '',
            UploadVideo: props.prevData.videoUrl === null || props.prevData.videoUrl === '' ? props.prevData.youtubeVideoFile : props.prevData.videoFile,

            ThumbnailImage: props.prevData.imageFile || "",

            Overview: props.prevData.overview || "",
            Content_Description: props.prevData.contentDescription || "",
            Chapter: JSON.stringify(props.prevData.chapterDetailsArr) || "",
            Resources: props.prevData.resourcesFile || "",
            QueAns: JSON.stringify(props.prevData.queAnsArr) || "",
        }
        console.log(requiredData, '+++++++++++++++++++++++++++++++++++++');
        // console.log(props.prevData.price, '+++++++++++++++++++++++++++++++++++++');

        let formData = new FormData();
        for (let key in requiredData) {
            formData.append(key, requiredData[key]);
        }
        try {
            let getData = await apiservice.getLocalStorage('userData')

            let res = await apiservice.apiCallFormData(`/Service_Update/${currServiceForedit.Service_id}`, getData.Token, formData, 'POST')
            setshowModal(true)

            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setLoadingCreateservice(false)
                await apiservice.setLocalStorage('currentService', res.data)
                setshowModal(true)

                setSuccessMessage(res.data)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
                setLoadingCreateservice(false)

                setErrorMessage(res.error)
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
            setLoadingCreateservice(false)
        }
    }


    function loadChapter() {
        let list = [];
        let reqdata = serviceData.chapterDetailsArr || JSON.parse(serviceData.Chapter);

        for (let i = 0; i < reqdata.length; i++) {
            let item = reqdata[i]
            list.push(
                <div key={i} className='mt-3 row bg-light p-2'>
                    <div className='col-md-10 col-8'>
                        {item.chapterTitle}
                    </div>
                    <div className='col-md-2 col-4 text-end'> {item.sessionDuration}{item.sessionDuration === '1' ? ' hr' : " min"}</div>
                </div>
            )
        }
        return list
    }

    const listOfReviews = () => {
        let list = []
        let dataArr = reviewListArr.length > 0 ? reviewListArr : []
        if (dataArr.length > 0) {
            for (let index = 0; index < dataArr.length; index++) {
                const element = dataArr[index];
                let learner = element.learner
                if (viewShowCount >= index + 1) {
                    list.push(
                        <div className="border-bottom py-3">
                            <div className="d-flex align-items-center py-2 mb-3">
                                <div className="rounded-circle overflow-auto" style={{ width: '100px', height: '100px' }}>
                                    <img src={learner.ProfileImage === null ? learner.SocialImage === null ? learner.profile_photo_url : learner.SocialImage : `${apiservice.apiImageShow()}/${learner.ProfileImage}`} width={'100%'} height={'100%'} alt="" />

                                </div>
                                <div className="px-3">
                                    <span className="reviewerName">{element.learner.name} {element.learner.lname}</span>
                                    <br />
                                    <StarRatings
                                        rating={Number(element.Ratting)}
                                        starDimension='20px'
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    />
                                </div>
                            </div>
                            <p>
                                {element.comment}
                            </p>
                        </div>
                    )
                }
            }
        } else {
            list.push(<div className=' fw-bold text-danger my-5'>To date, we have not received any reviews or feedback for this. Your insights are crucial to our ongoing enhancements, and we appreciate your valuable input.</div>)
        }
        return list
    }

    const onClickShowMore = () => {
        let count = viewShowCount
        count = count + 3
        // alert(count)
        setViewShowCount(count)

    }


    return (
        <>
            <Modal
                show={showModal}
                onHide={() => setshowModal(false)}
                backdrop="static"
                keyboard={false}
                size='lg'
            >
                <ModalHeader>
                    {successMessage !== '' ? successMessage : errorMessage}
                    {successMessage !== '' ?
                        <Button variant="primary" onClick={() => { setshowModal(false); navigate('/contetexpert-home/servicelist') }}>Ok</Button> :
                        <Button variant="primary" onClick={() => { setshowModal(false) }}>Ok</Button>}

                </ModalHeader>
            </Modal>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold Title'>Preview</div>
                        <div>
                            <button className='btn btn-dark btnM px-4' onClick={clickedOnEditService} >Edit</button>
                            {fromService === false ? " " : props.dataToEdit === true ? <button className='btn btn-orange px-lg-5 mx-1 btnM' onClick={updateService} disabled={loadingCreateservice ? true : false} > {loadingCreateservice ? 'Loading...' : ' Update'}</button> : ''}

                            {fromService === false ? " " : props.dataToEdit === false ? <button className='btn btn-orange px-lg-5 mx-1 btnM' onClick={createService} disabled={loadingCreateservice ? true : false} > {loadingCreateservice ? 'Loading...' : ' Create'}</button> : ''}
                        </div>
                    </div>
                    <hr />
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-lg-8 col-12'>

                                <div className='mt-3'>
                                    <div className='subTitle fw-bold mb-2'>{serviceData.serviceName}</div>
                                    <div className='desc mb-2'>{serviceData.serviceDescription}</div>
                                </div>
                                <div className='fw-bold'>{userData.name} {userData.lname}</div>
                                <div className='mb-2'>
                                    <StarRatings
                                        rating={serviceData.averageRating === null || serviceData.averageRating === undefined || serviceData.averageRating === '' ? 0 : Number(serviceData.averageRating)}
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    /> <span className='ps-2'>{serviceData.averageRating === null || serviceData.averageRating === undefined || serviceData.averageRating === '' ? 0 : Number(serviceData.averageRating).toFixed(1)}</span>
                                </div>
                                <div className='d-flex mt-3'>
                                    <div className='px-lg-1 px-1' style={{ backgroundColor: '#ABEFC6' }} > {serviceData.mentorshipType} </div>
                                    {/* <div className='mx-lg-2'>•</div> */}
                                    <div className='ms-2'><b>Total Time:</b> {serviceData.hours}h {serviceData.minutes}m  </div>

                                </div>
                                {serviceData.mentorshipType === 'Webinar Session' ?
                                    serviceData.start_date === null ? '' :
                                        <div className='mt-3'><b>Date:</b> {moment(serviceData.webinarDate).format('DD-MM-YYYY')}, <b>Time:</b> {serviceData.webinarTime}  ({serviceData.timezone})</div> : ''}

                                <hr />
                                {/* ......................................................................................................................... */}

                                <div className='p-3'>
                                    <div className='container-fluid'>
                                        <div className='fw-bold     Title'>Create a New Service</div>
                                    </div>
                                    <div className='mt-3 d-none d-lg-block'>
                                        <div className='row btnM'>
                                            <div className='col-lg-3 col-12 p-2'> <div className={currentStep === 'step1' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step1')} style={{ cursor: 'pointer' }}>Overview</div></div>
                                            <div className='col-lg-3 col-12 p-2'> <div className={currentStep === 'step2' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step2')} style={{ cursor: 'pointer' }}>Content</div></div>
                                            <div className='col-lg-3 col-12 p-2'> <div className={currentStep === 'step3' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step3')} style={{ cursor: 'pointer' }}>Q&A</div></div>
                                            <div className='col-lg-3 col-12 p-2'> <div className={currentStep === 'step4' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step4')} style={{ cursor: 'pointer' }}>Resources</div></div>
                                            <div className='col-lg-3 col-12 p-2'> <div className={currentStep === 'step5' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step5')} style={{ cursor: 'pointer' }}>Reviews</div></div>
                                        </div>
                                    </div>
                                    <div className='mt-3 d-lg-none d-block btnM'>
                                        <div className='row'>
                                            <div className='col-6 p-2'> <div className={currentStep === 'step1' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step1')}>Overview</div></div>
                                            <div className='col-6 p-2'> <div className={currentStep === 'step2' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step2')}>Content</div></div>


                                        </div>
                                        <div className='row'>
                                            <div className='col-6 p-2'> <div className={currentStep === 'step3' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step3')}>Q&A</div></div>
                                            <div className='col-6 p-2'> <div className={currentStep === 'step4' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step4')}>Resources</div></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 p-2'> <div className={currentStep === 'step5' ? 'p-2 fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={() => formSteps('step5')}>Reviews</div></div>
                                        </div>
                                    </div>
                                    <div className='mt-lg-5 mt-3'>
                                        {/* Form Step 1 */}
                                        {currentStep === 'step1' ?
                                            <div className='mt-lg-5'>
                                                <div className="fw-bold subTitle mb-3">Overview</div>
                                                {/* <div className='desc'> {serviceData.overview} </div> */}
                                                <div className='desc' dangerouslySetInnerHTML={{ __html: serviceData.overview }} />

                                            </div>
                                            :
                                            /* Form Step 2 */
                                            currentStep === 'step2' ?
                                                <div>
                                                    <div className="fw-bold subTitle">Service Content</div>
                                                    <div className="mt-3 mb-5 desc" dangerouslySetInnerHTML={{ __html: serviceData.contentDescription }}/>
                                                    {/* <div className='fw-bold'>Service Description :</div>
                                                <p>{serviceData.serviceDescription}</p> */}
                                                    {loadChapter()}

                                                </div>
                                                :
                                                /* Form Step 3 */
                                                currentStep === 'step3' ?
                                                    <div>
                                                        <div className='fw-bold subTitle'>Questions and Answers</div>

                                                        <div className='mt-3'>
                                                            <div className="accordion bg-transparent" id="accordionExample">
                                                                {loadQA()}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    /* Form Step 4 */
                                                    currentStep === 'step4' ?
                                                        <div>
                                                            <div className=' col-12'>

                                                                <div className='mt-3 d-flex justify-content-between'>
                                                                    {/* <div>
                                                                    <div className="form-group">
                                                                        <input type="file"
                                                                            className="form-control border-0" name="resourcesFile" onChange={onSelectResourcesFile} placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className='badge text-dark py-2 px-4 ' style={{ backgroundColor: '#ABEFC6' }}>Upload</div> */}
                                                                </div>
                                                                <div className='mt-3 row p-2' >
                                                                    {loadResources()}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        /* Form Step 5 */
                                                        <div>
                                                            {listOfReviews()}

                                                            {viewShowCount >= reviewListArr.length ? '' :
                                                                <div className="w-100 text-center py-3 my-2">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary border-0 text-center"
                                                                        style={{ backgroundColor: "#E57A4D" }}
                                                                        onClick={onClickShowMore}
                                                                    >
                                                                        Show More
                                                                    </button>
                                                                </div>}
                                                        </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-4 col-12'>
                                <div>
                                    <div className="card bg-transparent">
                                        <div>
                                            {/* <Player playsInline src={JSON.stringify(serviceData.UploadVideo)} /> */}
                                            {/* {console.log(serviceData.videoUrl, '................', serviceData.youtubeVideoFile)}
                                            {console.log(serviceData.videoUrl === null && serviceData.youtubeVideoFile === null)} */}
                                            {serviceData.videoUrl === null && (serviceData.youtubeVideoFile === null || serviceData.youtubeVideoFile === '') ?
                                                <div><img src={serviceData.imageURL} alt='...' className='w-100' /></div> :
                                                <ReactPlayer
                                                    controls
                                                    width='100%'
                                                    height='100%'
                                                    playing
                                                    light={<img width='100%' height='165px' src={serviceData.imageURL} alt='Thumbnail' />}
                                                    url={serviceData.videoUrl === null || serviceData.videoUrl === '' ? serviceData.youtubeVideoFile : serviceData.videoUrl}
                                                />
                                            }

                                        </div>
                                        <div className="card-body">
                                            <small className='fw-bold'>{serviceData.serviceName}</small><br />
                                            <small className=''>Enroll in our courses led by our esteemed Content Expert to hone your skills and achieve your goals. Let's embark on a journey of learning and growth together with <b>{userData.name} {userData.lname}.</b></small>
                                            <div className='fw-bold'>{userData.CurrencySymbol} {Number(serviceData.price).toFixed(2)}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default PreviewPage
