import React, { useState, Fragment } from "react";
import logo from "../../../assets/Logo1.png";
import search from "../../../assets/searchicon.png";
import arrow from "../../../assets/arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faGripHorizontal, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import './Header.scss'
import { current } from "@reduxjs/toolkit";
import Select from "react-select";
import noProfileImg from "../../../assets/noProfileImg.png"
import apiservice from "../../../apiservice/ApiService";
import IndustryArray from './../../MentorsComponents/IndustryArray/IndustryArray';




function Header(props) {
  const initialData = {
    currentMenu: '/xt'
  }
  const [data, setData] = useState(initialData)

  let [searchinp, setSearchInp] = useState('')
  let [industrytype, setIndustryType] = useState('')

  let [industryArr, setIndArr] = useState([])
  let [userdata, setUserData] = useState({})

  const [showSearch, setShowSearch] = useState(false)
  const onClickSearch = () => {
    setShowSearch(true)
  }
  const onClickClose = () => {
    setShowSearch(false)
  }

  useEffect(() => {
    let currPath = window.location.pathname
    localStorage.setItem('curMenu', currPath)

    async function getReqData() {
      if (window.location.pathname === '/') {
        localStorage.setItem('curMenu', 'xt')
      }
      if (localStorage.getItem('isFilter') === 'inactive' || window.location.pathname !== '/services') {
        localStorage.setItem('industryType', 'Accounting')
        localStorage.setItem('searchInput', '')
      }

      let induType = localStorage.getItem('industryType')
      setIndustryType(induType)

      let searchInput = localStorage.getItem('searchInput')
      setSearchInp(searchInput)

      let array = IndustryArray();
      setIndArr(array)
      let curMenu = localStorage.getItem('curMenu')
      let userData = await apiservice.getLocalStorage('userData')
      console.log(userData);
      setUserData(userData)
      setData({ ...data, currentMenu: curMenu })
    }
    getReqData()
  }, [])

  const onChangeHandle = (e) => {
    let inputValue = e.target.value
    setSearchInp(inputValue)
    localStorage.setItem('searchInput', inputValue)
  }
  const onChageMenu = (e) => {
    localStorage.setItem('curMenu', e)
    console.log(e);
    props.navigate(e)
    setData({ ...data, 'currentMenu': localStorage.getItem('curMenu') })
  }

  function clickedOnSearch() {
    let dataObject = {}
    dataObject.indusrtyType = industrytype
    dataObject.searchValue = searchinp
    console.log(dataObject);
    localStorage.setItem('indusrtyType', industrytype)
    localStorage.setItem('searchValue', searchinp)
    localStorage.setItem('isFilter', 'active');
    console.log(window.location.pathname);
    if (window.location.pathname === '/services') {
      props.onClickSearch()
    }
    props.navigate('/services')

  }


  async function changeIndustry(e) {
    let selectValue = e.target.value
    setIndustryType(selectValue)
    localStorage.setItem('industryType', e.target.value)
  }

  async function goToLandingpage() {
    let userType = await apiservice.getLocalStorage('userData')

    if (userType.user_type === 1) {
      props.navigate('/learner-home')
    }
    else if (userType.user_type === 2) {
      props.navigate('/contetexpert-home')
    }
  }

  return (

    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-white headerNavBar" id='header'>
        <div className="container">
          <div className="navbar-brand" type='button' onClick={() => props.navigate('/')}>
            <img src={logo} alt="" />
          </div>
          <div className="px-2">
            <div className="d-lg-block d-none border border-dark rounded-3 " style={{ outline: 'none', border: 'none' }}>
              <div className="form-group  border-0 p-1" >
                <select className="form-select border-0 py-2" defaultValue='/xt' style={{ outline: 'none', border: 'none' }} onChange={(e) => onChageMenu(e.target.value)} name='currentMenu' value={data.currentMenu}>
                  <option value='/xt'  >XperThrivr  For</option>
                  <option value="/xtForcareer"> Careers </option>
                  <option value='/forContentCreator'> Content Creators </option>
                  <option value='/xtforbuizz'> Businesses </option>
                </select>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-12 d-lg-block p-0 m-0 d-none mt-3'>
            <div className="input-group mb-3 border rounded-3 border-dark">
              <span className="input-group-text border-0 bg-transparent">
                <div className="dropdown border-end border-dark" style={{ width: '250px' }}>
                  <div className="form-group" style={{ outline: 'none' }}>
                    <select className="form-select border-0" value={industrytype} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
                      {
                        industryArr.map((item, i) => {
                          return (
                            <option key={i} value={item.name} className="border-0" >{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </span>
              <input type="text" className="form-control border-0 bg-transparent" value={searchinp} onChange={onChangeHandle} placeholder='Search by content experts' />
              <span className="input-group-text border-0 bg-transparent d-lg-block d-none">
                <button className='btn btn-search-btn-blue ' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} className='' /></button>
              </span>
              <span className="input-group-text border-0 bg-transparent w-100  d-lg-none d-block">
                <button className='btn btn-search-btn-blue w-100' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} className='' /></button>
              </span>
            </div>
          </div>

          {
            localStorage.getItem('userData') ?
              <div className="d-flex">
                <button className='btn d-lg-none d-lg-block border border-dark m-lg-1 p-lg-3 me-3' onClick={onClickSearch} ><img src={search} alt="" width={16} height={16} className='me-lg-2' /></button>
                <div className="ms-1 ms-lg-0">

                  <img src={userdata.ProfileImage === null ? userdata.SocialImage === null ? userdata.profile_photo_url : userdata.SocialImage : `${apiservice.apiImageShow()}/${userdata.ProfileImage}`} className=" rounded-circle" onClick={goToLandingpage} width={50} height={50} style={{ cursor: 'pointer' }} />
                </div>
              </div>
              :
              <div className='col-lg-2'>
                <button className='btn d-lg-none d-lg-block btn-outline-dark m-lg-1 p-lg-3 me-1' onClick={onClickSearch} ><img src={search} alt="" width={16} height={16} className='me-lg-2' /></button>
                <button className='btn p-lg-3 btn-outline-dark rounded m-lg-1 bg-white text-dark me-1' onClick={() => props.navigate('/login')} >Sign In</button>
                <button className='btn p-lg-3 btn-dark rounded ms-lg-1' onClick={() => props.navigate('/signup')} >Sign Up</button>
              </div>
          }

          {/* <FontAwesomeIcon icon={faEllipsisV} className="fa-2x mx-3 d-lg-none d-block" /> */}
          <div className="d-lg-none d-block w-100 mt-2">
            <div className="form-group w-100 border-dark border rounded-3">
              <select className="form-select border-0 py-2" defaultValue='/xt' style={{ outline: 'none', border: 'none' }} onChange={(e) => onChageMenu(e.target.value)} name='currentMenu' value={data.currentMenu}>
                <option value='/xt'  >XperThrivr  For</option>
                <option value="/xtForcareer"> Careers </option>
                <option value='/forContentCreator'> Content Creators </option>
                <option value='/xtforbuizz'> Businesses </option>
              </select>
            </div>
          </div>
          {
          showSearch ?
            <div className="px-xl-5 mx-xl-3 w-100 mt-2">
              <div className='text-end'>
                <FontAwesomeIcon className='text-danger' onClick={onClickClose} icon={faTimes} />
              </div>

              <div className="p-3 border border-black rounded">
                    <div className='col-6 col-12'>
                      <span className="border-0  bg-transparent ">
                        <div className="dropdown border-dark">
                          <div className="form-group" style={{ outline: 'none' }}>
                            <select className="form-select w-100" value={industrytype} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
                              {
                                industryArr.map((item, i) => {
                                  return (
                                    <option key={i} value={item.name} className="border-0" >{item.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div className='col-6 col-12'>
                      <div className='d-flex'>
                        <input type="text" className="form-control border-0 border-bottom bg-transparent mb-2" value={searchinp} onChange={onChangeHandle} placeholder='Search by content experts' />
                        <span className="input-group-text border-0 bg-transparent">
                          <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} /></button>
                        </span>
                      </div>
                    </div>
              </div>


              {/* <div className='col-lg-10 col-12 d-lg-none d-block'>
                <div className='row serachInput'>
                  <div className="input-group mb-3 border rounded-3 border-dark ">
                    <div className='col-6 col-12'>
                      <span className="input-group-text border-0  bg-transparent ">
                        <div className="dropdown border-dark">
                          <div className="form-group" style={{ outline: 'none' }}>
                            <select className="form-select" value={industrytype} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
                              {
                                industryArr.map((item, i) => {
                                  return (
                                    <option key={i} value={item.name} className="border-0" >{item.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div className='col-6 col-12'>
                      <div className='d-flex ms-3'>
                        <input type="text" className="form-control border-0 border-bottom bg-transparent mb-2" value={searchinp} onChange={onChangeHandle} placeholder='Search by content experts' />
                        <span className="input-group-text border-0 bg-transparent">
                          <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} /></button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            // <div className="d-lg-none d-block w-100 mt-2">
            //   <div className="px-md-5 px-4 pt-2 col-12">
            //     <div className='text-end'>
            //       <FontAwesomeIcon className='text-danger' onClick={onClickClose} icon={faTimes} />
            //     </div>


            //     <div className='col-lg-10 col-12 d-lg-none d-block'>
            //       <div className='row serachInput'>
            //         <div className="input-group mb-3 border rounded-3 border-dark ">
            //           <div className='col-6 col-12'>
            //             <span className="input-group-text border-0  bg-transparent ">
            //               <div className="dropdown border-dark">
            //                 <div className="form-group" style={{ outline: 'none' }}>
            //                   <select className="form-select" value={industrytype} onChange={changeIndustry} style={{ outline: 'none' }} name="" id="">
            //                     {
            //                       industryArr.map((item, i) => {
            //                         return (
            //                           <option key={i} value={item.name} className="border-0" >{item.name}</option>
            //                         )
            //                       })
            //                     }
            //                   </select>
            //                 </div>
            //               </div>
            //             </span>
            //           </div>

            //           <div className='col-6 col-12'>
            //             <div className='d-flex ms-3'>
            //               <input type="text" className="form-control border-0 border-bottom bg-transparent mb-2" value={searchinp} onChange={onChangeHandle} placeholder='Search' />
            //               <span className="input-group-text border-0 bg-transparent">
            //                 <button className='btn btn-search-btn-blue' onClick={clickedOnSearch} ><FontAwesomeIcon icon={faSearch} /></button>
            //               </span>
            //             </div>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            : ''}
        </div>
        
      

        <div style={{ display: "none" }} id="search-bar-input">
          <div className="d-lg-none d-block" style={{ display: "none", }}>
            <div className="container-fluid text-light py-3">
              <div className="container">
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    <input type="text" className="form-control" name="" id="" placeholder="Search" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </nav>
    </Fragment >
  )
}

export default Header;
