import React, { useEffect, useState } from 'react'
import apiservice from '../apiservice/ApiService';
import brandLogo from '../assets/Web_Logo.png'
import unsub from '../assets/unsubcribe.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Unsubscribe() {

    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    useEffect(() => {
        getUnsubscribeResponse()
    }, [])

    const getUnsubscribeResponse = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const email = searchParams.get('email');
        let dataObject = {
            email: email
        }
        await apiservice.apiCall('/unsubscribe', '', dataObject, 'POST').then(res => {
            if (res.data) {
                setSuccessMsg(res.data)
            } else {
                setErrorMsg(res.error)
            }
        }).catch(err => {
            console.log(err);
        })

    }

    const onClickOk = () => {
        window.close()
    }
    return (
        <>
            <div className='text-center mt-5 pt-3'>
                <img src={brandLogo} className='' alt='...' />
                <div className='mt-5 fw-bold'>
                       <div className='my-3'> <img src={unsub} width={40} /></div>
                    <div className='' style={{color:'#f34336'}}>{errorMsg}</div>
                    <div className='text-success'>{successMsg}</div>
                </div>

                <div className='mt-3'>
                    <button className='btn btn-primary' onClick={onClickOk}>OK</button>
                </div>
            </div>
        </>
    )
}

export default Unsubscribe
