import React, { Fragment, useEffect, useState } from 'react'
import { HiArrowLongRight } from 'react-icons/hi2'
import './ContactUs.scss'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import callingIcon from "../../../assets/callingIcon.png"
import emailIcon from "../../../assets/emailIcon.png"
import hollowStar from "../../../assets/startHollow.png"
import { countries } from 'country-list-json';
import Select from 'react-select';
import validation from '../../../components/Validations'
import apiservice from '../../../apiservice/ApiService'
import { Button, Modal, ModalHeader } from 'react-bootstrap'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'

function ContactUs(props) {

  let navigate = useNavigate()
  const initialState = {
    name: '',
    countryCode: {
      "value": "+1",
      "label": "US +1"
    },
    mobileNumber: '',
    email: '',
    subject: '',
    message: ''
  }

  let [data, setdata] = useState(initialState)
  let [errorMessage, setErrorMsg] = useState('')
  let [showModal, setshowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState({ value: '+1', label: 'US +1' });
  let [options] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const inputHandlechange = (e) => {
    let { name, value } = e.target;
    setdata({ ...data, [name]: value })
    setErrorMsg('')

  }


  options = countries.map((item, i) => {
    return { 'value': item.dial_code, 'label': `${item.code} ${item.dial_code}` }
  }).sort((a, b) => a.label.localeCompare(b.label));

  async function clickedOnContactUs() {
    if (data.name === '') {
      setErrorMsg('Name is required!')
    } else if (!data.name.match(validation().onlyStringVal)) {
      setErrorMsg('Name is not valid!')
    }
    else if (data.countryCode === '') {
      setErrorMsg('Country code is required!')
    }
    else if (data.mobileNumber === '') {
      setErrorMsg('Mobile Number is required!')
    }
    else if ((data.mobileNumber.length > 10 || data.mobileNumber.length < 10 || !data.mobileNumber.match(validation().mobileVal))) {
      setErrorMsg('Mobile Number is not valid!')
    }
    else if (data.email === '') {
      setErrorMsg('Email is required!')
    } else if (data.email && !data.email.match(validation().emailVal)) {
      setErrorMsg('Email is not valid!')
    }
    else if (data.subject === '') {
      setErrorMsg('Write some subject!')
    }
    else {
      setIsLoading(true)
      setdata({ ...data })
      let reqData = {
        name: data.name,
        email: data.email,
        mobile: `${data.countryCode.value} ${data.mobileNumber}`,
        subject: data.subject,
        message: data.message,
      }

      console.log(reqData);
      await apiservice.apiCall('/ContactUs', null, reqData, 'POST').then(res => {
        if (res.data) {
          setIsLoading(false)
          console.log(res.data);
          setshowModal(true)
          setdata(initialState)
          setSuccessMsg('Your inquiry has been successfully submitted. We will be in touch with you shortly.')
          setErrMsg('')
        } else {
          setSuccessMsg('')
          setIsLoading(false)
          setErrMsg('Something went wrong. Please try again!')
        }
      }).catch(err => {
        console.log(err);
      })



    }
  }
  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setdata({ ...data, 'countryCode': selectedOption })
    setSelectedOption(selectedOption)
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')

  }, [])
  return (
    <Fragment>
      <Header navigate={navigate} />

      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <ModalHeader>
          {errMsg === '' ? '' :
            <div className='text-danger'>{errMsg}</div>}
          {successMsg === '' ? '' :
            <div className='text-success'>{successMsg}</div>}
          <Button variant="primary" onClick={() => setshowModal(false)}>Ok</Button>
        </ModalHeader>
      </Modal>

      {isLoading ? <Loader /> :
        <section className='contactUsPage' style={{ backgroundColor: '#f1efe7' }}>
          <article className='container d-flex  py-4'>
            <div>
              <p className='fw-fw-semibold'><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt;  Contact us</p>
              <h3 className='fw-semibold'>Always Here to Help You</h3>
            </div>
            <img src={hollowStar} className='d-lg-block d-none start_splash_img' style={{ marginLeft: '50%  ' }} width={84} height={84} alt="" />
          </article>

          <section className="container">
            <div className='row'>
              <article className='col-lg-7 col-12'>
                <form  >
                  <div className="form-row w-100 card bg-transparent ">
                    {errorMessage !== '' ? <div className='text-danger text-center mt-3'>{errorMessage}</div> : ''}

                    <div className="row p-3">
                      <div className="col-lg-6 col-12 pb-3">
                        <label className='py-2 px-1' htmlFor="firstName">Name</label>
                        <input type="text" id='name' name='name' value={data.name} onChange={inputHandlechange} className="form-control bg-transparent" placeholder="Name" />
                      </div>

                      <div className='col-lg-6 col-12 bg-transparent'>
                        <label htmlFor="basic-addon1 " className='py-2 px-1' >
                          Mobile
                        </label>
                        <div className="input-group  rounded-2 " style={{ outline: "none" }}>
                          <span className="input-group-text bg-light p-0 " id="basic-addon1">
                            <Select onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} placeholder='US +1' />
                          </span>
                          <input type="text" onChange={inputHandlechange} autoComplete='off'
                          value={data.mobileNumber} className="form-control bg-transparent " name='mobileNumber' required placeholder="Mobile number" />
                        </div>
                      </div>

                      <div className="col-12 py-3">
                        <label className='py-2 px-1' htmlFor="email">Email</label>
                        <input type="email" onChange={inputHandlechange} value={data.email} name='email' id='email' className="form-control bg-transparent" placeholder="Email" />
                      </div>
                      <div className="col-12 py-3">
                        <label className='py-2 px-1' htmlFor="subject">Subject</label>
                        <input type="text" id='subject' name='subject' value={data.subject} onChange={inputHandlechange} className="form-control bg-transparent" placeholder="Subject" />
                      </div>
                      <div className="col-12 py-3">
                        <textarea name="message" id="message" value={data.message} onChange={inputHandlechange} placeholder='Write a message' className='w-100 form-control bg-transparent rounded-3' rows="6"></textarea>
                      </div>
                    </div>
                    <div className='w-100 text-center py-4'>
                      <button
                        type="button"
                        onClick={clickedOnContactUs}
                        className="btn btn-primary text-center border-0 px-4 py-2 btnM"
                        style={{ backgroundColor: "#E57A4D", }}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </article>


              <article className='col-lg-5 col-12 align-self-end py-3 py-lg-0'>
                <div className="card px-2 w-100 bg-transparent">
                  <div className='fw-bold p-2' style={{ fontSize: "2.4rem" }} ><h3>Get In Touch</h3>
                  </div>
                  <p className='px-3'>Feel free to contact us, and let's start a meaningful conversation.</p>

                  <div className='d-flex px-4 py-3'>
                    <div>
                      <img src={callingIcon} width={80} height={80} alt="" className='d-none d-lg-block' />
                      <img src={callingIcon} width={50} height={50} alt="" className='d-lg-none d-block' />
                    </div>
                    <div className='px-3'>
                      <h3 className='fw-semibold'>Contact</h3>
                      <p>+44 7743 460591</p>
                      {/* <p>+91 1234567890</p> */}
                    </div>

                  </div>


                  <div className='d-flex  py-3 px-4'>
                    <div>
                      <img src={emailIcon} width={80} height={80} alt="" className='d-none d-lg-block' />
                      <img src={emailIcon} width={50} height={50} alt="" className='d-lg-none d-block' />
                    </div>
                    <div className='px-3'>
                      <h3 className=' fw-semibold'>Email</h3>
                      <p><span className='fw-bold text-primary ' onClick={() => window.location = 'mailto:kavish@xperthrivr.com'} style={{ cursor: 'pointer' }}> kavish@xperthrivr.com</span></p>
                    </div>

                  </div>


                </div>
              </article>
            </div>
          </section>
          <Footersection navigate={navigate} />
        </section>}
    </Fragment>
  )
}

export default ContactUs
