import React from 'react'
import { Fragment, useEffect } from 'react'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import Header from '../../../components/LandingpageComponents/Header/Header'
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import mentorImg from "../../../assets/mentorImg.png"
import MentorCard from './../../../components/LandingpageComponents/MentorsCard/MentorCard';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function SearchMentors(props) {
  let navigate = useNavigate()

    function getMentorsList(){
        let list = [];
        let count=0
        let cardsToShow ;
    
    if(isDesktop){ cardsToShow = 15 }
    else if(isTablet){ cardsToShow = 6 }
    else if(isMobile){ cardsToShow = 3 }

    for (let i = 0; i < cardsToShow; i++) {
      list.push(
        <MentorCard
        key={i}
        mentorsName={"Devon Lane"}
        sideBgColor={`cardBg${count}`}
        mentorsImage={mentorImg}
        mentorsDescription={"Lorem Ipsum is simply dummy text of the printing and"}
        />
      );
      if (count === 5) {
        count = 0;
      } else {
        count++;
      }
    }

    return list;
    }
  return (
    <Fragment>
       <Header navigate={navigate} />
      <section  style={{ backgroundColor: "#f1efe7"}}>
        <article className='container py-3'>
            <div className="row">
       <h4>10,000 results for “Mentor”</h4>
            </div>
        </article>

        <article className='container'>
            <div className="row">
                {getMentorsList()}
            </div>
        </article>
        <article>
            
        </article>
       <Footersection navigate={navigate}/>
      </section>
    </Fragment>
  )
}

export default SearchMentors
