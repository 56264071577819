import React from 'react'
import './ServicesCard.scss'
import StarRatings from 'react-star-ratings'
import moment from 'moment'

function ServicesCard(props) {
    return (
        <>
            <div className="card bg-transparent border-2 myservicecard mb-2">
                <img className="card-img-top w-100 p-2" height={200} src={props.card_top_img} alt="" />
                <div className="card-body">
                    <div className="fw-bold titleAtServiceCard mb-2">{props.title}</div>
                    <div className="cName mb-2">{props.content_expert_name} <span className='badge text-dark ' style={{ backgroundColor: '#ABEFC6' }}>{props.serviceType}</span></div>
                    <div className='mb-2'>
                        <StarRatings
                            rating={props.rating}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                        /> <span className='ps-2'>{props.rating === 0 ? 0 : Number(props.rating).toFixed(1)}</span>
                    </div>

                    <div>
                        <div className='me-3'><b>Total Time:</b> {props.duration} </div>
                        {props.serviceType === 'Webinar Session' ? props.webinarTime === null ? '' :
                            <div>
                                <div> <b>Date : </b> {props.webinarDate}</div>
                                <div> <b>Time : </b> {props.webinarTime} ({props.timeZone})</div>
                            </div>
                            : ''}
                    </div>
                    <div className='fw-bold mb-2'>{props.currencySymbol} {Number(props.pricing).toFixed(2)}</div>
                </div>
            </div>
        </>
    )
}

export default ServicesCard
