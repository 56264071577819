import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import sliderImg1 from "../../../assets/forCCSliderImg1.png"
import sliderImg2 from "../../../assets/forCCSliderImg2.png"
import sliderImg3 from "../../../assets/forCCSliderImg3.png"
import sliderImg4 from "../../../assets/forCCSliderImg4.png"
import sliderImg5 from "../../../assets/forCCSliderImg5.png"
import sliderImg6 from "../../../assets/forCCSliderImg6.png"
import startHollow from "../../../assets/startHollow.png"
import InterConnecCard from '../../../components/LandingpageComponents/InterConnecCard.js/InterConnecCard'
import { HiArrowLongRight } from 'react-icons/hi2'
import apiservice from '../../../apiservice/ApiService'
import "./InterConnectForContentCreator.scss"
import { useNavigate } from 'react-router-dom'
// import ScrollAnimation from 'react-animate-on-scroll'
function InterConnectForContentCreator(props) {
  let navigate = useNavigate()
  let [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    async function getLoginStatus() {
      let logStatus = await apiservice.getLocalStorage('userData')
      if (logStatus) {
        setIsLoggedIn(true)
      }
      else {
        setIsLoggedIn(false)
      }
    }
    getLoginStatus()
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')

  }, [])


  let [activeText, setactiveText] = useState('text1')
  const [questionNumber, setQuestionNumber] = useState(0);

  function clickedOnSliderText(e) {
    setactiveText(e)
  }
  const onClickQuetions = (e) => {
    setQuestionNumber(e);
  };
  return (
    <Fragment>
      <Header navigate={navigate} />
      <section style={{ backgroundColor: "#f1efe7" }}>
        <article className="container pt-5">
          <div className='d-flex justify-content-between'>
            <div>
              <div><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt;  Content Creators</div>
              <h2 className='py-2 fw-semibold headTextAtContCreator'>Connecting content creators with their followers.</h2>
              <p>Monetise your passion, expertise, and deep knowledge while upskilling your community.</p>
            </div>
            <div className='d-lg-block d-none '><img className='start_splash_img' src={startHollow} alt="" width={80} height={80} /></div>
          </div>
        </article>
        <article className="container">
          <h3 className=' fw-semibold py-lg-3 py-1 text-center headTextAtContCreator'>How XperThrivr structures your journey : </h3>

          <div className='d-md-block d-none '>
            <article className='row py-3 '>
              <div className="col-lg-5 col-12 ">
                {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}

                <p className={activeText === 'text1' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text1')} style={{ cursor: 'pointer' }}  >Hold 1:1 sessions | Multi-sessions | Webinar sessions</p> <hr />
                <p className={activeText === 'text2' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text2')} style={{ cursor: 'pointer' }}  >Use our course builder to structure sessions | Add images, videos, and slides with a few clicks</p><hr />
                <p className={activeText === 'text3' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text3')} style={{ cursor: 'pointer' }}  > Post live webinars from your dashboard with ease | Reach a wider audience </p><hr />
                <p className={activeText === 'text4' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text4')} style={{ cursor: 'pointer' }} >Calendar integration | Booking reminder notification</p><hr />
                <p className={activeText === 'text5' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text5')} style={{ cursor: 'pointer' }} >DM your followers after booking to offer a personalized coaching plan</p><hr />
                <p className={activeText === 'text6' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text6')} style={{ cursor: 'pointer' }} >Testimonials | Build trust and showcase value</p>

                {/* </ScrollAnimation> */}
              </div>
              <div className="col-lg-7 col-12 d-flex p-2 m-0">
                {/* <ScrollAnimation animateIn='animate__fadeInRight' > */}
                {activeText === 'text1' ?
                  <img src={sliderImg1} className='w-100 align-self-center ' alt="" />
                  : activeText === 'text2' ?
                    <img src={sliderImg2} className='w-100 align-self-center ' alt="" />

                    : activeText === 'text3' ?
                      <img src={sliderImg3} className='w-100 align-self-center ' alt="" />

                      : activeText === 'text4' ?
                        <img src={sliderImg4} className='w-100 align-self-center ' alt="" />

                        : activeText === 'text5' ?
                          <img src={sliderImg5} className='w-100 align-self-center ' alt="" />
                          :
                          <img src={sliderImg6} className='w-100 align-self-center ' alt="" />}
                {/* </ScrollAnimation> */}
              </div>
            </article>
          </div>

          <article className='row py-3 d-md-none d-block'>
            <div className="col-lg-5 col-12 ">
              {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}

              <p className={activeText === 'text1' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text1')} style={{ cursor: 'pointer' }}  >Hold 1:1 sessions | Multi-sessions | Webinar sessions</p>
              {activeText === 'text1' ?
                <img src={sliderImg1} className='w-100 align-self-center ' alt="" />
                : ''}
              <hr />
              <p className={activeText === 'text2' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text2')} style={{ cursor: 'pointer' }}  >Use our course builder to structure sessions | Add images, videos, and slides with a few clicks</p>
              {activeText === 'text2' ?
                <img src={sliderImg2} className='w-100 align-self-center ' alt="" />

                : ''}
              <hr />
              <p className={activeText === 'text3' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text3')} style={{ cursor: 'pointer' }}  > Post live webinars from your dashboard with ease | Reach a wider audience </p>
              {activeText === 'text3' ?
                <img src={sliderImg3} className='w-100 align-self-center ' alt="" />

                : ''}
              <hr />
              <p className={activeText === 'text4' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text4')} style={{ cursor: 'pointer' }} >Calendar integration | Booking reminder notification</p>
              {activeText === 'text4' ?
                <img src={sliderImg4} className='w-100 align-self-center ' alt="" />

                : ''}
              <hr />
              <p className={activeText === 'text5' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text5')} style={{ cursor: 'pointer' }} >DM your followers after booking to offer a personalized coaching plan</p>
              {activeText === 'text5' ?
                <img src={sliderImg5} className='w-100 align-self-center ' alt="" />
                : ''}
              <hr />
              <p className={activeText === 'text6' ? ' px-2  activeSlider' : 'sliderText px-2 '} onClick={() => clickedOnSliderText('text6')} style={{ cursor: 'pointer' }} >Testimonials | Build trust and showcase value</p>
              {activeText === 'text6' ?
                <img src={sliderImg6} className='w-100 align-self-center ' alt="" /> : ''
              }

              {/* </ScrollAnimation> */}
            </div>

          </article>

          <h3 className='text-center fw-semibold py-lg-4 headTextAtContCreator'>XperThrivr for:</h3>



        </article>
        <InterConnecCard />
        {
          isLoggedIn === true ? ''
            :
            <article className='claimSec mt-3'>
              <div className="container row py-3">
                <div className="col-lg-4 col-12  offset-lg-4 offset-0">  <h3 className=' text-lg-start text-center headTextAtContCreatorC mb-3'>Claim your XperThrivr digital land!</h3> </div>
                <div className="col-lg-4 col-12 align-self-center">
                  <div className='w-100 text-center align-self-center'>
                    {
                      isLoggedIn === true ? ''
                        :
                        <button
                          type="button"
                          onClick={() => navigate('/signup')}
                          className="btn btn-primary border-0 text-center px-4 py-2 btnM"
                          style={{ backgroundColor: "#E57A4D" }}
                        >
                          Launch your Personalised Profile
                        </button>
                    }

                  </div>
                </div>
              </div>
            </article>}

        {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
        <article className="container bottomSectionICCC my-lg-5">
          <div className='w-100 d-flex flex-column align-items-end bottomSubSec'>
            <div className="card border-0 p-lg-2 bg-transparent"  >
              <div>
                <h4 className='py-2 text-start fw-semibold headTextAtContCreatorC'>Give yourself the XperThrivr Edge</h4>
              </div>
              <div className='border-1' style={{ backgroundColor: '#F1FCFF' }}>
                <div className="card-body d-flex">
                  <h6 className='border border-black text-center align-self-start rounded-circle p-3'>
                    .01
                  </h6>
                  <p className='px-3'>XT platform is combined with a LMS (learning management system) to enables you to deliver online coaching, talks, and webinars to your followers in an engaged and structured way.</p>
                </div>

                <div className="card-body d-flex">
                  <h6 className='border border-black text-center align-self-start rounded-circle p-3'>
                    .02
                  </h6>
                  <p className='px-3'>XT provides you access to a personalised dashboard where you can create and keep track of upcoming sessions, track progress and manage testimonials.</p>
                </div>
              </div>

            </div>
          </div>
        </article>
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation animateIn='animate__zoomIn' > */}

        <article className="container">
          <div className="row my-lg-5 py-lg-5">

            {/* <p className="smallSubTitle">Top Questions</p> */}


            <div className="col-lg-12 col-12">
              <div>
                <div className="mt-3">
                  <h4 className="fw-bold fs-3 mdTitleText mb-4 text-center">
                    Frequently Asked Questions
                  </h4>
                  <div
                    className="accordion "
                    // style={{--bs-body-bg:"transperent"}}
                    id="accordionExample"  >

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingOne"
                      >
                        <button
                          onClick={() => onClickQuetions(1)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          <div className='col-12'>

                            <div className="row">
                              <div
                                className={
                                  questionNumber === 1
                                    ? "col-2 rounded-circle  d-flex justify-content-center align-items-center text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle  text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                1
                              </div>
                              <div className="col-10 pt-2 acTitle"> What is a Content creator on XperThrivr? </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse bg-transparent "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body bg-transparent">
                          <p>XperThrivr enables you to monetise your knowledge and expertise; and to offer coaching and mentorship through 1:1 sessions. Set up your profile and access your dashboard - add your sessions, generate your profile link; and share it with your network of learners and followers.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingTwo"
                      >
                        <button
                          onClick={() => onClickQuetions(2)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 2
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                2
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                How much can I charge for my sessions?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>We enable both free and paid sessions. How much you can charge really depends on the perceived value and how much are the learners and followers willing to pay.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingThree"
                      >
                        <button
                          onClick={() => onClickQuetions(3)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        ><div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 3
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                3
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                {" "}
                                How does XperThrivr offer a personalised content creation experience?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div><b>Your content creation journey begins as follows: </b></div>
                          <p><b>a.</b> Once your session is booked, you will connect to the learner from your dashboard. </p>
                          <p><b>b.</b> Our in-built chat feature will be enabled on your dashboard, through which you will reach out directly to the learner to schedule the session at a convenient time and date. </p>
                          <p><b>c.</b> Use our chat feature to discuss their learning objective and the content of the session beforehand.  </p>
                          <p><b>d.</b> We automatically send the calendar invite and email notifications to both of you, making scheduling seamless and effortless. </p>
                          <p><b>e.</b> Dive in the session with confidence! </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingFour"
                      >
                        <button
                          onClick={() => onClickQuetions(4)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 4
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                4
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                {" "}
                                How sessions can be structured?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div><b>The sessions can be formatted as follows: </b></div>
                          <p>
                            <b>Single session:</b> Single 1:1 session is ideal for receiving focused support and guidance on a particular area without the commitment of multiple sessions.
                          </p>
                          <p>
                            <b>Multi-session:</b> Multiple session allows learners to master intricate skills and to dive deep into complex topics that requires more than one session.
                          </p>
                          <p>
                            <b>Webinar session:</b> Webinar is available for content experts that allows them to reach a wider audience. They can set the number of participants, select the time and date; and XperThrivr will generate the webinar link that can be shared with the participants ahead of the webinar.
                          </p>

                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingFive"
                      >
                        <button
                          onClick={() => onClickQuetions(5)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 5
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                5
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                How do I manage and
                                promote my content on
                                XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>XperThrivr provides tools and
                            features for content creators to
                            manage and promote their
                            content. You can create and
                            edit your content, set pricing,
                            schedule live sessions, and
                            use promotional tools to attract
                            learners to your offerings.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingSix"
                      >
                        <button
                          onClick={() => onClickQuetions(6)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 6
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                6
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                How do I communicate with learners on XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p> You can engage with learners
                            through XperThrivr's built-in
                            communication features,
                            including chat and video call
                            functionality. These tools
                            enable you to provide support,
                            answer questions, and foster
                            interaction with your audience</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingSeven"
                      >
                        <button
                          onClick={() => onClickQuetions(7)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 7
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                7
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                What are my responsibilities as a Content Expert on XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Your responsibilities include
                          creating high-quality, accurate
                          content, respecting the privacy
                          and rights of learners, abiding
                          by XperThrivr's policies, and
                          delivering a positive learning
                          experience
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingEight"
                      >
                        <button
                          onClick={() => onClickQuetions(8)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 8
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                8
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                How do I receive payments for my services on XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          XperThrivr typically provides
                          payment processing services
                          to Content Experts. Earnings
                          are transferred to your
                          designated account or payment
                          method, based on the
                          platform's payme
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingNine"
                      >
                        <button
                          onClick={() => onClickQuetions(9)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 9
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                9
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                Is there a review process for my content as a Content Expert?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            XperThrivr may have a review
                            process to ensure the quality
                            and appropriateness of the
                            content. This review process
                            can include a check for
                            accuracy, compliance with
                            platform guidelines, and
                            alignment with the interests of
                            learners.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingTen"
                      >
                        <button
                          onClick={() => onClickQuetions(10)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 10
                                    ? "col-2 rounded-circle text-center active-circle d-flex justify-content-center align-items-center"
                                    : "col-2 quetionbtn rounded-circle text-center d-flex justify-content-center align-items-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                10
                              </div>
                              <div className="col-10 pt-2 acTitle">
                                Can I interact with other Content Experts on XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, you can interact and
                            collaborate with other Content
                            Experts on XperThrivr. The
                            platform may provide features
                            such as forums, discussion
                            boards, or collaboration spaces
                            for experts to share knowledge
                            and experiences.
                          </p>
                        </div>
                      </div>
                    </div> */}






                  </div>
                </div>
              </div>
            </div>
          </div>

        </article>
        {/* </ScrollAnimation> */}

        <Footersection navigate={navigate} />
      </section>

    </Fragment>
  )
}

export default InterConnectForContentCreator
