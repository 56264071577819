import React, { useEffect, useState } from 'react'
import apiservice from '../../../apiservice/ApiService';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ConfirmationPage(props) {
    let navigate = useNavigate()
    const [messagePopup, setMessagePopup] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        confirmation()
    }, []);

    const confirmation = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const user_id = searchParams.get('user_id');
        const service = searchParams.get('service');
        const book = searchParams.get('book');
        const session = searchParams.get('session');
        const attend = searchParams.get('attend');

        console.log('user_id:', user_id);
        console.log('service:', service);
        console.log('book:', book);
        console.log('session:', session);
        console.log('attend:', attend);

        let dataObject = {
            'user_id': user_id,
            'service_id': service,
            'book_service_id': book,
            'session_number': session,
            'attend_status': attend,
        }

        console.log(dataObject);
        await apiservice.apiCall('/BookServiceConfirmation', '', dataObject, 'POST').then(res => {
            if (res.data) {
                console.log(res.data);
                setMessagePopup(true)
                setSuccessMessage(res.data)
            } else {
                console.log(res.error);
                setErrorMessage(res.error)
                setMessagePopup(true)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const onClickOk = () => {
        navigate('/');
    }

    return (
        <>
            <div className='container-fluid'>
                <Modal show={messagePopup}>
                    <ModalBody>
                        <div className='p-3'>
                            {successMessage !== ''?
                                <div className='text-center p-3 text-success fw-bold'>
                                    {successMessage}
                                </div>
                                :
                                <div className='text-center p-3 text-danger fw-bold'>
                                    {errorMessage}
                                </div>
                            }

                            <div className='my-2 text-center'>
                                <button className='btn btn-orange' onClick={onClickOk}>OK</button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default ConfirmationPage
