import React, { useEffect, useRef, useState } from 'react'
import './ProfilePage.scss'
import validation from '../../../components/Validations';
import { countries } from 'country-list-json';
import Select from 'react-select';
import apiservice from '../../../apiservice/ApiService';
import noImg from '../../../assets/no-image.png'
import Loader from '../../../components/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactQuill from 'react-quill';


function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}


function ProfilePage(props) {
    let navigate = useNavigate()
    const initialDataState = {
        selectedImgURL: '',
        profileImage: '',
        profileLink: '',
        firstName: '',
        lastName: '',
        aboutYourself: '',

        username: '',
        birthDate: '',
        languages: '',

        courseName: '',
        collegeName: '',
        universityName: '',
        startingYear: '',
        completionYear: '',

        emailAddress: "",
        mobileNo: '',
        countryCode: '',
        selectedCountryCode: '',
        countryName: '',
        selectedCountryName: '',

        currentPassword: '',
        newPassword: '',
        reEnterPassword: '',

        lernerdata: ''
    };

    const [data, setData] = useState(initialDataState);
    const [menuKey, setMenuKey] = useState('Profile');
    const [errorMsg, setErrorMsg] = useState('')
    const [userData, setUserData] = useState({})
    let [isLoading, setLoading] = useState(false)
    let [successMsg, setSuccessMsg] = useState('')
    const [copyClipboard, setCopyClipboard] = useState(false)
    const [countryNameArr, setCountryNameArr] = useState([])

    const [isMobileEditable, setIsMobileEditable] = useState(false)
    const [selectedOption, setSelectedOption] = useState({ value: 'US +1', label: 'US +1', code: '+1' });
    const inputRef = React.useRef();
    // const [crop, setCrop] = useState({ x: 0, y: 0 });
    // const [zoom, setZoom] = useState(1);
    // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropPopUp, setCropPopUp] = useState(false)
    const [yourselfValue, setYourselfValue] = useState('')
    async function getdata() {
        setLoading(true)
        let lernerdata = await apiservice.getLocalStorage('userData')

        await apiservice.apiCall(`/Learnerdatashow/${lernerdata.id}`, lernerdata.Token).then(res => {
            console.log(res.data);
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setLoading(false)
                let data = res.data[0]
                console.log(data);
                if ((data.country_code === null || data.mobile === null)) {
                    setIsMobileEditable(false)
                } else {
                    setIsMobileEditable(true)

                }
                setSelectedOption((data.country_code && { value: data.country_dial_code, label: data.country_dial_code, code: data.country_code }) || '')
                setYourselfValue(data.AboutSelf)
                setData({
                    ...data,
                    profileImage: data.ProfileImage,
                    firstName: data.name,
                    lastName: data.lname,
                    aboutYourself: data.AboutSelf,
                    profileLink: `${apiservice.webOriginUrl()}/contetexpert-home/learnerprofile/${data.UserName}`,
                    username: data.UserName,
                    birthDate: data.Birthdate,
                    languages: data.Language,
                    courseName: data.educations === null ? '' : data.educations.Name,
                    collegeName: data.educations === null ? '' : data.educations.CollegeName,
                    universityName: data.educations === null ? '' : data.educations.UniversityName,
                    startingYear: data.educations === null ? '' : data.educations.Startignyear,
                    completionYear: data.educations === null ? '' : data.educations.EndingYear,
                    emailAddress: data.email,
                    mobileNo: data.mobile,
                    countryCode: data.country_code,
                    selectedCountryCode: { value: data.country_dial_code, label: data.country_dial_code, code: data.country_code },
                    countryName: data.country_name,
                    selectedCountryName: { value: data.country_name, label: data.country_name }
                })

                setUserData(data)

            } else {
                setLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })

    }
    // console.log(data);
    // console.log(userData);
    useEffect(() => {

        getdata()
        getAllCountryData()
    }, [])

    const getAllCountryData = () => {
        fetch('https://restcountries.com/v3.1/all')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                console.log(data[0].name.common);
                setCountryNameArr(data)
                // for (let index = 0; index < data.length; index++) {
                //     const element = data[index];
                //     console.log(element.name.common);

                // }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
        setErrorMsg('')
    };
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();

    const [cropString, setCropString] = useState('')
    const onSelectProfileImage = (e) => {
        const file = e.target.files[0];

        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener('load', () => {

                setCropPopUp(true)
                setData({ ...data, profileImage: reader.result })
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }

    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 1));
    }

    async function completedCrop(ele) {
        if (ele?.width && ele?.height && imgRef.current) {
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = ele.width;
            canvas.height = ele.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                imgRef.current,
                ele.x * scaleX,
                ele.y * scaleY,
                ele.width * scaleX,
                ele.height * scaleY,
                0,
                0,
                ele.width,
                ele.height
            );
            const base64String = canvas.toDataURL('image/png');
            setCropString(base64String)
            console.log(base64String);
        }
    }

    const handleCropButtonClick = () => {
        setCropPopUp(false)
        setData({ ...data, profileImage: cropString, selectedImgURL: cropString, })
    }


    const onClickOK = () => {
        setData({ ...data, profileImage: '', selectedImgURL: '', })
        setCropPopUp(false)

    }


    const onClickTopMenu = (e) => {
        setMenuKey(e)
        console.log(e);
        setErrorMsg('')
    }


    const handleChange = (selectedOption) => {
        console.log(selectedOption.value);
        setData({ ...data, countryCode: selectedOption.code, selectedCountryCode: selectedOption })
        setSelectedOption(selectedOption)
    };

    const handleChangeCountryName = (selectedOption) => {
        console.log(selectedOption.value);
        setData({ ...data, countryName: selectedOption.value, selectedCountryName: selectedOption })
        // setSelectedOption(selectedOption)
    };


    let [options] = useState([]);
    options = countries.map((item, i) => {
        return { 'value': `${item.code} ${item.dial_code}`, 'label': `${item.code} ${item.dial_code}`, code: item.dial_code }
    }).sort((a, b) => a.label.localeCompare(b.label));

    let [optionsCoutry] = useState([]);
    optionsCoutry = countryNameArr.map((item, i) => {
        return { 'value': item.name.common, 'label': item.name.common }
    }).sort((a, b) => a.label.localeCompare(b.label));

    async function clickedOnSaveProfile() {
        let trimAboutSelf = yourselfValue.replace(/<.*?>/g, "")
        if (menuKey === 'Profile') {

            if (data.firstName === '') {
                setErrorMsg('First name is required!')
                return false
            }
            if (data.firstName && data.firstName.trim() === '') {
                setErrorMsg('First name is required!')
                return false
            }
            if (data.lastName === '') {
                setErrorMsg('Last name is required!')
                return false
            }
            if (data.lastName === '' && data.lastName.trim() === '') {
                setErrorMsg('Last name is required!')
                return false
            }
            if (yourselfValue === '' || yourselfValue === null) {
                setErrorMsg('About your self is required!')
                return false
            }
            if (trimAboutSelf.trim() === '') {
                setErrorMsg('About your self is required!')
                return false
            }
        }


        try {
            setLoading(true)
            console.log(data);
            let reqData = {
                name: data.firstName === null ? '' : data.firstName,
                lname: data.lastName === null ? '' : data.lastName,
                AboutSelf: yourselfValue === null ? '' : yourselfValue,
                UserName: data.username === null ? '' : data.username,
                Birthdate: data.birthDate === null ? '' : data.birthDate,
                Language: data.languages === null ? '' : data.languages,
                ProfileImage: data.profileImage === null ? '' : data.profileImage,
                country_code: data.countryCode === null ? '' : data.countryCode,
                country_dial_code: data.selectedCountryCode === null ? '' : data.selectedCountryCode.value,
                country_name: data.countryName === null ? '' : data.countryName,
                mobile: data.mobileNo === null ? '' : data.mobileNo,
            }
            console.log(reqData);
            let formData = new FormData();

            for (let key in reqData) {
                formData.append(key, reqData[key]);
            }
            let saveProfile = await apiservice.apiCallFormData('/learner_ProfileDeatilsStore', userData.Token, formData, 'POST')
            console.log(saveProfile)

            if (saveProfile.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            }

            else if (saveProfile.data) {
                console.log(saveProfile.data);
                await apiservice.setLocalStorage('userData', saveProfile.data)
                let newUserdata = await apiservice.getLocalStorage('userData')
                setUserData(newUserdata)
                setSuccessMsg('Successfully Updated!')

                setLoading(false)
                setTimeout(() => {
                    setSuccessMsg('')

                }, 3000);

            }
            else {
                console.log(saveProfile.error);
                setLoading(false)

            }
        } catch (error) {
            setLoading(false)

            console.log(error);
        }


    }


    async function saveLearnerEdu() {
        if (data.courseName === '') {
            setErrorMsg('Area of Study is required!')
        }
        if (data.courseName && data.courseName.trim() === '') {
            setErrorMsg('Area of Study is required!')
        }
        // else if (data.collegeName === '') {
        //     setErrorMsg('College name is required!')
        // }
        else if (data.universityName === '') {
            setErrorMsg('University name is required!')
        }
        else if (data.universityName && data.universityName.trim() === '') {
            setErrorMsg('University name is required!')
        }
        else if (data.startingYear === '') {
            setErrorMsg('Starting year is required!')
        }
        else if (data.completionYear === '') {
            setErrorMsg('Completion year is required!')
        }
        else if (new Date(data.completionYear) < new Date(data.startingYear)) {
            setErrorMsg('Please enter a completion date that is grater than the starting year!')
        }
        else {

            setLoading(true)
            let reqData = {
                Name: data.courseName,
                CollegeName: data.collegeName,
                UniversityName: data.universityName,
                Startignyear: data.startingYear,
                EndingYear: data.completionYear,
            }
            console.log(data.Token);
            try {
                let lernerEdu = await apiservice.apiCall('/learner_edu_profile_store', data.Token, reqData, 'POST')
                console.log(lernerEdu);
                if (lernerEdu.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (lernerEdu.data) {
                    console.log(lernerEdu.data);
                    setSuccessMsg('Successfully Updated!')

                    setLoading(false)
                    setTimeout(() => {
                        setSuccessMsg('')

                    }, 3000);
                }
                else {
                    console.log(lernerEdu.error);
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
    }

    const onClickChangePassword = async () => {
        if (data.currentPassword === '') {
            setErrorMsg('Current password is required!')
        }
        else if (data.newPassword === '') {
            setErrorMsg('New password is required!')
        }
        else if (data.newPassword && !data.newPassword.match(validation().passwordVal)) {
            setErrorMsg('Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.')
        }
        else if (data.newPassword !== data.reEnterPassword) {
            setErrorMsg('Please write the same password in new password field and Re-enter password field. ')
        }
        else {
            setLoading(true)
            let reqData = {
                password: data.currentPassword,
                newpass: data.newPassword,
                confirmpass: data.reEnterPassword,
            }
            try {
                let changePass = await apiservice.apiCall('/learner_changepassword', data.Token, reqData, 'POST')
                console.log(changePass);
                if (changePass.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (changePass.data) {
                    setLoading(false)
                    console.log(changePass.data);
                    setSuccessMsg('You will be auto logout in 3 second!')
                    setData({
                        ...data,
                        currentPassword: '',
                        newPassword: '',
                        reEnterPassword: ''
                    })
                    setTimeout(() => {
                        setSuccessMsg('')
                        localStorage.removeItem('userData')
                        navigate('/')
                    }, 3000);
                }
                else {
                    setLoading(false)
                    console.log(changePass.error);
                    setErrorMsg(changePass.error)
                }
            } catch (error) {
                setLoading(false)
                console.log(error);
            }

        }

    }

    const fnCopy = () => {
        setCopyClipboard(true)
        setTimeout(() => {
            setCopyClipboard(false)
        }, 2000);
    }

    return (
        <>

            <Modal show={cropPopUp}>

                <ModalBody>
                    <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickOK}><FontAwesomeIcon icon={faTimes} /></div>

                    {data.profileImage && (
                        <div className="crop-container">
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => completedCrop(c)}
                                aspect={false}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={data.profileImage}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-orange' onClick={handleCropButtonClick}>Crop Image</button>
                </ModalFooter>
            </Modal>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='container-fluid'>
                        <div className='fw-bold Title'>Profile</div>
                    </div>
                    <div className='mt-3'>
                        <div className='d-none d-lg-block'>
                            <div className='row'>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-2'>
                                        <button className={menuKey === 'Profile' ? 'btn btn-light w-100 menu active-menu btnM' : 'btn btn-light w-100 menu btnM'} onClick={() => onClickTopMenu('Profile')}>Profile</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-2'>
                                        <button className={menuKey === 'Personal' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Personal')}>Personal Details</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-2'>
                                        <button className={menuKey === 'account' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('account')}>Account Details</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-2'>
                                        <button className='btn btn-dark w-100' onClick={clickedOnSaveProfile} disabled={isLoading ? true : false}>{isLoading ? 'Loading...' : 'Save Change'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-lg-none d-block'>
                            <div className='row'>
                                <div className='col-6'>
                                    <button className={menuKey === 'Profile' ? 'btn btn-light menu active-menu w-100' : 'w-100 btn btn-light menu'} onClick={() => onClickTopMenu('Profile')}>Profile</button>
                                </div>
                                <div className='col-6'>
                                    <button className={menuKey === 'Personal' ? 'btn btn-light  menu active-menu w-100' : 'w-100 btn btn-light menu'} onClick={() => onClickTopMenu('Personal')}>Personal Details</button>
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <div className='col-6'>
                                    <button className={menuKey === 'account' ? 'btn btn-light  menu active-menu w-100' : 'w-100 btn btn-light menu'} onClick={() => onClickTopMenu('account')}>Account Details</button>
                                </div>


                                <div className='col-6'>
                                    <button className='btn btn-dark w-100' onClick={clickedOnSaveProfile} disabled={isLoading ? true : false}>{isLoading ? 'Loading...' : 'Save Change'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className='my-3 text-success text-center'>{successMsg}</div>
                    <div className='p-3'>
                        {menuKey === 'Profile' ?
                            <div className=''>
                                <div className='text-center mb-3'>
                                    {errorMsg !== '' ?
                                        <small className='text-danger ' >{errorMsg}</small>
                                        : ''}
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex'>
                                        <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                            <img src={data.selectedImgURL === undefined || data.selectedImgURL === '' ? data.profileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.profileImage}` : data.selectedImgURL} alt='' className='w-100 h-100' />
                                        </div>
                                        {/* <div className='mt-2'>
                                            <div className='ms-3 fw-bold pt-4 fs-5'>Profile Photo <span style={{ color: '#ff0000' }}>*</span></div>
                                        </div> */}
                                    </div>
                                    <div className='pt-4'>
                                        <input type="file"
                                            accept=".jpg,.png,.jpeg"
                                            style={{ display: 'none' }}
                                            onChange={onSelectProfileImage}
                                            ref={(instance) => {
                                                inputRef.current = instance; // We also need it to manipulate the elemnent
                                            }} />
                                        <div className='mt-2 border-bottom border-dark pointer' onClick={() => inputRef.current?.click()} >Change Photo</div>
                                        {/* <input type='file' onChange={handleImageFileChange} /> */}
                                    </div>
                                </div>

                                <div className='mt-5'>

                                    <div className='row'>
                                        <div className='col-lg-10 col-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Your Profile Link</label>
                                                <input type="text"
                                                    className="form-control" name="profileLink" value={data.profileLink} onChange={handleInputChange} disabled placeholder="Loremipsum12345/io.com" />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12'>
                                            <CopyToClipboard text={`${data.profileLink}`}
                                                onCopy={fnCopy}>
                                                <button className='btn px-5 mt-4 border rounded-2'>{copyClipboard ? 'Copied' : 'Copy'}</button>
                                            </CopyToClipboard>
                                            {/* <div className='text-end'>
                                                <button className='btn px-5 mt-4 border rounded-2'>Copy</button>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-group mb-2">
                                                <label htmlFor="">First name</label>
                                                <input type="text"
                                                    className="form-control" name="firstName" value={data.firstName} onChange={handleInputChange} placeholder="First name" />
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-12'>
                                            <div className="form-group mb-2">
                                                <label htmlFor="">Last name</label>
                                                <input type="text"
                                                    className="form-control" name="lastName" value={data.lastName} onChange={handleInputChange} placeholder="Last name" />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <label htmlFor="">About Yourself</label>
                                                <ReactQuill
                                                    style={{ fontSize: '22px' }} theme="snow" value={yourselfValue} rows={'5'} onChange={setYourselfValue} />
                                                {/* <textarea type="text"
                                                    className="form-control" name="aboutYourself" maxValue={300} value={data.aboutYourself} rows={'5'} onChange={handleInputChange} ></textarea> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            menuKey === 'Personal' ?
                                <div>
                                    <div className='text-center mb-3'>
                                        {errorMsg !== '' ?
                                            <small className='text-danger ' >{errorMsg}</small>
                                            : ''}
                                    </div>
                                    <div className=''>
                                        <div className=' fw-bold '>Personal Details</div>
                                    </div>

                                    <div className='mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="">Username</label>
                                                    <input type="text"
                                                        className="form-control" name="username" value={data.username} onChange={handleInputChange} disabled placeholder="Username" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="">Birthdate</label>
                                                    <input type="date"
                                                        className="form-control" name="birthDate" value={data.birthDate} onChange={handleInputChange} placeholder="Birthdate" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group">
                                                    <label htmlFor="">Country</label>
                                                    <Select onChange={handleChangeCountryName}
                                                        value={data.selectedCountryName} defaultValue={data.selectedCountryName} options={optionsCoutry} placeholder='' />
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-4 col-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Language</label>
                                                <select className="form-select" name="languages" value={data.languages} defaultValue={data.languages} onChange={handleInputChange} >
                                                    <option value='English'>English</option>
                                                    <option value='Hindi'>Hindi</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>




                                    <div className='mt-3 d-flex justify-content-between'>
                                        <div className=' fw-bold'>Education</div>

                                    </div>
                                    <div className='mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label htmlFor="">Area of Study</label>
                                                        <input type="text"
                                                            className="form-control" name="courseName" value={data.courseName} onChange={handleInputChange} placeholder="Area of Study" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label htmlFor="">University Name</label>
                                                        <input type="text"
                                                            className="form-control" name="universityName" value={data.universityName} onChange={handleInputChange} placeholder="University Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label htmlFor="">Starting Year</label>
                                                        <input type="date"
                                                            className="form-control" name="startingYear" value={data.startingYear} onChange={handleInputChange} placeholder="Starting Year" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label htmlFor="">Completion Year</label>
                                                        <input type="date"
                                                            className="form-control" name="completionYear" value={data.completionYear} onChange={handleInputChange} placeholder="Completion Year" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button className='btn btn-orange px-5 btnM' onClick={saveLearnerEdu}>Save</button>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div>
                                    <div className='text-center mb-3'>
                                        {errorMsg !== '' ?
                                            <small className='text-danger ' >{errorMsg}</small>
                                            : ''}
                                    </div>
                                    <div className='mt-3 row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="">Email Address</label>
                                                    <input type="text"
                                                        className="form-control" name="emailAddress" value={data.emailAddress} onChange={handleInputChange} placeholder="Email Address" disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    {/* <label >Mobile  {console.log(isMobileEditable)}</label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text bg-transparent p-0" id="basic-addon1">
                                                            <Select onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} isDisabled={isMobileEditable ? true : false} placeholder='+1' />
                                                        </span>
                                                        <input type="text" autoComplete='mobileNo'
                                                            className="form-control py-0" name="mobileNo" value={data.mobileNo} maxLength={10} onChange={handleInputChange} placeholder="Mobile" disabled={isMobileEditable ? true : false} />
                                                    </div> */}


                                                    <div className='d-md-flex'>
                                                        <div className='form-group me-3'>
                                                            <label >Country Code</label>
                                                            <Select className="form-control p-0" onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} isDisabled={isMobileEditable ? true : false} placeholder='+1' />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label >Mobile No.</label>
                                                            <input type="text" autoComplete='mobileNo'
                                                                className="form-control" name="mobileNo" value={data.mobileNo} maxLength={10} onChange={handleInputChange} placeholder="Mobile" disabled={isMobileEditable ? true : false} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <div className=' fw-bold  fs-5'>Change Password</div>
                                    </div>

                                    <div className='mt-3'>
                                        <div className=''>
                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">Current Password</label>
                                                        <input type="password"
                                                            className="form-control" name="currentPassword" value={data.currentPassword} onChange={handleInputChange} placeholder="Current Password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">New Password</label>
                                                        <input type="password"
                                                            className="form-control" name="newPassword" value={data.newPassword} onChange={handleInputChange} placeholder="New Password" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">Re-enter new Password</label>
                                                        <input type="password"
                                                            className="form-control" name="reEnterPassword" value={data.reEnterPassword} onChange={handleInputChange} placeholder="Re-enter new Password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <button className='btn btn-orange px-5' onClick={onClickChangePassword}>Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        }
                    </div>
                </div>}

        </>
    )
}

export default ProfilePage
