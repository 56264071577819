import React from 'react'
import { useNavigate } from 'react-router-dom';
function LogoutPage(props) {
    let navigate = useNavigate()
    function clickedOnLogout(){
        console.log('logout');
        localStorage.removeItem('userData');
        navigate('/')
    }
    return (
        <>
            <div className='p-lg-3'>
                <div className='position-absolute top-0 bottom-0 start-0 end-0 m-auto p-3 border rounded-2' style={{ width: '250px', height:'200px' }} >
                    <div className='text-center'>
                        <div className='text-danger fw-bold fs-5'>Log Out !</div>
                        <hr />
                        <div >Are you sure you want to <br />
                            log out ?</div>

                            <div className='d-flex justify-content-between mt-3'>
                                <button className='btn border w-100 me-1'>Cancel</button>
                                <button className='btn border text-danger w-100 ms-1'  onClick={clickedOnLogout}>Log Out</button>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogoutPage
