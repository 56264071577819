import React, { useState } from 'react'
import { Fragment, useEffect } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import starHollow from "../../../assets/startHollow.png"
import "./PrivacyPolicy.scss"
import { useNavigate } from 'react-router-dom'


function PrivacyPolicy(props) {
let navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')

  }, [])


  return (
    <Fragment>
      <Header navigate={navigate} />
      <section style={{ backgroundColor: "#f1efe7" }}>
        <div className='privacySec  container'>
          <div className='container'>
            <p className='pt-5 pb-0 m-0 mb-2'><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt;   Privacy Policy</p>
            <div className='row'>
              <div className='col-12'>
                <div className='TearmsTitle d-flex mb-2'>
                  <span> Privacy Policy </span> <img src={starHollow} className='d-lg-block d-none start_splash_img' height={84} width={84} style={{ marginLeft: '25%', marginBottom: '-20px' }} alt="" />
                </div>
                <div className='TearmsTextHalf '>
                  At XperThrivr, we are committed
                  to protecting your privacy and
                  ensuring the security of your
                  personal information. This Privacy
                  Policy outlines our practices
                  concerning the collection, use,
                  disclosure, and protection of your
                  information. By using our platform,
                  you agree to the terms and
                  conditions outlined in this policy.

                </div>
              </div>
            </div>
            <div className='TearmsText my-lg-5 my-4'>
              <div>
                <div className="fw-bold TearmsQues">
                  1. Information We Collect :
                </div> <br />
                <div className="fw-semibold mb-3">1.1. Information You Provide : </div>
                <ul className='my-3 py-0'>
                  <li>
                    <span className="fw-semibold">Personal Information : </span> When
                    you create an account, we
                    may collect information such
                    as your name, email address,
                    username, and password.

                  </li>
                  <li>
                    <span className="fw-semibold">Profile Information : </span>You have
                    the option to provide additional
                    details in your profile, such as
                    a profile picture, a bio, and
                    other personal information.
                  </li>
                </ul>


                <span className="fw-semibold mb-3 "> 1.2. Information We Collect
                  Automatically : </span>
                <ul className='my-3 py-0'>
                  <li>
                    <span className="fw-semibold">Usage Data : </span> We collect
                    information about your
                    interactions with our platform,
                    including the content you view,
                    your IP address, device
                    information, and browser type.
                  </li>
                  <li>
                    <span className="fw-semibold">
                      Log Data :
                    </span> We automatically
                    collect log information when
                    you use our services, including
                    access times, pages viewed,
                    and the page you visited
                    before navigating to our
                    platform.
                  </li>
                </ul>
                <span className="fw-semibold">1.3. Information from ThirdParty Services : </span>
                If you choose to link your
                XperThrivr account to thirdparty services, we may collect
                information from those
                services in accordance with
                their privacy policies.
              </div>
              <br />
              <div className='fw-bold mb-3 TearmsQues'>2. How We Use Your
                Information : </div>
              <ul>
                <li>
                  <span className='fw-bold'>
                    We use the information we collect
                    for various purposes, including but
                    not limited to :
                  </span>
                  <ul>
                    <li> Providing and maintaining our platform.</li>
                    <li>Personalizing your learning experience.</li>
                    <li>Communicating with you, including sending notifications and updates.</li>
                    <li>Analyzing and improving our services.</li>
                    <li>Responding to your requests, comments, and questions.</li>
                    <li>Administering your account.</li>

                  </ul>
                </li>

              </ul>

              <div className='fw-bold mb-3 TearmsQues'>3. Data Sharing and Disclosure : </div>
              <ul>
                <li>
                  <span className='fw-semibold'> We may share your information in
                    the following ways : </span>
                  <ul>
                    <li>With content experts to facilitate communication and learning.</li>
                    <li>With third-party service providers to help us operate and maintain our platform.</li>
                    <li>In response to a legal obligation or to protect our rights, privacy, safety, or property.</li>
                  </ul>
                </li>
              </ul>

              <div className=' fw-bold mb-3 TearmsQues'>4. Your Choices : </div>
              <ul>
                <li>
                  <span className="fw-semibold">You have choices regarding your
                    information on XperThrivr,
                    including : </span>
                  <ul>
                    <li>Accessing and updating your personal information in your profile settings.</li>
                    <li>Deleting your account, subject to certain limitations.</li>
                    <li>Controlling your communication preferences.</li>
                  </ul>
                </li>
              </ul>

              <div className=' fw-bold mb-3 TearmsQues'>5. Security : </div>
              We take reasonable measures to
              protect your information. However,
              please be aware that no data
              transmission or storage system
              can be guaranteed to be
              completely secure.
              <br /><br />
              <span className="fw-bold TearmsQues">
                6. Cookies and Tracking  :
              </span>
              <span> We use cookies and similar
                tracking technologies to provide
                and improve our services. You can
                control cookies through your
                browser settings.</span>
              <br /><br />
              <span className="fw-bold TearmsQues">
                7. Third-Party Links :
              </span>
              <span className='ms-1'>
                Our platform may contain links to
                third-party websites or services.
                We are not responsible for their
                privacy practices.
              </span>
              <br /><br />
              <span className="fw-bold TearmsQues">
                8. Changes to this Privacy :
              </span>

              <span className='ms-1'>
                We may update this Privacy Policy
                from time to time. The latest
                version will be posted on our
                platform with the effective date.
              </span>
              <br /><br />
              <span className="fw-bold TearmsQues">
                9. Contact Us :
              </span>
              <span className="ms-1">
                If you have any questions,
                concerns, or requests regarding
                your privacy or this policy, please
                contact us at <span className='fw-bold text-primary ' onClick={() => window.location = 'mailto:kavish@xperthrivr.com'} style={{ cursor: 'pointer' }}>kavish@xperthrivr.com</span>
              </span>
              <br />
              <br/>
              By using XperThrivr, you consent
              to the practices described in this
              Privacy Policy. Please review this
              policy periodically for any updates
              or changes. Your continued use of
              our platform constitutes your
              agreement to this policy and any
              updates.

            </div>
          </div>
        </div>
        <Footersection navigate={navigate} />
      </section>
    </Fragment>
  )
}

export default PrivacyPolicy
