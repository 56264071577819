import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import paymentMethods from "../../../assets/paymentMethods.png"
import './CheckoutPayment.scss'
import { HiArrowLongRight } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

function CheckoutPayment(props) {

    let navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header navigate={navigate} />
            <section className='checkOutPaymentSec' style={{ backgroundColor: '#f1efe7' }}>
                <article className='container'>
                    <p className='py-4'>Crypto/Web3  &gt;   Content Expert  &gt;  Services  &gt;  Select Schedule  &gt;   Checkout</p>
                </article>
                <article className='container'>
                    <h4>Payment Method </h4>
                    <img src={paymentMethods} className='paymentMethodImg my-4' alt="" />
                </article>
                <article className='container'>
                    <div className="card bg-transparent p-3 my-4">
                        <h5>Card Details</h5>
                        <div className="row">
                            <div className="col-lg-6 col-12 py-3">
                                <label className='py-2 px-1' htmlFor="companyname">Card holder name</label>
                                <input type="text" id='companyname' className="form-control bg-transparent" placeholder="Enter card holder name" />
                            </div>
                            <div className="col-lg-6 col-12 py-3">
                                <label className='py-2 px-1' htmlFor="companyname">Card number</label>
                                <input type="text" id='companyname' className="form-control bg-transparent" placeholder="Enter card number" />
                            </div>
                            <div className="col-lg-6 col-12 py-3">
                                <label className='py-2 px-1' htmlFor="companyname">Expiry date</label>
                                <input type="text" id='companyname' className="form-control bg-transparent" placeholder="DD/MM/YY" />
                            </div>
                            <div className="col-lg-6 col-12 py-3">
                                <label className='py-2 px-1' htmlFor="companyname">cvv</label>
                                <input type="text" id='companyname' className="form-control bg-transparent" placeholder="cvv" />
                            </div>
                        </div>
                        <div className='w-100 text-center py-4'>
                            <button
                                type="button"
                                className="btn btn-primary text-center border-0 px-5 py-2"
                                style={{ backgroundColor: "#E57A4D" }}
                            >
                                Pay Now<HiArrowLongRight className='ms-2' />
                            </button>
                        </div>
                    </div>

                </article>
                <Footersection navigate={navigate} />
            </section>
        </Fragment>
    )
}

export default CheckoutPayment
