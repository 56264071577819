import React, { Fragment, useState } from 'react';
import brandLogo from "../../../assets/LOGO.png";
import "./Signuppage.Styles.scss";
import { countries } from 'country-list-json';
import Select from 'react-select';
import { HiArrowLongRight } from "react-icons/hi2";
import linkedInImg from '../../../assets/linkedIn box.png'
import sideImageReg from "../../../assets/sideImageReg.png";
import validation from '../../../components/Validations';
import apiservice from '../../../apiservice/ApiService';
import { gapi } from 'gapi-script';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import 'animate.css';
import gLogo from '../../../assets/googleLogo.png'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



function SignupPage(props) {
  let navigate = useNavigate()
  const cid = '1056869252945-srha9oll7i8am1le089q5q7al1catg6j.apps.googleusercontent.com'

  const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+1',
    mobileNumber: '',
    password: '',
    userType: '',
    confPass: '',

  }
  let errorMessage = {
    fnameError: '',
    lNameError: '',
    mailError: "",
    mobileNumError: '',
    passError: '',
    uTypeError: '',
    confPassError: ''
  }

  const [data, setdata] = useState(initialData)
  const [errorData, setErrorData] = useState(errorMessage)
  const [errorMsg, setErrorMsg] = useState('')
  let [isRoleDefined, setRole] = useState(false)
  let [userRole, setUsrRole] = useState(null)
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [socialMediaLoading, setSocialMediaLoading] = useState(false)
  // ------------------------------GoogleLogin------------------------------------------------------
  const googleLogin = () => {
    setErrorMsg('')
    if (userRole === null) {

      setErrorMsg('Please choose your roll!')
      return false
    }

    gapi.load('auth2', () => {
      const auth2 = gapi.auth2.init({ client_id: cid })
      auth2.signIn().then(
        async (googleUser) => {
          console.log(googleUser);
          const idToken = googleUser.getAuthResponse().id_token;

          console.log("User ID (idToken):", idToken);

          try {
            let reqData = {
              user_type: userRole
            }
            console.log(reqData);

            setSocialMediaLoading(true)
            let googleLogin = await apiservice.apiCall('/googleLogin', idToken, reqData, 'POST')

            console.log(googleLogin);

            if (googleLogin.data) {
              let usersDatas = googleLogin.data
              console.log(usersDatas);
              await apiservice.setLocalStorage('userData', usersDatas)
              if (usersDatas.user_type === 2) {
                console.log(googleLogin);
                console.log(googleLogin.data.user_type);
                navigate('/contetexpert-home')


              }
              else if (usersDatas.user_type === 1) {
                console.log(googleLogin);
                console.log(googleLogin.data.user_type);
                navigate('/learner-home')


              }

            }
            else {
              setSocialMediaLoading(false)
              console.log(googleLogin.error);
              setErrorMsg(googleLogin.error)
            }
          } catch (error) {
            setSocialMediaLoading(false)
            console.log(error);
          }
        }, (error) => {
          setSocialMediaLoading(false)
          console.log(JSON.stringify(error))
        }
      )
    })
  }

  // ------------------------------LinkedInLogin------------------------------------------------------

  const onClickLoginLinkedIn = () => {

    setErrorMsg('')
    if (userRole === null) {

      setErrorMsg('Please choose your roll!')
      return false
    }
    try {
      linkedInLogin();
    } catch (error) {
      console.log(error);
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "865mis3s3mhtef",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log(code);
      setCode(code);
      linkedinCallBack(code)
      setErrorMsg("");
    },
    scope: "email openid profile r_learningdata w_member_social",
    onError: (error) => {
      console.log(error);
      setCode("");
      setErrorMsg(error.errorMessage);
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    },
  })
  const linkedinCallBack = async (code) => {
    setSocialMediaLoading(true)
    let dataObject = {
      user_type: userRole,
      code: code,
    }
    let callBackRes = await apiservice.apiCall('/auth/linkedin/callback', '', dataObject, 'POST')
    console.log(callBackRes);
    if (callBackRes.data) {
      let usersDatas = callBackRes.data
      console.log(usersDatas);
      await apiservice.setLocalStorage('userData', usersDatas)
      if (usersDatas.user_type === 2) {
        console.log(callBackRes);
        console.log(callBackRes.data.user_type);
        navigate('/contetexpert-home')

      }
      else if (usersDatas.user_type === 1) {
        console.log(callBackRes);
        console.log(callBackRes.data.user_type);
        navigate('/learner-home')

      }
    } else {
      console.log(callBackRes.error);
      setErrorMsg(callBackRes.error)
      setSocialMediaLoading(false)

    }
  }



  const [selectedOption, setSelectedOption] = useState({ value: 'US +1', label: 'US +1', 'code': '+1' });
  let [options] = useState([]);

  options = countries.map((item, i) => {
    return { 'value': `${item.code} ${item.dial_code}`, 'label': `${item.code} ${item.dial_code}`, 'code': item.dial_code }
  }).sort((a, b) => a.label.localeCompare(b.label));
  const handleChange = (selectedOption) => {
    console.log(selectedOption.code);
    setdata({ ...data, 'countryCode': selectedOption.code })
    setSelectedOption(selectedOption)
  };

  const inputHandlechange = (e) => {
    let { name, value } = e.target;

    setErrorData({ ...errorData, uTypeError: "Please select the if you are a Learner or Content Expert" })
    setdata({ ...data, [name]: value })
    setErrorMsg('')
    if (name === 'firstName' && value === '') {
      setErrorData({ ...errorData, 'fnameError': 'First Name is required!' })
    } else if (name === 'firstName' && !value.match(validation().onlyStringVal)) {
      setErrorData({ ...errorData, 'fnameError': 'First Name is not valid!' })
    }
    else if (name === 'lastName' && value === '') {
      setErrorData({ ...errorData, 'lNameError': 'Last Name is required!' })
    }
    else if (name === 'lastName' && !value.match(validation().onlyStringVal)) {
      setErrorData({ ...errorData, 'lNameError': 'Last Name is not valid!' })
    }
    else if (name === 'email' && value === '') {
      setErrorData({ ...errorData, 'mailError': 'Email is required!' })
    } else if (name === 'email' && !value.match(validation().emailVal)) {
      setErrorData({ ...errorData, 'mailError': 'Email is not valid!' })
    }
    else if (name === 'countryCode' && value === "") {
      setErrorData({ ...errorData, 'mailError': 'country code is required!' })
    }
    else if (name === 'mobileNumber' && value === '') {
      setErrorData({ ...errorData, 'mobileNumError': 'Mobile Number is required!' })
    }
    else if (name === 'mobileNumber' && (!value.match(validation().mobileVal))) {
      setErrorData({ ...errorData, 'mobileNumError': 'Mobile Number is not valid!' })
    }
    else if (name === 'password' && value === '') {
      setErrorData({ ...errorData, 'passError': 'Password is required!' })
    } else if (name === 'password' && !value.match(validation().passwordVal)) {
      setErrorData({ ...errorData, 'passError': 'Your password must be at least 8 characters long and include min 1 lowercase, 1 uppercase, 1 digit, and 1 special character.' })
    }
    else if (name === 'confPass' && value === '') {
      setErrorData({ ...errorData, 'confPassError': 'Password is required!' })
    } else if (name === 'confPass' && !value.match(validation().passwordVal)) {
      setErrorData({ ...errorData, 'confPassError': 'Your password must be at least 8 characters long and include min 1 lowercase, 1 uppercase, 1 digit, and 1 special character.' })
    }
    else if (name === 'userType' && value === '') {
      setErrorData({ ...errorData, 'uTypeError': 'First Name is required!' })
    }
    else {
      setErrorData(errorMessage)
    }
  }



  const clickedOnSubmit = () => {

    if (data.firstName === '') {
      setErrorMsg('First Name is required')
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (!data.firstName.match(validation().onlyStringVal)) {
      setErrorMsg('Please enter valid First Name')
      setdata({ ...data, fnameError: 'Please enter valid First Name' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.lastName === '') {
      setErrorMsg('Last Name is required')
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (!data.lastName.match(validation().onlyStringVal)) {
      setErrorMsg('Please enter valid Last Name')
      setdata({ ...data, lNameError: 'Please enter valid Last Name' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.email === '') {
      setErrorMsg('Email Id is required')
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    } else if (!data.email.match(validation().emailVal)) {
      setErrorMsg('Email Id is not valid')
      setdata({ ...data, mailError: 'Please enter valid email' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.mobileNumber === '') {
      setErrorMsg('Mobile number is required')
    }
    else if (!data.mobileNumber.match(validation().mobileVal)) {
      setErrorMsg('Please enter valid Mobile Number')
      setdata({ ...data, mobileNumError: 'Please enter valid mobile number' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.password === '') {
      setErrorMsg('Please enter password ')
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (!data.password.match(validation().passwordVal)) {
      setErrorMsg('Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.')
      setdata({ ...data, passError: 'Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.confPass === '') {
      setErrorMsg('Please enter confirm password ')
    }
    else if (!data.confPass.match(validation().passwordVal)) {
      setErrorMsg('Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.')
      setdata({ ...data, confPassError: 'Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.confPass !== data.password) {
      setErrorMsg('Password did not match')
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else if (data.userType === '') {
      setErrorMsg('Please select the if you are a Learner or Content Expert')
      setdata({ ...data, uTypeError: 'Please select the if you are a Learner or Content Expert' })
      setTimeout(() => {
        setErrorMsg('')
      }, 8000);
    }
    else {

      signUpApifn()


    }
  }


  const responseMessage = (response) => {
    console.log(response);
  };
  const errorResponse = (error) => {
    console.log(error);
  };



  async function signUpApifn() {
    setIsLoading(true)
    let signupData = {
      'name': data.firstName,
      'lname': data.lastName,
      'email': data.email,
      'mobile': data.mobileNumber,
      'country_code': data.countryCode,
      'country_dial_code': selectedOption.value,
      'user_type': data.userType,
      'password': data.password,
      'confPass': data.confPass

    }

    try {
      let dataObj = signupData;
      let response = await apiservice.apiCall('/Regs', null, dataObj, 'POST')
      console.log(response);
      if (response.data) {
        setIsLoading(false)
        console.log(response.data);

        sendOTP(dataObj)
      } else {
        setIsLoading(false)
        setErrorMsg(response.error)
        setTimeout(() => {
          setErrorMsg('')
        }, 8000);
        console.log(response.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  const sendOTP = async (dataObj) => {
    setIsLoading(true)
    try {
      let sendOTPUrl = `/SendOtp/${dataObj.country_code}/${dataObj.mobile}`
      console.log(sendOTPUrl);
      let response = await apiservice.apiCall(sendOTPUrl)
      if (response.data) {
        console.log(response.data);
        let urlObject = {
          countryCode: dataObj.country_code,
          mobile: dataObj.mobile
        }
        let mobileNo = JSON.stringify(urlObject)

        console.log(mobileNo, 'mobileNo ---------------- mobileNo ----------------- mobileNo');
        navigate(`/verify/${mobileNo}`)
        setIsLoading(false)
      } else {
        console.log(response.error);
        setErrorMsg(response.error)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }



  return (

    <Fragment>
      <section className='d-flex flex-column align-items-center' style={{ height: '100vh', backgroundColor: '#ADD6DA' }}>


        <div className="container-fluid bg-white" >
          <div className="container-lg d-flex justify-content-between py-3">
            <img className="pointer" src={brandLogo} width={170} onClick={() => navigate('/')} height={40} alt="" />
            <button type="button" onClick={() => navigate("/login")} className="btn btn-outline-dark buttonText">
              Sign In
            </button>
          </div>
        </div>

        <div
          className="container-fluid my-auto d-flex align-items-center py-4"
          style={{
            backgroundColor: "#ADD6DA",
            maxWidth: "100%",

          }}
        >
          <div className="container">
            <div
              className="card border-3 rounded-3 border-white overflow-hidden"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                backgroundColor: "#EEEFF580",
              }}
            >

              <div className="card-body row flex-row p-0 m-0">
                <div className="col-lg-6 col-12 p-4">
                  <div className='p-0 m-0' >
                    <h5 className="regHeadText">Create an account</h5>
                    <p className="regTitleSmall">Open your account in seconds!</p>
                  </div>
                  <h5>Choose Your Role</h5>


                  <div className="d-flex justify-content-start">
                    <div className="form-check">
                      <input className="form-check-input" onClick={() => { setRole(true); setUsrRole(1); setdata({ ...data, userType: 1 }); setErrorMsg('') }} type="radio" name="userType" id="Learner" />
                      <label className="form-check-label" htmlFor="Learner">
                        Learner
                      </label>
                    </div>
                    <div className="form-check ms-3">
                      <input className="form-check-input" onClick={() => { setRole(true); setUsrRole(2); setdata({ ...data, userType: 2 }); setErrorMsg('') }} type="radio" name="userType" id="mentor" />
                      <label className="form-check-label" htmlFor="mentor">
                        Content Expert
                      </label>
                    </div>
                  </div>
                  <hr />


                  {
                    !isRoleDefined ? ' ' :
                      <section className='animate__animated animate__fadeInLeft'>

                        {socialMediaLoading ?
                          <div className='p-3 text-center fw-bold fs-5' style={{ color: '#e57a4d' }}>
                            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                          </div>
                          :
                          <div className="row">
                            <div className=" col-lg-6 col-md-6 col-12 my-2 pointer">
                              <button onClick={googleLogin} className="g-btn w-100">
                                <div className="d-flex align-items-center ">
                                  <img className=" bg-white p-1" width={40} height={40} src={gLogo} />
                                  <p className="text-white text-center w-100 m-0 p-0">Continue with Google</p>
                                </div>
                              </button>
                            </div>


                            <div className="col-lg-6 col-md-6 col-12 my-2 linkedInBtn pointer">

                              <button className="sig" onClick={onClickLoginLinkedIn}>
                                <div className='svgImg'>
                                  <img src={linkedInImg} width={40} height={40} alt="" />
                                </div>
                                <p className="text-center w-100 m-0 px-2">Continue with LinkedIn</p>
                              </button>

                            </div>
                          </div>}

                        <div className="d-flex justify-content-center align-items-center">
                          <hr className=" align-self-center mx-2 w-50" /> or <hr className="mx-2 align-self-cente w-50" />
                        </div>
                        <div className='text-center'>
                          {errorMsg !== '' ? <h6 className='text-danger '>{errorMsg}</h6> : ''}
                        </div>

                        <div className='row'>
                          <div className='col-lg-6 col-12'>
                            <div className="">
                              <label htmlFor="firstName"  >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                minLength={1}
                                required
                                value={data.firstName}
                                onChange={inputHandlechange}
                                placeholder="First Name"
                                className="border-1 w-100 rounded-2 px-2 py-2 my-1"
                                style={{ border: "1px solid #BBBBBB", outline: "none" }}
                              />
                              <div className="mb-3">
                                {errorData.fnameError !== '' ?
                                  <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.fnameError}</span> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 col-12'>
                            <div className="">
                              <label htmlFor="lastName"  >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                maxLength={32}
                                minLength={1}
                                placeholder="Last Name"
                                value={data.lastName}
                                onChange={inputHandlechange}
                                required
                                className="border-1 rounded-2 w-100 px-2 py-2 my-1"
                                style={{ border: "1px solid #BBBBBB", outline: "none" }}
                              />
                              <div className="mb-3">
                                {errorData.lNameError !== '' ?
                                  <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.lNameError}</span> : ''
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6 col-12'>
                            <div className="">
                              <label htmlFor="signUpMail"  >
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                placeholder="Email"
                                name="email"
                                onChange={inputHandlechange}
                                value={data.email}
                                required
                                className="border-1 w-100 rounded-2 px-2 py-2 my-1"
                                style={{ border: "1px solid #BBBBBB", outline: "none" }}
                              />
                              <div className="mb-3">
                                {errorData.mailError !== '' ?
                                  <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.mailError}</span> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 col-12'>
                            <label htmlFor="basic-addon1" className='mt-1' >
                              Mobile Number
                            </label>
                            <div className="input-group  rounded-2 " style={{ border: "1px solid #BBBBBB", outline: "none" }}>
                              <span className="input-group-text bg-light p-0 " id="basic-addon1">
                                <Select onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} placeholder='+91' />
                              </span>
                              <input type="text" onChange={inputHandlechange} autoComplete='off'
                                className="form-control " name='mobileNumber' required placeholder="Mobile number" />
                            </div>
                            <div className="mb-3">
                              {errorData.mobileNumError !== '' ?
                                <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.mobileNumError}</span> : ''
                              }
                            </div>
                          </div>
                        </div>


                        <div className='row'>
                          <div className="col-lg-6 col-12 ">
                            <label htmlFor="password"  >
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Password"
                              value={data.password}
                              onChange={inputHandlechange}
                              required
                              className="border-1 rounded-2 px-2 py-2 w-100 my-1"
                              style={{ border: "1px solid #BBBBBB", outline: "none" }}
                            />
                            <div className="mb-3">
                              {errorData.passError !== '' ?
                                <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.passError}</span> : ''
                              }
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-12">
                            <label htmlFor="userType"  >
                              Sign me up as a...
                            </label>
                            <select required value={data.userType} name='userType' id='userType' onChange={inputHandlechange} className="form-select border-1 rounded-2 px-2 py-2 my-1 text-muted" style={{ outline: "none !important", border: "1px solid #BBBBBB" }}>
                              <option value='' disabled className="text-muted">
                                Content Expert/Learner
                              </option>
                              <option value="2" className="text-black">Content Expert</option>
                              <option value="1" className="text-black">Learner</option>
                            </select>
                            <div className="mb-3">
                              {errorData.uTypeError !== '' ?
                                <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.uTypeError}</span> : ''
                              }
                            </div>
                          </div> */}

                          <div className="col-lg-6 col-12 ">
                            <label htmlFor="confPass"  >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              id="confPass"
                              name="confPass"
                              placeholder="Confirm Password"
                              value={data.confPass}
                              onChange={inputHandlechange}
                              required
                              className="border-1 rounded-2 px-2 py-2 w-100 my-1"
                              style={{ border: "1px solid #BBBBBB", outline: "none" }}
                            />
                            <div className="mb-3">
                              {errorData.confPassError !== '' ?
                                <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errorData.passError}</span> : ''
                              }
                            </div>
                          </div>

                        </div>

                        <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between ">
                          <button type="submit" onClick={clickedOnSubmit} className="btn btn-outline-dark buttonText px-3 m-2" disabled={isLoading ? true : false}>
                            {isLoading ? 'Loading...' : 'Sign Up'}
                          </button>
                        </div>
                      </section>

                  }
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none">
                  <div className='mt-4 pb-4'>
                    <img src={sideImageReg} className="sideImgSignUp" alt="" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SignupPage;
