import React, { useRef, useState } from 'react';
import './CreateANewServices.scss';
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import validation from '../../../components/Validations';
import apiservice from '../../../apiservice/ApiService';
import IndustryArray from './../../../components/MentorsComponents/IndustryArray/IndustryArray';
import { useEffect } from 'react';
import PreviewPage from './../../MentorsPages/PreviewPage/PreviewPage';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

function CreateANewServices(props) {
  let navigate = useNavigate()
  // const [userEditableData, setUserEditableData] = useState({})

  // useEffect(() => {
  //   async function tempFun() {
  //     let tempData = await apiservice.getLocalStorage('serviceData');
  //     setUserEditableData(tempData)
  //     console.log(userEditableData);
  //   }
  //   tempFun()
  // }, [])

  // let [curServ,setCurServ] = useState()
  // useEffect(()=>{
  //   async function getData(){
  //     let reqData = await apiservice.getLocalStorage('currentService')
  //     setCurServ(reqData);
  //     console.log(reqData);
  //   }
  //   console.log(curServ);
  //   getData()

  // },[])

  const initialDataState = {
    industryFocus: '',
    otherIndustryFocus: '',
    serviceName: '',
    serviceDescription: '',
    hours: '',
    minutes: '',
    typeOfService: '',
    price: '',
    mentorshipType: '',
    sessionsNo: '',
    participantsNo: '',
    webinarDate: '',
    webinarTime: '',

    imageFile: '',
    imageURL: '',
    videoFile: '',
    imageFileforName: '',
    videoUrl: null,
    youtubeVideoFile: null,

    overview: '',
    contentDescription: '',
    chapterTitle: '',
    sessionDuration: '',
    resourcesFile: [],
    resourcesUrlArr: [],
    chapterDetailsArr: [],


    question: '',
    answer: '',
    queAnsArr: [],
    editQAIndex: '',

    fromHours: '',
    toHours: '',
  };
  const [data, setData] = useState(initialDataState);
  const [currentStep, setCurrentStep] = useState('step1')
  const [errorMsg, setErrorMsg] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [stepCount, setStepCount] = useState(1)
  let [dataToEdit, setDataToEdit] = useState(false)
  let [isResoursesLoading, setResLoading] = useState(false)
  let [isLoading, setIsLoading] = useState(false)
  const scrollRef = useRef(null);
  const [userData, setUserData] = useState('')
  const [overviewDesc, setOverviewDesc] = useState('')
  const [contentDesc, setContentDesc] = useState('')
  const [faqAnswer, setFaqAnswer] = useState('')
  const [cropPopUp, setCropPopUp] = useState(false)
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        16 / 9,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }
  useEffect(() => {



    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    async function getData() {

      function isYouTubeLink(link) {
        let youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).*/;
        return youtubeRegex.test(link);
      }

      try {
        let reqData = await apiservice.getLocalStorage('currentService')
        if (reqData !== null) {
          let isYoutubeVideo = isYouTubeLink(reqData.UploadVideo)
          console.log(isYoutubeVideo);
          console.log(reqData.UploadVideo);
          setDataToEdit(true)
          setStepCount(4)
          setOverviewDesc(reqData.overview)
          setContentDesc(reqData.Content_Description)
          setData(
            {
              ...reqData,
              industryFocus: reqData.Industry,
              otherIndustryFocus: reqData.otherIndustryFocus,
              serviceName: reqData.Service_Name,
              serviceDescription: reqData.Service_Description,
              hours: reqData.Hour,
              minutes: reqData.Minutes,
              price: reqData.Price,
              typeOfService: reqData.typeOfService,
              mentorshipType: reqData.MentorshipType,
              sessionsNo: reqData.NumberOfSession,
              participantsNo: reqData.NumberOfParticipant,
              imageFile: reqData.ThumbnailImage !== null ? reqData.ThumbnailImage : data.imageFile,
              imageFileforName: reqData.ThumbnailImage !== null ? reqData.ThumbnailImage : data.imageFileforName,
              imageURL: reqData.ThumbnailImage,
              // videoFile: reqData.UploadVideo !== null ? reqData.UploadVideo : data.videoFile,
              videoUrl: isYoutubeVideo ? null : reqData.UploadVideo,
              youtubeVideoFile: isYoutubeVideo ? reqData.youtubeVideoFile : null,
              overview: reqData.overview,
              contentDescription: reqData.Content_Description,
              chapterTitle: null,
              sessionDuration: null,
              resourcesFile: reqData.Resources !== null ? reqData.Resources : data.resourcesFile,
              chapterDetailsArr: JSON.parse(reqData.Chapter),
              queAnsArr: reqData.QueAns,
              webinarDate: reqData.start_date === null ? null : reqData.start_date,
              webinarTime: reqData.start_time === null ? null : reqData.start_time
            })


        }
        else {
          setData(initialDataState)
          setDataToEdit(false)

        }
      } catch (error) {
        console.log(error);
      }
    }
    getData()
    getUserData()
  }, [])

  const getUserData = async () => {
    let uData = await apiservice.getLocalStorage('userData')
    setUserData(uData)
  }

  console.log(data, ' ------------------');
  const handleInputChange = event => {
    const { name, value } = event.target;

    if (name === 'industryFocus' && value === 'Crypto/Web3') {
      setData({ ...data, 'otherIndustryFocus': '', [name]: value });
    } else if (name === 'mentorshipType' && value === '1:1 Single Session') {
      setData({ ...data, 'sessionsNo': '', 'participantsNo': '', [name]: value });
    }
    else if (name === 'mentorshipType' && value === '1:1 Multi-Session') {
      setData({ ...data, 'participantsNo': '', [name]: value });
    }
    else if (name === 'mentorshipType' && value === 'Webinar Session') {
      setData({ ...data, 'sessionsNo': '', [name]: value });
    }
    else if (name === 'typeOfService') {
      setData({ ...data, 'price': 0, [name]: value });

    }

    else {
      setData({ ...data, [name]: value });
    }
    setErrorMsg('')
  };


  const onSelectVideoFile = (file) => {

    let fileUrl = URL.createObjectURL(file)
    console.log(fileUrl);

    setData({ ...data, 'videoFile': file, 'videoUrl': fileUrl, youtubeVideoFile: null }); setErrorMsg('')
  }


  const onSelectImageFile = (file) => {
    let fileUrl = URL.createObjectURL(file)
    setData({ ...data, 'imageFile': file, 'imageURL': fileUrl }); setErrorMsg('')
  }

  
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [cropString, setCropString] = useState('')


  const onSelectCropImageFile = (e) => {
    const file = e;

    if (file) {
      console.log(file);
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setCropPopUp(true)
        setData({ ...data, imageFile: reader.result, imageFileforName: file })
      }
      );
      reader.readAsDataURL(file);
    }

  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  async function completedCrop(ele) {
    if (ele?.width && ele?.height && imgRef.current) {
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = ele.width;
      canvas.height = ele.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        imgRef.current,
        ele.x * scaleX,
        ele.y * scaleY,
        ele.width * scaleX,
        ele.height * scaleY,
        0,
        0,
        ele.width,
        ele.height
      );
      const base64String = canvas.toDataURL('image/png');
      setCropString(base64String)
      console.log(base64String);
    }
  }

  const handleCropButtonClick = () => {
    setCropPopUp(false)
    setData({ ...data, 'imageFile': cropString, 'imageURL': cropString });
  }
  const onClickOK = () => {
    setData({ ...data, selectedImgURL: '', })
    setCropPopUp(false)

  }



  let tempResourceFile = []
  let tempResUrl = []
  const onSelectResourcesFile = async (file) => {
    setResLoading(true)

    if (!Array.isArray(data.resourcesFile)) {
      let item = data.resourcesFile
      for (let x in item) {
        tempResourceFile.push(item[x].file_path)
      }
    }
    else {
      tempResourceFile = data.resourcesFile
    }


    // tempResourceFile = data.resourcesFile

    let filesArr = file.target.files[0]
    let formdata = new FormData()
    formdata.append('service_file', filesArr)

    try {
      let uData = await apiservice.getLocalStorage('userData')
      let resourcefile = await apiservice.apiCallFormData('/resource_file_add', uData.Token, formdata, 'POST')

      if (resourcefile.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (resourcefile.data) {

        let resFile = resourcefile.data

        tempResourceFile.push(resFile)

        setResLoading(false)

      }
      else {
        console.log(resourcefile.error);
        setResLoading(false)

      }
    } catch (error) {
      console.log(error);
      setResLoading(false)

    }
    setData({ ...data, 'resourcesFile': tempResourceFile });

  }
  async function uploadResFile() {

  }
  const removeResourceFileData = (id) => {
    data.resourcesFile.splice(id, 1)
    setData({ ...data, 'resourcesFile': data.resourcesFile });

  }
  async function clickedOnDeleteResource(value) {
    setIsLoading(true)
    let reqData = {
      service_id: data.Service_id,
      resource_file_id: +value
    }
    let uData = await apiservice.getLocalStorage('userData')
    try {
      let deleteResource = await apiservice.apiCall('/resource_file_delete', uData.Token, reqData, 'POST')
      console.log(deleteResource)
      if (deleteResource.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (deleteResource.data) {
        setIsLoading(false)
        delete data.resourcesFile[value];
        setData({ ...data, resourcesFile: data.resourcesFile })

      }
      else {
        setIsLoading(false)
        console.log(deleteResource.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }



  const resourcesFileView = () => {

    let resourcesData = data.resourcesFile
    let list = []

    if (Array.isArray(resourcesData)) {
      for (let index = 0; index < resourcesData.length; index++) {
        const datas = resourcesData[index];
        list.push(
          <div key={index} className='mt-3 d-flex justify-content-between bg-light p-2'>
            <div>
              {datas}
            </div>
            <div><FontAwesomeIcon icon={faTrashAlt} onClick={() => removeResourceFileData(index)} /></div>
          </div>
        )
      }
    }
    else {
      for (let value in resourcesData) {

        list.push(
          <div key={value} className='p-2 mt-2 bg-light rounded-1'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='overflow-hidden'>{resourcesData[value].file_path}</div>
              <div className='ms-3' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => clickedOnDeleteResource(value)} /></div>
            </div>
          </div>
        )
      }
    }
    return list
  }
  const durationFn = () => {
    let list = []
    for (let index = 30; index < 60; index++) {

      list.push(
        <option key={index} value={index}>{index} mins</option>
      )
    }
    list.push(
      <option key={'1hr'} value={1}>1 hr</option>
    )
    return list
  }
  const questionAnsFn = () => {
    let list = []
    let currdata = data.queAnsArr;
    for (let index = 0; index < currdata.length; index++) {
      let element = currdata[index]
      list.push(
        <div key={index} className='p-3 d-none d-lg-block'>
          <div className='row mb-2'>
            <div className='col-lg-1 rounded-circle text-center pt-2' style={{ height: '50px', width: '50px', backgroundColor: '#E6E6E6' }}>
              <div className='mt-1 fw-bold'>  {index + 1}</div>
            </div>

            <div className='col-lg-10 col-12 ms-lg-3 mt-lg-0 mt-3 '>
              <div className='fw-bold'>{element.question}</div>
              <div className='desc' dangerouslySetInnerHTML={{ __html: element.answer }} />
            </div>

            <div className='col-lg-1 d-flex  pt-lg-2'>
              <FontAwesomeIcon className='me-3' icon={faPencilAlt} onClick={() => editQAdata(element, index)} />
              <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeQAdata(index)} />
            </div>
          </div>
        </div>

      )
      list.push(
        <div key={index} className='d-lg-none d-block'>
          <div className='d-flex mb-2'>
            <div className='rounded-circle text-center p-2' style={{ height: '35px', width: '35px', backgroundColor: '#E6E6E6' }}>
              <div className=' fw-bold'>  {index + 1}</div>
            </div>

            <div className='ms-3'>
              <div className='fw-bold'>{element.question}</div>
              <div className='desc' dangerouslySetInnerHTML={{ __html: element.answer }} />
            </div>

            <div className=' d-flex justify-content-between'>
              <FontAwesomeIcon className='me-2' icon={faPencilAlt} onClick={() => editQAdata(element, index)} />
              <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeQAdata(index)} />
            </div>
          </div>
        </div>

      )
    }
    return list
  }
  let tempQAarr = []
  const questionandAnswerSavebtn = () => {
    let newAnswerText = faqAnswer.replace(/<.*?>/g, "");
    tempQAarr = data.queAnsArr;
    if (!data.question || data.question.trim() === '') {
      setErrorMsg('Question is required!');
    } else if (data.question.length < 5 || data.question.length > 200) {
      setErrorMsg('Please enter the question with a character count between 5 and 200 characters.');
    } else if (!faqAnswer || newAnswerText.trim() === '') {
      setErrorMsg('Answer is required!');
    } else if (faqAnswer.length < 5 || faqAnswer.length > 500) {
      setErrorMsg('Please enter the answer with a character count between 5 and 500 characters.');
    }
    else {
      let dataObject = {
        question: data.question,
        answer: faqAnswer
      }
      tempQAarr.push(dataObject)
      setFaqAnswer('')
      setData({ ...data, queAnsArr: tempQAarr, question: "", answer: "" })

    }
  }

  const removeQAdata = (id) => {
    data.queAnsArr.splice(id, 1)
    setData({ ...data, queAnsArr: data.queAnsArr });
  }

  function editQAdata(reqItem, id) {

    setIsUpdate(true)
    setFaqAnswer(reqItem.answer)
    setData({ ...data, question: reqItem.question, answer: reqItem.answer, editQAIndex: id })
  }

  const questionandAnswerUpdatebtn = () => {
    tempQAarr = data.queAnsArr;
    if (data.question === '' || faqAnswer === null) {
      setErrorMsg('Question is required!')
    }
    else if (data.question.trim() === '') {
      setErrorMsg('Question is required!')
    }
    else if (data.question.length > 200 || data.question.length < 5) {
      setErrorMsg('Please enter the question with a character count between 5 and 200 characters.!')
    }
    else if (faqAnswer === '' || faqAnswer === null) {
      setErrorMsg('Answer is required!')
    }
    else if (faqAnswer.trim() === '') {
      setErrorMsg('Answer is required!')
    }
    else if (faqAnswer.length > 500 || faqAnswer.length < 5) {
      setErrorMsg('Please enter the answer with a character count between 5 and 500 characters.!')
    } else {
      let dataObject = {
        question: data.question,
        answer: faqAnswer,
      }

      data.queAnsArr[data.editQAIndex] = dataObject
      setFaqAnswer('')
      setData({ ...data, queAnsArr: tempQAarr, question: "", answer: "" })

    }
    setIsUpdate(false)
  }

  const serviceInfoNext = () => {
    let price = data.price.toString()
    if (data.industryFocus === '') {
      setErrorMsg('Industry Focus is required!')
    }
    else if (data.industryFocus === 'General' && data.otherIndustryFocus === '') {
      setErrorMsg('Other Indusrty focus in required!')
    }
    else if (data.serviceName === '' || data.serviceName === null) {
      setErrorMsg('Service name is required!')
    }
    else if (data.serviceName.trim() === '') {
      setErrorMsg('Service name is required!')
    }
    else if (data.serviceName.length > 100 || data.serviceName.length < 5) {
      setErrorMsg('Please enter the service name with a character count between 5 and 100 characters.')
    }
    else if (data.serviceDescription === '' || data.serviceDescription === null) {
      setErrorMsg('Service description is required!')
    }
    else if (data.serviceDescription.trim() === '') {
      setErrorMsg('Service description is required!')
    }
    else if (data.serviceDescription.length > 300 || data.serviceDescription.length < 5) {
      setErrorMsg('Please enter the service description with a character count between 5 and 300 characters.')
    }
    else if (data.hours === '') {
      setErrorMsg('Hours is required!')
    }
    else if (!data.hours.toString().match(validation().onlyNumberVal)) {
      setErrorMsg('Hours should be number only')
    }
    else if (data.hours < 1 && data.minutes < 30) {
      setErrorMsg('Kindly input a duration of at least 30 minutes.')
    }
    else if (data.hours.length > 3) {
      setErrorMsg('Please enter the hours in a maximum of three digits.')
    }
    else if (data.minutes === '') {
      setErrorMsg('Minites is required!')
    }
    else if (!data.minutes.toString().match(validation().onlyNumberVal)) {
      setErrorMsg('Minites should be in number only!')
    }
    else if (data.minutes >= 60) {
      setErrorMsg('Please input a duration less than 60 minutes.')
    }
    else if (data.typeOfService === '') {
      setErrorMsg('Type of service is required!')
    }
    else if (data.typeOfService === 'Paid' && data.price === '') {
      setErrorMsg('Price is required!')
    }
    else if (data.typeOfService === 'Paid' && !price.match(validation().numberWithDecimal)) {
      setErrorMsg('Price is required!')
    }
    else if (data.typeOfService === 'Paid' && (data.price < 1 || data.price.length > 6)) {
      setErrorMsg("Please note that for paid services, the entry of '0' is not applicable. In the event of providing a service with associated charges, we kindly request inputting the accurate pricing information.")
    }
    else if (data.mentorshipType === '') {
      setErrorMsg('Mentorship Type is required!')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' && (data.sessionsNo === '' || data.sessionsNo === null || data.sessionsNo === undefined)) {
      setErrorMsg('Sessions no is required!')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' && data.sessionsNo < 1) {
      setErrorMsg('Kindly enter a sessions no. greater than zero!!')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' && (!data.sessionsNo.toString().match(validation().onlyNumberVal))) {
      setErrorMsg('Sessions No should be in number only!')
    }
    else if (data.mentorshipType === 'Webinar Session' && (data.participantsNo === '' || data.participantsNo === null || data.participantsNo === undefined)) {
      setErrorMsg('Participants no is required!')
    }
    else if (data.mentorshipType === 'Webinar Session' && data.participantsNo < 0) {
      setErrorMsg('Kindly enter a participants no. greater than zero!')
    }
    else if (data.mentorshipType === 'Webinar Session' && (!data.participantsNo.toString().match(validation().onlyNumberVal) || data.participantsNo > 100)) {
      setErrorMsg('The maximum number of participants allowed in a webinar session is limited to 100.')
    }
    else if (data.mentorshipType === 'Webinar Session' && !data.participantsNo.toString().match(validation().onlyNumberVal)) {
      setErrorMsg('Participants No should be in number only!')
    }
    else if (data.mentorshipType === 'Webinar Session' && (data.webinarDate === '' || data.webinarDate === null || data.webinarDate === undefined)) {
      setErrorMsg('Choose date is required!')
    }
    else if (data.mentorshipType === 'Webinar Session' && (data.webinarTime === '' || data.webinarTime === null || data.webinarDate === undefined)) {
      setErrorMsg('Choose time is required!')
    }
    else {
      let dataObject = {
        industryFocus: data.industryFocus,
        otherIndustryFocus: data.otherIndustryFocus,
        serviceName: data.serviceName,
        serviceDescription: data.serviceDescription,
        hours: data.hours,
        minutes: data.minutes,
        price: data.price,
        mentorshipType: data.mentorshipType,
        sessionsNo: data.sessionsNo,
        participantsNo: data.participantsNo,
        webinarDate: data.webinarDate,
        webinarTime: data.webinarTime
      }

      setCurrentStep('step2')
      setStepCount(2)
      setErrorMsg('')

    }
  }

  const mediaFileNext = () => {
    // if (data.videoFile === '' && data.youtubeVideoFile === '') {
    //   setErrorMsg('Please select video file or link is required!')
    // } else 
    if (data.imageFile === '') {
      setErrorMsg('Please thumbnail image is required!')
    } else {
      setCurrentStep('step3')
      setStepCount(3)
      setErrorMsg('')
    }
  }



  let TempChapterDataArr = [];

  const onClickChapertDetailsSave = () => {
    TempChapterDataArr = data.chapterDetailsArr;
    let curentAddedTime = 0;
    for (let index = 0; index < TempChapterDataArr.length; index++) {
      const element = TempChapterDataArr[index];
      console.log(element.sessionDuration);
      curentAddedTime = Number(curentAddedTime) + Number(element.sessionDuration === '1' ? 60 : element.sessionDuration);
    }

    let currentTimeMinute = Number(curentAddedTime);
    let totalDurationInMinute = (Number(data.hours) * 60) + Number(data.minutes);


    if (data.chapterTitle === null || data.chapterTitle === '') {
      setErrorMsg('Session name is required!');
      return
    }
    if (data.chapterTitle.trim() === '') {
      setErrorMsg('Session name is required!');
      return
    }
    if (data.chapterTitle.length < 5 || data.chapterTitle.length > 100) {
      setErrorMsg('Please enter the session name with a character count between 5 and 100 characters.');
      return
    }
    if (data.sessionDuration === '') {
      setErrorMsg('Session duration is required!');
      return
    }
    if (TempChapterDataArr.length >= data.sessionsNo || currentTimeMinute >= totalDurationInMinute) {

      setErrorMsg(`Your service includes ${data.sessionsNo} sessions, with a total duration of ${data.hours} hour and ${data.minutes} minutes. To ensure optimal results, 
      kindly adhere to these limits. If you wish for more sessions or an extended duration, 
      feel free to adjust the total number of sessions or service duration.`);
      return

    }
    let dataObject = {
      chapterTitle: data.chapterTitle,
      sessionDuration: data.sessionDuration,
    };

    TempChapterDataArr.push(dataObject);
    setData({ ...data, chapterDetailsArr: TempChapterDataArr, chapterTitle: '', sessionDuration: '' });
    setErrorMsg('');

  };

  const additionalInformationNext = () => {
    TempChapterDataArr = data.chapterDetailsArr;
    let curentAddedTime = 0;
    for (let index = 0; index < TempChapterDataArr.length; index++) {
      const element = TempChapterDataArr[index];
      console.log(element.sessionDuration);
      console.log(element.sessionDuration === '1' ? 60 : Number(element.sessionDuration));
      curentAddedTime = Number(curentAddedTime) + Number(element.sessionDuration === '1' ? 60 : Number(element.sessionDuration));
    }

    let currentTimeMinute = Number(curentAddedTime)
    let totalDurationInMinute = (Number(data.hours) * 60) + Number(data.minutes);
    console.log(currentTimeMinute, totalDurationInMinute);
    console.log(TempChapterDataArr.length, data.sessionsNo);

    let newOverviewText = overviewDesc.replace(/<.*?>/g, "");
    let newContentText = contentDesc.replace(/<.*?>/g, "");
    console.log(newOverviewText);
    if (overviewDesc === '' || overviewDesc === null) {
      setErrorMsg('Overview is required!')
    }
    else if (newOverviewText.trim() === '') {
      setErrorMsg('Overview is required.')
    }
    else if (overviewDesc.length > 5000 || overviewDesc.length < 5) {
      setErrorMsg('Please enter the overview with a character count between 5 and 5000 characters.')
    }
    else if (contentDesc === '' || contentDesc === null) {
      setErrorMsg('Content description is required!')
    }
    else if (newContentText.trim() === '') {
      setErrorMsg('Content description is required!')
    }
    else if (contentDesc.length > 1000 || contentDesc.length < 5) {
      setErrorMsg('Please enter the content description with a character count between 5 and 1000 characters.')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' && (TempChapterDataArr.length !== Number(data.sessionsNo) || currentTimeMinute !== totalDurationInMinute)) {
      setErrorMsg(`Your service includes ${data.sessionsNo} sessions, with a total duration of ${data.hours} hour and ${data.minutes} minutes. To ensure optimal results, 
      kindly adhere to these limits. If you wish for more sessions or an extended duration, 
      feel free to adjust the total number of sessions or service duration.`);

    }
    else {
      let dataObject = {
        overview: data.overview,
        contentDescription: contentDesc,
        chapterDetailsArr: data.chapterDetailsArr,
        resourcesFile: data.resourcesFile,
      }

      setCurrentStep('step4')
      setStepCount(4)

      setErrorMsg('')


    }
  }

  const removeChapterDetailsdata = (id) => {
    data.chapterDetailsArr.splice(id, 1)
    console.log(data.chapterDetailsArr);
    console.log(id);
    setData({ ...data, 'chapterDetailsArr': data.chapterDetailsArr });
  }

  async function createService() {
    if (data.industryFocus === '') {
      setErrorMsg('Industry Focus is required!')
    }
    else if (data.industryFocus === 'General' && data.otherIndustryFocus === '') {
      setErrorMsg('Other Indusrty focus in required!')
    }
    else if (data.serviceName === '') {
      setErrorMsg('Service name is required!')
    }
    else if (data.serviceDescription === '') {
      setErrorMsg('Service description is required!')
    }
    else if (data.hours === '') {
      setErrorMsg('Hours is required!')
    }
    else if (!data.hours.match(validation().onlyNumberVal)) {
      setErrorMsg('Hours should be number only')
    }
    else if (data.minutes === '') {
      setErrorMsg('Minites is required!')
    }
    else if (!data.minutes.match(validation().onlyNumberVal)) {
      setErrorMsg('Minites should be in number only!')
    }
    else if (data.typeOfService === '') {
      setErrorMsg('Type of service is required!')
    }
    else if (data.typeOfService === 'Paid' && data.price === '') {
      setErrorMsg('Price is required!')
    }
    else if (data.typeOfService === 'Paid' && !data.price.match(validation().numberWithDecimal)) {
      setErrorMsg('Price is required!')
    }
    else if (data.mentorshipType === '') {
      setErrorMsg('Mentorship Type is required!')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' && data.sessionsNo === '') {
      setErrorMsg('Sessions no is required!')
    }
    else if (data.mentorshipType === 'Webinar Session' && data.participantsNo === '') {
      setErrorMsg('Participants no is required!')
    }
    else if (data.videoFile === '' && data.youtubeVideoFile === '') {
      setErrorMsg('Please select video file or link is required!')
    }
    else if (overviewDesc === '') {
      setErrorMsg('Overview is required!')
    }
    else if (contentDesc === '') {
      setErrorMsg('Content description is required!')
    }
    else if (data.mentorshipType === '1:1 Multi-Session' || data.chapterDetailsArr.length === 0) {
      setErrorMsg('Chapter Details is required!')
    }
    else if (data.imageFile === '') {
      setErrorMsg('Please thumbnail image is required!')
    }
    else {
      let requiredData = {

        Industry: data.industryFocus,
        Other_Industry_Name: data.otherIndustryFocus,
        Service_Name: data.serviceName,
        Service_Description: data.serviceDescription,

        Hour: data.hours,
        Minutes: data.minutes,
        Price: data.typeOfService === 'Free' ? 0 : data.price,

        MentorshipType: data.mentorshipType,
        NumberOfSession: data.sessionsNo,
        NumberOfParticipant: data.participantsNo,

        UploadVideo: data.videoFile,
        ThumbnailImage: data.imageFile,

        Overview: overviewDesc,
        Content_Description: contentDesc,
        Chapter: JSON.stringify(data.chapterDetailsArr),
        Resources: JSON.stringify(data.resourcesFile),
        QueAns: JSON.stringify(data.queAnsArr),
      }

      let formData = new FormData();
      for (let key in requiredData) {
        formData.append(key, requiredData[key]);
      }
      try {
        let uData = await apiservice.getLocalStorage('userData')
        let res = await apiservice.apiCallFormData("/Service_Add", uData.Token, formData, 'POST')
        console.log(res);
        if (res.unAuthenticated) {
          navigate('/')
          localStorage.removeItem('userData')
        } else if (res.data) {
          console.log(res.data);
        }
        else {
          console.log(res.error);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function showPreviewfn() {
    console.log(data);
    //  alert(overviewDesc);
    setData({ ...data, 'overview': overviewDesc, 'contentDescription': contentDesc })
    setShowPreview(true)

  }

  let closePreviewfn = () => {
    setCurrentStep('step1')
    setShowPreview(false)
  }

  function clickedOnStep1() {
    setCurrentStep('step1')
  }

  function clickedOnStep2() {
    if (stepCount === 1 || stepCount === 2) {
      serviceInfoNext()
    }
    else if (stepCount === 3 || stepCount === 4) {
      setCurrentStep(2)
      setCurrentStep('step2')
    }
  }

  function clickedOnStep3() {
    if (stepCount === 2 || stepCount === 3) {
      mediaFileNext()
    }
    else if (stepCount === 4) {
      setCurrentStep(3)
      setCurrentStep('step3')
    }
  }

  function clickedOnStep4() {
    if (stepCount === 3 || stepCount === 4) {
      additionalInformationNext()
    }

  }




  return (
    <>
      <Modal show={cropPopUp}>

        <ModalBody>
          <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickOK}><FontAwesomeIcon icon={faTimes} /></div>

          {data.imageFile && (
            <div className="crop-container">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => completedCrop(c)}
                aspect={false}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={data.imageFile}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-orange' onClick={handleCropButtonClick}>Crop Image</button>
        </ModalFooter>
      </Modal>

      {showPreview ? <PreviewPage prevData={data} dataToEdit={dataToEdit} closePreviewfn={closePreviewfn} setCurrentStep={setCurrentStep} navigate={navigate} videoFile={data.videoFile} showPreview={showPreview} setShowPreview={setShowPreview} />
        :

        <div className='p-3' ref={scrollRef}>
          <div className=''>
            <div className='fw-bold Title'>Create a New Service</div>
          </div>
          <div className='d-none d-lg-block'>
            <div className='mt-3'>
              <div className='row ' style={{ height: 'max-content' }}>
                <div className='col-lg-3 col-12 p-2' style={{ cursor: 'pointer' }} > <div className={currentStep === 'step1' ? 'p-3  fw-bold active-form-step text-center rounded  h-100' : 'p-3 bg-light fw-bold text-center rounded h-100'} onClick={clickedOnStep1}  >Service Info</div></div>
                <div className='col-lg-3 col-12 p-2' style={{ cursor: 'pointer' }} > <div className={currentStep === 'step2' ? 'p-3  fw-bold active-form-step text-center rounded h-100' : 'p-3 bg-light fw-bold text-center rounded h-100'} onClick={clickedOnStep2} >Media Files</div></div>
                <div className='col-lg-3 col-12 p-2' style={{ cursor: 'pointer' }} > <div className={currentStep === 'step3' ? 'p-3  fw-bold active-form-step text-center rounded h-100' : 'p-3 bg-light fw-bold text-center rounded h-100'} onClick={clickedOnStep3}  >Additional Information</div></div>
                <div className='col-lg-3 col-12 p-2' style={{ cursor: 'pointer' }} > <div className={currentStep === 'step4' ? 'p-3  fw-bold active-form-step text-center rounded h-100' : 'p-3 bg-light fw-bold text-center rounded h-100'} onClick={clickedOnStep4}  >Q&A</div></div>
              </div>
            </div>
          </div>
          <div className='d-lg-none d-block'>
            <div className='mt-3'>
              <div className='' style={{ height: 'max-content' }}>
                <div className='row'>
                  <div className='col-6 p-1'> <div className={currentStep === 'step1' ? 'p-2  fw-bold active-form-step text-center rounded ' : 'p-2 bg-light fw-bold text-center rounded'} onClick={clickedOnStep1}  >Service Info</div></div>
                  <div className='col-6 p-1'> <div className={currentStep === 'step2' ? 'p-2  fw-bold active-form-step text-center rounded' : 'p-2 bg-light fw-bold text-center rounded'} onClick={clickedOnStep2} >Media Files</div></div>
                </div>
                <div className='row'>
                  <div className='col-6 p-1'> <div className={currentStep === 'step3' ? 'p-2  fw-bold active-form-step text-center rounded ' : 'p-2 bg-light fw-bold text-center rounded '} onClick={clickedOnStep3}  >Additional Information</div></div>
                  <div className='col-6 p-1'> <div className={currentStep === 'step4' ? 'p-2  fw-bold active-form-step text-center rounded ' : 'p-2 bg-light fw-bold text-center rounded '} onClick={clickedOnStep4}  >Q&A</div></div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* Form Step 1 */}
          {currentStep === 'step1' ?
            <div className=' mt-3'>
              <div className='text-center mb-3'>
                {errorMsg !== '' ?
                  <small className='text-danger'>{errorMsg}</small>
                  : ''}
              </div>
              <div className='row'>
                <div className='col-lg-4 col-12'>
                  <div className='mb-3'>
                    <div className="form-group">
                      <label >Industry Focus</label>
                      <select className="form-select" name="industryFocus" value={data.industryFocus} onChange={handleInputChange}>
                        <option value=''>Select Option</option>
                        {
                          IndustryArray().map((item) => {
                            return <option key={item.name} value={item.name} >{item.name}</option>
                          })
                        }


                      </select>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 col-12'>
                  <div className='mb-3'>
                    <div className="form-group">
                      <label >Service Name</label>
                      <input type="text"
                        className="form-control" name="serviceName" value={data.serviceName} onChange={handleInputChange} placeholder="Service Name" />
                    </div>
                  </div>
                </div>
              </div>
              {data.industryFocus === 'General' ?
                <div className='row'>
                  <div className='col-lg-4 col-12'>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Other Industry Focus</label>
                        <input type="text"
                          className="form-control" name="otherIndustryFocus" value={data.otherIndustryFocus} onChange={handleInputChange} placeholder="Other Industry Focus" />
                      </div>
                    </div>
                  </div>
                </div> : ''}

              <div className='mb-3'>
                <div className="form-group">
                  <label >Service Description</label>
                  <textarea className="form-control" name="serviceDescription" value={data.serviceDescription} rows="3" onChange={handleInputChange} placeholder='Description'></textarea>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-10 col-12'>
                  <div className='mb-3'>
                    <div className='row'>
                      <div className='col-lg-5 col-12'>
                        <div>Total Duration</div>
                        <div className='d-flex'>
                          <div className="form-group">
                            <input type="text"
                              className="form-control" name="hours" value={data.hours} onChange={handleInputChange} placeholder="hours" />
                          </div>

                          <div className="form-group ms-3">
                            <input type="text"
                              className="form-control" name="minutes" value={data.minutes} onChange={handleInputChange} placeholder="minutes" />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-12'>
                        <div className='d-flex'>
                          <div className="form-group">
                            <label htmlFor=''>Type of Service</label>

                            <select
                              className="form-select" name="typeOfService" value={data.typeOfService} onChange={handleInputChange} >
                              <option value='' >Select</option>
                              <option value='Free' >Free</option>
                              <option value='Paid' >Paid</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-12'>
                        <div className='d-flex'>
                          <div className="form-group">
                            <label htmlFor=''>Price {userData.CurrencySymbol === null ? '' : `${userData.CurrencySymbol}`}</label>
                            <input type="text"
                              className="form-control" name="price" value={data.price} onChange={handleInputChange} disabled={data.typeOfService === 'Free' ? true : false} placeholder={`Price ${userData.CurrencySymbol === null ? '' : `${userData.CurrencySymbol}`}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mb-3'>
                <div className='row'>
                  <div className='col-lg-4 col-12'>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Mentorship Type</label>
                        <select className="form-select" name="mentorshipType" value={data.mentorshipType} onChange={handleInputChange} disabled={dataToEdit ? true : false}>
                          <option value="">Select Option</option>
                          <option value="1:1 Single Session">1:1 Single Session</option>
                          <option value="1:1 Multi-Session">1:1 Multi-Session</option>
                          <option value="Webinar Session">Webinar</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Number of sessions</label>
                        <input type="text"
                          className="form-control" name="sessionsNo" value={data.sessionsNo} disabled={data.mentorshipType === '1:1 Multi-Session' ? false : true} onChange={handleInputChange} placeholder="Number of sessions" />

                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Number of participants</label>
                        <input type="text"
                          className="form-control" name="participantsNo" value={data.participantsNo} disabled={data.mentorshipType === 'Webinar Session' ? false : true} onChange={handleInputChange} placeholder="Number of participants" />

                      </div>
                    </div>
                  </div>
                </div>

                {data.mentorshipType === 'Webinar Session' ?
                  <div className='row'>
                    <div className='col-lg-4 col-12'>
                      <div className='mb-3'>
                        <div className="form-group">
                          <label >Choose date</label>
                          <input type={dataToEdit ? 'text' : "date"} disabled={dataToEdit ? true : false}
                            className="form-control" name="webinarDate" value={data.webinarDate} onChange={handleInputChange} placeholder="Choose date" />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      {dataToEdit ? <div className='mb-3'>
                        <div className="form-group">
                          <label >Choose time</label>
                          <input type="text" disabled
                            className="form-control" name="webinarTime" value={data.webinarTime} placeholder="Choose time" />
                        </div>
                      </div> :
                        <div className='mb-3'>
                          <div className="form-group">
                            <label >Choose time</label>
                            <input type="time"
                              className="form-control" name="webinarTime" value={data.webinarTime} onChange={handleInputChange} placeholder="Choose time" />
                          </div>
                        </div>
                      }
                    </div>
                  </div> : ''}


              </div>
              <div className=''>
                <button className='btn btn-orange px-5 btnM' onClick={serviceInfoNext}>Next</button>
              </div>
            </div>
            :
            /* Form Step 2 */
            currentStep === 'step2' ?
              <div>
                <div className='text-center mb-3'>
                  {errorMsg !== '' ?
                    <small className='text-danger'>{errorMsg}</small>
                    : ''}
                </div>
                {/* <div className='fw-bold Title'>Intro Video</div> */}
                <div className='mb-3'>

                  {
                    data.youtubeVideoFile === '' || data.youtubeVideoFile === null ?
                      <div><div>Upload Video</div>
                        {console.log(data.youtubeVideoFile)}
                        <FileUploader handleChange={onSelectVideoFile} file={data.videoFile || data.videoUrl} name="videoFile" types={["mp4"]}
                        />
                        <div>
                          {data.videoFile !== '' && data.videoFile !== null ?
                            <div className='d-flex justify-content-between p-2 bg-light mt-3'>
                              <div>{data.videoFile.name !== undefined ? data.videoFile.name : data.videoUrl}</div>
                              <FontAwesomeIcon icon={faTrashAlt} onClick={() => setData({ ...data, videoFile: null, videoUrl: null })} />
                            </div> : ''}
                        </div>
                      </div>
                      :
                      ''
                  }
                </div>

                {
                  data.videoFile === '' || data.videoUrl === null ?
                    <div className='mb-3'>
                      <div className='mb-3 col-lg-10 col-12'>
                        <div className="form-group">
                          <label >Video URL</label>
                          <input type="text"
                            className="form-control" pattern='/^.(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]).*/' name="youtubeVideoFile" value={data.youtubeVideoFile} onChange={(e) => { setData({ ...data, youtubeVideoFile: e.target.value, videoFile: null, videoUrl: null }) }} placeholder="Example: https://www.youtube.com/watch?v=yourvideoid45454" />
                        </div>
                      </div>
                    </div> : ''
                }

                {/* <div className='fw-bold Title'>Thumbnail Image</div> */}
                <div className='mb-3'>
                  <div>Thumbnail image </div>
                  <FileUploader
                    handleChange={onSelectImageFile}
                    name="imageFile"
                    file={data.imageFile}
                    types={["JPG", "PNG", "JPEG"]}
                  />
                  {data.imageFile.name ?
                    <div className='d-flex justify-content-between p-2 bg-light mt-3'>
                      <p className='p-0 m-0'>{data.imageFile.name !== undefined ? data.imageFile.name : ''}</p>
                    </div>
                    :
                    <div className='  bg-light mt-3 rounded-2'>
                      <p className='p-0 m-0'>{data.imageURL ? <div className='p-2'>{data.imageURL}</div> : ''}</p>
                    </div>}

                </div>

                <div className=''>
                  <button className='btn btn-orange px-5 btnM' onClick={mediaFileNext} >Next</button>
                </div>
              </div>
              :
              /* Form Step 3 */
              currentStep === 'step3' ?
                <div>
                  <div className='text-center mb-3'>
                    {errorMsg !== '' ?
                      <small className='text-danger'>{errorMsg}</small>
                      : ''}
                  </div>
                  {/* <div className='fw-bold Title'>Overview</div> */}
                  <div className='mb-3'>
                    <div className="form-group">
                      <label >Overview</label>
                      <ReactQuill id='overview' placeholder='Enter your overview'
                        style={{ fontSize: '22px' }} theme="snow" value={overviewDesc} rows={5} onChange={setOverviewDesc} />
                      {/* {overviewDesc} */}
                      {/* <textarea className="form-control" name="overview" value={data.overview} rows="5" onChange={handleInputChange} placeholder='Overview'></textarea> */}
                    </div>
                  </div>
                  {/* <div className='fs-3 fw-bold'>Content</div> */}
                  <div className='mb-3'>
                    <div className="form-group">
                      <label >Content Description</label>
                      <ReactQuill id='contentDescription' placeholder='Enter your content description'
                        style={{ fontSize: '22px' }} theme="snow" value={contentDesc} rows={5} onChange={setContentDesc} />
                      {/* <textarea className="form-control" name="contentDescription" value={data.contentDescription} rows="3" onChange={handleInputChange} placeholder='Content Description '></textarea> */}
                    </div>
                  </div>
                  {data.mentorshipType === '1:1 Multi-Session' ?
                    <div className='row'>
                      <div className='col-lg-10 col-12'>
                        <div className='row'>
                          <div className='col-lg-5 col-12'>
                            <div className="form-group">
                              <label >Session Name</label>
                              <input type="text"
                                className="form-control" name="chapterTitle" value={data.chapterTitle} onChange={handleInputChange} placeholder="Introduction" />
                            </div>
                          </div>
                          <div className='col-lg-5 col-12'>
                            <div className="form-group">
                              <label >Session Duration</label>
                              <select className="form-select" name="sessionDuration" value={data.sessionDuration} onChange={handleInputChange}>
                                <option value="">Select Duration</option>
                                {durationFn()}
                              </select>
                            </div>
                          </div>
                          <div className='col-lg-2 col-12'>
                            <div className='mt-3 pt-2'>
                              <button className='btn btn-orange px-5 btnM' onClick={onClickChapertDetailsSave}>Save</button>
                            </div>
                          </div>

                          <div >
                            {data.chapterDetailsArr.length > 0 ?
                              <div className='my-2'>
                                {data.chapterDetailsArr.map((data, i) =>
                                  <div key={i} className='p-2 mt-2 bg-light rounded-2'>
                                    <div className='row'>
                                      <div className='col-5'>{data.chapterTitle}</div>
                                      <div className='col-5'>{data.sessionDuration} {data.sessionDuration === '1' ? 'hr' : 'Mins'}</div>
                                      <div className='col-2 text-end'><FontAwesomeIcon icon={faTrashAlt} onClick={() => removeChapterDetailsdata(i)} /></div>
                                    </div>
                                  </div>
                                )}
                              </div> : ''}
                          </div>
                        </div>
                      </div>
                    </div> : ''}

                  <div className='fw-bold mt-3 Title'>Resources</div>
                  <div className='col-lg-6 col-12'>

                    <div className='mt-3 d-flex justify-content-between'>
                      <div>
                        <div className="form-group">
                          <input type="file" accept=".png, .jpg, .jpeg, .pdf, .docx, .txt"
                            className="form-control border-0" name="resourcesFile" onChange={onSelectResourcesFile} placeholder="" />
                        </div>
                      </div>
                      {/* <div className='badge text-dark align-self-center py-1 py-2 px-4 h-100 ms-2 h-100' onClick={uploadResFile} style={{ backgroundColor: '#ABEFC6', }}>Upload</div> */}
                    </div>

                    {resourcesFileView()}

                  </div>

                  <div className='my-5'>
                    <button className='btn btn-orange px-5 btnM' onClick={additionalInformationNext}>{isResoursesLoading ? 'Loading ...' : 'Next'}</button>
                  </div>


                </div>
                :
                /* Form Step 4 */

                <div>
                  <div className='text-center mb-3'>
                    {errorMsg !== '' ?
                      <small className='text-danger'>{errorMsg}</small>
                      : ''}
                  </div>
                  <div className='mt-3'>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Question</label>
                        <input type="text"
                          className="form-control" name="question" value={data.question} onChange={handleInputChange} placeholder="What is LMS" />
                      </div>
                    </div>
                    <div className='mb-3'>
                      <div className="form-group">
                        <label >Answer</label>
                        <ReactQuill
                          style={{ fontSize: '22px' }} theme="snow" value={faqAnswer} rows={5} onChange={setFaqAnswer} />

                        {/* <textarea type="text"
                          className="form-control" name="answer" value={data.answer} onChange={handleInputChange} rows={'3'} placeholder="LMS is a tool that enables you to connect with your audience through 1:1 sessions - to share your knowledge and 
                          monetise your time better. " ></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3'>
                    {
                      isUpdate ? <button className='btn btn-orange px-lg-5' onClick={questionandAnswerUpdatebtn}>Update</button> :
                        <button className='btn btn-orange px-5 btnM' onClick={questionandAnswerSavebtn}>Save</button>
                    }
                  </div>
                  <div className='my-3'>
                    {data.queAnsArr.length > 0 ?
                      <div className='p-3 border border-2 rounded-4'>
                        {questionAnsFn()}
                      </div> : ''}
                  </div>
                  <div className='mt-5'>
                    <button className='btn btn-orange px-5 btnM' onClick={showPreviewfn} >Preview</button>
                  </div>
                </div>
          }
        </div>
      }
    </>
  )
}

export default CreateANewServices
