import React, { useEffect } from "react";
import { useState } from "react";
import "./PreviewPage.scss";
import StarRatings from "react-star-ratings";
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from "../../../components/LandingpageComponents/Footer/Footer";
import MyservicesCard from "../../../components/LandingpageComponents/MyServicesCard/MyservicesCard";
import { HiArrowLongRight } from "react-icons/hi2";
import ratingStar from "../../../assets/ratingStarSmall.png";
import share from "../../../assets/share.png"
import apiservice from "../../../apiservice/ApiService";
import ShareOnSocial from "react-share-on-social";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck, faCopy, faDownload, faSpinner, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { CopyToClipboard } from 'react-copy-to-clipboard';


function PreviewPage(props) {
  let navigate = useNavigate()
  const params = useParams();
  const initialDataState = {
    ratings: 0,
    comments: ''
  };
  const [data, setData] = useState(initialDataState);
  let [mentorData, setMentorsData] = useState({})
  let [serviceData, setServiceData] = useState({})
  let [realatedServices, setRealatedService] = useState([])
  let [userData, setUserdata] = useState('')

  const [currentStep, setCurrentStep] = useState("step1");
  const [questionNumber, setQuestionNumber] = useState(0);
  const [sucessMsg, setSuccessMsg] = useState('')
  const [resourceArr, setResourceArr] = useState([])
  let [showpopup, setShowPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const avgValue = "25%";
  const [submitLoading, setSubmitLoading] = useState(false)
  useEffect(() => {

    getServicesData()

  }, [])

  const onChangeHandle = (e) => {
    setData({ ...data, 'comments': e.target.value })
    setErrorMsg('')
  }

  async function getServicesData() {
    setIsLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    console.log(uData);
    setUserdata(uData)
    let id = params.id
    if (uData !== null && uData.user_type === 1) {

      await apiservice.apiCall(`/LearnerServiceDetails/${id}`, uData.Token).then(res => {
        console.log(res.data);
        if (res.data) {
          setIsLoading(false)
          console.log(res.data);
          let responsedata = res.data
          setServiceData(responsedata)
          setResourceArr(responsedata.Resources)
          setMentorsData(responsedata.content_expert)
          getRealatedServices(responsedata)
        } else {
          setIsLoading(false)
          console.log(res);
        }
      }).catch(err => {
        setIsLoading(false)
        console.log(err);
      })
    } else {
      setIsLoading(true)
      await apiservice.apiCall(`/ServiceDetails/${id}`).then(res => {
        console.log(res.data);
        if (res.data) {
          setIsLoading(false)
          console.log(res.data);
          let responsedata = res.data
          setServiceData(responsedata)
          setResourceArr(responsedata.Resources)
          setMentorsData(responsedata.content_expert)
          getRealatedServices(responsedata)
        } else {
          setIsLoading(false)
          console.log(res);
        }
      }).catch(err => {
        setIsLoading(false)
        console.log(err);
      })
    }

  }

  const getRealatedServices = async (data) => {
    setIsLoading(true)
    let sdata = data
    let dataObject = {
      Industry: data.Industry,
    }
    await apiservice.apiCall(`/showRelatedService/${sdata.Service_id}`, '', dataObject, 'POST').then(res => {
      console.log(res.data);
      if (res.data) {
        setIsLoading(false)
        setRealatedService(res.data)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }



  function loadQA() {
    let list = [];
    let QAs = JSON.parse(serviceData.QueAns)
    console.log(QAs);
    for (let i = 0; i < QAs.length; i++) {
      let item = QAs[i]
      console.log(item);
      list.push(
        <div class="accordion-item bg-transparent border">
          <h2 class="accordion-header" id={`flush-headingOne${i + 1}`}>

            <button onClick={() => onClickQuetions(i)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne${i + 1}`} aria-expanded="true" aria-controls={`flush-collapseOne${i + 1}`}>
              <div className='p-lg-2 col-1'>
                <div className={questionNumber === i ? 'rounded-circle  text-center active-circle  px-2 pt-2 mt-2' : 'quetionbtn rounded-circle px-2 pt-2 mt-2 text-center '} style={{ width: '35px', height: '35px' }}>
                  {i + 1}
                </div>
              </div>
              <div className='p-2 col-10 '>
                <div className='mx-3 pt-2'>{item.question}</div>

              </div>

            </button>
          </h2>
          <div id={`flush-collapseOne${i + 1}`} class="accordion-collapse collapse bg-transparent" aria-labelledby={`flush-headingOne${i + 1}`} data-bs-parent="#accordionFlushExample">


            <div className='accordion-body ' dangerouslySetInnerHTML={{ __html: item.answer }} />
          </div>
        </div>
      )
    }
    return list;
  }


  async function clickedOnBookSession() {
    let uData = await apiservice.getLocalStorage('userData')
    if (uData === null) {
      navigate('/login')
    }
    else {
      await apiservice.setLocalStorage('serviceData', serviceData)
      navigate('/checkout')
    }
  }

  async function clickedOnBookFreeSession() {
    setIsLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    if (uData === null) {
      navigate('/login')
    } else {

      let dataObject = {
        serviceId: serviceData.Service_id
      }
      await apiservice.apiCall('/BookFreeService', uData.Token, dataObject, 'POST').then(res => {
        if (res.unAuthenticated) {
          navigate('/')
          localStorage.removeItem('userData')
        } else if (res.data) {
          setIsLoading(false)
          setShowPopup(true)
        } else {
          setIsLoading(false)
          console.log(res);
        }
      }).catch(err => {
        setIsLoading(false)
        console.log(err);
      })
    }

  }

  const changeRating = (newRating, name) => {
    // console.log(newRating, name);
    setData({ ...data, 'ratings': newRating })
    setErrorMsg('')
  }
  function loadServicesForThisMentor() {
    let list = []
    console.log(realatedServices);
    let dataArr = realatedServices.length > 0 ? realatedServices : []
    for (let i = 0; i < dataArr.length; i++) {
      let item = dataArr[i]
      console.log(item);
      if (i < 2) {
        list.push(
          <div className="col-6" key={i}
            onClick={() => { navigate(`/servicedetail/${item.Service_id}`); window.location.reload() }}
          >
            <MyservicesCard
              courseTitle={item.Service_Name}
              courseImg={item.ThumbnailImage}
              serviceType={item.MentorshipType}
              ratting={item.averageRating}
              mentorName={`${item.content_expert.name} ${item.content_expert.lname}`}
              courseDuration={` ${item.Hour}h ${item.Minutes}m`}
              timeZone={item.timezone}
              webinarDate={item.start_date}
              webinarTime={item.start_time}
              courseCost={item.Price}
              currencySymbol={item.CurrencySymbol}
            />
          </div>
        )
      }
      else {
        return list
      }
    }
    return list
  }




  const formSteps = (e) => {
    setCurrentStep(e);
  };

  const courseCurriculumFn = () => {
    let list = [];
    let chapters = JSON.parse(serviceData.Chapter);
    for (let index = 0; index < chapters.length; index++) {
      let item = chapters[index]
      list.push(
        <div key={index} className="p-2 mt-2 bg-light rounded-1">
          <div className="d-flex justify-content-between">
            <div className="">{item.chapterTitle}</div>
            <div>{item.sessionDuration} {item.sessionDuration === '1' ? 'hr' : 'min'} </div>
          </div>
        </div>
      );
    }
    return list;
  };

  const onClickQuetions = (e) => {
    setQuestionNumber(e);
  };


  const [viewShowCount, setViewShowCount] = useState(3)

  const listOfReviews = () => {
    let list = []
    let dataArr = serviceData && serviceData.ReviewsList
    if (dataArr.length > 0) {


      for (let index = 0; index < dataArr.length; index++) {
        const element = dataArr[index];
        let learner = element.learner
        if (viewShowCount >= index + 1) {
          if (element.Status === 1) {

            list.push(
              <div className="border-bottom py-3">
                <div className="d-flex align-items-center py-2 mb-3">
                  <div className="rounded-circle overflow-auto" style={{ width: '100px', height: '100px' }}>
                    <img src={learner.ProfileImage === null ? learner.SocialImage === null ? learner.profile_photo_url : learner.SocialImage : `${apiservice.apiImageShow()}/${learner.ProfileImage}`} width={'100%'} height={'100%'} alt="" />

                  </div>
                  <div className="px-3">
                    <span className="reviewerName">{element.learner.name} {element.learner.lname}</span>
                    <br />
                    <StarRatings
                      rating={Number(element.Ratting)}
                      starDimension='20px'
                      starRatedColor="#FDB022"
                      numberOfStars={5}
                    />
                  </div>
                </div>
                <p>
                  {element.comment}
                </p>
              </div>
            )
          }
        }
      }
    } else {
      list.push(<div className=' fw-bold my-5 text-danger'>To date, we have not received any reviews or feedback for this. Your insights are crucial to our ongoing enhancements, and we appreciate your valuable input.</div>)
    }
    return list
  }

  const onClickShowMore = () => {
    let count = viewShowCount
    count = count + 3
    // alert(count)
    setViewShowCount(count)

  }
  const [errorMsg, setErrorMsg] = useState('')

  const onClickSubmit = async () => {
    let uData = await apiservice.getLocalStorage('userData')
    if (data.ratings === 0) {
      setErrorMsg('Kindly choose your rating and proceed to compose a review for submission.')
      return false
    } else
      if (data.comments.length < 5 || data.comments.length > 200) {
        setErrorMsg('Please input between 5 and 200 characters to submit your review.')
        return false
      } else {
        setSubmitLoading(true)
        console.log(uData);
        let dataObject = {
          serviceid: serviceData.Service_id,
          learnerId: uData.id,
          ratting: data.ratings,
          comment: data.comments
        }
        console.log(dataObject);
        await apiservice.apiCall('/ServiceReview', uData.Token, dataObject, 'POST').then(res => {
          if (res.unAuthenticated) {
            // navigate('/')
            // localStorage.removeItem('userData')
          } else if (res.data) {
            setSubmitLoading(false)
            console.log(res.data);
            setSuccessMsg('Successfully submitted!')
            setTimeout(() => {
              setSuccessMsg('')
              setData(initialDataState)
              getServicesData()
            }, 3000);
          } else {
            setSubmitLoading(false)
            console.log(res);
          }
        }).catch(err => {
          setSubmitLoading(false)
          console.log(err);
        })
      }
  }

  async function showMentorData(item) {
    await apiservice.setLocalStorage('mentorData', item)
    // navigate('/contentexpert')
    navigate(`/contentexpert/${item.content_experts_id}`)
  }

  const onClickDownload = (filename) => {
    console.log(filename);

    const fileUrl = `${apiservice.apiImageShow()}/${filename}`

    const link = document.createElement('a')
    link.href = fileUrl
    link.download = filename
    link.target = '_blank'
    document.body.appendChild(link)
    link.click();
    link.parentNode.removeChild(link)
  }


  const resourceList = () => {
    let list = []
    let dataArr = resourceArr === null ? [] : resourceArr
    if (dataArr !== null) {
      for (let value in dataArr) {

        list.push(
          <div key={value} className='p-2 mt-2 bg-light rounded-1'>
            <div className='d-flex justify-content-between'>
              <div className='overflow-hidden'>{dataArr[value].file_path}</div>
              {serviceData.booked === 0 ? '' :
                <div className="ms-3"><FontAwesomeIcon className="pointer" icon={faDownload} onClick={() => onClickDownload(dataArr[value].file_path)} /></div>
              }
            </div>
          </div>
        )
      }
    } else {
      list.push(
        <div className="fw-semibold mt-3 text-center">Data not available</div>
      )

    }
    return list
  }

  function onClickPopupOk() {
    setShowPopup(false)
    navigate('/learner-home')

  }
  const metaData = {
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react, document, meta',
      },
      property: {
        'og:title': serviceData.Service_Name,
        'og:description': serviceData.Service_Description,
        'og:type': 'website',
        'og:url': window.location.href,
        'og:image': `${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`, // URL to the image you want to display
      },
    },
  };



  const onClickLoginLinkedIn = () => {

    try {
      linkedInLogin();
    } catch (error) {
      console.log(error);
    }
  };

  const [linkedinPostPopup, setLinkedinPostPopup] = useState(false)
  const [postSuccess, setPostSuccess] = useState('')
  const [postError, setPostError] = useState('')
  const [linkedinPostLoading, setlinkedinPostLoading] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const { linkedInLogin } = useLinkedIn({
    clientId: "865mis3s3mhtef",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log(code);
      linkedinCallBack(code)
    },
    scope: "email openid profile r_learningdata w_member_social",
    onError: (error) => {
      console.log(error);

    },
  })


  const linkedinCallBack = async (code) => {
    setlinkedinPostLoading(true)
    setLinkedinPostPopup(true)
    let dataObject = {
      access_token: code,
      service_id: serviceData.Service_id,
    }
    await apiservice.apiCall('/service/linkedin/share', '', dataObject, 'POST').then(res => {
      if (res.data) {
        console.log(res.data);
        setPostSuccess(res.data)
        setlinkedinPostLoading(false)
        setPostError('')
      } else {
        setPostError(res.error)
        setlinkedinPostLoading(false)
        setPostSuccess('')
        console.log(res.error);
      }
    }).catch(err => {
      console.log(err);
    })

  }

  const onClickLinkedinPostOk = () => {
    if (postSuccess !== '') {
      window.open('https://www.linkedin.com/');
      setLinkedinPostPopup(false)
    } else {
      setLinkedinPostPopup(false)
    }
  }
  const onClickLinkedinPostClose = () => {
    setLinkedinPostPopup(false)
  }

  const onClickCopy = () => {
    setIsCopy(true)
    setTimeout(() => {
      setIsCopy(false)
    }, 3000);
  }

  return (
    <>

      <Header navigate={navigate} />
      <Modal show={showpopup}>
        <ModalBody>
          <div>Your {serviceData.Service_Name} has been booked successfully!!</div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={onClickPopupOk}>OK</button>

        </ModalFooter>
      </Modal>

      <Modal show={linkedinPostPopup}>
        <ModalBody>
          {linkedinPostLoading ?
            <div className='p-3 text-center'>
              <FontAwesomeIcon icon={faSpinner} spin /> Loading...

            </div>
            : <div className='p-3 text-center'>
              <div className='my-3'>
                {postSuccess === '' ? '' : <div className='text-success'>{postSuccess}</div>}
                {postError === '' ? '' : <div className='text-danger'>{postError}</div>}
              </div>
              <div className='mt-3'>
                <button className='btn btn-orange' onClick={onClickLinkedinPostOk}>View Post</button>
                <button className='btn btn-secondary ms-3' onClick={onClickLinkedinPostClose}>Close</button>
              </div>
            </div>}

        </ModalBody>
      </Modal>
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: "#F1EFE7" }}>
          <article className="container">
            <div className="py-3">
              <div className="mt-3">
                <div className="row">
                  <div className="col-lg-8 col-12">

                    <div className="">
                      <div className="d-xl-flex justify-content-between">
                        <div className="fs-5 fw-bold mb-2 mb-lg-3">
                          {serviceData.Service_Name}
                        </div>
                        <div className="ms-xl-5 mx-0 d-flex">

                          <div className=""> <CopyToClipboard text={`${window.location.href}`}
                            onCopy={onClickCopy}
                          >
                            <button className="btn btn-orange">
                              {isCopy ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                            </button>
                          </CopyToClipboard>
                          </div>
                          <div>

                            <button className='btn ms-1' style={{ backgroundColor: '#007ab9', color: '#ffffff' }} onClick={onClickLoginLinkedIn}>
                              <FontAwesomeIcon className=' ' icon={faLinkedin} />
                            </button>
                          </div>

                        </div>
                      </div>
                      <div className="mb-2">
                        {serviceData.Service_Description}
                      </div>
                    </div>
                    <div className="fw-bold">{mentorData.name} {mentorData.lname}</div>
                    <div>
                      <StarRatings
                        rating={serviceData.AvgRating}
                        starDimension='20px'
                        starRatedColor="#FDB022"
                        numberOfStars={5}
                      />{" "}
                      <span className="ps-2">{serviceData.AvgRating === 0 ? 0 : Number(serviceData.AvgRating).toFixed(1)}</span>
                    </div>
                    <div className="d-lg-flex d-block justify-content-between align-items-center w-100 mt-3">
                      <div className="d-flex d-block align-items-center">
                        <span
                          className="px-2 py-1 text-center rounded-2"
                          style={{ backgroundColor: "#ABEFC6" }}
                        >
                          {" "}
                          {serviceData.MentorshipType}
                        </span>
                        <div className="d-lg-flex d-block my-2 ms-3 my-lg-0">
                          <div><b>Total time:</b> {serviceData.Hour === null ? 0 : serviceData.Hour}h {serviceData.Minutes === null ? 0 : serviceData.Minutes}m
                          </div>
                        </div>
                      </div>
                      {serviceData.MentorshipType === 'Webinar Session' ?
                        serviceData.start_date === null ? '' :
                          <div className='ms-lg-2 ms-0 d-none d-lg-block'>  <b>Date :</b> {serviceData.start_date}, <b>Time :</b> {serviceData.start_time} ({serviceData.timezone})</div> : ''}
                      {serviceData.MentorshipType === 'Webinar Session' ?
                        serviceData.start_date === null ? '' :
                          <div className='ms-lg-2 ms-0 d-lg-none d-block'>  <b>Date :</b> {serviceData.start_date}, <b>Time :</b> {serviceData.start_time} ({serviceData.timezone})</div> : ''}
                      {/* <div className=" py-3 my-2">
                      <button
                        type="button"
                        onClick={() => navigate('/selectschedule')}
                        className="btn btn-primary text-center border-0"
                        style={{ backgroundColor: "#E57A4D" }}
                      >
                        Join Session
                      </button>
                    </div> */}
                    </div>
                    <hr />
                    {/* ......................................................................................................................... */}

                    <div className="p-3">
                      <div className="container-fluid">
                      </div>
                      <div className="mt-3">
                        <div className="row">
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step1"
                                  ? "p-3 fw-bold active-form-step1 text-center rounded "
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step1")}
                            >
                              Overview
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step2"
                                  ? "p-3 fw-bold active-form-step2 text-center rounded"
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step2")}
                            >
                              Content
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step3"
                                  ? "p-3 fw-bold active-form-step3 text-center rounded"
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step3")}
                            >
                              Content Expert
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step4"
                                  ? "p-3 fw-bold active-form-step4 text-center rounded"
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step4")}
                            >
                              Q&A
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step5"
                                  ? "p-3 fw-bold active-form-step5 text-center rounded"
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step5")}
                            >
                              Reviews
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-2">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step6"
                                  ? "p-3 fw-bold active-form-step6 text-center rounded"
                                  : "p-3 bg-light fw-bold text-center rounded btnMS"
                              }
                              onClick={() => formSteps("step6")}
                            >
                              Resources
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-lg-5 mt-3">
                        {/* Form Step 1 */}
                        {currentStep === "step1" ? (
                          <div className="mt-lg-5">
                            <div className="mb-5">
                              <p>
                                <div dangerouslySetInnerHTML={{ __html: serviceData.Overview }} />
                              </p>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="d-lg-none d-block">
                                <div className="mb-3">
                                  <div className="card p-2 bg-transparent">
                                    {serviceData.UploadVideo === null ?
                                      <div>
                                        <img src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt="..." className="w-100" />
                                      </div> :
                                      <ReactPlayer
                                        controls
                                        width='100%'
                                        height='100%'
                                        playing
                                        light={<img width='100%' height='100%' src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt='Thumbnail' />}
                                        url={`${apiservice.apiImageShow()}/${serviceData.UploadVideo}`}
                                      />
                                    }
                                    <div className="card-body">
                                      <small className="fw-bold">
                                        {serviceData.Service_Name}
                                      </small>
                                      <br />
                                      <small className=''>Enroll in our courses led by our esteemed Content Expert to hone your skills and achieve your goals. Let's embark on a journey of learning and growth together with <b>{mentorData.name} {mentorData.lname}</b>  .</small>

                                      <div className="fw-bold">{serviceData.CurrencySymbol} {Number(serviceData.Price).toFixed(2)}</div>
                                      {serviceData.booked === 0 ?
                                        <div>
                                          {
                                            userData === null || userData.user_type === 1 ?
                                              <div className="  mt-3">
                                                {serviceData.Price !== 0 ?
                                                  <button
                                                    type="button"
                                                    onClick={clickedOnBookSession}
                                                    className="btn btn-primary text-center border-0"
                                                    style={{ backgroundColor: "#E57A4D" }}
                                                  >
                                                    Book Session
                                                  </button>
                                                  :
                                                  <button
                                                    type="button"
                                                    onClick={clickedOnBookFreeSession}
                                                    className="btn btn-primary text-center border-0"
                                                    style={{ backgroundColor: "#E57A4D" }}
                                                  >
                                                    Book For Free Session
                                                  </button>
                                                }
                                              </div>
                                              : ''
                                          }
                                        </div>
                                        :
                                        <div className="mt-3">
                                          <div className="badge" style={{ backgroundColor: '#E57A4D' }}>Already Booked!</div>
                                        </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {realatedServices.length > 0 ?
                              <div>
                                <div className="d-flex justify-content-between">
                                  <h3 className="fw-bold px-1">Related Services</h3>
                                  <div>
                                    <p className=" fw-bold" onClick={() => navigate('/courses')} style={{ cursor: 'pointer' }}>Explore <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></p>
                                    {/* <img src={prevBtn} width={35} className="me-3" height={35} alt="" />
                              <img src={nextBtn} width={35} height={35} onClick={() => navigate('/courses')} alt="" /> */}
                                  </div>
                                </div>
                                <div className="row">
                                  {loadServicesForThisMentor()}
                                </div>
                              </div> : ''}
                          </div>
                        ) : /* Form Step 2 */
                          currentStep === "step2" ? (
                            <div>
                              <div className="fw-bold">Service Content</div>
                              <div className="mt-3 mb-5">
                                <div dangerouslySetInnerHTML={{ __html: serviceData.Content_Description }} />
                              </div>
                              {/* <div className="fw-bold">Course Curriculum</div> */}
                              <div className="">
                                {courseCurriculumFn()}
                                {/* {serviceData.Content_Description} */}
                              </div>

                              <div className="mt-3"></div>
                            </div>
                          ) : /* Form Step 4 */
                            currentStep === "step4" ? (
                              <div>
                                <div className="fw-bold">Questions and Answers</div>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                  {loadQA()}


                                </div>
                              </div>
                            ) : /* Form Step 3 */
                              currentStep === "step3" ? (
                                <div>
                                  <div className=" col-12">
                                    <div className="mt-3 d-flex align-items-center ">
                                      <img src={mentorData.ProfileImage === null ? mentorData.SocialImage === null ? mentorData.profile_photo_url : mentorData.SocialImage : `${apiservice.apiImageShow()}/${mentorData.ProfileImage}`} width={187} height={187} className=" rounded-circle d-none d-lg-block" alt="" />
                                      <img src={mentorData.ProfileImage === null ? mentorData.SocialImage === null ? mentorData.profile_photo_url : mentorData.SocialImage : `${apiservice.apiImageShow()}/${mentorData.ProfileImage}`} width={100} height={100} className=" rounded-circle d-lg-none d-block" alt="" />
                                      <div>
                                        <div className="ms-3">
                                          <h5>{mentorData.name} {mentorData.lname}</h5>
                                          <span>
                                            {mentorData.lastDesignation}
                                          </span>
                                          <div className="w-100 py-lg-3 my-lg-2">
                                            <button
                                              type="button"
                                              onClick={() => showMentorData(mentorData)}
                                              className="btn btn-primary text-center border-0 btnMS"
                                              style={{ backgroundColor: "#E57A4D" }}
                                            >
                                              Know More
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="py-3">
                                      {/* {mentorData.AboutSelf} */}
                                      <div dangerouslySetInnerHTML={{ __html: mentorData.AboutSelf }}></div>
                                    </p>
                                  </div>
                                </div>
                              ) :
                                currentStep === "step6" ? (
                                  /* Form Step 6 */
                                  <div>
                                    {resourceList()}

                                  </div>
                                ) : (
                                  /* Form Step 5 */
                                  <div>
                                    <div className="row d-flex">
                                      <div className="col-lg-5 col-12" style={{ height: "inherite" }}>
                                        <div className="mb-3 mb-lg-0">
                                          <div className="card bg-transparent justify-content-center">
                                            <div className="card-body text-center align-items-center">
                                              <h1
                                                className="text-center fw-semibold Title"
                                                style={{ fontSize: "4.375rem" }}
                                              >
                                                {Number(serviceData.AvgRating).toFixed(1)}
                                              </h1>
                                              <StarRatings
                                                rating={Number(serviceData.AvgRating)}
                                                starDimension={'20px'}
                                                starRatedColor="#FDB022"
                                                numberOfStars={5}
                                              />
                                              <p className="py-2">{serviceData.AvgRatingCount === 0 ? 0 : Number(serviceData.AvgRatingCount).toFixed(1)} Ratings</p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <div className="col-lg-7 col-12 p-lg-3 p-2  card bg-transparent">
                                        <div className=" d-flex flex-column justify-content-evenly align-content-between">

                                          <div className="row align-items-center">
                                            <div className="col-2 d-flex">
                                              <span className="px-lg-2 me-2">5</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              />
                                            </div>
                                            <div className="progress p-0 col-8 bg-white">
                                              <div
                                                className="progress-bar "
                                                role="progressbar"
                                                style={{
                                                  width: `${serviceData.SeparateReviewsList.fivePercent}%`,
                                                  backgroundColor: "pink",
                                                }}
                                                aria-valuenow="30"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="col-2">{serviceData.SeparateReviewsList.five}</div>
                                          </div>

                                          <div className="row align-items-center">
                                            <div className="col-2  d-flex">
                                              <span className="px-lg-2 me-2">4</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                            <div className="progress p-0 col-8 bg-white">
                                              <div
                                                className="progress-bar "
                                                role="progressbar"
                                                style={{
                                                  width: `${serviceData.SeparateReviewsList.fourPercent}%`,
                                                  backgroundColor: "#ADD6DA",
                                                }}
                                                aria-valuenow="30"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="col-2">{serviceData.SeparateReviewsList.four}</div>
                                          </div>

                                          <div className="row align-items-center">
                                            <div className="col-2 d-flex">
                                              <span className="px-lg-2 me-2">3</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                            <div className="progress p-0 col-8 bg-white">
                                              <div
                                                className="progress-bar "
                                                role="progressbar"
                                                style={{
                                                  width: `${serviceData.SeparateReviewsList.threePercent}%`,
                                                  backgroundColor: "#0d98ba",
                                                }}
                                                aria-valuenow="30"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="col-2 d-flex">{serviceData.SeparateReviewsList.three}</div>
                                          </div>

                                          <div className="row align-items-center">
                                            <div className="col-2 d-flex">
                                              <span className="px-lg-2 me-2">2</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                            <div className="progress p-0 col-8 bg-white">
                                              <div
                                                className="progress-bar "
                                                role="progressbar"
                                                style={{
                                                  width: `${serviceData.SeparateReviewsList.twoPercent}%`,
                                                  backgroundColor: "#d9d6fe",
                                                }}
                                                aria-valuenow="30"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="col-2">{serviceData.SeparateReviewsList.two}</div>
                                          </div>

                                          <div className="row align-items-center">
                                            <div className="col-2 d-flex">
                                              <span className="px-lg-2 me-2">1</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                            <div className="progress p-0 col-8 bg-white">
                                              <div
                                                className="progress-bar "
                                                role="progressbar"
                                                style={{
                                                  width: `${serviceData.SeparateReviewsList.onePercent}%`,
                                                  backgroundColor: "#0d98ba",
                                                }}
                                                aria-valuenow="30"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="col-2">{serviceData.SeparateReviewsList.one}</div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    <h1 className="fw-semibold mt-4 Title">Service Reviews</h1>

                                    <div className="mt-lg-3">
                                      {listOfReviews()}
                                    </div>



                                    {viewShowCount >= serviceData.ReviewsList.length ? '' :
                                      <div className="w-100 text-center py-3 my-2">
                                        <button
                                          type="button"
                                          className="btn btn-primary border-0 text-center"
                                          style={{ backgroundColor: "#E57A4D" }}
                                          onClick={onClickShowMore}
                                        >
                                          Show More
                                        </button>
                                      </div>}
                                    {userData !== null && userData.user_type === 1 ?
                                      <div>
                                        {sucessMsg === '' ?
                                          <div className="row">
                                            <div className=" card bg-transparent py-5 px-3 col-12">
                                              <h1 className="Title">Write a Review</h1>
                                              <span>We value your feedback! Share your review with us and contribute to enhancing your experience.</span>
                                              <div className="text-danger py-3">{errorMsg}</div>
                                              <StarRatings
                                                rating={data.ratings}
                                                starRatedColor="#FDB022"
                                                starDimension='39px'
                                                starHoverColor="#FDB022"
                                                numberOfStars={5}
                                                changeRating={changeRating}
                                                name='ratings'
                                              />
                                              <div>
                                                <textarea className="w-100 bg-transparent card rounded-2 p-2 mt-3" onChange={onChangeHandle}
                                                  name="comment" placeholder="comment" style={{ outline: "none" }} id="reviewItem" rows="7"></textarea>
                                              </div>


                                              <div className="w-100 py-3 my-2">
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-dark text-center btnMS"
                                                  onClick={onClickSubmit}
                                                  disabled={submitLoading ? true : false}
                                                >
                                                  {submitLoading ? 'Loading...' : 'Submit Now'}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className="my-5">
                                            <div className="text-success text-center fw-bold">{sucessMsg}</div>
                                          </div>
                                        }
                                      </div> : ''}
                                  </div>
                                )}

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="d-lg-block d-none">
                      <div className="card p-2 bg-transparent">
                        {serviceData.UploadVideo === null ?
                          <div>
                            <img src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt="..." className="w-100" />
                          </div> :
                          <ReactPlayer
                            controls
                            width='100%'
                            height='100%'
                            playing
                            light={<img width='100%' height='100%' src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt='Thumbnail' />}
                            url={`${apiservice.apiImageShow()}/${serviceData.UploadVideo}`}
                          />
                        }
                        <div className="card-body">
                          <small className="fw-bold">
                            {serviceData.Service_Name}
                          </small>
                          <br />
                          <small className=''>Enroll in our courses led by our esteemed Content Expert to hone your skills and achieve your goals. Let's embark on a journey of learning and growth together with <b>{mentorData.name} {mentorData.lname}</b>  .</small>

                          <div className="fw-bold">{serviceData.CurrencySymbol} {Number(serviceData.Price).toFixed(2)}</div>
                          {serviceData.booked === 0 ?
                            <div>
                              {
                                userData === null || userData.user_type === 1 ?
                                  <div className="  mt-3">
                                    {serviceData.Price !== 0 ?
                                      <button
                                        type="button"
                                        onClick={clickedOnBookSession}
                                        className="btn btn-primary text-center border-0"
                                        style={{ backgroundColor: "#E57A4D" }}
                                      >
                                        Book Session
                                      </button>
                                      :
                                      <button
                                        type="button"
                                        onClick={clickedOnBookFreeSession}
                                        className="btn btn-primary text-center border-0"
                                        style={{ backgroundColor: "#E57A4D" }}
                                      >
                                        Book For Free Session
                                      </button>
                                    }
                                  </div>
                                  : ''
                              }
                            </div>
                            :
                            <div className="mt-3">
                              <div className="badge" style={{ backgroundColor: '#E57A4D' }}>Already Booked!</div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <Footersection navigate={navigate} />
        </section>}
    </>
  );
}

export default PreviewPage;
