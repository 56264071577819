import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import brandLogo from "../../../assets/LOGO.png";
import "../SignUpPage/Signuppage.Styles.scss";
import { HiArrowLongRight } from "react-icons/hi2";
import { AiOutlineDown } from "react-icons/ai";
import sideImageReg from "../../../assets/sideImageReg.png";
import validation from "../../../components/Validations";
import apiservice from "../../../apiservice/ApiService";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";

function OTPverification(props) {
  let navigate = useNavigate()
  const params = useParams();

  const initialState = {
    otp: ""
  }


  const [data, setData] = useState(initialState)
  const [errMsg, setErrMsg] = useState("")
  let [timer, setTimer] = useState(60)
  const [isLoading, setIsLoading] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const resendOtp = () => {
    setTimer(60)
    setTimeout(() => {
      if (timer === 0) {
        setTimer(0)
        console.log(timer);
        return false
      } else {
        let curTime = timer - 1
        setTimer(curTime)
      }
    }, 1000);
  }

  useEffect(() => {
    setTimeout(() => {
      if (timer === 0) {
        setTimer(0)
        console.log(timer);
        return false
      } else {
        let curTime = timer - 1
        setTimer(curTime)
      }
    }, 1000);
  }, [timer])

  const clickedOnSignUp = () => {

    if (data.otp === "") {
      setErrMsg('OTP is required!')
    }
    else {
      setErrMsg('')
      verifyOtp()
      console.log(data);
    }
  }


  async function verifyOtp() {

    let urlValue = JSON.parse(params.id)
    try {
      setIsLoading(true)
      let sendOTPUrl = `/verifyOTP/${urlValue.countryCode}/${urlValue.mobile}/${data.otp}`
      console.log(sendOTPUrl);
      let response = await apiservice.apiCall(sendOTPUrl)
      if (response.data) {
        await apiservice.setLocalStorage('userData', response.data);
        let userData = response.data
        if (userData.user_type === 1) {
          console.log("hello learner");
          navigate('/learner-home');
        } else if (userData.user_type === 2) {
          console.log("hello mentor");
          navigate('/contetexpert-home');
        }
        // navigate('/login')
        console.log(response);
        setIsLoading(false)
      } else {
        console.log(response.error);
        setErrMsg(response.error)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }


  const sendOTP = async (dataObj) => {
    setIsLoading(true)
    try {
      let urlValue = JSON.parse(params.id)
      let sendOTPUrl = `/SendOtp/${urlValue.countryCode}/${urlValue.mobile}`
      console.log(sendOTPUrl);
      let response = await apiservice.apiCall(sendOTPUrl)
      if (response.data) {
        setIsLoading(false)
        console.log(response);
        setSuccessMsg(response.data)
        setTimeout(() => {
          setSuccessMsg('')

        }, 2000);
        setErrorMsg('')
        resendOtp()
      } else {
        setIsLoading(false)
        setErrorMsg(response.error)
        setSuccessMsg('')
        setTimeout(() => {
          setErrorMsg('')
        }, 5000);
        console.log(response);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  return (
    <Fragment>

      <section className='d-flex flex-column align-items-center' style={{ minHeight: '100vh', backgroundColor: '#ADD6DA' }}>

        <div className="container-fluid bg-white">
          <div className="container-lg d-flex justify-content-between py-3">
            <img src={brandLogo} onClick={() => navigate('/')} width={170} height={40} alt="" />
            {/* <button type="button" className="btn btn-outline-dark buttonText">
                Login
              </button> */}
          </div>
        </div>

        <div
          className="container-fluid d-flex align-items-center "
          style={{ backgroundColor: "#ADD6DA", minHeight: "100vh" }}
        >
          <div className="container py-4">
            <div
              className="card border-3 rounded-3 border-white"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                backgroundColor: "#EEEFF580",
              }}
            >
              <div className="card-body p-0 m-0 row">
                <div className="col-lg-6 col-12 p-5">
                  <div className="p-0 m-0">
                    <h5 className="regHeadText">Verify OTP</h5>
                    <p className="regTitleSmall">We have sent an OTP to the registered Phone Number.</p>
                  </div>

                  <div className="d-flex flex-column mx-0 inpFieldContainer ">
                    <input
                      type="text"
                      required
                      maxLength={6}
                      placeholder="OTP"
                      onChange={(e) => { setData({ ...data, otp: e.target.value }) }}
                      className="border-1 rounded-2 px-2 py-1 mx-0"
                      style={{ border: "1px solid #BBBBBB", outline: "none" }}
                    />
                  </div>
                  <div className="mb-3">
                    {errMsg ? <span className="text-danger fw-semibold" style={{ fontSize: '12px' }}>* {errMsg}</span> : ''}
                  </div>
                  <p className="px-1" >00:{timer}</p>

                  <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between ">
                    <button type="submit" onClick={clickedOnSignUp} className="btn btn-outline-dark mb-3 buttonText" disabled={isLoading ? true : false}>
                      {isLoading ? 'Loading...' : 'Submit'}
                    </button>
                  </div>
                  {timer === 0 ?
                    <p>
                      Haven’t received OTP?{" "}
                      <span className="clickableBlueText" onClick={sendOTP}>Resend OTP</span>
                    </p>

                    : ''}
                  <div className="text-danger">{errorMsg}</div>
                  <div className="text-success">{successMsg}</div>
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none">
                  <img src={sideImageReg} className="sideImgSignUp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
}

export default OTPverification;