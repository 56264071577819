import React, { useEffect } from 'react'
import { useState } from 'react';
import './MyBookingsPage.scss'
import cardImage from '../../../assets/card-top-image.png'
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment/moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function MyBookingsPage(props) {
  let navigate = useNavigate()
  const initialData = {
    startDate: '',
    endDate: '',
    completedText: '',
    isCompleted: 'Yes',
    openCompletedFormPopup: false
  }

  const [data, setData] = useState(initialData)

  let [bookedServices, setbookedServices] = useState([])
  let [currentUser, setCurrentUser] = useState()
  let [onGoingDataArr, setOnGoingDataArr] = useState([])
  let [completedDataArr, setCompletedDataArr] = useState([])
  let [upCommingDataArr, setUpCommingDataArr] = useState([])
  const [viewShowOnGoingCount, setViewShowOnGoingCount] = useState(6)
  const [viewShowUpCommingCount, setViewShowUpCommingCount] = useState(6)
  const [viewShowCompletedCount, setViewShowCompletedCount] = useState(6)
  let [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getAllData()
    let currentTab = localStorage.getItem('currentServiceTab')
    setMenuKey(currentTab)
  }, [])


  async function getAllData() {
    let uData = await apiservice.getLocalStorage('userData')
    bookServiceOnGoingList(uData)
    bookServiceCompletedList(uData)
    bookServiceUpcommingList(uData)
    setCurrentUser(uData)
  }

  const bookServiceOnGoingList = async (udata) => {
    setIsLoading(true)
    await apiservice.apiCall('/BookServiceOnGoingList', udata.Token).then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setOnGoingDataArr(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const bookServiceCompletedList = async (udata) => {
    setIsLoading(true)
    await apiservice.apiCall('/BookServiceCompletedList', udata.Token).then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setCompletedDataArr(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const bookServiceUpcommingList = async (udata) => {
    setIsLoading(true)
    await apiservice.apiCall('/BookServiceUpcomingList', udata.Token).then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        console.log(res.data);
        setIsLoading(false)
        setUpCommingDataArr(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }



  const inputHandleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }
  const [menuKey, setMenuKey] = useState('On-Going');

  const onClickTopMenu = (e) => {
    localStorage.setItem('currentServiceTab', e)
    let currentTab = localStorage.getItem('currentServiceTab')
    setMenuKey(currentTab)
    console.log(currentTab);
  }
  const openPopUp = () => {
    setData({ ...data, 'openCompletedFormPopup': true })
  }
  const closePopUp = () => {
    setData(initialData)

  }
  const onClickSubmit = async () => {

    let uData = await apiservice.getLocalStorage('userData')
    setIsLoading(true)
    let dataObejct = {

    }
    await apiservice.apiCall('', uData, dataObejct, 'POST').then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      }
      else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }


  const onClickJoinSession = (id) => {
    console.log(id);
    localStorage.setItem('schedulePageMenu', 2)
    navigate(`/contetexpert-home/learnerlistforschedule/${id}`)
  }
  const onClickCompleted = (id) => {
    console.log(id);
    localStorage.setItem('schedulePageMenu', 2)
    navigate(`/contetexpert-home/learnerlistforschedule/${id}`)
  }

  const onClickScheedule = (id) => {
    console.log(id);
    localStorage.setItem('schedulePageMenu', 1)
    navigate(`/contetexpert-home/learnerlistforschedule/${id}`)
  }


  function getOngoingEvents() {
    let list = [];
    let dataArr = onGoingDataArr && onGoingDataArr
    if (dataArr.length > 0) {
      for (let index = 0; index < dataArr.length; index++) {
        const item = dataArr[index];
        if (viewShowOnGoingCount >= index + 1) {
          list.push(
            <div key={index} className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-md-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.ThumbnailImage}`} width={'100%'} height={150} alt='...' style={{objectFit:'contain'}} />

                  </div>

                  <div className='col-md-6 col-12'>
                    <div className='d-none d-md-block'>
                      <div className='mt-lg-0 mt-3'>
                        <div className='fw-bold title-text-wrap mb-1'>{item.Service_Name}</div>
                        <div>{currentUser.name}  {currentUser.lname} <span className='badge text-dark ms-2 mb-1' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span></div>
                        <div className='mb-2'>
                          <StarRatings
                            rating={Number(item.averageRating)}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                          /> <span className='ps-2'>{item.averageRating === 0 ? 0 : Number(item.averageRating).toFixed(1)}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <div><b>Total time:</b> {item.Hour}h {item.Minutes}m </div>
                            {item.MentorshipType === 'Webinar Session' ?
                              <div className=''>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div> : ''}
                            <div className='fw-bold'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                          </div>
                          {
                            item.MentorshipType === 'Webinar Session' ?
                              <div className=' text-end'>
                                <button className='btn btn-orange w-100 rounded-2 mt-lg-1 mt-3' onClick={() => onCLickJoinBtn(item)}>Join Session </button>
                              </div>
                              :
                              <div className=' text-end '>
                                <button className='btn btn-orange w-100 rounded-2 mt-lg-1 mt-3' onClick={() => onClickJoinSession(item.Service_id)}>Join Session </button>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-md-none d-block'>
                    <div className='col-lg-6 col-12'>
                      <div className='mt-lg-0 mt-3'>
                        <div className='fw-bold title-text-wrap mb-1'>{item.Service_Name}</div>
                        <div>{currentUser.name}  {currentUser.lname}</div>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <span className='badge text-dark ms-2 mb-1' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span>
                          </div>
                          <div className='ms-3'>
                            {
                              item.MentorshipType === 'Webinar Session' ?
                                <div className=' text-end'>
                                  <button className='btn btn-orange w-100 rounded-2 mt-3' onClick={() => onCLickJoinBtn(item)}>Join Session </button>
                                </div>
                                :
                                <div className=' text-end'>
                                  <button className='btn btn-orange w-100 rounded-2' onClick={() => onClickJoinSession(item.Service_id)}>Join Session </button>
                                </div>
                            }
                          </div>

                        </div>

                        <div className='mb-2'>
                          <StarRatings
                            rating={Number(item.averageRating)}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                          /> <span className='ps-2'>{item.averageRating === 0 ? 0 : Number(item.averageRating).toFixed(1)}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <div><b>Total time:</b> {item.Hour}h {item.Minutes}m </div>
                            {item.MentorshipType === 'Webinar Session' ?
                              <div className=''>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div> : ''}
                            <div className='fw-bold'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-12' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold'>Start Date : </span> {moment(item.created_at).format("MMM Do YYYY")} </div>
                    <div className='mt-4'>
                      <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => onClickCompleted(item.Service_id)}>Completed  </button>
                    </div>
                    <div className='mt-3'>
                      <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => { apiservice.setLocalStorage('serviceData', item.Service_id); apiservice.setLocalStorage('fromServices', false); navigate('/contetexpert-home/previewservice') }}>View Details </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )
        }
      }
    } else {
      list.push(
        <div className='text-center text-danger mt-5'>
          Once a service is booked by learners, as a Content Expert, you will schedule the session with them. This will categorize the service as "Ongoing." If you haven't added any service yet, kindly do so. Upon receiving bookings, schedule your initial session accordingly.
        </div>
      )
    }

    return list
  }
  
  const onCLickJoinBtn = async (data) => {
    onClickOpenNewTab(data.session_code)
    // setIsLoading(true)
    // let dataObject = {
    //   service_id: id,
    //   book_services_id: id,
    //   session_number: 1,
    // }
    // console.log(dataObject);
    // let uData = await apiservice.getLocalStorage('userData')

    // await apiservice.apiCall('/MentorJoinSession', uData.Token, dataObject, 'POST').then(res => {
    //   if (res.unAuthenticated) {
    //     navigate('/')
    //     localStorage.removeItem('userData')
    //   } else if (res.data) {
    //     setIsLoading(false)
    //     console.log(res.data);

    //     let datas = res.data
    //     let url = `${apiservice.backendOrigin()}/meeting/index.php?name=${uData.name} ${uData.lname}&meeting_number=${datas.session_code}&meeting_pwd=${datas.session_password}&role=${1}&booking_id=${id}`
    //     onClickOpenNewTab(url)
    //     // setMeetingData(res.data)
    //     // createZoomMeeting(res.data)
    //   }
    //   else {
    //     setIsLoading(false)
    //     console.log(res.error);
    //   }
    // }).catch(err => {
    //   setIsLoading(false)
    //   console.log(err);
    // })
  }
  const onClickOpenNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }

  const onClickSchedule = (id) => {
    console.log(id);

    localStorage.setItem('schedulePageMenu', id)
    navigate(`/contetexpert-home/learnerlistforschedule/${id}`)
  }



  function getUpcomimgEvents() {
    let list = [];
    let dataArr = upCommingDataArr && upCommingDataArr
    console.log(upCommingDataArr);
    if (dataArr.length > 0) {
      for (let index = 0; index < dataArr.length; index++) {
        const item = dataArr[index];
        console.log(item);
        if (viewShowUpCommingCount >= index + 1) {
          list.push(
            <div key={index} className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-md-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.ThumbnailImage}`} width={'100%'} height={150} alt='...' style={{objectFit:'contain'}} />

                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='mt-lg-0 mt-3'>
                      <div className='fw-bold title-text-wrap'>{item.Service_Name}</div>
                      <div>{currentUser.name} {currentUser.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span></div>
                      <div>
                        <StarRatings
                          rating={Number(item.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.averageRating === 0 ? 0 : Number(item.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <div>Total time: {item.Hour}h {item.Minutes}m</div>
                          {item.MentorshipType === 'Webinar Session' ?
                            <div className=''>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div> : ''}
                          <div className='fw-bold'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-12' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold'>Start Date : </span> {moment(item.created_at).format("MMM Do YYYY")} </div>
                    {item.MentorshipType === 'Webinar Session' ? '' :
                      <div className=' text-end'>
                        <button className='btn btn-orange w-100 rounded-2 mt-4 btnM' onClick={() => onClickScheedule(item.Service_id)}>Schedule </button> 
                      </div>}
                    <div className='mt-3'>
                      <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => { apiservice.setLocalStorage('serviceData', item.Service_id); apiservice.setLocalStorage('fromServices', false); navigate('/contetexpert-home/previewservice') }}>View Details </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )
        }
      }
    } else {
      list.push(
        <div className='text-center text-danger mt-5'>
          No sessions are currently upcoming. Once booked, it will be shown here!
          {/* To begin, choose your industry to explore the service offerings of content experts ready to help you achieve your goals!  */}
          
        </div>
      )
    }

    return list
  }


  function getCompletedEvents() {
    let list = [];
    let dataArr = completedDataArr && completedDataArr
    if (dataArr.length > 0) {
      for (let index = 0; index < dataArr.length; index++) {
        const item = dataArr[index];
        if (viewShowCompletedCount >= index + 1) {
          list.push(
            <div key={index} className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-md-3 col-lg-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.ThumbnailImage}`} width={'100%'} height={150} alt='...' style={{objectFit:'contain'}} />

                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='mt-lg-0 '>
                      <div className='fw-bold text-break'>{item.Service_Name}</div>
                      <div>{currentUser.name} {currentUser.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span></div>
                      <div>
                        <StarRatings
                          rating={Number(item.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.averageRating === 0 ? 0 : Number(item.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <div><b>Total time:</b> {item.Hour}h {item.Minutes}m</div>
                          {item.MentorshipType === 'Webinar Session' ?
                            <div className=''>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div> : ''}
                          <div className='fw-bold'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                        </div>
                        {/* <div className=' text-end'>
                      <button className='btn btn-orange w-100 rounded-2' style={{ marginTop: '3px' }}>Join Session </button>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-12' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold'>Start Date : </span> {moment(item.created_at).format("MMM Do YYYY")} </div>
                    {/* <div className='mt-4'>
                  <button className='btn btn-orange w-100 rounded-2' onClick={openPopUp}>Completed <FontAwesomeIcon className='ms-3' icon={faArrowRight} /> </button>
                </div> */}
                    <div className='mt-3'>
                      <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => { apiservice.setLocalStorage('serviceData', item.Service_id); apiservice.setLocalStorage('fromServices', false); navigate('/contetexpert-home/previewservice') }}>View Details </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )
        }
      }
    } else {
      list.push(
        <div className='text-center text-danger mt-5'>
          You haven’t completed any services yet! 
          {/* Once you have completed all sessions, hit "Completed" to wrap things up. We'll give your XperThrivr progress report a shiny update! */}
        </div>
      )
    }
    return list;
  }

  const onClickOnGoingShowMore = () => {
    let count = viewShowOnGoingCount
    count = count + 6
    // alert(count)
    setViewShowOnGoingCount(count)

  }
  const onClickUpCommingShowMore = () => {
    let count = viewShowUpCommingCount
    count = count + 6
    // alert(count)
    setViewShowUpCommingCount(count)

  }
  const onClickCompletedShowMore = () => {
    let count = viewShowCompletedCount
    count = count + 6
    // alert(count)
    setViewShowCompletedCount(count)

  }

  return (
    <>
      <Modal show={data.openCompletedFormPopup} onHide={closePopUp} style={{ marginTop: '200px' }}>
        <ModalBody>
          <div className='p-3'>
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <div className='mb-3'>
                  <div className="form-group">
                    <label >Start date</label>
                    <input type="date"
                      className="form-control" name="startDate" value={data.startDate} onChange={inputHandleChange} placeholder="" />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='mb-3'>
                  <div className="form-group">
                    <label >End date</label>
                    <input type="date"
                      className="form-control" name="endDate" value={data.endDate} onChange={inputHandleChange} placeholder="" />
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-3'>
              <div className="form-group">
                <label >How is it Completed ?</label>
                <textarea className="form-control" name="completedText" value={data.completedText} rows="5" onChange={inputHandleChange} placeholder='Text here'></textarea>
              </div>
            </div>
            <div className='mb-3 d-flex justify-content-center'>
              <button className='btn border w-50 btn-grey' onClick={closePopUp}> Cancel</button>
              <button className='btn btn-orange ms-3 w-50' onClick={onClickSubmit}> Submit</button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {isLoading ? <Loader /> :
        <div className='p-3'>
          <div className=''>
            <div className='container-fluid'>
              <div className='fw-bold Title'>My Booking</div>
            </div>
            <div className='mt-3'>
              <div className='row'>
                <div className='col-lg-3 col-12'>
                  <div className=''>
                    <button className={menuKey === 'On-Going' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('On-Going')}>On-Going</button>
                  </div>
                </div>
                <div className='col-lg-3 col-6 mt-lg-0 mt-2'>
                  <div className=''>
                    <button className={menuKey === 'Upcoming' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Upcoming')}>Upcoming</button>
                  </div>
                </div>
                <div className='col-lg-3 col-6 mt-lg-0 mt-2'>
                  <div className=''>
                    <button className={menuKey === 'Completed' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Completed')}>Completed</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='mt-3'>
            {menuKey === 'On-Going' ?
              <div>
                {getOngoingEvents()}

                {viewShowOnGoingCount >= onGoingDataArr.length ? '' :
                  <div className='text-center my-5'>
                    <button className='btn btn-orange' onClick={onClickOnGoingShowMore}>Show More </button>
                  </div>
                }
              </div>
              :
              menuKey === 'Upcoming' ?
                <div>
                  {getUpcomimgEvents()}

                  {viewShowUpCommingCount >= upCommingDataArr.length ? '' :
                    <div className='text-center my-5'>
                      <button className='btn btn-orange' onClick={onClickUpCommingShowMore}>Show More </button>
                    </div>
                  }
                </div>
                :
                <div>
                  {getCompletedEvents()}

                  {viewShowCompletedCount >= completedDataArr.length ? '' :
                    <div className='text-center my-5'>
                      <button className='btn btn-orange' onClick={onClickCompletedShowMore}>Show More </button>
                    </div>
                  }

                </div>
            }

          </div>
        </div>}
    </>
  )
}

export default MyBookingsPage
