import React from 'react'
import { useState } from 'react'
import TimezoneSelect from 'react-timezone-select'
import "./GetTimeZone.scss"

function GetTimeZone() {
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    return (
        <>
            <div className=" bg-transparent">
                <div className="select-wrapper">
                    <TimezoneSelect
                    className='bg-transparent'
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                    />
                </div>
                {/* <h3>Output:</h3>
                <div
                    style={{
                        backgroundColor: '#ccc',
                        padding: '20px',
                        margin: '20px auto',
                        borderRadius: '5px',
                        maxWidth: '600px',
                    }}
                >
                    <pre
                        style={{
                            margin: '0 20px',
                            fontWeight: 500,
                            fontFamily: 'monospace',
                        }}
                    >
                        {JSON.stringify(selectedTimezone, null, 2)}
                    </pre>
                </div> */}
            </div>
        </>
    )
}

export default GetTimeZone
