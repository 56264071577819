import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { Modal, ModalBody } from 'react-bootstrap';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

function AnnouceMyService() {

    let navigate = useNavigate()
    const [serviceList, setServiceList] = useState([]);
    let [serviceData, setServiceData] = useState({})
    const [viewShowCount, setViewShowCount] = useState(6)
    let [userData, setUserData] = useState({})
    let [isLoading, setLoading] = useState(false)
    useEffect(() => {

        getServices()
    }, [])
    async function getServices() {
        setLoading(true)
        let usrData = await apiservice.getLocalStorage('userData')
        setUserData(usrData)
        console.log(usrData);

        try {

            let serviceData = await apiservice.apiCall('/Service', usrData.Token, null, 'GET')
            console.log(serviceData);
            if (serviceData.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (serviceData) {
                setLoading(false)
                console.log(serviceData.data);
                setServiceList(serviceData.data.reverse() || [])
            }
            else {
                setLoading(false)
                console.log(serviceData.error);
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
        // console.log(usrData);
    }



    const onClickLoginLinkedIn = (data) => {

        try {
            setServiceData(data)
            linkedInLogin();
        } catch (error) {
            console.log(error);
        }
    };

    const [linkedinPostPopup, setLinkedinPostPopup] = useState(false)
    const [postSuccess, setPostSuccess] = useState('')
    const [postError, setPostError] = useState('')
    const [linkedinPostLoading, setlinkedinPostLoading] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const { linkedInLogin } = useLinkedIn({
        clientId: "865mis3s3mhtef",
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: (code) => {
            console.log(code);
            linkedinCallBack(code)
        },
        scope: "email openid profile r_learningdata w_member_social",
        onError: (error) => {
            console.log(error);

        },
    })


    const linkedinCallBack = async (code) => {
        setlinkedinPostLoading(true)
        setLinkedinPostPopup(true)
        let dataObject = {
            access_token: code,
            service_id: serviceData.Service_id,
        }
        await apiservice.apiCall('/service/linkedin/share', '', dataObject, 'POST').then(res => {
            if (res.data) {
                console.log(res.data);
                setPostSuccess(res.data)
                setlinkedinPostLoading(false)
                setPostError('')
            } else {
                setPostError(res.error)
                setlinkedinPostLoading(false)
                setPostSuccess('')
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    const onClickLinkedinPostOk = () => {
        if (postSuccess !== '') {
            window.open('https://www.linkedin.com/');
            setLinkedinPostPopup(false)
        } else {
            setLinkedinPostPopup(false)
        }
    }
    const onClickLinkedinPostClose = () => {
        setLinkedinPostPopup(false)
    }
    const [currentIndex, setCurrentIndex] = useState('')
    const onClickCopy = (index) => {
        setCurrentIndex(index)
        setIsCopy(true)
        setTimeout(() => {
            setIsCopy(false)
        }, 3000);
    }
    function showServiceList() {
        let list = [];
        let dataArr = serviceList || [];


        if (dataArr.length > 0) {
            for (let index = 0; index < dataArr.length; index++) {
                let item = dataArr[index];

                if (viewShowCount >= index + 1) {
                    list.push(
                        <div key={index} className='mt-3'>
                            <div className='p-3 border rounded-2'>
                                <div className='row'>
                                    <div className='col-md-3 col-12 '>
                                        <img
                                            src={`${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                                            className='w-100'
                                            height={'150px'}
                                            alt='...'
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </div>
                                    <div className='col-md-5 col-12'>
                                        <div className='mt-lg-0 mt-3'>
                                            <div className='fw-bold mb-1'>{item.Service_Name}</div>
                                            <div className='mb-1'>{userData.name} {userData.lname}</div>
                                            <div>
                                                <span className='badge text-dark me-2 mb-2' style={{ backgroundColor: '#ABEFC6' }}>
                                                    {item.MentorshipType}
                                                </span>
                                                {item.MentorshipType === 'Webinar Session' && (
                                                    <span className='badge text-dark mb-2' style={{ backgroundColor: '#c7cce5' }}>
                                                        {item.start_date} {item.start_time}
                                                    </span>
                                                )}
                                            </div>
                                            <div className='mb-1'>
                                                <StarRatings
                                                    rating={item.averageRating}
                                                    starRatedColor="#FDB022"
                                                    numberOfStars={5}
                                                />
                                                <span className='ps-2'>{Number(item.averageRating).toFixed(1)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <div className='mb-1'>Total Time {item.Hour || '0'}h {item.Minutes}m </div>
                                                    {item.MentorshipType === 'Webinar Session' && (
                                                        <div className='mb-1'>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div>
                                                    )}
                                                    <div className='fw-bold mb-2'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div>
                                            <CopyToClipboard text={`${window.location.origin}/servicedetail/${item.Service_id}`} onCopy={() => onClickCopy(index)}>
                                                <button className="btn btn-orange me-2">
                                                    {isCopy && currentIndex === index ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                                                </button>
                                            </CopyToClipboard>
                                            <button className='btn btn-primary' onClick={() => onClickLoginLinkedIn(item)}>
                                                <FontAwesomeIcon className=' ' icon={faLinkedin} /> Share on LinkedIn
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        } else {
            list.push(
                <div key="empty" className='my-5 text-center text-danger'>
                    You haven't added any services for our learners. Please add your first service to start receiving bookings from our learners.
                </div>
            );
        }

        return list;
    }

    const onClickShowMore = () => {
        let count = viewShowCount
        count = count + 6
        // alert(count)
        setViewShowCount(count)

    }
    return (
        <>
            <Modal show={linkedinPostPopup}>
                <ModalBody>
                    {linkedinPostLoading ?
                        <div className='p-3 text-center'>
                            <FontAwesomeIcon icon={faSpinner} spin /> Loading...

                        </div>
                        : <div className='p-3 text-center'>
                            <div className='my-3'>
                                {postSuccess === '' ? '' : <div className='text-success'>{postSuccess}</div>}
                                {postError === '' ? '' : <div className='text-danger'>{postError}</div>}
                            </div>
                            <div className='mt-3'>
                                {postSuccess === '' ? '' : <button className='btn btn-orange' onClick={onClickLinkedinPostOk}>View Post</button>}
                                <button className='btn btn-secondary ms-3' onClick={onClickLinkedinPostClose}>Close</button>
                            </div>
                        </div>}

                </ModalBody>
            </Modal>
            <div className='mb-3'>
                <div className=''>
                    {showServiceList()}

                    {viewShowCount >= serviceList.length ? '' :
                        <div className='text-center my-5'>
                            <button className='btn btn-orange' onClick={onClickShowMore}>Show More </button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AnnouceMyService