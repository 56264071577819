import React, { Fragment, useState } from "react";
import sideImageReg from "../../../assets/sideImageReg.png";
import brandLogo from "../../../assets/LOGO.png";
import "../SignUpPage/Signuppage.Styles.scss";
import { HiArrowLongRight } from "react-icons/hi2";
import validation from "../../../components/Validations";
import apiservice from "../../../apiservice/ApiService";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";

function ForgotPass(props) {
  let navigate = useNavigate()
  const initialState = {
    email: ""
  }
  const [data, setData] = useState(initialState)
  const [errMsg, setErrMsg] = useState('')
  let [successMsg, setSucccessMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const clickedOnSendOTP = () => {
    if (data.email === "") {
      setErrMsg('Enter email Address')
    }
    else if (!data.email.match(validation().emailVal)) {
      setErrMsg('Please enter valid email')
    }
    else {
      setErrMsg('')
      forgotPass()
      console.log(data);
    }
  }
  const inputhandleChange = (e) => {
    setErrMsg('')
    setSucccessMsg('')
    let { name, value } = e.target
    console.log(value);
    setData({ ...data, [name]: value })
  }

  async function forgotPass() {
    try {
      setIsLoading(true)
      let dataObj = data;
      let response = await apiservice.apiCall('/password/email', '', dataObj, 'POST')
      console.log(response);
      if (response.data) {
        console.log(response.data);
        setIsLoading(false)
        setErrMsg('')
        setSucccessMsg('Reset password link sent on your email id !!')
      } else {
        setIsLoading(false)
        console.log(response.error);
        setErrMsg(response.error)
        setSucccessMsg('')
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  return (
    <Fragment>
      {isLoading ? <Loader /> :
        <section className='d-flex flex-column align-items-center' style={{ minHeight: '100vh', backgroundColor: '#ADD6DA' }}>

          <div className="container-fluid bg-white">
            <div className="container-lg d-flex justify-content-between py-3">
              <img className="pointer" src={brandLogo} onClick={() => navigate('/')} width={170} height={40} alt="" />
            </div>
          </div>

          <div
            className="container-fluid d-flex align-items-center "

          >
            <div className="container py-4">
              <div
                className="card border-3 rounded-3 border-white"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  backgroundColor: "#EEEFF580",
                }}
              >
                <div className="card-body p-0 m-0 row">
                  <div className="col-lg-6 col-12 p-5">
                    <div className="p-0 m-0">
                      <h5>Forgot your password?</h5>
                      <p>
                        To receive a link, please enter the Email address associated
                        with your account.
                      </p>
                    </div>
                    {/* {
                  successMsg!=='' ?<p className="alert-success" >{successMsg}</p>:errMsg!==''?<p className="alert-danger">{errMsg}</p>:""
                } */}
                    <div className='text-center'>
                      <div className="text-success" >{successMsg}</div><div className="text-danger">{errMsg}</div>

                    </div>
                    <div className="d-flex flex-column w-100 inpFieldContainer">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={inputhandleChange}
                        required
                        className="border-1 rounded-2 px-2 py-2 w-100 my-1"
                        style={{ border: "1px solid #BBBBBB", outline: "none" }}
                      />
                    </div>

                    <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between my-3">
                      <button type="submit" onClick={clickedOnSendOTP} className="btn btn-outline-dark mb-3 buttonText">
                        Submit 
                      </button>
                    </div>
                    <p className="clickableBlueText" onClick={() => navigate('/login')} >Back</p>
                  </div>
                  <div className="col-lg-6 col-12 d-lg-block d-none mb-5 pt-5">
                    <img src={sideImageReg} className="sideImgSignUp" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}
    </Fragment>
  );
}

export default ForgotPass;
