import React, { useEffect } from 'react'
import "./LearnerListForSchedule.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import { Modal, ModalBody, Pagination } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TimezoneSelect from 'react-timezone-select';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
import validation from '../../../components/Validations';
import { loadAuth2WithProps, loadClientAuth2, loadGapiInsideDOM } from 'gapi-script';
const cid = '1056869252945-srha9oll7i8am1le089q5q7al1catg6j.apps.googleusercontent.com'
const SCOPES = "https://www.googleapis.com/auth/calendar";
function AllLearner(props) {
    let navigate = useNavigate()
    const initialState = {
        startDate: '',
        startTime: '',
        endTime: '',
        meetingLink: ''
    }
    const [gapi, setGapi] = useState('')
    const [data, setData] = useState(initialState)
    const [myLearners, setMyLearners] = useState([])
    const [serviceDetails, setServiceDetails] = useState([])
    const [learnerSessionList, setLearnerSessionList] = useState([])
    const [bookSessionResponse, setBookSessionResponse] = useState([])
    const [currentData, setCurrentData] = useState('')
    const [currentSession, setCurrentSession] = useState('')
    const [bookingDetails, setBookingDetails] = useState('')
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        loadGapi()
        getAlldata()
    }, [])

    const loadGapi = async () => {
        const gapi = await loadGapiInsideDOM();
        setGapi(gapi)
    }

    const onChangeHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        setErrorMsg('')
        if (name === 'startTime') {
            const start = value;
            const minutes = currentData.sessionDuration === "1" ? 60 : currentData.sessionDuration;


            // Parse the initial time string
            const parsedTime1 = moment(start, 'HH:mm');


            // Add the specified minutes
            const end = parsedTime1.add(minutes, 'minutes').format('HH:mm');
            console.log(end);
            setData({ ...data, startTime: value, endTime: end })
        }
    }

    async function getAlldata() {
        setIsLoading(true)
        let id = props.id
        let uData = await apiservice.getLocalStorage('userData')
        try {
            let myLearnersData = await apiservice.apiCall('/BookServiceLearnerList', uData.Token, { service_id: id }, 'POST')
            if (myLearnersData.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (myLearnersData.data) {
                setIsLoading(false)
                setMyLearners(myLearnersData.data)
            }
            else {
                setIsLoading(false)
                console.log(myLearnersData.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }
    const onClickLearnerCard = async (data) => {
        let learnerId = data.users.id
        let sid = props.id
        localStorage.setItem('scheduleServiceid', sid)
        localStorage.setItem('isSchedulePage', true)
        await apiservice.setLocalStorage('learnerId', learnerId)
        // console.log(learnerId);
        navigate(`/contetexpert-home/learnerprofile/${learnerId}`)

    }

    const [sessionListPopup, setSessionListpopup] = useState(false)
    const [showScheduleForm, setShowScheduleForm] = useState(false)

    const onClickScheduleBtn = (data) => {
        setSessionListpopup(true)
        getSessionList(data)
        setBookingDetails(data)

    }
    const onClickCloseScheduleBtn = () => {
        setSuccessMsg('')
        setErrorMsg('')
        setSessionListpopup(false)

    }
    const onClickSessions = (data, index) => {
        // alert(1)

        setSuccessMsg('')
        setErrorMsg('')
        console.log(bookSessionResponse.completed);
        console.log(data, index + 1);
        // if (index + 1 === bookSessionResponse.completed + 1) {
        // }

        setCurrentData(data)
        setCurrentSession(index + 1)
        setShowScheduleForm(true)
        setSessionListpopup(false)

    }
    const onClickCloseSessions = () => {
        setSuccessMsg('')
        setErrorMsg('')
        setShowScheduleForm(false)
        setSessionListpopup(true)
        setData(initialState)

    }

    const getSessionList = async (data) => {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        let dataObject = {
            service_id: props.id,
            book_services_id: data.Book_Service_Id,
            learner_id: data.users.id,
        }
        // console.log(dataObject);
        await apiservice.apiCall('/BookServiceLearnerSessionList', uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                let datas = res.data
                let servicesData = datas.service_details
                let sessionData = datas.chapter_array
                setLearnerSessionList(sessionData)
                setServiceDetails(servicesData)
                setBookSessionResponse(datas)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const listOfSessionList = () => {
        let list = []
        let dataArr = learnerSessionList
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];

            list.push(
                <div class="row border-bottom p-3">
                    <div class="col-lg-8 d-flex justify-content-between">
                        <div>{element.chapterTitle}</div>
                        <div class="d-flex"></div>
                    </div>
                    <div class="col-lg-2 text-end">
                        <span class="badge bg-primary">
                            {element.sessionDuration} {element.sessionDuration === "1" ? "hr" : 'min'}
                        </span>
                    </div>
                    <div class="col-lg-2  text-end">
                        {element.status === 9 ?
                            <div>
                                <button className='btn btn-orange btnM' onClick={() => onClickSessions(element, index)}>Schedule</button>
                            </div> : element.status === 2 ?
                                <div>
                                    <button className='btn btn-orange btnM' onClick={() => onClickSessions(element, index)}>Reschedule</button>
                                </div> : <div className='badge text-dark border btnM' >Scheduled</div>
                        }
                    </div>
                </div>
            )

        }

        return list
    }

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onClickSubmit = async () => {
        let currentDate = new Date();
        let formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD');
        const selectedTime = moment(data.startTime, 'HH:mm').toDate();
        if (data.meetingLink === '') {
            setErrorMsg('Meeting link is required. Please Enter meeting link!');
            return;
        } else if (!data.meetingLink.match(validation().isValidUrl)) {
            setErrorMsg('Invalid meeting link!');
            return;
        }
        else if (data.startDate === '') {
            setErrorMsg('Date is required!');
            return;
        } else if (data.startDate < formattedCurrentDate) {
            setErrorMsg('You cannot schedule on a past date.');
            return;
        } else if (data.startTime === '') {
            setErrorMsg('Start time is required!');
            return;
        } else if (currentDate.getTime() > selectedTime.getTime()) {
            setErrorMsg('You cannot schedule on a past time.');
            return;
        } else {
            setIsLoading(true)
            let uData = await apiservice.getLocalStorage('userData')
            let dataObject = {
                service_id: props.id,
                book_services_id: bookingDetails.Book_Service_Id,
                started_date: data.startDate,
                started_time: data.startTime,
                timezone: selectedTimezone,
                end_time: data.endTime,
                session_number: currentSession,
                session_link: data.meetingLink
            }
            console.log(dataObject);
            await apiservice.apiCall('/ScheduleService', uData.Token, dataObject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setErrorMsg('');
                    setSuccessMsg('Successfully scheduled your service!')
                    setTimeout(() => {
                        setSuccessMsg('')
                        setShowScheduleForm(false)
                        setSessionListpopup(false)
                        setData(initialState)
                    }, 3000);
                }
                else {
                    setIsLoading(false)
                    setSuccessMsg('')
                    setErrorMsg('')
                    console.log(res.error);
                    setErrorMsg(res.error)
                }
            }).catch(err => {
                setIsLoading(false)
                setSuccessMsg('')
                setErrorMsg('')
                console.log(err);
            })
        }
    }
    const onClickGoogleCalendarSubmit = async () => {
        let currentDate = new Date();
        let formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD');
        const selectedTime = moment(data.startTime, 'HH:mm').toDate();
        console.log(formattedCurrentDate, '===', data.startDate);
        if (data.startDate === '') {
            setErrorMsg('Date is required!');
            return;
        } else if (data.startDate < formattedCurrentDate) {
            setErrorMsg('You cannot schedule on a past date.');
            return;
        } else if (data.startTime === '') {
            setErrorMsg('Start time is required!');
            return;
        } else if (formattedCurrentDate === data.startDate && currentDate.getTime() > selectedTime.getTime()) {
            setErrorMsg('You cannot schedule on a past time.');
            return;
        } else {
            setIsLoading(true)
            let uData = await apiservice.getLocalStorage('userData')
            let dataObject = {
                service_id: props.id,
                book_services_id: bookingDetails.Book_Service_Id,
                started_date: data.startDate,
                started_time: data.startTime,
                timezone: selectedTimezone,
                end_time: data.endTime,
                session_number: currentSession,
            }
            console.log(dataObject);
            setIsLoading(true)

            // createEvents(dataObject)
            await apiservice.apiCall('/ScheduleService', uData.Token, dataObject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    let resData = res.authUrl
                    let scheduleId = res.ScheduleId
                    // alert('before')
                    localStorage.removeItem('currServiceId')
                    localStorage.setItem('currScheduleId', scheduleId)
                    console.log(resData);
                    // alert('after')
                    window.open(resData)
                    setErrorMsg('');
                    setSuccessMsg('Successfully scheduled your service!')
                    setTimeout(() => {
                        setSuccessMsg('')
                        setShowScheduleForm(false)
                        setSessionListpopup(false)
                        setData(initialState)
                    }, 3000);
                }
                else {
                    setIsLoading(false)
                    setSuccessMsg('')
                    setErrorMsg('')
                    console.log(res.error);
                    setErrorMsg(res.error)
                }
            }).catch(err => {
                setIsLoading(false)
                setSuccessMsg('')
                setErrorMsg('')
                console.log(err);
            })

        }
    }








    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(myLearners.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = myLearners.slice(startIndex, endIndex);



    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const learnerList = () => {
        let array = itemsToDisplay
        // console.log(array);
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let item = array[index]

            list.push(
                <div className='col-md-4 col-12 '
                    key={index}>
                    <div className='learnerCardMentors my-2'>
                        <div className="card" >
                            <div className={`cardBg${count}`}>
                            </div>
                            <div className="card-body p-2">
                                <div className="  learner_icon " >
                                    <div className='d-flex justify-content-center'>
                                        <div className=' rounded-circle overflow-hidden ms-2' style={{ width: '70px', height: '70px' }}>
                                            <img src={item.users.ProfileImage === null ? item.users.SocialImage === null ? item.users.profile_photo_url : item.users.SocialImage : `${apiservice.apiImageShow()}/${item.users.ProfileImage}`} className='' alt="" width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div className='card-text  m-2 text-center'>
                                        <div className=' fw-bold'>{item.users.name ? `${item.users.name} ${item.users.lname}` : 'Learner'} </div>
                                    </div>
                                    <div className='text-center px-3'>
                                        <div className=''>
                                            <div className='w-100 btn btn-orange-border btnM' onClick={() => onClickLearnerCard(item)}>View Details</div>
                                        </div>
                                        <div className='mt-3'>
                                            <div className='w-100 btn btn-orange btnM' onClick={() => onClickScheduleBtn(item)}>Schedule</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

            if (count === 5) {
                count = 0
            } else {
                count++
            }
        }
        return list
    }
    return (
        <>
            <Modal show={sessionListPopup} size='lg' style={{ marginTop: '80px' }}>
                <ModalBody>
                    <div className='text-center pt-1  bg-light shadow rounded-circle pointer' style={{ width: 30, height: 30, position: 'absolute', top: -9, right: -6 }} onClick={onClickCloseScheduleBtn}><FontAwesomeIcon icon={faTimes} /></div>
                    <div className='p-2 text'>
                        <div className='mb-3 fw-bold' >
                            {serviceDetails.Service_Name}
                        </div>
                        <hr />
                        {listOfSessionList()}
                    </div>
                </ModalBody>
            </Modal>
            <Modal show={showScheduleForm} className='mt-5'>
                <ModalBody>
                    {successMsg === '' ?
                        <div>
                            <div className='text-center pt-1  bg-light shadow rounded-circle pointer' style={{ width: 30, height: 30, position: 'absolute', top: -9, right: -5 }} onClick={onClickCloseSessions}><FontAwesomeIcon icon={faTimes} /></div>
                            <div className='p-3'>
                                <div className='mb-3 fw-bold'>Schedule :</div>
                                <div className='text-danger text-center'>{errorMsg}</div>
                                <div>
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="">Your Meeting Link</label>
                                            <input type="text" onChange={onChangeHandle}
                                                className="form-control" name="meetingLink" value={data.meetingLink} placeholder="" />
                                        </div>
                                    </div>
                                    {data.meetingLink !== '' ? '' :
                                        <div className='text-secondary text-center mt-3'>

                                            <div className='mb-3 fw-bold'>OR <br />Use Google Meet</div>
                                        </div>}
                                </div>

                                <div>
                                    <div className='row my-3 border rounded p-3' >
                                        <div className='col-9'>{currentData.chapterTitle}</div>
                                        <div className='col-3 text-end'>{currentData.sessionDuration} {currentData.sessionDuration === "1" ? "hr" : 'min'}</div>
                                    </div>
                                    <div className="select-wrapper">

                                        <label htmlFor="">Time zone</label>
                                        <TimezoneSelect
                                            className='bg-transparent'
                                            value={selectedTimezone}
                                            onChange={setSelectedTimezone}
                                        />

                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-12 col-12'>
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="">Date</label>
                                                    <input type="date" onChange={onChangeHandle}
                                                        className="form-control" name="startDate" value={data.startDate} placeholder="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="">Start time</label>
                                                    <input type="time" onChange={onChangeHandle}
                                                        className="form-control" name="startTime" value={data.startTime} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="">End time</label>
                                                    <input type="time" onChange={onChangeHandle}
                                                        className="form-control" name="endTime" value={data.endTime} placeholder="" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {data.meetingLink !== '' ?

                                        <div className='mt-3'>
                                            <button className='btn btn-orange w-100' onClick={onClickSubmit}>Submit</button>
                                        </div> :
                                        <div className='mt-3'>
                                            <button className='btn btn-orange w-100' onClick={onClickGoogleCalendarSubmit}>Google Calendar</button>
                                        </div>}
                                </div>
                            </div>
                        </div> :
                        <div className='text-center fw-bold text-success p-5'>
                            {successMsg}
                        </div>}
                </ModalBody>
            </Modal>
            {isLoading ? <Loader /> :
                <div>
                    {myLearners.length ?
                        <div className='mt-3'>
                            <div className=' row'>
                                {learnerList()}
                            </div>
                            <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination>
                                    <Pagination.Prev
                                        className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1 ? true : false}
                                    >
                                        <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                    </Pagination.Prev>
                                    {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                        <Pagination.Item
                                            key={pageRangeStart + offset}
                                            active={pageRangeStart + offset === currentPage}
                                            onClick={() => handlePageChange(pageRangeStart + offset)}
                                        >
                                            {pageRangeStart + offset}
                                        </Pagination.Item>
                                    ))}
                                    {pageRangeEnd < totalPages && (
                                        <Pagination.Ellipsis disabled />
                                    )}
                                    <Pagination.Next
                                        className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages ? true : false}
                                    >
                                        <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </div> :
                        <div>
                            <div className='fw-bold text-center text-danger mt-5'>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
                        </div>

                    }
                </div>
            }
        </>
    )
}

export default AllLearner
