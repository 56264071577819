import React from 'react'
import { Fragment } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import serviceCardImg1 from "../../../assets/serviceStructImg1.png"
import serviceCardImg2 from "../../../assets/serviceStructImg2.png"
import serviceCardImg3 from "../../../assets/serviceStructImg3.png"
import serviceCardImg4 from "../../../assets/serviceStructImg4.png"
import serviceCardImg5 from "../../../assets/serviceStructImg5.png"
import serviceCardImg6 from "../../../assets/serviceStructImg6.png"
import MultiColorCard from '../../../components/LandingpageComponents/MulticolorCardAtICBuizz/MultiColorCard'
import girlAtFooterBizz from "../../../assets/girlAtFooterBizz.png"
import { HiArrowLongRight } from 'react-icons/hi2'
import { useState, useEffect } from 'react'
import startHollow from '../../../assets/startHollow.png'
import apiservice from '../../../apiservice/ApiService'
import "./InterConnectForBussiness.scss";
import { useNavigate } from 'react-router-dom'
// import ScrollAnimation from 'react-animate-on-scroll'


function InterConnectForBussiness(props) {
  let navigate = useNavigate()
  const [questionNumber, setQuestionNumber] = useState(0);
  let [activeText, setactiveText] = useState('text1')
  let [isLoggedIn, setLoggedIn] = useState(false)


  function clickedOnSliderText(e) {
    setactiveText(e)
  }
  const onClickQuetions = (e) => {
    setQuestionNumber(e);
  };

  useEffect(() => {
    async function getLoginStatus() {
      let logStatus = await apiservice.getLocalStorage('userData')
      if (logStatus) {
        setLoggedIn(true)
      }
      else {
        setLoggedIn(false)
      }
    }
    localStorage.removeItem('isFilter')
    getLoginStatus()
    window.scrollTo(0, 0)

  }, [])

  return (
    <Fragment>
      <Header navigate={navigate} />
      <section style={{ backgroundColor: "#f1efe7" }}>

        <article className="container py-lg-5 py-2">
          <div className='d-flex justify-content-between'>
            <div>
              <div><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt; Businesses</div>
              <div className='headTextAtContCreator'>
                <h2 className='py-2 fw-semibold'>Monetise the full aspect of your expertise.</h2>
              </div>

              <p>Schedule, host and get paid for your 1:1 consultation sessions and online classes.</p>
            </div>
            <div className='d-lg-block d-none' ><img className='start_splash_img' src={startHollow} alt="" width={80} height={80} /></div>
          </div>
        </article>
        <article className='py-3' style={{ backgroundColor: '#ADD6DA' }}>
          {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
          <div className='headTextAtContCreator'>
            <h3 className=' fw-semibold py-3 text-center'>How does XperThrivr structure your service offerings?</h3>
          </div>

          <div className="container">
            <div className="row " style={{ height: 'max-content' }}>
              <div className="col-lg-2 col-md-6 col-12 mt-3 ">
                <div className="card justify-content-lg-start align-items-center justify-content-center w-100 border-white border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg1} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body ">
                      <p className='text-center icStructureCardText'>Hold 1:1 sessions | Multi-sessions | Post live webinar sessions</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12 mt-3">
                <div className="card w-100 border-white justify-content-lg-start justify-content-lg-start align-items-center justify-content-center border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg2} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body">
                      <p className='text-center icStructureCardText'>Use our course builder to structure sessions | Add images, videos, and slides with a few clicks.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12 mt-3">
                <div className="card w-100 border-white justify-content-lg-start align-items-center justify-content-center border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg3} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body">
                      <p className='text-center icStructureCardText'>Calendar integration | Booking reminder notification</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12 mt-3">
                <div className="card w-100 border-white justify-content-lg-start align-items-center justify-content-center border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg4} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body">
                      <p className='text-center icStructureCardText'>Transparent pricing | Flexible session duration</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12 mt-3">
                <div className="card w-100 border-white justify-content-lg-start align-items-center justify-content-center border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg5} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body">
                      <p className='text-center icStructureCardText'>Ability to DM your audience after booking for personalised coaching plan.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12 mt-3">
                <div className="card w-100 border-white justify-content-lg-start align-items-center justify-content-center border-2 p-2 bg-transparent rounded-3" style={{ height: '100%' }}>
                  <div>
                    <div className="text-center cImg">
                      <img src={serviceCardImg6} width={114} height={114} alt="" />
                    </div>
                    <div className="card-body">
                      <p className='text-center icStructureCardText'>Testimonials | Build trust and credibility</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* </ScrollAnimation> */}
        </article>
        <div className='headTextAtContCreator'>
          <h3 className='text-center fw-semibold my-lg-5 my-3'>XperThrivr for:</h3>
        </div>
        <MultiColorCard />
        {isLoggedIn === true ? '' :
          <article className='claimSec  py-2'>
            <div className="container row py-3">
              <div className="col-lg-4 col-12  offset-lg-4 offset-0">  <h3 className=' text-lg-start text-center headTextAtContCreatorC'>Claim your XperThrivr digital land!</h3> </div>
              <div className="col-lg-4 col-12 align-self-center">
                <div className='w-100 text-center align-self-center'>
                  {isLoggedIn === true ? '' : <button
                    type="button"
                    onClick={() => navigate('/signup')}
                    className="btn btn-primary border-0 text-center px-4 py-2 btnM"
                    style={{ backgroundColor: "#E57A4D" }}
                  >
                    Launch your Personalised Profile
                  </button>}
                </div>
              </div>
            </div>
          </article>}

        <article className='container'>
          <div className="row">
            <div className="col-lg-6 p-3 col-12">
              {/* <ScrollAnimation animateIn='animate__fadeInLeft' > */}
              <img src={girlAtFooterBizz} className='w-100 d-none d-lg-block imgAnimatin' alt="" />
              <img src={girlAtFooterBizz} className='w-75 d-lg-none d-block ms-5 imgAnimatin' alt="" />
              {/* </ScrollAnimation> */}
            </div>
            <div className="col-lg-6 col-12 p-3 align-self-center">
              {/* <ScrollAnimation animateIn='animate__fadeInRight' > */}
              <div className='headTextAtContCreator'>
                <h3 className='fw-semibold'>Give yourself the  XperThrivr Edge.</h3>
              </div>

              <p>

                <li>XT provides more personalised and engaging clients interactions through our LMS features (Learning Management System) that allows you to structure your service offerings in a clear and concise manner.</li>
                <br />
                <li>Makes best use of your XperThrivr dashboard where you can create and keep track of upcoming appointments, track progress and manage testimonials.</li>
              </p>
              {/* </ScrollAnimation> */}
            </div>
          </div>
        </article>


        <article className="container">
          <div className="row my-lg-5 my-1  py-5">
            {/* <div className="col-lg-3 col-12"> */}
            {/* <p className="smallSubTitle">Top Questions</p> */}

            {/* </div> */}
            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
            <div className="col-lg-12 col-12">
              <div>
                <div className="mt-3">
                  <div className='headTextAtContCreator mb-4'>
                    <h4 className="fw-bold text-center">
                      Frequently Asked Questions
                    </h4>
                  </div>

                  <div
                    className="accordion "
                    // style={{--bs-body-bg:"transperent"}}
                    id="accordionExample"
                  >
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingOne"
                      >
                        <button
                          onClick={() => onClickQuetions(1)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 1
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                1
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                What is XperThrivr’s raison-d’être?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            XperThrivr enables you to monetise your expertise through 1:1 consultation sessions and online classes. Set up your profile page and access your dashboard - add your service sessions, generate your profile link; and share it with your network.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingTwo"
                      >
                        <button
                          onClick={() => onClickQuetions(2)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 2
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                2
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                How much can I charge for my sessions?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            We enable both free and paid service offerings. How much you can charge really depends on the perceived value and how much are your clients willing to pay.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingThree"
                      >
                        <button
                          onClick={() => onClickQuetions(3)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 3
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                3
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                {" "}
                                How does XperThrivr enable a personalised client experience?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div><b>The client experience journey begins as follows: </b></div>
                          <p>
                            <b>a.</b> Once your session is booked, you will connect to your client from your dashboard.
                          </p>
                          <p>
                            <b>b.</b> Our in-built chat feature will be enabled on your dashboard, through which you will reach out directly to your clients to schedule the session at a convenient time and date.
                          </p>
                          <p>
                            <b>c.</b> Use our chat feature to discuss their service expectation and the content of the session beforehand. You can also exchange documents securely.
                          </p>
                          <p>
                            <b>d.</b> We automatically send the calendar invite and email notifications to both of you, making scheduling seamless and effortless.
                          </p>
                          <p>
                            <b>e.</b> Dive in the session with confidence!
                          </p>


                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingFour"
                      >
                        <button
                          onClick={() => onClickQuetions(4)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 4
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                4
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                {" "}
                                How sessions can be structured?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            <b>Single session:</b> Single 1:1 session is ideal for receiving focused training, coaching, or guidance on a particular area without the commitment of multiple sessions.
                          </p>
                          <p>
                            <b>Multi-session:</b> Spread your coaching or consulting service offerings over multiple sessions, enabling you to cater for diverse client needs with different session frequencies. You can structure your session content with more certainty, knowing clients are committed to multiple sessions.
                          </p>
                          <p>
                            <b>Webinar session:</b> Webinar feature is also available and allows you to reach a wider audience. You can set the number of participants, select the time and date; and XperThrivr will generate the webinar link that can be shared with the participants ahead of the webinar.
                          </p>
                        </div>
                      </div>
                    </div>



                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingFive"
                      >
                        <button
                          onClick={() => onClickQuetions(5)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 5
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                5
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                For online businesses, is XperThrivr free to use?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, Xperthrivr is free to use and we only charge a very small commission of 5% on your earnings to cover the operational cost of the website. For example, we partner with Paypal for secure payment that charges around 3% of transaction amount.
                          </p>
                        </div>
                      </div>
                    </div>


                    {/* <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingSix"
                      >
                        <button
                          onClick={() => onClickQuetions(6)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 6
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                6
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                Can I earn certifications or credentials through XperThrivr courses as a business professional?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, many courses on
                            XperThrivr offer certifications
                            upon successful completion.
                            These certifications can be
                            valuable for professional
                            development and career
                            advancement.
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingSeven"
                      >
                        <button
                          onClick={() => onClickQuetions(7)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 7
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                7
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                Can I interact with content
                                experts or instructors on
                                XperThrivr?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, you can typically interact
                            with content experts and
                            instructors through chat, video
                            calls, and discussion forums.
                            This enables you to ask
                            questions, seek guidance, and
                            engage in discussions
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingEight"
                      >
                        <button
                          onClick={() => onClickQuetions(8)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 8
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                8
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                How can I track my progress
                                and measure the impact of
                                my learning on XperThrivr?

                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            XperThrivr typically provides
                            features for tracking your
                            course progress and accessing
                            performance metrics. You can
                            monitor your achievements and
                            the skills you've gained during
                            your learning journey.
                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingNine"
                      >
                        <button
                          onClick={() => onClickQuetions(9)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 9
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                9
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                Is XperThrivr suitable for
                                businesses looking to upskill
                                their employees?


                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, XperThrivr can be a
                            valuable resource for businesses seeking to upskill
                            their workforce. The platform
                            offers content that can help
                            employees develop the skills
                            and knowledge required for
                            their roles.
                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="accordion-item bg-transparent">
                      <h2
                        className="accordion-header bg-transparent"
                        id="headingFive"
                      >
                        <button
                          onClick={() => onClickQuetions(10)}
                          className="accordion-button collapsed pe-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          <div className='col-12'>
                            <div className="row">
                              <div
                                className={
                                  questionNumber === 10
                                    ? "col-2 rounded-circle pt-2 text-center active-circle"
                                    : "col-2 quetionbtn rounded-circle pt-2 text-center "
                                }
                                style={{ width: "35px", height: "35px" }}
                              >
                                10
                              </div>
                              <div className="col-10  pt-2 acTitle">
                                How do I get started with XperThrivr, and where can I find additional support if needed?
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>To get started with XperThrivr,
                            create an account, explore the
                            available content, and start your
                            learning journey. If you need
                            assistance, the platform typically
                            offers user support and resources
                            to guide you through the process.
                          </p>
                        </div>
                      </div>
                    </div> */}



                  </div>
                </div>
              </div>
            </div>
            {/* </ScrollAnimation> */}
          </div>
        </article>
        <Footersection navigate={navigate} />
      </section>
    </Fragment>
  )
}

export default InterConnectForBussiness
