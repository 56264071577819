import React from 'react'
import { useState } from 'react';
import './MyServices.scss'
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment/moment';
import Loader from '../../../components/Loader';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TimezoneSelect from 'react-timezone-select';
import validation from '../../../components/Validations';

function MyServices(props) {
  let navigate = useNavigate()

  const initialState = {
    startDate: '',
    startTime: '',
    endTime: '',
    meetingLink: ''
  }

  const [data, setData] = useState(initialState)
  const [serviceList, setServiceList] = useState([]);
  let [userData, setUserData] = useState({})
  let [isLoading, setLoading] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)
  const [currentDeleteItem, setCurrentDeleteItem] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [viewShowCount, setViewShowCount] = useState(6)
  useEffect(() => {

    getServices()
  }, [])

  const onChangeHandle = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
    setErrorMsg('')
    if (name === 'startTime') {
      const start = value;
      const minutes = currentData.sessionDuration === "1" ? 60 : currentData.sessionDuration;


      // Parse the initial time string
      const parsedTime1 = moment(start, 'HH:mm');


      // Add the specified minutes
      const end = parsedTime1.add(minutes, 'minutes').format('HH:mm');
      console.log(end);
      setData({ ...data, startTime: value, endTime: end })
    }
  }

  async function getServices() {
    setLoading(true)
    let usrData = await apiservice.getLocalStorage('userData')
    setUserData(usrData)
    console.log(usrData);

    try {

      let serviceData = await apiservice.apiCall('/Service', usrData.Token, null, 'GET')
      console.log(serviceData);
      if (serviceData.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (serviceData) {
        setLoading(false)
        console.log(serviceData.data);
        setServiceList(serviceData.data.reverse() || [])
      }
      else {
        setLoading(false)
        console.log(serviceData.error);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    // console.log(usrData);
  }
  async function getUpdateServices() {
    let usrData = await apiservice.getLocalStorage('userData')
    setUserData(usrData)
    console.log(usrData);

    try {

      let serviceData = await apiservice.apiCall('/Service', usrData.Token, null, 'GET')
      console.log(serviceData);
      if (serviceData.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (serviceData) {
        console.log(serviceData.data);
        setServiceList(serviceData.data.reverse() || [])
      }
      else {
        console.log(serviceData.error);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    // console.log(usrData);
  }

  function clickedOnViewProfile(item) {
    navigate('/contetexpert-home/previewservice')
    console.log(item);
    apiservice.setLocalStorage('serviceData', item.Service_id)
    apiservice.setLocalStorage('fromServices', true)
  }
  function clickedOnCreateProfile() {
    localStorage.removeItem('currentService')
    navigate('/contetexpert-home/createnewservices')

  }

  const onClickDeleteConfirmation = (item) => {
    setCurrentDeleteItem(item)
    setErrorMsg('')
    setSuccessMsg('')
    setDeletePopup(true)
  }

  const onClickYes = () => {
    clickedOnDeleteProfile(currentDeleteItem)
  }
  const onClickNo = () => {
    setDeletePopup(false)

  }
  const onClickOK = () => {
    setDeletePopup(false)
    getServices()

  }

  async function clickedOnDeleteProfile(item) {
    setLoading(true)
    console.log(item);
    try {

      let deleteService = await apiservice.apiCall(`/Service_delete/${item.Service_id}`, userData.Token, null, 'POST')
      console.log(deleteService);
      if (deleteService.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (deleteService.data) {
        setLoading(false)
        setSuccessMsg(deleteService.data)
        setErrorMsg('')
        console.log(deleteService.data);
      }
      else {
        setLoading(false)
        setSuccessMsg('')
        setErrorMsg(deleteService.error)
        console.log(deleteService.error);

      }
    } catch (error) {
      setLoading(false)
      console.log(error);


    }

  }
  function changeServiceStatus(item) {
    console.log(item);
    if (item.Status === 1) {
      changeStatusfuntion(item, 0)
    }
    else {
      changeStatusfuntion(item, 1)
    }
  }

  async function changeStatusfuntion(value, status) {
    let reqData = {
      Status: status
    }
    try {

      let updateService = await apiservice.apiCall(`/service_update_status/${value.Service_id}`, userData.Token, reqData, 'POST')
      console.log(updateService);
      if (updateService.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (updateService.data) {
        console.log(updateService.data);
        getUpdateServices()
      }
      else {
        console.log(updateService.error);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);

    }
  }


  function showServiceList() {
    let list = []
    let dataArr = serviceList
    if (dataArr.length > 0) {
      for (let index = 0; index < dataArr.length; index++) {
        let item = dataArr[index];
        if (viewShowCount >= index + 1) {
          list.push(
            <div key={index} className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-md-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.ThumbnailImage}`} className='w-100' height={'150px'} alt='...' style={{objectFit:'contain'}}/>
                  </div>
                  <div className='col-md-5 col-12'>
                    <div className='mt-lg-0 mt-3'>
                      <div className='fw-bold mb-1'>{item.Service_Name}</div>
                      <div className='mb-1'>{userData.name} {userData.lname}</div>
                      <div>
                        <span className='badge text-dark me-2 mb-2' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span>
                        {item.MentorshipType === 'Webinar Session' ?
                          <span className='badge text-dark mb-2' style={{ backgroundColor: '#c7cce5' }}>{item.start_date} {item.start_time}</span> : ''}
                      </div>
                      <div className='mb-1'>
                        <StarRatings
                          rating={item.averageRating}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.averageRating === 0 ? 0 : Number(item.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <div className='mb-1'>Total Time {item.Hour === null ? '0' : item.Hour}h {item.Minutes}m </div>
                          {console.log(item.start_date)}
                          {item.MentorshipType === 'Webinar Session' ?
                            <div className='mb-1'>  <b>Date and Time :</b> {item.start_date} {item.start_time}</div> : ''}
                          <div className='fw-bold mb-2'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                        </div>
                      </div>



                    </div>
                  </div>
                  <div className='col-md-4 col-12 ' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold'>Creatd On :</span> {moment(item.created_at).format("DD MMM ,  YY")}</div>
                    <div className='d-none d-lg-block'>
                      <div className='ms-auto pt-2'>
                        <label className="switch" style={{ fontSize: '14px' }}>
                          <input className="switch-input" type="checkbox" onChange={() => changeServiceStatus(item)} checked={item.Status !== 1 ? false : true} />
                          <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className='mt-2'>
                        <button className='btn btn-orange  rounded-2  mx-2 btnM mt-lg-3' onClick={() => clickedOnViewProfile(item)} >View Details </button>
                         <button className='btn btn-orange  rounded-2 mt-lg-3 mt-0  mx-2 btnM' onClick={() => onClickDeleteConfirmation(item)} >Delete  </button>
                        {item.MentorshipType === 'Webinar Session' && (item.session_code === '' || item.session_code === null)  ?
                         
                        <div>
                           <button className='btn btn-grey  rounded-2 mt-lg-3 mt-0  mx-2 btnM' onClick={() => onClickShowSchedulePopUp(item)}>Schedule</button> 
                           {/* <button className='btn border mt-3'><FontAwesomeIcon className='' icon={faRefresh} /></button> */}
                        </div>
                         : ''}
                      </div>
                    </div>
                    <div className='d-lg-none d-block'>
                      <div className='d-flex'>
                        <div className='pt-2'>
                          <label className="switch" style={{ fontSize: '14px' }}>
                            <input className="switch-input" type="checkbox" onChange={() => changeServiceStatus(item)} checked={item.Status !== 1 ? false : true} />
                            <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className='mt-2'>
                        <button className='btn btn-orange  rounded-2  btnM' onClick={() => clickedOnViewProfile(item)}>View Details </button>
                        <button className='btn btn-orange  rounded-2 mx-2 btnM' onClick={() => onClickDeleteConfirmation(item)} >Delete  </button></div>
                        {item.MentorshipType === 'Webinar Session' && (item.session_code === '' || item.session_code === null)  ?
                         
                         <button className='btn btn-grey  rounded-2 mt-lg-3 mt-3  btnM' onClick={() => onClickShowSchedulePopUp(item)}>Schedule</button>
                         : ''}
                     
                    </div>

                  </div>
                </div>

              </div>
            </div>
          )
        }
      }
    } else {
      list.push(
        <div className='my-5 text-center text-danger'>You haven't added any services for our learners. Please add your first service to start receiving bookings from our learners.</div>
      )
    }

    return list
  }
  const onClickShowMore = () => {
    let count = viewShowCount
    count = count + 6
    // alert(count)
    setViewShowCount(count)

  }
  const [sessionListPopup, setSessionListpopup] = useState(false)
  const [showScheduleForm, setShowScheduleForm] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [currentData, setCurrentData] = useState('')

  const [currentSession, setCurrentSession] = useState('')
  const [bookingDetails, setBookingDetails] = useState('')

  const onClickShowSchedulePopUp = (data) => {
    console.log(data);
    setCurrentData(data)
    setShowScheduleForm(true)
  }

  const onClickCloseSessions = () => {
    setShowScheduleForm(false)
    setData(initialState)

  }

  const onClickSubmit = async () => {
    let currentDate = new Date();
    if (data.meetingLink === '') {
      setErrorMsg('Meeting link is required. Please Enter meeting link!');
      return;
    } else if (!data.meetingLink.match(validation().isValidUrl)) {
      setErrorMsg('Invalid meeting link!');
      return;
    } else {
      setLoading(true)
      let uData = await apiservice.getLocalStorage('userData')
      let dataObject = {
        service_id: currentData.Service_id,
        session_link: data.meetingLink
      }
      console.log(dataObject);
      await apiservice.apiCall('/ScheduleWebinar', uData.Token, dataObject, 'POST').then(res => {
        if (res.unAuthenticated) {
          navigate('/')
          localStorage.removeItem('userData')
        } else if (res.data) {
          setLoading(false)
          console.log(res.data);
          setErrorMsg('');
          setSuccessMsg('Successfully scheduled your service!')
          setTimeout(() => {
            setSuccessMsg('')
            setShowScheduleForm(false)
            setSessionListpopup(false)
            setData(initialState)
            getUpdateServices()
          }, 3000);
        }
        else {
          setLoading(false)
          setSuccessMsg('')
          setErrorMsg('')
          console.log(res.error);
          setErrorMsg(res.error)
        }
      }).catch(err => {
        setLoading(false)
        setSuccessMsg('')
        setErrorMsg('')
        console.log(err);
      })
    }
  }
  const onClickGoogleCalendarSubmit = async () => {

    setLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    let dataObject = {
      service_id: currentData.Service_id,
    }
    console.log(dataObject);
    setLoading(true)

    // createEvents(dataObject)
    await apiservice.apiCall('/ScheduleWebinar', uData.Token, dataObject, 'POST').then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setLoading(false)
        console.log(res.data);
        let resData = res.authUrl
        let service_id = res.service_id
        console.log(service_id, 'servide id');
        localStorage.removeItem('currScheduleId')
        // alert('before')
        localStorage.setItem('currServiceId', service_id)
        console.log(resData);
        // alert('after')
        window.open(resData)
        setErrorMsg('');
        setSuccessMsg('Successfully scheduled your service!')
        setTimeout(() => {
          setSuccessMsg('')
          setShowScheduleForm(false)
          setSessionListpopup(false)
          setData(initialState)
          getUpdateServices()
        }, 3000);
      }
      else {
        setLoading(false)
        setSuccessMsg('')
        setErrorMsg('')
        console.log(res.error);
        setErrorMsg(res.error)
      }
    }).catch(err => {
      setLoading(false)
      setSuccessMsg('')
      setErrorMsg('')
      console.log(err);
    })


  }
  return (
    <>
      <Modal show={showScheduleForm} className='mt-5'>
        <ModalBody>
          {successMsg === '' ?
            <div>
              <div className='text-center pt-1  bg-light shadow rounded-circle pointer' style={{ width: 30, height: 30, position: 'absolute', top: -9, right: -5 }} onClick={onClickCloseSessions}><FontAwesomeIcon icon={faTimes} /></div>
              <div className='p-3'>
                <div className='mb-3 fw-bold'>Schedule :</div>
                <div className='text-danger text-center'>{errorMsg}</div>
                <div>
                  <div>
                    <div className="form-group">
                      <label htmlFor="">Your Meeting Link</label>
                      <input type="text" onChange={onChangeHandle}
                        className="form-control" name="meetingLink" value={data.meetingLink} placeholder="" />
                    </div>
                  </div>
                  {data.meetingLink !== '' ? '' :
                    <div className='text-secondary text-center mt-3'>

                      <div className='mb-3 fw-bold'>OR <br />Use Google Meet</div>
                    </div>}
                </div>

                <div>
                  <label>Service Name :</label>
                  <div className='row mb-3 border rounded p-3' >
                    <div className='col-12'>{currentData.Service_Name}</div>

                  </div>
                  <div className="select-wrapper">

                    <label htmlFor="">Time zone :</label>
                    <TimezoneSelect
                      className='bg-transparent'
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                    />

                  </div>
                  <div className='mt-4'>
                    <b>Date and Time :</b>{currentData.start_date} {currentData.start_time}
                  </div>

                  {/* <div className='row'>
                    <div className='col-lg-12 col-12'>
                      <div className="mb-3">
                        <div className="form-group">
                          <label htmlFor="">Date</label>
                          <input type="date" onChange={onChangeHandle}
                            className="form-control" name="startDate" value={data.startDate} placeholder="" />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-12'>
                      <div className="mb-3">
                        <div className="form-group">
                          <label htmlFor="">Start time</label>
                          <input type="time" onChange={onChangeHandle}
                            className="form-control" name="startTime" value={data.startTime} placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-12'>
                      <div className="mb-3">
                        <div className="form-group">
                          <label htmlFor="">End time</label>
                          <input type="time" onChange={onChangeHandle}
                            className="form-control" name="endTime" value={data.endTime} placeholder="" disabled />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {data.meetingLink !== '' ?

                    <div className='mt-3'>
                      <button className='btn btn-orange w-100' onClick={onClickSubmit}>Submit</button>
                    </div> :
                    <div className='mt-3'>
                      <button className='btn btn-orange w-100' onClick={onClickGoogleCalendarSubmit}>Google Calendar</button>
                    </div>}
                </div>
              </div>
            </div> :
            <div className='text-center fw-bold text-success p-5'>
              {successMsg}
            </div>}
        </ModalBody>
      </Modal>
      <Modal show={deletePopup}>
        <ModalBody>
          {successMsg === '' && errorMsg === '' ?
            <div>
              <div className='text-center my-3'>Are you sure? Do you want to delete?</div>
              <div className='text-center'>
                <button className='btn btn-secondary me-2' onClick={onClickNo}>No</button>
                <button className='btn btn-orange ms-2' onClick={onClickYes}>Yes</button>
              </div>
            </div>
            :
            <div className='d-flex justify-content-between'>
              {successMsg === '' ? '' : <div className=' text-success'>{successMsg}</div>}
              {errorMsg === '' ? '' : <div className='text-danger'>{errorMsg}</div>}
              <button className='btn btn-primary' onClick={onClickOK}>OK</button>
            </div>
          }
        </ModalBody>
      </Modal>
      {
        isLoading ? <Loader /> :

          <div className='my- myServicesMentorSec'>
            <div className='container-fluid d-flex justify-content-between my-3'>
              <div className='fw-bold Title'>My Services</div>
              <button className='btn btn-orange rounded-2 btnM' onClick={() => clickedOnCreateProfile()} >Create a New Service </button>

            </div>
            <div>
              {showServiceList()}

              {viewShowCount >= serviceList.length ? '' :
                <div className='text-center my-5'>
                  <button className='btn btn-orange' onClick={onClickShowMore}>Show More </button>
                </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default MyServices
