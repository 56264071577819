import React from 'react'
import './Homepage.scss'
import Sidebar from './../../../components/LearnersComponents/Side-bar/Sidebar';
import SupportPage from './../SupportPage/SupportPage';
import MyServicesPage from './../MyServicesPage/MyServicesPage';
import ProfilePage from './../Profile/ProfilePage';
import NotificationPage from './../NotificationPage/NotificationPage';
import DashboardPage from './../DashboardPage/DashboardPage';
import Error404Page from './../../../components/LearnersComponents/Error404Page/Error404Page';
import LogoutPage from './../LogoutPage/LogoutPage';
import AllCoursesProvided from '../AllCoursesprovided/AllCoursesProvided'
import Header from '../../../components/LearnersComponents/Header/Header';
import MessagePage from '../Message/MessagePage';
import ProgressReport from '../ProgressReport/ProgressReport';
import routeProtect from '../../../components/routerProtect';
import { Route, Routes, useNavigate } from 'react-router-dom';


export default function HomePage(props) {
let navigate = useNavigate()



  return (
    <>
      {!routeProtect() ? navigate('/') :
        <div className='mainpage-container'>
          <div className='container-xl container-fluid'>
            <div className='row'>
              <div className='col-3 d-xl-block d-none'>
                <Sidebar navigate={navigate} />
              </div>
              <div className='col-12 d-xl-none d-block p-0'>
                <Header navigate={navigate} />
              </div>
              <div className='col-xl-9 col-12 main-container'>
                <Routes>

                  <Route path='/logout' element={<LogoutPage />} />
                  <Route path='/supports' element={<SupportPage />} />
                  <Route path='/progressreport' element={<ProgressReport />} />
                  <Route path='/myservices' element={<MyServicesPage />} />
                  <Route path='/profile' element={<ProfilePage />} />
                  <Route path='/message' element={<MessagePage />} />
                  <Route path='/allcourses' element={<AllCoursesProvided />} />
                  <Route path='/notification' element={<NotificationPage />} />
                  {/* <Route path='/learner-home' element={<DashboardPage />} /> */}
                  <Route path='/' element={<DashboardPage />} />
                </Routes>
                  {/* <Route component={Error404Page} /> */}
                
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
