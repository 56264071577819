import React, { Fragment } from 'react'
import "./CardToElevateJourney.scss"
function CardToElevateJourney({ mentorsName , mentorsDescription , mentorsImage , sideBgColor}) {
  return (
    <Fragment>
                    <div className='col-lg-4 col-md-6 col-12 elevateJourneyCard my-2 '>
                    <div className="card justify-content-center bg-transparent position-relative" style={{ width: '100%', height:'200px' }}>
                        <div className={sideBgColor}></div>
                        <div className="card-body d-flex align-items-center p-2">
                            <img src={mentorsImage} alt="" width={80} height={80} />
                            <div className='card-text ms-4'>
                                <div className='fs-5 fw-bold'>{mentorsName} </div>
                                <div className="fs-6">{mentorsDescription}</div>
                            </div>
                        </div>
                    </div>
                </div>
    </Fragment>
  )
}
export default CardToElevateJourney
