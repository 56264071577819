import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import './DashboardPage.scss'
import bell_icon from '../../../assets/bell.png'
import card_top_img from '../../../assets/card-top-image.png'
import circle_add_icon from '../../../assets/circle_add_icon.png'
import booking_completed_icon from '../../../assets/booking-completed-icon.png'
import review_submited_icon from '../../../assets/reviews-submited-icon.png'
import total_earning_icon from '../../../assets/total-earning-icon.png'
import ServicesCard from './../../../components/MentorsComponents/ServicesCard.js/ServicesCard';
import IndustryArray from '../../../components/MentorsComponents/IndustryArray/IndustryArray'
import { useEffect } from 'react'
import apiservice from '../../../apiservice/ApiService'
import noImg from '../../../assets/no-image.png'
import Services from './../../LandingpagePages/Servicespage/Services';
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'

export default function DashboardPage(props) {
  let navigate = useNavigate()
  const initialData = {
    industryType: '',
    searchValue: ''
  }
  const [data, setData] = useState(initialData)
  const [activeMenu, setActiveMenu] = useState('All')
  let [userData, setUserData] = useState({})
  let [serviceData, setServiceData] = useState([])
  const [isProfileCompleted, setIsProfileCompleted] = useState(true)
  const [dashboardCount, setDashboardCount] = useState('')
  const [searchResult, setSetSearchResult] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  useEffect(() => {
    
    getdata()
    getDashboardCount()
    getNotificationCount()
  }, [])
  async function getdata() {
    let usrData = await apiservice.getLocalStorage('userData')
    // if(usrData === null || usrData === undefined){
    //   navigate('/')
    //   return false
    // }
    setUserData(usrData)
    console.log(usrData);
    if (usrData.name === null ||
      usrData.lname === null ||
      usrData.AboutSelf === null ||
      usrData.Birthdate === null ||
      usrData.workExperience === 0 ||
      usrData.certifications === 0 ||
      usrData.educations === 0 ||
      usrData.mobile === null ||
      usrData.HolderName === null ||
      usrData.AccountNumber === null ||
      usrData.BankName === null
    ) {
      setIsProfileCompleted(true)
    } else {
      setIsProfileCompleted(false)
    }
    try {
      setIsLoading(true)
      let allServices = await apiservice.apiCall('/Service', usrData.Token)
      console.log(allServices.data);
      if (allServices.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (allServices.data) {
        setIsLoading(false)
        setServiceData(allServices.data);
      }
      else {
        setIsLoading(false)
        console.log(allServices.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  }

  const getDashboardCount = async () => {
    setIsLoading(true)
    let usrData = await apiservice.getLocalStorage('userData')
    await apiservice.apiCall('/mentor_dashboard', usrData.Token).then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setDashboardCount(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const onChangeHandle = async (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }


  const onClickManu = (e) => {
    setActiveMenu(e)
  }

  // const myServiceList = () => {
  //   let list = []
  //   let count = 0
  //   for (let index = 0; index < serviceData.length; index++) {
  //     let serviceItem = serviceData[index]
  //     console.log(serviceItem);
  //     list.push(
  //       <div key={index} className='col-lg-4 col-12 py-3'>
  //         <ServicesCard
  //           card_top_img={serviceItem.ThumbnailImage}
  //           title={serviceItem.Service_Name}
  //           chapterName={`${userData.name} ${userData.lname}`}
  //           sessoinType={userData.MentorshipType}
  //           rating={4.3}
  //           duration={`${serviceItem.Hour}h ${serviceItem.Minutes}m `}
  //           totalLactures={'320 lectures'}
  //           pricing={'3,199'}
  //         />

  //       </div>
  //     )

  //   }
  //   return list
  // }

  const myServiceList = () => {
    let list = [];
    let count = 0;
    for (let index = 0; index < serviceData.length; index++) {
      let item = serviceData[index]
      if (activeMenu === '1:1 Single Session' && count < 3) {
        if (item.MentorshipType === '1:1 Single Session') {
          count = count + 1;
          console.log('Items in 1:1 single session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                mentorName={`${userData.name} ${userData.lname}`}
                discription={item.Service_Description}
                sessoinType={item.MentorshipType}
                rating={item.averageRating}
                duration={`${item.Hour}h ${item.Minutes}m`}
                totalLactures={item.NumberOfSession === null ? '1 lecture' : `${item.NumberOfSession} ${' '} lectures`}
                pricing={item.Price}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                timeZone={item.timezone}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === '1:1 Multi-Session' && count < 3) {
        if (item.MentorshipType === '1:1 Multi-Session') {
          count = count + 1;
          console.log('Items in 1:1 Multi-Session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                mentorName={`${userData.name} ${userData.lname}`}
                discription={item.Service_Description}
                sessoinType={item.MentorshipType}
                rating={item.averageRating}
                duration={`${item.Hour}h ${item.Minutes}m `}
                totalLactures={item.NumberOfSession === null ? '1 lecture' : `${item.NumberOfSession} ${' '} lectures`}
                pricing={item.Price}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                timeZone={item.timezone}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === 'Webinar Session' && count < 3) {
        if (item.MentorshipType === 'Webinar Session') {
          count = count + 1;
          console.log('Items in 1:1 Webinar Session', item);
          list.push(
            <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-4 col-12 p-3'>
              <ServicesCard
                card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
                title={item.Service_Name}
                mentorName={`${userData.name} ${userData.lname}`}
                discription={item.Service_Description}
                sessoinType={item.MentorshipType}
                rating={item.averageRating}
                duration={`${item.Hour}h ${item.Minutes}m `}
                totalLactures={item.NumberOfSession === null ? '1 lecture' : `${item.NumberOfSession} ${' '} lectures`}
                pricing={item.Price}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                timeZone={item.timezone}
                currencySymbol={item.CurrencySymbol}
              />
            </div>
          )
        }
      }
      else if (activeMenu === 'All' && count < 3) {
        count = count + 1;
        console.log('Items in 1:1 All', item);

        list.push(
          <div key={index} onClick={() => clickedOnSingleService(item)} style={{ cursor: 'pointer' }} className='col-md-4 col-12 p-3'>
            <ServicesCard
              card_top_img={item.ThumbnailImage === null ? noImg : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`}
              title={item.Service_Name}
              mentorName={`${userData.name} ${userData.lname}`}
              discription={item.Service_Description}
              sessoinType={item.MentorshipType}
              rating={item.averageRating}
              duration={`${item.Hour}h ${item.Minutes}m `}
              totalLactures={item.NumberOfSession === null ? '1 lecture' : `${item.NumberOfSession} ${' '} lectures`}
              pricing={item.Price}
              webinarDate={item.start_date}
                webinarTime={item.start_time}
              timeZone={item.timezone}
              currencySymbol={item.CurrencySymbol}
            />

          </div>
        )

      }
    }
    return list
  }


  async function clickedOnSingleService(item) {
    console.log(item);
    await apiservice.setLocalStorage('mentorData', userData)
    await apiservice.setLocalStorage('serviceData', item.Service_id)
    await apiservice.setLocalStorage('fromServices', false)
    navigate(`/contetexpert-home/previewservice`)


  }



  const onClickSearchBtn = async () => {
    setIsLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    let dataObject = {
      industry: data.industryType,
      search: data.searchValue
    }
    console.log(dataObject);
    await apiservice.apiCall('/Service', uData.Token, dataObject, 'POST').then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setServiceData(res.data)
      }
      else {
        setIsLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const onClickBottomCard = (url) => {
    if(url === '/contetexpert-home/myservices'){
      localStorage.setItem('currentServiceTab','Completed')
    }
    navigate(url)
  }

  const getNotificationCount = async () => {
    let userData = await apiservice.getLocalStorage('userData')
    await apiservice.apiCall('/mentor_notifications_count', userData.Token)
      .then(res => {
        if (res.unAuthenticated) {
          navigate('/')
          localStorage.removeItem('userData')
        } else if (res.data) {
          console.log(res.data);
          setNotificationCount(res.data.notificationCount)
        } else {
          console.log(res);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const onClickExploreAll = () => {
    navigate('/contetexpert-home/servicelist')
    localStorage.setItem('curItem', '/contetexpert-home/servicelist')
  }

  return (
    <>{isLoading ?
      <Loader /> :
      <div className='dashboard-mainpage p-3'>
        <div className='container-fluid'>
          {isProfileCompleted ?
            <div className='col-lg-3 col-12 text-center pt-2 mb-3'>
              <button className='btn btn-orange completebtnHover w-100 rounded-4' onClick={() => navigate('/contetexpert-home/profile')}>Complete Profile  </button>
            </div> : ''}

          <div className='mt-3'>
            {console.log(isProfileCompleted)}
            <div className='row'>
              <div className='col-lg-10 col-12 d-none d-lg-block'>
                <div className="input-group mb-3 border rounded-3 border-dark ">
                  <span className="input-group-text border-0 p-0 bg-transparent">
                    <div className="form-group mt-2 mt-lg-0" >
                      <select className="form-select bg-transparent border-0 border-end rounded-0 searchInput" onChange={onChangeHandle}
                        name="industryType" value={data.industryType} style={{ width: '250px' }}>
                        <option value='All'>Industry </option>

                        {IndustryArray().map((data, i) =>

                          <option key={i} value={data.name}>{data.name}</option>
                        )}
                      </select>
                      {/* <button className="btn  dropdown-toggle border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Services <img src={Alt_Arrow_Down} alt='..' />
                      </button> */}
                      {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div><div className="dropdown-item">Action</div></div>
                        <div><div className="dropdown-item">Another action</div></div>
                        <div><div className="dropdown-item">Something else here</div></div>
                      </ul> */}
                    </div>
                  </span>
                  <input type="text" onChange={onChangeHandle}
                    className="form-control border-0 bg-transparent searchInput" name='searchValue' value={data.searchValue} placeholder='Search' />
                  <span className="input-group-text border-0 bg-transparent d-lg-block d-none">
                    <button className='btn btn-search-btn-blue ' onClick={onClickSearchBtn}><FontAwesomeIcon icon={faSearch} className='' /></button>
                  </span>
                  <span className="input-group-text border-0 bg-transparent   d-lg-none d-block">
                    <button className='btn btn-search-btn-blue ' onClick={onClickSearchBtn}><FontAwesomeIcon icon={faSearch} className='' /> </button>
                  </span>
                </div>
              </div>
              <div className='col-lg-10 col-12 d-lg-none d-block'>
                <div className='row serachInput'>
                  <div className="input-group mb-3 border rounded-3 border-dark ">
                    <div className='col-6 col-12'>

                      <span className="input-group-text p-0 bg-transparent mt-2">
                        <div className="form-group mt-2 mt-lg-0">
                          <select className="form-select bg-transparent border-0 border-end rounded-0" onChange={onChangeHandle}
                            name="industryType" value={data.industryType}>
                            <option value='All'>Industry </option>

                            {IndustryArray().map((data, i) =>

                              <option key={i} value={data.name}>{data.name}</option>
                            )}
                          </select>
                        </div>
                      </span>
                      <div className='col-6 col-12'>
                        <div className='d-flex mt-2'>
                          <input type="text" onChange={onChangeHandle}
                            className="form-control border-0 border-bottom bg-transparent searchInput mb-2" name='searchValue' value={data.searchValue} placeholder='Search' />
                          <span className="input-group-text border-0 bg-transparent">
                            <button className='btn btn-search-btn-blue' onClick={onClickSearchBtn}><FontAwesomeIcon icon={faSearch} /> </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="input-group mb-3 border rounded-3 border-dark ">
                    <span className="input-group-text border-0 p-0 bg-transparent">
                      <div className="form-group mt-2 mt-lg-0">
                        <select className="form-select bg-transparent border-0 border-end rounded-0 searchInput" onChange={onChangeHandle}
                          name="industryType" value={data.industryType}>
                          <option value='All'>Industry </option>

                          {IndustryArray().map((data, i) =>

                            <option key={i} value={data.name}>{data.name}</option>
                          )}
                        </select> */}
                {/* <button className="btn  dropdown-toggle border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Services <img src={Alt_Arrow_Down} alt='..' />
                      </button> */}
                {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div><div className="dropdown-item">Action</div></div>
                        <div><div className="dropdown-item">Another action</div></div>
                        <div><div className="dropdown-item">Something else here</div></div>
                      </ul> */}

                {/* </span>
                    <input type="text" onChange={onChangeHandle}
                      className="form-control border-0 bg-transparent searchInput" name='searchValue' value={data.searchValue} placeholder='Search' /> */}
                {/* <span className="input-group-text border-0 bg-transparent d-lg-block d-none">
                    <button className='btn btn-search-btn-blue ' onClick={onClickSearchBtn}><FontAwesomeIcon icon={faSearch} className='' /></button>
                  </span> */}
                {/* <span className="input-group-text border-0 bg-transparent">
                  <button className='btn btn-search-btn-blue ' onClick={onClickSearchBtn}><FontAwesomeIcon icon={faSearch} className='' /> </button>
                </span>
              </div> */}
              </div>
              <div className='col-lg-2 col-12'>
                <div className='notification-icon-conntainer text-center pt-2 d-lg-block d-none position-relative pointer' onClick={() => navigate('/contetexpert-home/notification')}>
                  <div className='position-absolute top-0 end-0 me-2'><div className='badge p-1 bg-danger' style={{ fontSize: '10px' }}>{notificationCount}</div></div>
                  <img src={bell_icon} alt='...' className='mt-1' />
                </div>
              </div>
            </div>

          </div>

          <div className='mt-3'>
            <div className='fw-bold Title'>My Services</div>

            <div className='mt-3'>
              {serviceData.length > 0 ?
                <div className=''>
                  <div className='d-lg-flex justify-content-between'>
                    <div className='d-md-block d-none'>
                      <div className='d-flex'>
                        <div className={activeMenu === 'All' ? 'px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'px-3 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('All')} >All</div>
                        <div className={activeMenu === '1:1 Single Session' ? 'px-3 py-2 mx-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'px-3 py-2 mx-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Single Session')} >1:1 Single Session</div>
                        <div className={activeMenu === '1:1 Multi-Session' ? 'px-3 py-2 me-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'px-3 py-2 me-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Multi-Session')} >1:1 Multi-Session</div>
                        <div className={activeMenu === 'Webinar Session' ? 'px-5 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'px-5 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('Webinar Session')} >Webinar </div>
                      </div>
                    </div>
                    <div className='d-md-none d-block'>
                      <div className='row'>
                        <div className='p-1  col-6'>
                          <div className={activeMenu === 'All' ? 'col-12 px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-3 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('All')} >All</div>
                        </div>
                        <div className='p-1  col-6'>
                          <div className={activeMenu === '1:1 Single Session' ? 'col-12 px-1 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-1  py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Single Session')} >1:1 Single Session</div>
                        </div>
                        <div className='p-1  col-6'>
                          <div className={activeMenu === '1:1 Multi-Session' ? 'col-12 px-2 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-2 py-2  border rounded-2 btnHover border-dark'} onClick={() => onClickManu('1:1 Multi-Session')} >1:1 Multi-Session</div>
                        </div>
                        <div className='p-1  col-6'>
                          <div className={activeMenu === 'Webinar Session' ? 'col-12 px-3 py-2 border rounded-2 btnHover text-light bg-dark border-dark' : 'col-12 px-3 py-2 border rounded-2 btnHover border-dark'} onClick={() => onClickManu('Webinar Session')} >Webinar </div>
                        </div>
                      </div>
                    </div>
                    <div className='my-lg-0 my-3 mt-3 fw-bold text-end ' onClick={onClickExploreAll} style={{ cursor: 'pointer' }}> Explore All Services <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></div>
                  </div>

                  <div className='mt-3'>
                    <div className='row'>
                      {myServiceList()}

                    </div>
                  </div>
                </div> :
                <div className='my-5 text-danger'>
                 Maximize your profile's impact by adding services. Showcase your expertise and attract bookings. Promote your services within your network for even greater visibility. 
                </div>}

              <div className='mt-3'>
                <div className='col-lg-4 col-12 pointer text-center p-3 rounded-3 new-service-button' onClick={() => { localStorage.removeItem('currentService'); navigate('/contetexpert-home/createnewservices') }}>
                  <img src={circle_add_icon} alt='...' />
                  <div className='fw-bold '>Create a New Service</div>
                </div>
              </div>

              <div className='mt-3'>
                <div className='fw-bold Title'>My Dashboard</div>
                <div className='mt-3'>
                  <div className='row' style={{ height: 'max-content' }}>
                    <div className='col-md-6 col-lg-4 col-12 p-2 h-100'>
                      <div className='d-flex px-3 py-4 bg-light pointer' onClick={() => onClickBottomCard('/contetexpert-home/myservices')}>
                        <div className='dashboard-card-circle text-center pt-3 ' style={{ backgroundColor: 'rgba(253, 133, 58, 0.5)' }}>
                          <img src={booking_completed_icon} alt='' width={30} />
                        </div>
                        <div className='pt-2 ps-3'>
                          <div className='fs-5 fw-bold'>{dashboardCount.booking_completed}</div>
                          <div>Bookings Completed</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-12 p-2 h-100'>
                      <div className='d-flex px-3 py-4 bg-light pointer' onClick={() => onClickBottomCard('/contetexpert-home/myreviews')}>
                        <div className='dashboard-card-circle text-center pt-3' style={{ backgroundColor: 'rgba(253, 111, 142, 0.5)' }}>
                          <img src={review_submited_icon} alt='' width={30} />
                        </div>
                        <div className='pt-2 ps-3'>
                          <div className='fs-5 fw-bold'>{dashboardCount.total_reviews}</div>
                          <div>Reviews Submitted</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-12 p-2 h-100'>
                      <div className='d-flex px-3 py-4 bg-light pointer' onClick={() => onClickBottomCard('/contetexpert-home/payments')}>
                        <div className='dashboard-card-circle text-center pt-3' style={{ backgroundColor: 'rgba(71, 205, 137, 0.5)' }}>
                          <img src={total_earning_icon} alt='' width={'30px'} />
                        </div>
                        <div className='pt-2 ps-3'>
                          <div className='fs-5 fw-bold'> {userData.CurrencySymbol} {Number(dashboardCount.total_earning).toFixed(2)}</div>
                          <div>Total Earning</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >}
    </>
  )
}
