import React, { useEffect } from 'react'
import "./LearnerListForSchedule.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import { Modal, ModalBody, Pagination } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function ScheduledLearner(props) {
    let navigate = useNavigate()
    const initialData = {
        startDate: '',
        endDate: '',
        completedText: '',
        isCompleted: 'Yes',
        openCompletedFormPopup: false
    }

    const [data, setData] = useState(initialData)

    const [myLearners, setMyLearners] = useState([])
    const [chapterList, setChapterList] = useState([])
    const [showSessionList, setShowSessionList] = useState(false)
    const [bookingData, setBookingData] = useState([])
    const [completedSession, setCompletedSession] = useState([])
    const [completedMessage, setCompletedMessage] = useState('')
    const [currentLearner, setCurrentLearner] = useState('')
    const [sucessMessage, SetSuccessMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        getAlldata()
    }, [])
    const inputHandleChange = (e) => {
        console.log(e.target.name, e.target.value);
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    async function getAlldata() {
        setIsLoading(true)
        let id = props.id
        let uData = await apiservice.getLocalStorage('userData')
        await apiservice.apiCall('/BookServiceScheduledLearnerList', uData.Token, { service_id: id }, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                let datas = res.data
                console.log(datas);
                setMyLearners(datas)

            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }
    const onClickLearnerCard = async (data) => {
        console.log(data);
        localStorage.setItem('isSchedulePage', true)

        let sid = props.id
        localStorage.setItem('scheduleServiceid', sid)
        let learnerId = data.users.id
        await apiservice.setLocalStorage('learnerId', learnerId)
        console.log(learnerId);
        navigate(`/contetexpert-home/learnerprofile/${learnerId}`)

    }

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(myLearners.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = myLearners.slice(startIndex, endIndex);



    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const learnerList = () => {
        let array = itemsToDisplay
        console.log(array);
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let item = array[index]

            list.push(
                <div className='col-md-4 '
                    key={index}
                >
                    <div className='col-12 learnerCardMentors my-2'>
                        <div className="card" >
                            <div className={`cardBg${count}`}>
                            </div>
                            <div className="card-body p-2">
                                <div className="  learner_icon " >
                                    <div className='d-flex justify-content-center'>
                                        <div className=' rounded-circle overflow-hidden ms-2' style={{ width: '70px', height: '70px' }}>
                                            <img src={item.users.ProfileImage === null ? item.users.SocialImage === null ? item.users.profile_photo_url : item.users.SocialImage : `${apiservice.apiImageShow()}/${item.users.ProfileImage}`} className='' alt="" width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div className='card-text  m-2 text-center'>
                                        <div className=' fw-bold'>{item.users.name ? `${item.users.name} ${item.users.lname}` : 'Learner'} </div>
                                    </div>
                                    <div className='text-center px-3'>
                                        <div className=''>
                                            <div className='w-100 btn btn-orange-border' onClick={() => onClickLearnerCard(item)}>View Details</div>
                                        </div>
                                        <div className='mt-3'>
                                            {item.MentorshipType === 'Webinar Session' ?
                                                <div className='w-100 btn btn-orange' onClick={() => openSessionWebinarJoin(item)}>Join Session</div> :
                                                <div className='w-100 btn btn-orange' onClick={() => openSessionPopup(item)}>Join Session</div>}
                                        </div>
                                        <div className='mt-3'>
                                            <div className='w-100 btn btn-orange' onClick={() => openPopUp(item)}>Completed</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

            if (count === 5) {
                count = 0
            } else {
                count++
            }
        }
        return list
    }

    const onCLickJoinBtn = async (ele) => {

        console.log(ele)
        onClickOpenNewTab(ele.session_link)

        // setIsLoading(true)
        // console.log(bookingData);
        // let dataObject = {
        //     service_id: bookingData.ServiceId,
        //     book_services_id: bookingData.Book_Service_Id,
        //     session_number: index + 1,
        // }
        // console.log(dataObject);
        // let uData = await apiservice.getLocalStorage('userData')

        // await apiservice.apiCall('/MentorJoinSession', uData.Token, dataObject, 'POST').then(res => {
        //     if (res.unAuthenticated) {
        //         navigate('/')
        //         localStorage.removeItem('userData')
        //     } else if (res.data) {
        //         setIsLoading(false)
        //         console.log(res.data);
        //         setShowSessionList(false)
        //         let datas = res.data
        //         let url = `${apiservice.backendOrigin()}/meeting/index.php?name=${uData.name} ${uData.lname}&meeting_number=${datas.session_code}&meeting_pwd=${datas.session_password}&role=${1}&booking_id=${bookingData.Book_Service_Id}`
        //         // setMeetingData(res.data)
        //         // createZoomMeeting(res.data)
        //     }
        //     else {
        //         setIsLoading(false)
        //         console.log(res.error);
        //     }
        // }).catch(err => {
        //     setIsLoading(false)
        //     console.log(err);
        // })
    }
    const onClickOpenNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer')
    }
const openSessionWebinarJoin = (data) =>{
    console.log(data);
    let url = data.chapter_array[0].session_link
    onClickOpenNewTab(url)
}
    const openSessionPopup = (data) => {
        console.log(data);
        setBookingData(data)
        console.log(data.chapter_array);
        setShowSessionList(true)
        setChapterList(data.chapter_array)
    }
    const closeSessionPopup = () => {
        setShowSessionList(false)

    }
    const listOfChapter = () => {
        let list = []
        let dataArr = chapterList
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            console.log(element);
            list.push(

                <div className='' key={index}>
                    <div class="row border-bottom p-3">
                        <div class="col-lg-8 d-flex justify-content-between">
                            <div>{element.chapterTitle}</div>
                            <div class="d-flex"></div>
                        </div>
                        <div class="col-lg-2 text-end">
                            <span class="badge bg-primary">
                                {element.sessionDuration} {element.sessionDuration === "1" ? "hr" : 'min'}
                            </span>
                        </div>
                        <div class="col-lg-2  text-end">
                            {element.status === 0 ?
                                <div className='ms-3'>
                                    <button className='btn btn-orange' onClick={() => onCLickJoinBtn(element)}>Join</button>
                                </div>
                                :
                                element.status === 1 ?
                                    <div className='badge text-success border'>Completed</div>
                                    :
                                    <div className='badge text-dark border'>Pending</div>}
                        </div>
                    </div>


                </div>
            )

        }
        return list
    }
    console.log(chapterList);
    const openPopUp = async (ele) => {
        setIsLoading(true)
        console.log(ele);
        setCurrentLearner(ele)
        let dataObject = {
            service_id: ele.ServiceId,
            book_services_id: ele.Book_Service_Id,
            learner_id: ele.users.id
        }
        let uData = await apiservice.getLocalStorage('userData')
        await apiservice.apiCall('/MentorCheckServiceCompletedStatus', uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            }
            else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                let responseData = res.data
                console.log('sdate', responseData.session_start_date, 'edate', responseData.session_end_date);
                let start_date = moment(responseData.session_start_date).format('YYYY-MM-DD')
                let end_date = moment(responseData.session_end_date).format('YYYY-MM-DD')
                setData({ ...data, 'startDate': start_date, 'endDate': end_date, 'openCompletedFormPopup': true })
                setCompletedSession(res.data)
                setCompletedMessage('')
            }
            else {
                setIsLoading(false)
                console.log(res.error);
                setData({ ...data, 'openCompletedFormPopup': true })
                setCompletedMessage(res.error)
                setCompletedSession([])
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }
    console.log(data, '------------- data -------------');
    const closePopUp = () => {
        setData(initialData)

    }
    const onClickSubmit = async () => {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData')
        let dataObejct = {
            service_id: currentLearner.ServiceId,
            book_services_id: currentLearner.Book_Service_Id,
            learner_id: currentLearner.users.id,
            completed_comments: data.completedText,
            started_at: data.startDate,
            completed_at: data.endDate
        }
        console.log(dataObejct);
        await apiservice.apiCall('/MentorUpdateServiceCompletedStatus', uData.Token, dataObejct, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            }
            else if (res.data) {
                getAlldata()
                setIsLoading(false)
                console.log(res.data);
                SetSuccessMessage(true)
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }

    const onClickOkButton = () => {
        SetSuccessMessage(false)
        setData({ ...data, openCompletedFormPopup: false })
    }
    return (
        <>
            <Modal show={data.openCompletedFormPopup} onHide={closePopUp} >
                <ModalBody>

                    {completedMessage !== '' ?
                        <div className='p-3'>
                            <div className='text-danger text-center'>
                                {completedMessage}
                            </div>

                            <div className='mt-3'>
                                <button className='btn btn-secondary w-100' onClick={closePopUp}>Cancel</button>
                            </div>
                        </div>
                        :
                        <div className='p-3'>
                            {sucessMessage ?
                                <div className='p-3'>
                                    <div className=' text-center text-success'>The service completion request has been successfully submitted.</div>
                                    <div className='mt-3 text-center'>
                                        <button className='btn btn-orange' onClick={onClickOkButton}>OK</button>
                                    </div>
                                </div> :
                                <div>

                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >Start date</label>
                                                    <input type="date"
                                                        className="form-control" name="startDate" value={data.startDate} onChange={inputHandleChange} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >End date</label>
                                                    <input type="date"
                                                        className="form-control" name="endDate" value={data.endDate} onChange={inputHandleChange} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className="form-group">
                                            <label >How is it Completed ?</label>
                                            <textarea className="form-control" name="completedText" value={data.completedText} rows="5" onChange={inputHandleChange} placeholder='Text here'></textarea>
                                        </div>
                                    </div>
                                    {currentLearner.learner_completed_status === 0 ?
                                        <div className='mb-3 d-flex justify-content-center'>
                                            <button className='btn border w-50 btn-grey' onClick={closePopUp}> Cancel</button>
                                            <button className='btn btn-orange ms-3 w-50' onClick={onClickSubmit}> Submit</button>
                                        </div> :
                                        <div className='mb-3'>
                                            <button className='btn btn-orange w-100' onClick={onClickSubmit}>Accept</button>
                                        </div>
                                    }
                                </div>}
                        </div>}
                </ModalBody>
            </Modal>
            <Modal show={showSessionList} size='lg' style={{ marginTop: '80px' }}>
                <ModalBody> <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -8, right: -6 }} onClick={closeSessionPopup}><FontAwesomeIcon icon={faTimes} /></div>
                    <div className='p-3'>
                        <div className='mb-3 fw-bold' >
                            {bookingData.service_name}
                        </div>
                        <hr />
                        {listOfChapter()}
                    </div>
                </ModalBody>
            </Modal>
            {isLoading ? <Loader /> : <div>
                {myLearners.length > 0 ?
                    <div className='mt-3'>
                        <div className=' row'>
                            {learnerList()}
                        </div>
                        <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination>
                                <Pagination.Prev
                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1 ? true : false}
                                >
                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                </Pagination.Prev>
                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                    <Pagination.Item
                                        key={pageRangeStart + offset}
                                        active={pageRangeStart + offset === currentPage}
                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                    >
                                        {pageRangeStart + offset}
                                    </Pagination.Item>
                                ))}
                                {pageRangeEnd < totalPages && (
                                    <Pagination.Ellipsis disabled />
                                )}
                                <Pagination.Next
                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages ? true : false}
                                >
                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                </Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='fw-bold text-center mt-5 text-danger'>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
                    </div>

                }
            </div>}
        </>
    )
}

export default ScheduledLearner
