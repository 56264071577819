import React from 'react'
import { useState } from 'react';
import './MyServicesPage.scss'
import cardImage from '../../../assets/card-top-image.png'
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody } from 'react-bootstrap';
import { useEffect } from 'react';
import apiservice from '../../../apiservice/ApiService';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
function MyServicesPage(props) {
  let navigate = useNavigate()
  const initialData = {
    startDate: '',
    endDate: '',
    completedText: '',
    isCompleted: 'Yes',
    openCompletedFormPopup: false
  }

  const [data, setData] = useState(initialData)
  let [services, setServices] = useState([])
  const [menuKey, setMenuKey] = useState('On-Going');
  let [fetchServices, setFetchservices] = useState([])
  let [isLoading, setLoading] = useState(false)

  const [chapterList, setChapterList] = useState([])
  const [showSessionList, setShowSessionList] = useState(false)
  const [bookingData, setBookingData] = useState([])

  const [currentServicedata, seturrentServicedata] = useState('')

  const [completedSession, setCompletedSession] = useState([])
  const [completedMessage, setCompletedMessage] = useState('')

  const [sucessMessage, SetSuccessMessage] = useState(false)
  async function getReqData() {
    let uData = await apiservice.getLocalStorage('userData')
    console.log(uData);
    try {
      setLoading(true)
      let learnerServices = await apiservice.apiCall('/BookServicedata', uData.Token)
      if (learnerServices.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (learnerServices.data) {
        console.log(learnerServices.data);
        setServices(learnerServices.data)
        setLoading(false)

      }
      else {
        console.log(learnerServices.error);
        setLoading(false)

      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }
  useEffect(() => {
    getReqData()

  }, [])

  const inputHandleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const onClickTopMenu = (e) => {
    setMenuKey(e)
    console.log(e);
  }
  const openPopUp = async (ele) => {
    setLoading(true)
    console.log(ele);
    seturrentServicedata(ele)
    let dataObject = {
      service_id: ele.ServiceId,
      book_services_id: ele.Book_Service_Id,
      content_experts_id: ele.contentexpid
    }
    let uData = await apiservice.getLocalStorage('userData')
    console.log(uData);
    await apiservice.apiCall('/LearnerCheckServiceCompletedStatus', uData.Token, dataObject, 'POST').then(res => {
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      }
      else if (res.data) {
        setLoading(false)
        console.log(res.data);
        let responseData = res.data
        console.log('sdate', responseData.session_start_date, 'edate', responseData.session_end_date);
        let start_date = moment(responseData.session_start_date).format('YYYY-MM-DD')
        let end_date = moment(responseData.session_end_date).format('YYYY-MM-DD')
        setData({ ...data, 'startDate': start_date, 'endDate': end_date, 'openCompletedFormPopup': true })
        setCompletedSession(res.data)
        setCompletedMessage('')
      }
      else {
        setLoading(false)
        console.log(res.error);
        setData({ ...data, 'openCompletedFormPopup': true })
        setCompletedMessage(res.error)
        setCompletedSession([])
      }
    }).catch(err => {
      setLoading(false)
      console.log(err);
    })

  }
  const closePopUp = () => {
    setData(initialData)

  }
  const onClickSubmit = async () => {
    setLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    let dataObejct = {
      service_id: currentServicedata.ServiceId,
      book_services_id: currentServicedata.Book_Service_Id,
      content_experts_id: currentServicedata.contentexpid,
      completed_comments: data.completedText,
      started_at: data.startDate,
      completed_at: data.endDate
    }
    console.log(dataObejct);
    await apiservice.apiCall('/UpdateServiceCompletedStatus', uData.Token, dataObejct, 'POST').then(res => {

      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      }
      else if (res.data) {
        setLoading(false)
        console.log(res.data);
        getReqData()
        SetSuccessMessage(true)
      }
      else {
        setLoading(false)
        console.log(res.error);
      }
    }).catch(err => {
      setLoading(false)
      console.log(err);
    })
  }


  function loadCompletedServices() {
    let list = [];
    let fetchitems;
    if (menuKey === 'Completed') {
      fetchitems = services.filter((value, index) => value.Status === 'Completed')
      console.log(fetchitems);
    }

    if (fetchitems.length > 0) {


      for (let i = 0; i < fetchitems.length; i++) {
        let item = fetchitems[i]
        console.log(item);
        list.push(
          <div key={i} >


            <div className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-lg-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100 mb-2' height={150} alt='...' />
                  </div>
                  <div className='col-lg-6 col-12  h-100'>
                    <div className=''>
                      <div className='fw-bold title-text-wrap'>{item.service.Service_Name}</div>
                      <small>{item.content_expert.name} {item.content_expert.lname} <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                      <div>
                        <StarRatings
                          rating={Number(item.service.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.service.averageRating === 0 ? 0 : Number(item.service.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between align-items-end h-100'>
                        <div>
                          <div>{item.service.Hour}h{item.service.Minutes}m <span> &#9679; </span>{JSON.parse(item.service.Chapter).length} lectures </div>
                          {item.service.MentorshipType === 'Webinar Session' ? item.service.start_date === null ? '' :
                            <div>Date and Time : {item.service.start_date} {item.service.start_time}</div> : ''}
                          <div className='fw-bold'>{item.service.CurrencySymbol}{Number(item.service.Price).toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold me-1'>Start Date :</span> {item.BookingDate} </div>
                    <div><span className='fw-bold me-1'>Finish Date :</span>{item.EndDate}</div>

                    <div className='mt-3'>
                      <button className='btn btn-orange w-100 rounded-2 btnM' onClick={() => navigate(`/servicedetail/${item.ServiceId}`)} >View Details </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        )
      }
    } else {
      list.push(
        <div className='text-center text-danger mt-5 p-3'>You haven’t completed any services yet! Once you have completed all sessions, hit "Completed" to wrap things up. We'll give your XperThrivr progress report a shiny update!</div>
      )
    }

    return list
  }
  function loadUpcomingservices() {
    let list = [];
    let fetchitems;
    if (menuKey === 'Upcoming') {
      fetchitems = services.filter((value, index) => value.Status === 'Upcoming')
      console.log(fetchitems);
    }
    if (fetchitems.length > 0) {

      for (let i = 0; i < fetchitems.length; i++) {
        let item = fetchitems[i]
        console.log(item);
        list.push(
          <div key={i} >

            <div className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-lg-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100 mb-2' height={150} alt='...' />
                  </div>
                  <div className='col-lg-6 col-12  h-100'>
                    <div className=''>
                      <div className='fw-bold title-text-wrap'>{item.service.Service_Name}</div>
                      <small>{item.content_expert.name} {item.content_expert.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                      <div>
                        <StarRatings
                          rating={Number(item.service.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.service.averageRating === 0 ? 0 : Number(item.service.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between align-items-end h-100'>
                        <div>
                          <div><b>Total time:</b> {item.service.Hour}h {item.service.Minutes}m

                            {item.service.MentorshipType === '1:1 Multi-Session' ?
                              <span> &#9679;  {JSON.parse(item.service.Chapter).length} Session</span> : ''}
                          </div>
                          {item.service.MentorshipType === 'Webinar Session' ? item.service.start_date === null ? '' :
                            <div>Date and Time :{item.service.start_date} {item.service.start_time}</div> : ''}
                          <div className='fw-bold'>{item.service.CurrencySymbol}{Number(item.service.Price).toFixed(2)}</div>
                        </div>
                        {/* <div>
                          <button className='btn btn-orange w-100 rounded-2' >Join Session </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12' style={{ fontSize: '14px', alignSelf: 'start' }}>
                    <div><span className='fw-bold me-1'>Start Date :</span> {item.BookingDate}</div>
                    {/* <div className='mt-4'>
                      <button className='btn btn-orange w-100 rounded-2' onClick={openPopUp}>Completed <FontAwesomeIcon className='ms-3' icon={faArrowRight} /> </button>
                    </div> */}
                    <div className='mt-3' >
                      <button className='btn btn-orange w-100 rounded-2' onClick={() => navigate(`/servicedetail/${item.ServiceId}`)}>View Details  </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }
    } else {
      list.push(
        <div className='text-danger text-center mt-5 p-3'>No sessions are currently upcoming. To begin, choose your industry to explore the service offerings of content experts ready to help you achieve your goals! Once booked, it will be shown here!   </div>
      )
    }
    return list

  }
  function loadBookedOnGoingServices() {
    let list = [];
    let fetchitems;
    if (menuKey === 'On-Going') {
      fetchitems = services.filter((value, index) => value.Status === 'Ongoing')
      console.log(fetchitems);
    }
    if (fetchitems.length > 0) {

      for (let i = 0; i < fetchitems.length; i++) {
        let item = fetchitems[i]
        // console.log(item);
        list.push(
          <div key={i} >

            <div className='mt-3'>
              <div className='p-3 border rounded-2'>
                <div className='row'>
                  <div className='col-md-3 col-12'>
                    <img src={`${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100 ' height={150} alt='...' />
                  </div>
                  <div className='col-md-6 col-12  h-100'>
                    <div className='d-none d-lg-block'>
                      <div className='fw-bold title-text-wrap mt-2'>{item.service.Service_Name}</div>
                      <small>{item.content_expert.name} {item.content_expert.lname}  <span className='badge text-dark ms-2 mb-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                      <div>
                        <StarRatings
                          rating={Number(item.service.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.service.averageRating === 0 ? 0 : Number(item.service.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between  h-100'>
                        <div>
                          <div>{item.service.Hour}h{item.service.Minutes}m <span> &#9679; </span>{JSON.parse(item.service.Chapter).length} lectures </div>
                          {item.service.MentorshipType === 'Webinar Session' ? item.service.start_date === null ? '' :
                            <div>Date and Time : {item.service.start_date} {item.service.start_time}</div> : ''}
                          <div className='fw-bold'>{item.service.CurrencySymbol}{Number(item.service.Price).toFixed(2)}</div>
                        </div>
                        {item.service.MentorshipType === 'Webinar Session'
                          ?
                          <div className='webBtnLpy-3 pt-1  '>
                            <button className='btn btn-orange w-100 rounded-2 mt-2' onClick={() => onCLickJoinWebinarBtn(item)}>Join Session </button>
                          </div>
                          :
                          <div className='webBtnL pt-1'>
                            <button className='btn btn-orange w-100 rounded-2 mt-2 ' onClick={() => openSessionPopup(item)}>Join Session </button>
                          </div>
                        }

                      </div>
                    </div>
                    <div className='d-lg-none d-block'>
                      <div className='fw-bold title-text-wrap mt-2'>{item.service.Service_Name}</div>
                      <small>{item.content_expert.name} {item.content_expert.lname}  </small>
                      <div className='d-flex justify-content-between pt-2'>
                        <span className='badge text-dark mb-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span>
                        {item.service.MentorshipType === 'Webinar Session'
                          ?
                          <div className='webBtnL'>
                            <button className='btn btn-orange w-100 rounded-2' onClick={() => onCLickJoinWebinarBtn(item)}>Join Session </button>
                          </div>
                          :
                          <div className='webBtnL'>
                            <button className='btn btn-orange w-100 rounded-2' onClick={() => openSessionPopup(item)}>Join Session </button>
                          </div>
                        }
                      </div>

                      <div>
                        <StarRatings
                          rating={Number(item.service.averageRating)}
                          starRatedColor="#FDB022"
                          numberOfStars={5}
                        /> <span className='ps-2'>{item.service.averageRating === 0 ? 0 : Number(item.service.averageRating).toFixed(1)}</span>
                      </div>
                      <div className='d-flex justify-content-between align-items-end h-100'>
                        <div>
                          <div>{item.service.Hour}h{item.service.Minutes}m <span> &#9679; </span>{JSON.parse(item.service.Chapter).length} lectures </div>
                          {item.service.MentorshipType === 'Webinar Session' ? item.service.start_date === null ? '' :
                            <div>Date and Time :{item.service.start_date} {item.service.start_time}</div> : ''}
                          <div className='fw-bold'>{item.service.CurrencySymbol}{Number(item.service.Price).toFixed(2)}</div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-12' style={{ fontSize: '14px' }}>
                    <div><span className='fw-bold me-1'>Start Date :</span> {item.BookingDate}</div>
                    <div className='mt-4'>
                      <button className='btn btn-orange w-100 rounded-2 btnMS' onClick={() => openPopUp(item)}>Completed </button>
                    </div>
                    <div className='mt-3'>
                      <button className='btn btn-orange w-100 rounded-2 btnMS' onClick={() => navigate(`/servicedetail/${item.ServiceId}`)}>View Details </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      list.push(
        <div className='text-danger text-center mt-5 p-3'>No sessions are currently scheduled by the Content Expert. To begin, you need to book your service and coordinate with the Content Expert via chat to schedule sessions according to your preferred time. Once this coordination is made, the service will be categorized as "Ongoing."</div>
      )
    }
    return list;
  }
  const openSessionPopup = (data) => {
    console.log(data);
    console.log(data.chapter_array);
    setBookingData(data)
    setShowSessionList(true)
    setChapterList(data.chapter_array)
  }

  const closeSessionPopup = () => {
    setShowSessionList(false)
  }

  const onCLickJoinWebinarBtn = async (data) => {
    onClickOpenNewTab(data.service.session_code)
    // setLoading(true)

    // let dataObject = {
    //   service_id: data.service.Service_id,
    //   book_services_id: data.Book_Service_Id,
    //   session_number: 1,
    // }
    // console.log(dataObject);
    // let uData = await apiservice.getLocalStorage('userData')

    // await apiservice.apiCall('/LearnerJoinSession', uData.Token, dataObject, 'POST').then(res => {
    //   if (res.unAuthenticated) {
    //     navigate('/')
    //     localStorage.removeItem('userData')
    //   } else if (res.data) {
    //     setLoading(false)
    //     console.log(res.data);

    //     let datas = res.data
    //     let url = `${apiservice.backendOrigin()}/meeting/index.php?name=${uData.name} ${uData.lname}&meeting_number=${datas.session_code}&meeting_pwd=${datas.session_password}&role=${0}&booking_id=${data.Book_Service_Id}`
    //     // setMeetingData(res.data)
    //     // createZoomMeeting(res.data)
    //   }
    //   else {
    //     setLoading(false)
    //     console.log(res.error);
    //   }
    // }).catch(err => {
    //   setLoading(false)
    //   console.log(err);
    // })
  }
  const onCLickJoinBtn = async (data) => {
    onClickOpenNewTab(data.session_link)
    // setLoading(true)
    // console.log(bookingData.service.Service_id);
    // let dataObject = {
    //   service_id: bookingData.service.Service_id,
    //   book_services_id: bookingData.Book_Service_Id,
    //   session_number: index + 1,
    // }
    // console.log(dataObject);
    // let uData = await apiservice.getLocalStorage('userData')

    // await apiservice.apiCall('/LearnerJoinSession', uData.Token, dataObject, 'POST').then(res => {
    //   if (res.unAuthenticated) {
    //     navigate('/')
    //     localStorage.removeItem('userData')
    //   } else if (res.data) {
    //     setLoading(false)

    //     setShowSessionList(false)
    //     console.log(res.data);

    //     let datas = res.data
    //     let url = `${apiservice.backendOrigin()}/meeting/index.php?name=${uData.name} ${uData.lname}&meeting_number=${datas.session_code}&meeting_pwd=${datas.session_password}&role=${0}&booking_id=${bookingData.Book_Service_Id}`
    //     // setMeetingData(res.data)
    //     // createZoomMeeting(res.data)
    //   }
    //   else {
    //     setLoading(false)
    //     console.log(res.error);
    //   }
    // }).catch(err => {
    //   setLoading(false)
    //   console.log(err);
    // })
  }
  const onClickOpenNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  const listOfChapter = () => {
    let list = []
    let dataArr = chapterList
    for (let index = 0; index < dataArr.length; index++) {
      const element = dataArr[index];
      console.log(element);
      list.push(

        <div className='' key={index}>
          <div className='row border-bottom p-3' >
            <div className='col-8 d-flex justify-content-between'>
              <div className='me-3'><span>{index + 1}.</span> </div><div>{element.chapterTitle}</div>
              <div className='d-flex'><div>{element.sessionDuration}</div> <div className='ms-1'>{element.sessionDuration === "1" ? "hr" : 'min'}</div></div>

            </div>
            <div className='col-4  text-end'>
              {element.status === 0 ?
                <div className='ms-3'>
                  <button className='btn btn-orange' onClick={() => onCLickJoinBtn(element)}>Join</button>
                </div>
                :
                element.status === 1 ?
                  <div className='badge text-success border'>Completed</div>
                  :
                  <div className='badge text-dark border'>Pending</div>}
            </div>
          </div>
        </div>

      )

    }
    return list
  }
  const onClickOkButton = () => {
    SetSuccessMessage(false)
    setData({ ...data, openCompletedFormPopup: false })
  }
  return (
    <>
      <Modal show={showSessionList} size='lg' style={{ marginTop: '80px' }}>
        <ModalBody><div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -9, right: -6 }} onClick={closeSessionPopup}><FontAwesomeIcon icon={faTimes} /></div>
          <div className='p-3'>
            {listOfChapter()}
          </div>
        </ModalBody>
      </Modal>
      <Modal show={data.openCompletedFormPopup} onHide={closePopUp} style={{ marginTop: '200px' }}>
        <ModalBody>
          {completedMessage !== '' ?
            <div className='p-3'>
              <div className='text-danger text-center'>
                {completedMessage}
              </div>

              <div className='mt-3'>
                <button className='btn btn-secondary w-100' onClick={closePopUp}>Cancel</button>
              </div>
            </div>
            :
            <div className='p-3'>
              {sucessMessage ?
                <div className='p-3'>
                  <div className=' text-center text-success'>The service completion request has been successfully submitted.</div>
                  <div className='mt-3 text-center'>
                    <button className='btn btn-orange' onClick={onClickOkButton}>OK</button>
                  </div>
                </div> :
                <div className=''>
                  <div className='row'>
                    <div className='col-lg-6 col-12'>
                      <div className='mb-3'>
                        <div className="form-group">
                          <label htmlFor="">Start date</label>
                          <input type="date"
                            className="form-control" name="startDate" value={data.startDate} onChange={inputHandleChange} placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-12'>
                      <div className='mb-3'>
                        <div className="form-group">
                          <label htmlFor="">End date</label>
                          <input type="date"
                            className="form-control" name="endDate" value={data.endDate} onChange={inputHandleChange} placeholder="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className="form-group">
                      <label htmlFor="">How is it Completed ?</label>
                      <textarea className="form-control" name="completedText" value={data.completedText} rows="5" onChange={inputHandleChange} placeholder='Text here'></textarea>
                    </div>
                  </div>
                  {completedSession.content_expert_completed_status === 0 ?
                    <div className='mb-3 d-flex justify-content-center'>
                      <button className='btn border w-50 btn-grey' onClick={closePopUp}> Cancel</button>
                      <button className='btn btn-orange ms-3 w-50' onClick={onClickSubmit}> Submit</button>
                    </div>
                    :
                    <div className='mb-3'>
                      <button className='btn btn-orange w-100' onClick={onClickSubmit}>Accept</button>
                    </div>
                  }
                </div>}
            </div>}
        </ModalBody>
      </Modal>
      {isLoading ? <Loader /> :
        <div className='p-3'>
          <div className=''>
            <div className='container-fluid'>
              <div className='fw-bold Title'>My Services</div>
            </div>
            <div className='mt-3'>
              <div className='d-none d-lg-block'>
                <div className='row'>
                  <div className='col-lg-3 col-12'>
                    <div className='mb-2'>
                      <button className={menuKey === 'On-Going' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('On-Going')}>On-Going</button>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                    <div className=''>
                      <button className={menuKey === 'Upcoming' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Upcoming')}>Upcoming</button>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                    <div className=''>
                      <button className={menuKey === 'Completed' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Completed')}>Completed</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-lg-none d-block'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='mb-2 text-center'>
                      <button className={menuKey === 'On-Going' ? 'btn btn-light w-100 menu active-menu btnMS' : 'btn btn-light w-100 menu btnMS'} onClick={() => onClickTopMenu('On-Going')}>On-Going</button>
                    </div>

                  </div>

                </div>

                <div className='row'>
                  <div className='col-6'>
                    <button className={menuKey === 'Upcoming' ? 'btn btn-light w-100 menu active-menu btnMS' : 'btn btn-light w-100 menu btnMS'} onClick={() => onClickTopMenu('Upcoming')}>Upcoming</button>
                  </div>

                  <div className='col-6'>
                    <button className={menuKey === 'Completed' ? 'btn btn-light w-100 menu active-menu btnMS' : 'btn btn-light w-100 menu btnMS'} onClick={() => onClickTopMenu('Completed')}>Completed</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <hr />
          <div className='mt-3'>

            {menuKey === 'On-Going' ?


              <div>
                {loadBookedOnGoingServices()}
              </div>
              :
              menuKey === 'Upcoming' ?

                <div>
                  {loadUpcomingservices()}
                </div>

                :
                <div>
                  {
                    <div> {loadCompletedServices()} </div>
                  }
                </div>
            }

          </div>
        </div>
      }
    </>
  )
}

export default MyServicesPage
