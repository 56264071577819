import React, { useState } from 'react'
import './Terms.scss'
import { Fragment, useEffect } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import starHollow from "../../../assets/startHollow.png"
import { useNavigate } from 'react-router-dom'

export default function Tearmsandcondition(props) {
  let navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem('isFilter')
    window.scrollTo(0, 0)
  }, [])


  return (
    <Fragment>
      <Header navigate={navigate} />
      <section style={{ backgroundColor: "#f1efe7" }}>

        <div className='Tearmsection'>
          <div className='container'>
            <p className='pt-5 pb-0 m-0 mb-2'><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt;  Terms and conditions</p>
            <div className='row'>
              <div className='col-12'>
                <div className='TearmsTitle d-flex '>
                  <span> Terms and conditions </span> <img src={starHollow} className='d-lg-block d-none start_splash_img' height={84} width={84} style={{ marginLeft: '25%', marginBottom: '-20px' }} alt="" />
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="fw-bold">1. Introduction:</div>
                <p>
                  These Terms & Conditions ("Terms") govern your use of the XperThrivr platform and services offered by XperThrivr Ltd. ("XperThrivr", "we", "us", or "our"). By accessing or using the platform, you ("Client", "you", "User" or "your") agree to be bound by these Terms. If you do not agree to all of these Terms, you are not authorized to use the platform.
                </p>

              </div>

              <div>
                <div className='fw-bold'>2. Definitions:</div>
                <ul>
                  <li>

                    <b>Client:</b> Refers to you, the individual using the XperThrivr platform and agreeing to these Terms. You can be categorized as either a <b>Content Expert</b> or a <b>Learner</b>.

                  </li>
                  <li>

                    <b>Content Expert:</b> A User who shares their knowledge and expertise through the platform.

                  </li>
                  <li>

                    <b>Learner:</b> A User who seeks knowledge and expertise from Content Experts through the platform.

                  </li>
                  <li>

                    <b>Platform:</b> Refers to the XperThrivr website and all related services offered by XperThrivr.

                  </li>
                  <li>

                    <b>Content:</b> Refers to any information, data, text, audio, video, or other materials uploaded, shared, or accessed through the platform.

                  </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>3. Description of Services </div>
                <p>XperThrivr provides a platform for users to connect and exchange educational content through 1:1 video-enabled interactions. Content Experts can share their knowledge and expertise with Learners through various features offered on the platform, such as: </p>
                <ul>
                  <li>
                    Creating profiles and showcasing expertise.
                  </li>
                  <li>
                    Setting session fees, duration and availability.
                  </li>
                  <li>
                    Conducting 1:1 video sessions.
                  </li>
                  <li>
                    Sharing additional educational resources.
                  </li>
                </ul>
                <p>
                  Learners can benefit from the platform by:
                </p>
                <ul>
                  <li>Searching for and connecting with Content Experts. </li>
                  <li>Booking and attending 1:1 video sessions. </li>
                  <li>Accessing additional educational resources shared by Content Experts. </li>
                </ul>
              </div>


              <div>
                <div className='fw-bold'>4. User Accounts: </div>
                <ul>
                  <li>To access the features of the platform, you may need to create an account. </li>
                  <li>You are responsible for maintaining the confidentiality of your account information, including your username and password. </li>
                  <li>You are solely responsible for all activities that occur under your account. </li>
                </ul>
              </div>


              <div>
                <div className='fw-bold'>5. User Conduct: </div>
                <ul>
                  <li>You agree to use the platform in a lawful and respectful manner.  </li>
                  <li>You will not upload, share, or access content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.  </li>
                  <li>You will not violate the intellectual property rights of others.  </li>
                  <li>You will not impersonate any other person or entity. </li>
                  <li>You will not attempt to gain unauthorized access to the platform or its systems. </li>
                  <li>You must not engage in any conduct that disrupts or interferes with the operation of XperThrivr. </li> </ul>
              </div>


              <div>
                <div className='fw-bold'>
                  6. Content Ownership:
                </div>
                <ul>
                  <li>Content Experts retain ownership of all intellectual property rights in the content they upload to the platform. </li>
                  <li>By uploading content, Content Experts grant XperThrivr a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, and distribute their content on the platform solely for the purpose of facilitating educational interactions. </li>
                  <li>Learners are prohibited from sharing or distributing content obtained through the platform without the express permission of the Content Expert. </li>
                  <li>XperThrivr reserves the right to remove or restrict the content provided by Content Experts that violates these terms or the law. </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>7. Payment Terms:</div>
                <ul>
                  <li>The platform offers both free and paid sessions. </li>
                  <li>Content Experts set their own session fees, reflecting the perceived value and expertise they offer. </li>
                  <li>Learners are responsible for paying the session fees set by the Content Expert. </li>
                  <li>XperThrivr retains a small percentage of the session fee as a commission for facilitating the transaction and maintaining the smooth operation of the website. The specific commission rate will be clearly displayed on the platform. </li>
                  <li>After both parties (Content Expert and Learner) submit successful completion of the service, XperThrivr will transfer the amount, after commission deduction, to the Content Expert. </li>
                  <li>XperThrivr uses secure payment processing services supported by Paypal to ensure the safety and security of all financial transactions. </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>8. Disclaimers and Warranties: </div>
                <ul>
                  <li>XperThrivr provides the platform and its services on an "as is" and "as available" basis. </li>
                  <li>XperThrivr disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. </li>
                  <li>XperThrivr does not warrant that the platform will be uninterrupted, error-free, or free of viruses or other harmful components. </li>
                  <li>XperThrivr is not responsible for the quality, accuracy, or effectiveness of content shared by Content Experts. </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>9. Privacy:</div>
                <ul>
                  <li>Your use of XperThrivr is subject to our Privacy Policy, which outlines how we collect, use, and protect your information </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>10. Limitation of Liability:</div>
                <ul>
                  <li>XperThrivr shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of the platform.  </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>11. Changes to Terms and Conditions:</div>
                <ul>
                  <li>We may update these terms at any time, and the latest version will be posted on our platform. Your continued use of XperThrivr after the changes constitutes your agreement to the updated terms. </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>12. Termination:</div>
                <ul>
                  <li>We reserve the right to terminate or suspend your account for any reason, including violation of these terms or illegal activities. </li>
                  <li>You may terminate your account at any time by following the instructions on XperThrivr. </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>13. Governing Laws:</div>
                <ul>
                  <li>These terms are governed by the laws of the jurisdiction XperThrivr is incorporated and subject to without regard to its conflict of law provisions.  </li>
                </ul>
              </div>

              <div>
                <div className='fw-bold'>14. Contact us:</div>
                <ul>
                  <li>If you have any questions, concerns, or requests regarding these terms, please contact us at  <span className='fw-bold text-primary' onClick={() => window.location = 'mailto:kavish@xperthrivr.com'} style={{ cursor: 'pointer' }}>kavish@xperthrivr.com</span>. </li>
                </ul>
              </div>


              <div>
                <p>
                  By using XperThrivr, you agree to abide by these terms and conditions. Please review these terms periodically for any updates or changes. Your continued use of our platform constitutes your agreement to these terms and any updates.
                </p>
              </div>

            </div>
          </div>
        </div>
        <Footersection navigate={navigate} />
      </section>
    </Fragment >


  )
}
