import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import hollowStar from "../../../assets/startHollow.png"
import aboutUsImg from "../../../assets/imgAtAboutUsTop.png"
import starImg from "../../../assets/smallStar.png"
import onlineServices from "../../../assets/onlineservices.png"
import expertMentor from "../../../assets/expertMentors.png"
import certifiedServices from "../../../assets/cirtifiedServices.png"
import onlineLearners from "../../../assets/onlineLearners.png"
import globleCommunity from '../../../assets/globleCommunity.png'
import noImg from '../../../assets/no-image.png'
import MentorCard from '../../../components/LandingpageComponents/MentorsCard/MentorCard'
import { HiArrowLongRight } from 'react-icons/hi2'
import becomeMentor from "../../../assets/becomeMentor.png"
import achiveGoals from "../../../assets/achiveGoal.png"
import "./AboutUs.scss"
import apiservice from '../../../apiservice/ApiService'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'



function AboutUs(props) {
  let navigate = useNavigate()
  let [isLoggedIn, setIsLoggedIn] = useState(false)
  let [mentors, setMentors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  async function checkFunction() {
    let isUserPresent = await apiservice.getLocalStorage('userData')
    if (isUserPresent) {
      setIsLoggedIn(true)
    }
    else {
      setIsLoggedIn(false)
    }
  }

  async function getAllInfo() {
    setIsLoading(true)
    try {
      let mentors = await apiservice.apiCall('/ContentExpertListFavorite')
      console.log(mentors);
      if (mentors.data) {
        setIsLoading(false)
        console.log('mentorsData : ', mentors.data);
        setMentors(mentors.data)
      }
      else {
        setIsLoading(false)
        console.log(mentors.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    checkFunction()
    getAllInfo()
    localStorage.removeItem('isFilter')

    window.scrollTo(0, 0)
  }, [])

  function launchYourPage() {
    navigate('/signup')
  }
  function exploreServices() {
    navigate('/services')

  }

  async function clickedOnCard(item) {
    apiservice.setLocalStorage('mentorData', item)
    // navigate('/contentexpert')
    navigate(`/contentexpert/${item.content_experts_id}`)
  }

  function getCard() {
    let list = [];
    let count = 0;
    // // console.log(count);

    for (let i = 0; i < mentors.length; i++) {
      let item = mentors[i]
      console.log(item);
      if (i < 5) {
        list.push(
          <MentorCard
            key={i}
            onClick={() => { clickedOnCard(item) }}
            mentorsName={`${item.name} ${item.lname}`}
            sideBgColor={`cardBg${count}`}
            mentorsImage={item.ProfileImage === null ? item.SocialImage === null ? item.profile_photo_url : item.SocialImage : `${apiservice.apiImageShow()}/${item.ProfileImage}`}
            mentorsDescription={item.shortDescription}
            mentorDesignation={`${item.lastDesignation} @ ${item.company}`}
          />
        );
      }
      if (count === 4) {
        count = 0;
      } else {
        count++;
      }
    }

    return list;
  }
  const onClickBookFreeSessionCareer = () => {
    navigate('/servicedetail/1')
  }
  const onClickBookFreeSessionbusiness = () => {
    navigate('/servicedetail/2')
  }


  return (
    <Fragment>
      <Header navigate={navigate} />
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: '#f1efe7' }}>
          <article className='container'>
            <div className="row">
              <div className="col-lg-7 col-12 ">
                <div className="d-flex justify-content-between pt-4 m-0">
                  <p className='p-0 m-0'><span className='pointer' onClick={() => navigate('/')}>Home</span>  &gt;  About Us</p>
                  <img src={hollowStar} width={50} height={50} className='start_splash_img d-md-block d-none' alt="" />
                </div>
                <h4 className='fw-semibold p-0 m-0 mdTitleText mb-4' >Words from Kavish, CEO & Founder of XperThrivr. </h4>

                <div className='   mt-2'>

                  <span className='pe-4' ><div className='fw-bold'>Our Mission Journey</div>
                    <br />
                    <p>At XperThrivr, we believe individuals who have a passion in a particular field are always very keen to share their knowledge and help others achieve success. </p>
                    <p>We value those individuals and empower them to impart their knowledge and insights in the most structured and meaningful way.   </p>
                    <p>Reach out and let’s go through your journey (career, life path, specific project, etc.) extract meaningful learnings. Then, let’s turn them into educational sessions for others to benefit from. </p>
                    <p>Together, let’s empower others! </p>
                    <div className='signature'>
                      Kavish Bullyraz
                    </div>
                    <div className=' mt-4'>
                      <button className='btn btn-orange px-4' onClick={onClickBookFreeSessionCareer}>For Career: Reach out for a free 1:1 Session!</button>
                    </div>
                    <div className=' mt-2'>
                      <button className='btn btn-orange px-3 ' onClick={onClickBookFreeSessionbusiness}>For Business: Reach out for a free 1:1 Session!</button>
                    </div>
                  </span>

                  <br /><br />
                  {/* <p className=''>
                    <span className='fw-bold ' >Our Vision : </span> <br />
                    Our vision is to empower
                    individuals to thrive by facilitating
                    seamless, engaging, and
                    personalized learning
                    experiences. We believe in the
                    power of expertise and the
                    potential for growth that lies within
                    every learner.
                  </p> */}


                </div>


              </div>

              <div className="col-lg-5 col-12 p-5">
                <img src={aboutUsImg} className='w-100  imgAnimatin' alt="" />


              </div>

            </div>
          </article>

          <article className='container'>
            <div >
              <div className='border-bottom border-2 px-2 py-3'>

                <img src={starImg} width={32} height={32} alt="" className='starAnimatin' /> <span className='  fs-3 ms-2  fw-bold'>Two Essential Components of XperThrivr</span>
              </div>

              {/* <div className=' py-3'>XperThrivr is a dynamic platform
                that brings together two essential
                components: learners and content
                experts. We believe that learning
                is a collaborative effort, and our
                platform is designed to foster this
                collaboration.
              </div> */}
              <div className='mt-3'>
                <p>
                  <span className='fw-bold' >Meet Our Content Experts : </span>
                  Our content experts are the
                  backbone of XperThrivr. They are
                  experienced professionals,
                  industry leaders, and passionate
                  educators who are dedicated to
                  sharing their knowledge and
                  expertise with our learners. These
                  experts provide a variety of
                  learning resources, including live
                  sessions, webinars, pre-recorded
                  videos, and written content. Our
                  content experts are committed to
                  your success and personal
                  growth.
                </p>

                <p>
                  <span className='fw-bold'>Empowering Learners : </span>
                  At XperThrivr, learners are at the
                  forefront of everything we do. We
                  provide a range of features and
                  tools to ensure that your learning
                  journey is smooth, engaging, and
                  effective.
                </p>
              </div>

              <div className='border-bottom border-2 px-2 py-3'>

                <img src={starImg} width={32} height={32} alt="" className='starAnimatin' /> <span className='  fs-3 ms-2  fw-bold'>Our Main Features</span>
              </div>

              <div className='mt-3'>
                <p>
                  <span className='fw-bold'> 1. Personalized Learning : </span>
                  Our platform adapts to your needs and
                  preferences. We understand that
                  everyone learns differently, so we
                  offer a range of content formats
                  and learning paths tailored to you.
                </p>

                <p><span className='fw-bold'>2. Interactive Chat : </span>
                  Connect with
                  fellow learners and content
                  experts through our built-in chat
                  feature. Ask questions, share
                  ideas, and collaborate with like-minded individuals.</p>

                <p>
                  <span className='fw-bold'>3. Video Call Functionality : </span>
                  Engage in face-to-face
                  interactions with content experts
                  through our video call functionality.
                  This ensures that you can receive
                  personalized guidance and
                  support as you progress on your
                  learning journey.
                </p>

                <p><span className='fw-bold'>4. Progress Tracking : </span>
                  Monitor your progress with ease. Our
                  platform provides detailed insights
                  into your learning journey, helping
                  you stay on top of your goals.</p>

                <p>
                  <span className='fw-bold' >5. Seamless Access : </span>
                  XperThrivr is accessible anytime, anywhere,
                  and on any device. Whether
                  you're at home, in the office, or on
                  the go, your learning journey is
                  just a click away.
                </p>
              </div>

              <div className='border-bottom border-2 px-2 py-3'>

                <img src={starImg} width={32} height={32} alt="" className='starAnimatin' /> <span className='  fs-3 ms-2  fw-bold'>Why Choose XperThrivr</span>
              </div>

              <div className='mt-3'>

                <p>
                  <span className='fw-bold'>Expert-Driven Content : </span>
                  Our content is created and delivered by industry experts who are passionate about sharing their knowledge and helping you thrive.

                </p>

                <p>
                  <span className='fw-bold'>Interactive Learning : </span>
                  Our platform is designed to be interactive, ensuring you stay engaged and motivated throughout your learning journey.
                </p>

                <p>
                  <span className='fw-bold'>Personalization : </span>
                  We believe in catering to your unique needs. Your learning path is yours to define, and we're here to support you every step of the way.
                </p>

                <p>
                  <span className='fw-bold'>Smooth Communication : </span>
                  With chat and video call functionality, we facilitate smooth communication and collaboration, connecting you with the right experts and fellow learners.
                </p>

                <p>
                  <span className='fw-bold'> Convenience : </span>
                  We offer a seamless and flexible learning experience. You decide when and where you want to learn.
                </p>

                <p>
                  <span className='fw-bold'>Join Us on the Journey to Thrive : </span>
                  At XperThrivr, we invite you to embark on a learning journey like no other. Our platform is your gateway to unlocking your full potential and achieving your goals. Whether you're looking to expand your knowledge, enhance your skills, or stay updated in your industry, XperThrivr is here to empower you.

                  <br /> <br /> Join our community of learners and content experts today and experience the transformative power of collaborative learning. Thrive with XperThrivr!
                </p>
              </div>

              {/* <p>
                <span className='fw-bold' >Why Choose XperThrivr : </span>
                We understand that there are numerous learning platforms out there, but XperThrivr stands out because we prioritize your success and personal growth. Here's why you should choose us:
              </p> */}

            </div>
          </article>

          <article className='py-5 container-fluid mt-5' style={{ backgroundColor: "#FECDCA" }}>
            <div className='container'>
              <div className="row">
                <div className=" col-xl-3 col-lg-6 col-12 col-md-6 p-2 ">
                  <div className="card border-white p-4 flex-row align-items-center justify-content-between border-3 w-100" style={{ backgroundColor: '#FFE6E5' }}>
                    <div> <img src={onlineServices} width={99} height={99} alt="" /> </div>
                    <div className=' px-3 fw-bold'> Expert-Driven Content </div>
                  </div>
                </div>

                <div className=" col-xl-3 col-lg-6 col-12 col-md-6 p-2 ">
                  <div className="card border-white p-4 flex-row align-items-center justify-content-between border-3 w-100" style={{ backgroundColor: '#FFE6E5' }}>
                    <div> <img src={expertMentor} width={99} height={99} alt="" /> </div>
                    <div className=' px-3 fw-bold'> Interactive Learning Journey </div>
                  </div>
                </div>

                <div className=" col-xl-3 col-lg-6 col-12 col-md-6 p-2 ">
                  <div className="card border-white p-4 flex-row align-items-center justify-content-between border-3 w-100" style={{ backgroundColor: '#FFE6E5' }}>
                    <div> <img src={certifiedServices} width={99} height={99} alt="" /> </div>
                    <div className=' px-3 fw-bold'> Learner’s Progress Report </div>
                  </div>
                </div>

                <div className=" col-xl-3 col-lg-6 col-12 col-md-6 p-2 ">
                  <div className="card border-white p-4 flex-row align-items-center justify-content-between border-3 w-100" style={{ backgroundColor: '#FFE6E5' }}>
                    <div> <img src={onlineLearners} width={99} height={99} alt="" /> </div>
                    <div className=' px-3 fw-bold'> A Thriving Community of Online Learners </div>
                  </div>
                </div>

              </div>
            </div>
          </article>
          <article className="container py-5 my-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                <div>
                  {/* <p className="smallSubTitle p-0 m-0">Our Content Expert</p> */}
                  <h4 className="mdTitleText mb-4">Greatest Mentors Inspire</h4>
                  <button
                    type="button"
                    onClick={() => navigate('/exploreAllMentors')}
                    className="btn btn-primary border px-4 py-2 mb-2 btnM"
                    style={{ backgroundColor: "#E57A4D", maxWidth: 'max-content' }}
                  >
                    Explore All Content Expert <HiArrowLongRight />
                  </button>
                </div>
                <div style={{ marginTop: '-70px' }}>
                  <img src={hollowStar} className='align-self-end start_splash_img' width={75} height={75} alt="" style={{ marginLeft: '50px' }} />
                </div>

              </div>

              {getCard()}
            </div>
          </article>

          <article className='container'>
            <p className='smallSubTitle text-center'>Build Up a Community</p>
            <h3 className='mdTitleText text-center'>The Biggest Community of Learners and Experts</h3>
            <div className=' position-relative text-center mb-3 pt-2'>
              <img src={globleCommunity} style={{ width: '80%' }} alt="" />
              <h3 className='numOfSubs text-center d-none d-lg-block'>Connect with <br /> <span>Our </span>Content Experts  </h3>
              <h3 className='numOfSubs text-center d-lg-none d-block'>Connect with  <span>Our </span>Content Experts  </h3>
            </div>
          </article>
          {
            isLoggedIn ? " "
              :
              <article className='container'>
                <div className="row" style={{ height: 'max-content' }}>

                  <div className="col-lg-6 row col-12 p-3 bg-transparent " style={{ height: '100%' }}>
                    <div className="card align-self-center p-3 flex-row" style={{ backgroundColor: '#FFDEDA', height: '100%' }}>
                      <div className="col-7 pt-3 m-0">
                        <h4 className='pt-2'>Become a Mentor</h4>
                        <p className=''>Provide your session, share your knowledge, and upskill others through our platform.</p>
                        <button className="btn btnM" onClick={launchYourPage} style={{ backgroundColor: '#FD6F8E' }}>Launch your page</button>
                      </div>
                      <div className="col-5 d-flex">
                        <img src={becomeMentor} className='w-100 align-self-center' height={178} alt="" />
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6 row col-12 bg-transparent p-3" style={{ height: '100%' }}>
                    <div className="card align-self-center p-3  flex-row" style={{ backgroundColor: '#FDDCAB', height: '100%' }}>
                      <div className="col-7 pt-3 m-0">
                        <h4 className='pt-2 mb-2 m-0'>Learn and Thrive with Us</h4>
                        <p>Tap into the wealth of experience of our content experts and obtain hands-on skill through
                          1:1 mentoring and coaching.</p>
                        <button className="btn btnM" onClick={exploreServices} style={{ backgroundColor: '#FDB54B' }}>Explore Services</button>
                      </div>
                      <div className="col-5 d-flex">
                        <img src={achiveGoals} className='w-100 align-self-center' height={178} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

              </article>

          }
          <Footersection navigate={navigate} />
        </section>}

    </Fragment>
  )
}

export default AboutUs
