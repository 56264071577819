import React, { useState } from 'react'
import { Fragment, useEffect } from 'react'
import mentorAtServiceDetail from "../../../assets/mentorAtServiceDetail.png"
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import MyservicesCard from '../../../components/LandingpageComponents/MyServicesCard/MyservicesCard'
import OnClickSlide from '../../../components/LandingpageComponents/OnClickSlide/OnClickSlide'
import { HiArrowLongRight } from 'react-icons/hi2'
import StarRatings from 'react-star-ratings'
import courseImg from "../../../assets/cardImage.png"
import apiservice from '../../../apiservice/ApiService'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'

function SelectSchedule(props) {
  let navigate = useNavigate()
  let [mentorData, setMentorData] = useState('')
  let [serviceData, setServiceData] = useState('')
  let [allServices, setAllServices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  async function getReqData() {

    try {
      setIsLoading(true)
      await apiservice.getLocalStorage('userData');
      let sData = await apiservice.getLocalStorage('serviceData')
      let mData = await apiservice.getLocalStorage('mentorData')
      let service = await apiservice.apiCall(`/ContentExpertService/${mData.content_experts_id}`)
      if (service.data) {
        console.log(service.data);
        setIsLoading(false)
        setAllServices(service.data)
      }
      else {
        setIsLoading(false)
        console.log(service.error);
      }
      setMentorData(mData);
      setServiceData(sData);

      console.log(service);

    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  function loadServices() {
    let list = [];

    for (let i = 0; i < allServices.length; i++) {
      let item = allServices[i]
      console.log(item);
      if (i < 3) {
        list.push(
          <div key={i} className="col-lg-4 col-sm-12 col-md-6"
            onClick={() => navigate(`/servicedetail/${item.Service_id}`)}>
            <MyservicesCard
              courseTitle={item.Service_Name}
              mentorName={`${mentorData.name} ${mentorData.lname}`}
              courseDuration={`${item.Hour}h ${item.Minutes}m`}
              ratting={item.averageRating}
              timeZone={item.timezone}
              webinarDate={item.start_date}
              webinarTime={item.start_time}
              courseCost={item.Price} 
              currencySymbol={item.CurrencySymbol}/>

          </div>
        )
      }
    }
    return list;
  }

  useEffect(() => {
    getReqData()
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')

  }, [])
  console.log(mentorData);
  console.log(serviceData);




  return (
    <Fragment>
      <Header navigate={navigate} />
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: '#F1EFE7' }}>
          <article className='container py-4'>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 card p-3 m-0 bg-transparent ">

                <div className="card bg-transparent border-0" style={{ width: "100%" }}>
                  <img className="card-img-top px-3 rounded-3" src={courseImg} style={{ backgroundColor: "#FECDCA" }} alt="Card image cap" />
                  <div className="card-body p-0 m-0">
                    <h3 className="myServicesHeadText pt-2">{serviceData.Service_Name}</h3>
                    <p>
                      {serviceData.Service_Description}
                    </p>
                    <p className="p-0 m-0 fw-medium">{`${mentorData.name} ${mentorData.lname}`}</p>

                    <div className='my-2'>
                      <StarRatings
                        rating={4.3}
                        starDimension='20px'
                        starRatedColor="#FDB022"
                        numberOfStars={5}
                      /> <span>{4.3}</span>
                    </div>


                    <div className="py-1 m-0 d-flex align-items-center "><span style={{ backgroundColor: "#ABEFC6", height: 'fit-content' }}>{serviceData.MentorshipType}</span>
                      <span className="mx-1 m-0" > {`${serviceData.Hour}h ${serviceData.Minutes}m`} total length </span> </div>


                  </div>
                </div>
              </div>

            </div>
          </article>
          <article className='container'>
            <div>
              <div className="col-12">
                <div className="mt-3 d-flex align-items-center ">
                  <img src={mentorAtServiceDetail} alt="" />
                  <div>
                    <div className="ms-3">
                      <h5>{mentorData.name} {mentorData.lname}</h5>
                      <span>
                        Web Developer and Designer
                      </span>
                      <div className="w-100 py-3 my-2">
                        <button
                          type="button"
                          onClick={() => navigate('/servicedetail-mentorDetails')}
                          className="btn btn-primary text-center border-0"
                          style={{ backgroundColor: "#E57A4D" }}
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="py-3">
                  Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the
                  1500s, when an unknown printer took a galley of
                  type and scrambled it to make a type specimen
                  book. It has survived not only five centuries, but
                  also the leap into electronic typesetting,
                  remaining essentially unchanged. It was
                  popularised in the 1960s with the release of
                  Letraset sheets containing Lorem Ipsum passages,
                  and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem
                  Ipsum.
                </p>
              </div>
            </div>
          </article>
          <article className="container">
            <div className="myServices">
              <div className="d-flex justify-content-between align-items-center" >
                <h1 className=" fw-semibold">My Services</h1>
                <div className='fw-semibold' style={{ cursor: 'pointer' }}>
                  Explore All Services
                </div>
              </div>
            </div>

            <div className="row  bg-transparent">
              {loadServices()}
            </div>
          </article>
          <Footersection navigate={navigate} />
        </section>
      }
    </Fragment>
  )
}

export default SelectSchedule
