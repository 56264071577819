import React from "react";
import { Fragment, useState } from "react";
import "./Loginpage.Styles.scss";
import "../SignUpPage/Signuppage.Styles.scss";
import { HiArrowLongRight } from "react-icons/hi2";
import sideImageReg from "../../../assets/sideImageReg.png";
import brandLogo from "../../../assets/LOGO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import validation from "../../../components/Validations";
import apiservice from "../../../apiservice/ApiService";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { gapi } from 'gapi-script';
import gLogo from '../../../assets/googleLogo.png'
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";


const cid = '1056869252945-srha9oll7i8am1le089q5q7al1catg6j.apps.googleusercontent.com'

function Loginpage(props) {
  let navigate = useNavigate()
  const initialdata = {
    email: '',
    password: '',
    showPassword: true
  }
  const [data, setData] = useState(initialdata)
  const [errorMsg, setErrorMsg] = useState('')

  let [isRoleDefined, setRole] = useState(false)
  let [userRole, setUsrRole] = useState(null)
  let [errorMessage, setErrorMessage] = useState('')
  const [code, setCode] = useState("");
  let [isLoading, setIsLoading] = useState(false)
  const [socialMediaLoading, setSocialMediaLoading] = useState(false)
  const onChangeHandle = (e) => {
    const { name, value } = e.target
    setErrorMsg('')
    setData({ ...data, [name]: value })
  }
  // ------------------------------GoogleLogin------------------------------------------------------

  const googleLogin = () => {
    
    setErrorMsg('')
    if (userRole === null) {
      setErrorMsg('Please choose your roll!')
      return false
    } else {
      gapi.load('auth2', () => {
        const auth2 = gapi.auth2.init({ client_id: cid })
        auth2.signIn().then(
          async (googleUser) => {
            console.log(googleUser);
            const idToken = googleUser.getAuthResponse().id_token;

            console.log("User ID (idToken):", idToken);

            try {

              let reqData = {
                user_type: userRole
              }
              console.log(reqData);
              setSocialMediaLoading(true)
              let googleLogin = await apiservice.apiCall('/googleLogin', idToken, reqData, 'POST')

              console.log(googleLogin);

              if (googleLogin.data) {
                let usersDatas = googleLogin.data

                console.log(usersDatas);
                await apiservice.setLocalStorage('userData', usersDatas)
                // navigate('/')
                if (usersDatas.user_type === 2) {
                  console.log(googleLogin);
                  console.log(googleLogin.data.user_type);
                  navigate('/contetexpert-home')
                }
                else if (usersDatas.user_type === 1) {
                  console.log(googleLogin);
                  console.log(googleLogin.data.user_type);
                  navigate('/learner-home')
                }

              }
              else {
                setSocialMediaLoading(false)
                console.log(googleLogin.error);
                setErrorMsg(googleLogin.error)
              }
            } catch (error) {
              setSocialMediaLoading(false)
              console.log(error);
            }
          }, (error) => {
            setSocialMediaLoading(false)
            console.log(JSON.stringify(error))
          }
        )
      })
    }
  }

  // ------------------------------LinkedInLogin------------------------------------------------------


  const onClickLoginLinkedIn = () => {
    
    setErrorMsg('')
    if (userRole === null) {
      setErrorMsg('Please choose your roll!')
      return false
    } else {
      try {
        linkedInLogin();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "865mis3s3mhtef",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log(code);
      setCode(code);
      linkedinCallBack(code)
      setErrorMsg("");
    },
    scope: "email openid profile r_learningdata w_member_social",
    onError: (error) => {
      console.log(error);
      setCode("");
      setErrorMsg(error.errorMessage);
    },
  })
  const linkedinCallBack = async (code) => {
    setSocialMediaLoading(true)
    let dataObject = {
      user_type: userRole,
      code: code,
    }
    let callBackRes = await apiservice.apiCall('/auth/linkedin/callback', '', dataObject, 'POST')
    console.log(callBackRes);
    if (callBackRes.data) {
      let usersDatas = callBackRes.data

      console.log(usersDatas);
      await apiservice.setLocalStorage('userData', usersDatas)
      // navigate('/')
      if (usersDatas.user_type === 2) {
        console.log(callBackRes);
        console.log(callBackRes.data.user_type);
        navigate('/contetexpert-home')

      }
      else if (usersDatas.user_type === 1) {
        console.log(callBackRes);
        console.log(callBackRes.data.user_type);
        navigate('/learner-home')
      }
    } else {
      setErrorMsg(callBackRes.error)
      console.log(callBackRes.error);
      setSocialMediaLoading(false)
    }
  }


  //  const { linkedInLogin } = useLinkedIn({
  //    redirectUri: `${window.location.origin}/linkedin`,
  //    clientId: "865mis3s3mhtef",
  //    onSuccess: async (code) => {
  //      console.log(code);
  //      setCode(code);
  //      let dataObject = {
  //        user_type: userRole
  //      }
  //      let callBackRes = await apiservice.apiCall(`${apiservice.webOriginUrl()}/api/auth/linkedin/callback?code=${code}`, '', dataObject, 'POST')
  //      if (callBackRes) {
  //        console.log();
  //      }
  //      setErrorMsg("");
  //    },
  //    onError: (error) => {
  //      console.log(error);
  //      setCode("");
  //      setErrorMsg('');
  //    },
  //    scope: "email profile",
  //    state: '',
  //  });


  //  const onClickLoginLinkedIn = () => {
  //   if(userRole === null){
  //     setErrorMsg('Please choose your roll!')
  //     return false
  //   }
  //    try {
  //      linkedInLogin();
  //    } catch (error) {
  //      console.log(error);
  //    }
  //  };
  // ------------------------------------------------------------------------------------
  const onClickShowPassword = () => {
    setData({ ...data, 'showPassword': data.showPassword = !data.showPassword })
  }
  const clickedOnLogin = () => {
    if (data.email === '') {
      setErrorMsg('Email Id is required')
    }
    else if (!data.email.match(validation().emailVal)) {
      setErrorMsg('Email Id is not valid')
    }
    else if (data.password === '') {
      setErrorMsg('Please enter password ')
    }
    else if (userRole === null) {
      setErrorMsg('Please choose your role!')
    }
    else {
      loginApifn()

      // console.log(data);
    }
  }

  //-----------------Api calling function--------------------

  async function loginApifn() {
    setIsLoading(true);

    const loginData = {
      'email': data.email,
      'password': data.password,
      'user_type': userRole
    };

    try {
      const response = await apiservice.apiCall('/signin', null, loginData, 'POST');
      console.log(response);

      if (response.data) {
        setIsLoading(false);
        console.log(response.data);
        let userDatas = response.data

        if (userDatas.otp_verify === false) {
          sendOTP(userDatas)
          return false
        }

        const userType = response.data.user_type;
        await apiservice.setLocalStorage('userData', response.data);

        // navigate('/');
        if (userType === 1) {
          console.log("hello learner");
          navigate('/learner-home');
        } else if (userType === 2) {
          console.log("hello mentor");
          navigate('/contetexpert-home');
        }
      } else {
        setIsLoading(false);
        console.log(response.error);
        setErrorMsg('Invalid Credentials');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const sendOTP = async (dataObj) => {
    setIsLoading(true)
    try {
      let sendOTPUrl = `/SendOtp/${dataObj.country_code}/${dataObj.mobile}`
      console.log(sendOTPUrl);
      let response = await apiservice.apiCall(sendOTPUrl)
      if (response.data) {
        console.log(response.data);
        let urlObject = {
          countryCode: dataObj.country_code,
          mobile: dataObj.mobile
        }
        let mobileNo = JSON.stringify(urlObject)

        console.log(mobileNo, 'mobileNo ---------------- mobileNo ----------------- mobileNo');
        navigate(`/verify/${mobileNo}`)
        setIsLoading(false)
      } else {
        console.log(response.error);
        setErrorMsg(response.error)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }



  return (
    <Fragment>

      <section className='d-flex flex-column align-items-center' style={{ minHeight: '100vh', backgroundColor: '#ADD6DA' }}>

        <div className="container-fluid bg-white" >
          <div className="container-lg d-flex justify-content-between py-3" >
            <img className="pointer" src={brandLogo} width={170} onClick={() => navigate('/')} height={40} alt="" />
            <button type="button" onClick={(e) => navigate("/signup")} className="btn btn-outline-dark">
              Sign Up
            </button>
          </div>
        </div>

        <div
          className="container-fluid d-flex align-items-center "

        >
          <div className="container py-4">
            <div
              className="card border-3 rounded-3 border-white overflow-hidden"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                backgroundColor: "#EEEFF580",
              }}
            >

              <div className="card-body p-0 m-0 row">
                <div className="col-lg-6 col-12 p-5">
                  <div className='' >
                    <h5 className="regHeadText">Sign In</h5>
                  </div>
                  <div className="d-xl-flex d-block justify-content-start mt-5">
                    <h5>Choose Your Role :</h5>
                    <div class="form-check ms-3">
                      <input class="form-check-input" onClick={() => { setRole(true); setUsrRole(1) }} type="radio" name="userRole" id="Learner" />
                      <label class="form-check-label fw-bold" htmlFor="Learner">
                        Learner
                      </label>
                    </div>
                    <div class="form-check ms-3">
                      <input class="form-check-input" onClick={() => { setRole(true); setUsrRole(2) }} type="radio" name="userRole" id="mentor" />
                      <label class="form-check-label fw-bold" htmlFor="mentor">
                        Content Expert
                      </label>
                    </div>
                  </div>
                  <hr />
                  {!isRoleDefined ? '' :
                    <div className='animate__animated animate__fadeInLeft'>
                      {socialMediaLoading ?
                        <div className='p-3 text-center fw-bold fs-5' style={{ color: '#e57a4d' }}>
                          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                        </div>
                        :
                        <div className="row">
                          <div className=" col-lg-6 col-md-6 col-12 my-2 pointer">
                            <div onClick={googleLogin} class="g-btn w-100">
                              <div class="d-flex align-items-center ">
                                <img class=" bg-white p-1" width={40} height={40} src={gLogo} />
                                <p class="text-white text-center w-100 m-0 p-0">Continue with google</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-12 my-2 linkedInBtn pointer">
                            <div class="sig" onClick={onClickLoginLinkedIn}>
                              <div class='svgImg'>
                                <svg class="ico" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" throwIfNamespace='false'>
                                    <g id="LinkedIn" throwIfNamespace='false' fill="#FFFFFF">
                                      <g id="Fill-6-+-Fill-7" throwIfNamespace='false' transform="translate(6.000000, 5.000000)">
                                        <path d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
                                          id="Fill-6" throwIfNamespace='false'></path>
                                        <path d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
                                          id="Fill-7" throwIfNamespace='false'></path>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p className="text-center w-100 m-0 px-2">Continue with LinkedIn</p>
                            </div>
                          </div>
                        </div>
                      }


                      <div className="d-flex justify-content-center align-items-center">
                        <hr className=" align-self-center mx-2 w-50" /> or <hr className="mx-2 align-self-cente w-50" />
                      </div>
                      <div className='text-center'>
                        {errorMsg !== '' ? <div className='text-danger'>{errorMsg}</div> : ''}
                      </div>
                      <div className="row" >
                        <div className="col-12">
                          <label htmlFor="emailLogin">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={data.email}
                            onChange={onChangeHandle}
                            required
                            className="form-control rounded-2 w-100 px-2 py-2 my-1"
                            style={{ border: "1px solid #BBBBBB", outline: "none" }}
                          />
                        </div>
                        <div className='mb-3'>
                          <label htmlFor="">Password</label>
                          <div className="input-group ">
                            <input type={data.showPassword ? "password" : "text"}
                              className="form-control border-end-0" name="password" value={data.password} onChange={onChangeHandle} placeholder="Password" style={{ border: "1px solid #BBBBBB", outline: "none" }} />
                            <span className="input-group-text bg-white " id="basic-addon2" style={{ border: "1px solid #BBBBBB", outline: "none" }}>
                              {data.showPassword ?
                                <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowPassword} /> :
                                <FontAwesomeIcon icon={faEye} onClick={onClickShowPassword} />}
                            </span>
                          </div>
                        </div>

                        <p className="clickableBlueText p-1 ms-2" onClick={() => navigate('/forgotpass')} >Forgot Password ?</p>
                        <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between ">
                          <button type="submit" onClick={clickedOnLogin} className="btn btn-outline-dark my-1 buttonText" disabled={isLoading ? true : false}>
                            {isLoading ? 'Loading...' : 'Sign In'}
                          </button>
                        </div>
                      </div>
                    </div>
                  }

                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none mt-4 mb-4">
                  <img src={sideImageReg} className="sideImgSignUp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Loginpage;
