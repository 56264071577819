import { React, Fragment, useEffect, useRef } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import "./Services.Styles.scss"
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { useState } from 'react';
import IndustryArray from '../../../components/LandingpageComponents/IndustryArray/IndustryArray';
import Slider from 'react-slick';
import noImg from "../../../assets/no-image.png"
import MentorCard from './../../../components/LandingpageComponents/MentorsCard/MentorCard';
import { HiArrowLongRight } from 'react-icons/hi2';
import Footersection from '../../../components/LandingpageComponents/Footer/Footer';
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';

function Services(props) {
    let navigate = useNavigate()
    const sliderRef = useRef(null);
    const [settings, setSettings] = useState({})
    const [activeIndustry, setActiveIndustry] = useState('Accounting')
    let [mentorsList, setMentorsList] = useState([])
    let [isFilter, setIsfilter] = useState("inactive")
    const [isLoading, setIsLoading] = useState(false)
    async function myData() {
        setIsLoading(true)
        let reqData = {
            Industry: localStorage.getItem('industryType'),
            value: localStorage.getItem('searchInput')
        }
        if (reqData.Industry === '' && reqData.value === '') {
            try {
                let allMentors = await apiservice.apiCall('/ContentExpertList')
                console.log(allMentors);
                if (allMentors.data) {
                    setIsLoading(false)
                    setMentorsList([...allMentors.data])
                } else {
                    setIsLoading(false)
                    console.log(allMentors.error);
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }

        }
        else {
            try {
                setIsLoading(true)
                let allMentors = await apiservice.apiCall('/SearchContentExpert', null, reqData, 'POST')
                console.log(allMentors);

                console.log(allMentors);
                if (allMentors.data) {
                    setIsLoading(false)
                    setMentorsList([...allMentors.data])
                    console.log(allMentors.data);
                    console.log(mentorsList);
                }
                else {
                    setIsLoading(false)
                    console.log(allMentors.error);
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
    }

    const getFilterValues = () =>{
        let index = 0
        let isFil = localStorage.getItem('isFilter')
        setIsfilter(isFil)
        if (isFil) {
            let indType = localStorage.getItem('industryType');
            setActiveIndustry(indType);
            let indArray = IndustryArray();
            index = indArray.findIndex(item => item.name === indType);
            sliderRef.current.slickGoTo(index)

        }
    }

    

    useEffect(() => {
        getFilterValues()
        myData();
        window.scrollTo(0, 0)
        if (isMobile) {
            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2, 

                }
            )
        }
        if (isTablet) {
            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            )
        }
        if (isDesktop) {
            setSettings(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
            )
        }

    }, [])

    const industryListing = () => {
        let array = IndustryArray()
        let list = []

        for (let index = 0; index < array.length; index++) {
            let data = array[index]
            list.push(
                <div className={activeIndustry === data.name ? 'text-center border-bottom border-dark pointer' : 'text-center pointer'} onClick={() => clickedOnIndustry(data)} key={index} >
                    <div className='py-3 text-center'>
                        <div className='m-auto text-center industry_list_icon'>
                            <img src={data.icon} className='' alt='...' />
                        </div>
                        <div className='text-center text-break mt-3' style={{ fontSize: '11px' }}> {data.name}</div>
                    </div>
                </div>
            )

        }
        return list
    }

    async function clickedOnIndustry(data) {
        setIsLoading(true)
        let reqData = {
            Industry: data.name,
            value: localStorage.getItem('searchInput') || null
        }
        try {
            let allMentors = await apiservice.apiCall('/SearchContentExpert', null, reqData, 'POST')
            console.log(allMentors);
            if (allMentors.data) {
                setIsLoading(false)
                setMentorsList([...allMentors.data])
                console.log(allMentors.data);
            }
            else {
                setIsLoading(false)
                console.log(allMentors.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }


        setActiveIndustry(data.name);
    }


    const clickedOnMentorsCard = (items) => {
        console.log(items);
        let mentorsdata = apiservice.setLocalStorage('mentorData', items)
        // navigate('/contentexpert')
        navigate(`/contentexpert/${items.content_experts_id}`)
    }

    function getMentorsList() {
        let list = [];
        let count = 0
        let cardsToShow;

        if (isDesktop) { cardsToShow = 15 }
        else if (isTablet) { cardsToShow = 6 }
        else if (isMobile) { cardsToShow = 3 }

        for (let i = 0; i < mentorsList.length; i++) {
            let items = mentorsList[i]
            console.log(items);
            if (mentorsList.length <= cardsToShow) {

                list.push(
                    <MentorCard
                        key={i}
                        onClick={() => clickedOnMentorsCard(items)}
                        sideBgColor={`cardBg${count}`}
                        mentorsName={`${items.name} ${items.lname}`}
                        mentorsImage={items.ProfileImage === null ? items.SocialImage === null ? items.profile_photo_url : items.SocialImage : `${apiservice.apiImageShow()}/${items.ProfileImage}`}
                        mentorsDescription={items.shortDescription}
                        mentorDesignation = {`${items.lastDesignation} @ ${items.company}`}
                    />
                );
            }
            if (count === 5) {
                count = 0;
            } else {
                count++;
            }
        }
        return list;
    }

    function onClickSearch() {
        setActiveIndustry(localStorage.getItem('indusrtyType'))
        getFilterValues()
        myData()
    }
    return (
        <Fragment>
            <Header navigate={navigate} onClickSearch={onClickSearch}  />

            <section className='p-0 m-0' style={{ backgroundColor: "#f1efe7" }}>
                {/* ----------Slider Menu------------------ */}

                <article >
                    <div className='px-5 container'>
                        <div className='mt-3 border-bottom'>
                            <Slider ref={sliderRef} className='px-2'  {...settings}>
                                {industryListing()}
                            </Slider>
                        </div>
                    </div>
                </article>
                {isLoading ? <Loader /> :
                    <article className='container'>

                        <div className="row m-0">
                            {getMentorsList()}
                        </div>
                        <div className='w-100 text-center py-4 my-4'>

                            {
                                mentorsList.length === 0 ? <div className='text-center text-danger'>Exciting news! We're on a mission to bring in amazing content experts and mentors across every industry, but it takes time to find the best. We can't wait to introduce you to them soon! In the meantime, thanks for your patience.</div> :
                                    <button
                                        type="button"
                                        onClick={() => navigate('/exploreAllMentors')}
                                        className="btn btn-primary text-center px-4 border-0 py-2 btnM"
                                        style={{ backgroundColor: "#E57A4D" }}
                                    >
                                        Show More  
                                    </button>
                            }

                        </div>
                    </article>}
                <Footersection navigate={navigate} />

            </section>


        </Fragment>
    )
}

export default Services
