import React, { Fragment, useState } from "react";
import sideImageReg from "../../../assets/sideImageReg.png";
import brandLogo from "../../../assets/LOGO.png";
import "../SignUpPage/Signuppage.Styles.scss";
import { HiArrowLongRight } from "react-icons/hi2";
import validation from "../../../components/Validations";
import apiservice from "../../../apiservice/ApiService";
import Select from "react-select";
import { countries } from "country-list-json";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";

function MobileVerification(props) {
  let navigate = useNavigate()
  const initialState = {
    phone: "",
    countryCode: ''
  }
  const [data, setData] = useState(initialState)
  const [errMsg, setErrMsg] = useState('')
  const [selectedOption, setSelectedOption] = useState({ value: '+91', label: '+91' });
  let [options] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  options = countries.map((item, i) => {
    return { 'value': item.dial_code, 'label': item.dial_code }
  })

  const clickedOnSendOTP = () => {
    if (data.phone === "") {
      setErrMsg('Enter number Address')
    }
    else if (!data.phone.match(validation().onlyNumberVal)) {
      setErrMsg('Please enter valid number')
    }
    else {
      setErrMsg('')
      console.log(data);
    }
  }

  const inputhandleChange = (e) => {
    setErrMsg('')
    let { name, value } = e.target
    setData({ ...data, [name]: value })
  }
  const handleChange = (selectedOption) => {
    console.log(selectedOption.value);
    setData({ ...data, 'countryCode': selectedOption.value })
    selectedOption(selectedOption)
  };

  //-----------------Api calling function--------------------
  async function phoneVerify() {
    setIsLoading(true)
    let mobileVerification = {
      'mobile': data.phone,
      'country_Code': data.countryCode
    }
    try {
      let dataObj = mobileVerification;
      let response = await apiservice.apiCall('/signin', dataObj, 'POST')
      console.log(response);
      if (response.data) {
        setIsLoading(false)
        console.log(response.data);
      } else {
        setIsLoading(false)
        console.log(response.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }


  return (
    <Fragment>
      {isLoading ? <Loader /> :
        <div>

          <div className="container-fluid bg-white">
            <div className="container-lg d-flex justify-content-between py-3">
              <img src={brandLogo} width={72} height={60} alt="" />
            </div>
          </div>

          <div
            className="container-fluid d-flex align-items-center "
            style={{ backgroundColor: "#ADD6DA", minHeight: "100vh" }}
          >
            <div className="container py-4">
              <div
                className="card border-3 rounded-3 border-white"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  backgroundColor: "#EEEFF580",
                }}
              >
                <div className="card-body p-0 m-0 row">
                  <div className="col-lg-6 col-12 p-5">
                    <div className="p-0 m-0">
                      <h5>Verify your contact number</h5>
                      <p>
                        To receive an OTP, please enter the contact number associated
                        with your account.
                      </p>
                    </div>
                    <div className='text-center'>
                      {errMsg !== '' ? <div className='text-danger'>{errMsg}</div> : ''}
                    </div>
                    <div className=' col-12'>
                      <label htmlFor="basic-addon1" className='mt-1' >
                        Mobile
                      </label>
                      <div className="input-group  rounded-2 " style={{ border: "1px solid #BBBBBB", outline: "none" }}>
                        <span className="input-group-text bg-light p-0 " id="basic-addon1">
                          <Select onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} placeholder='+91' />
                        </span>
                        <input type="text" onChange={inputhandleChange} className="form-control " name='phone' required placeholder="9876543210" />
                      </div>
                      <div className="mb-3">
                        {errMsg.mobileNumError !== '' ?
                          <span className='fw-semibold text-danger' style={{ fontSize: '12px' }}>{errMsg.mobileNumError}</span> : ''
                        }
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between my-3">
                      <button type="submit" onClick={clickedOnSendOTP} className="btn btn-outline-dark mb-3 buttonText">
                        Send OTP 
                      </button>
                    </div>
                    <p className="clickableBlueText" onClick={() => navigate('/login')} >Back</p>
                  </div>
                  <div className="col-lg-6 col-12 d-lg-block d-none">
                    <img src={sideImageReg} className="sideImgSignUp" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

export default MobileVerification;
