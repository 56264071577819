import React, { useEffect, useState } from 'react'
import chaticon from '../../../assets/chatIcon.png'
import reviewicon from '../../../assets/reviewIcon.png'
import degreeIcon from '../../../assets/degreeIcon.png'
import StarRatings from 'react-star-ratings'
import apiservice from '../../../apiservice/ApiService'
import Loader from '../../../components/Loader'
import { useNavigate, useParams } from 'react-router-dom'

function LearnerProfilePage(props) {
    let navigate = useNavigate()

    const params = useParams();
    let initialState = {
        name: '',
        lname: '',
        education: '',
        AboutSelf: '',
        id: '',
        ProfileImage: ''
    }
    let [data, setData] = useState(initialState);
    let [userData, setuserData] = useState({});
    let [reviewdata, setReviewdata] = useState();
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {

        getAllData()


    }, [])

    async function getAllData() {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userData');
        let learnerId = params.id
        console.log(learnerId);
        await apiservice.apiCall(`/SinglelearnerData/${learnerId}`, uData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                let data = res.data[0]
                setData(data);
                getReaviews(data)
            }
            else {
                setIsLoading(false)
                console.error(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

        async function getReaviews(data) {
            setIsLoading(true)
            let uData = await apiservice.getLocalStorage('userData');
            await apiservice.apiCall(`/LearnerReviewByLearner/${data.id}`, uData.Token).then(res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setReviewdata(res.data);
                }
                else {
                    setIsLoading(false)
                    console.error(res.error);
                }
            })
        }

    }

    function listOfReviews() {
        let list = []
        let dataArr = reviewdata || []
        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            list.push(
                <div className='mt-3 p-3 border-bottom'>
                    <div className='d-flex'>
                        <div className=' '>
                            <div className='rounded-circle overflow-hidden' style={{ width: '70px', height: '70px' }}>
                                <img src={element.content_expert.ProfileImage === null ? element.content_expert.SocialImage === null ? element.content_expert.profile_photo_url : element.content_expert.SocialImage : `${apiservice.apiImageShow()}/${element.content_expert.ProfileImage}`} width={'100%'} height={'100%'} alt='' />
                            </div>
                        </div>
                        <div className='mt-3 ms-3'>
                            <div className='fw-bold'>{element.content_expert.name} {element.content_expert.lname}</div>
                            <div>
                                <StarRatings
                                    rating={Number(element.Ratting)}
                                    starRatedColor="#FDB022"
                                    numberOfStars={5}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        {element.comment}
                    </div>
                </div>
            )
        }
        return list
    }

    const onClickBack = async () => {
        let isSchedulePage = localStorage.getItem('isSchedulePage')
        if (isSchedulePage === 'true') {
            let id = localStorage.getItem('scheduleServiceid')
            navigate(`/contetexpert-home/learnerlistforschedule/${id}`);
        } else {
            navigate(`/contetexpert-home/mylearners`);

        }
    }
    async function clickedOnGiveReview() {
        await apiservice.setLocalStorage('lernerIdToPostReview', data.id)
        navigate(`/contetexpert-home/writereview/${data.id}`)

    }

    const onClickChatNow = (data) => {
        navigate('/contetexpert-home/message')
        localStorage.setItem('chatFeedValue', JSON.stringify(data))
        localStorage.setItem('chatfeedfromLearnerprofile', true)
    }
    return (
        <>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='fs-3 fw-bold'>Learner Profile</div>
                        <div>
                            <button className='btn btn-orange px-4' onClick={onClickBack}>Back</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div className='p-3 border rounded-2'>
                            <div className='row m-0'>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-3 '>
                                        <div className='rounded-circle overflow-hidden' style={{ width: '130px', height: '130px' }}>
                                            <img src={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`} className='w-100 h-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-9 mt-3 col-12'>
                                    <div className='d-none d-lg-block'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='fw-bold mt-2'>{data.name === null ? 'Learner' : `${data.name} ${data.lname}`}</div>
                                            <div className='text-end text-lg-none'>
                                                <button className='btn btn-light mb-2 mb-xl-0' onClick={() => onClickChatNow(data)}><img src={chaticon} alt='' className='me-2' />Chat Now!</button>
                                                <button className='btn btn-light ms-1 ms-lg-3' onClick={clickedOnGiveReview}><img src={reviewicon} alt='' className='me-2' />Give a Review</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-lg-none d-block'>
                                        <div className='fw-bold mt-2 mb-2'>{data.name === null ? 'Learner' : `${data.name} ${data.lname}`}</div>
                                        <div className='d-flex'>
                                            <button className='btn btn-light me-2' onClick={() => onClickChatNow(data)} style={{ fontSize: '12px' }}><img src={chaticon} alt='' className='' style={{ paddingRight: '6px' }} />Chat Now!</button>
                                            <button className='btn btn-light' onClick={clickedOnGiveReview} style={{ fontSize: '12px' }}><img src={reviewicon} alt='' className='' style={{ paddingRight: '6px' }} />Give a Review</button>

                                        </div>

                                    </div>

                                    <div className='fw-bold mt-2'>Education</div>
                                    <div className='mt-2'>{data.qualification && data.qualification === null ? 'Qualification not available' : data.qualification}</div>
                                    <div className='d-flex'>
                                        <div className='mt-2'><img src={degreeIcon} alt='' className='me-2' /> </div>
                                        <div className='mt-2'> {data.universityName === null ? 'UniversityName not available' : data.universityName}</div>
                                    </div>


                                    <div className='fw-bold mt-3'>About me</div>
                                    <div className=' mt-2'> {data.AboutSelf === null ? 'About Data not Available' :
                                        <div dangerouslySetInnerHTML={{ __html: data.AboutSelf }}></div>
                                    } </div>
                                </div>
                            </div>
                        </div>


                        <div className='mt-5'>
                            <div className=''>
                                <div className='fs-3 fw-bold'>Reviews</div>
                            </div>

                            <div className=''>
                                {listOfReviews()}
                            </div>


                        </div>
                    </div>
                </div>}
        </>
    )
}

export default LearnerProfilePage
