import React from 'react'
import './ServicesCard.scss'
import StarRatings from 'react-star-ratings'
import moment from 'moment'

function ServicesCard(props) {
  return (
    <>
      <div className="card bg-transparent border-0 myservicecard p-1 mb-2">
        <img className="card-img-top" src={props.card_top_img} height={200} alt="" style={{objectFit:'contain'}}/>
        <div className="card-body px-0">

          <div className=''>
            <div className='fw-bold serviceTitleAtMentor '>{props.title}</div>
            <small>{props.mentorName} </small>
            <div> <span className='badge text-dark' style={{ backgroundColor: '#ABEFC6' }}>{props.sessoinType}</span>
             
            </div>
            <div className='mt-2'>
              <StarRatings
                rating={Number(props.rating)}
                starRatedColor="#FDB022"
                numberOfStars={5}
              /> <span className='ps-2'>{props.rating === 0?0:Number(props.rating).toFixed(1)}</span>
            </div>
            <div className='d-flex justify-content-between mt-2 mb-2'>
              <div className='mt-2'>
                <div> <b>Total time: </b>{props.duration}</div>
                <div>  {props.sessoinType === 'Webinar Session' ?
                  props.webinarDate === null ? '' :
                    <div>
                      <div className=''>  <b>Date & Time: </b> {props.sessoinType === 'Webinar Session' ?
                          <span className='badge text-dark mb-2' style={{ backgroundColor: '#c7cce5' }}>{props.webinarDate} {props.webinarTime}</span> : ''}</div>
                      <div className=''>  <b>Time zone:</b> ({props.timeZone})</div>
                    </div>
                  :
                  ''}</div>
                <div className='fw-bold mt-2'>{props.currencySymbol} {Number(props.pricing).toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesCard
