
import React, { useState } from 'react'
import "./OnClickSlide.scss"
import Slider from 'react-slick'
import { HiArrowLongRight } from 'react-icons/hi2'
function OnClickSlide(props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    }
    const [menuStep, setmMnuStep] = useState('Mon')
    const onclickMenu = (e) => {
        setmMnuStep(e)
    }
   

    
    return (
        <>
            <div className='border p-3 rounded-2'>
                <div className='fs-6 fw-bold'>Available Time</div>
                <div className='row mt-3'>
                    <div className='px-5'>
                        <Slider  {...settings}>
                          <div>  <div className={menuStep === 'Mon' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Mon')}>Mon</div> </div>
                          <div>  <div className={menuStep === 'Tue' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Tue')}>Tue</div> </div>
                          <div>  <div className={menuStep === 'Wed' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Wed')}>Wed</div> </div>
                          <div>  <div className={menuStep === 'Thu' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Thu')}>Thu</div> </div>
                          <div>  <div className={menuStep === 'Fri' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Fri')}>Fri</div> </div>
                          <div>  <div className={menuStep === 'Sat' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Sat')}>Sat</div> </div>
                          <div>  <div className={menuStep === 'Sun' ? 'me-2 text-center border rounded-2 px-2 border-dark bg-dark text-light' : 'me-2 text-center border rounded-2 px-2 border-dark'} onClick={() => onclickMenu('Sun')}>Sun</div> </div>
                        </Slider>
                    </div>

                </div>
                <div className='mt-3'>
                    {menuStep === 'Mon' ?
                        <div>
                            <div className='p-2 border rounded-2 badge text-dark'>
                                <div>{'01:10 PM - 03:10 PM'}</div>
                            </div>
                        </div>
                        :
                        menuStep === 'Tue'
                            ?
                            <div>
                                <div className='p-2 border rounded-2 badge text-dark'>
                                    <div>{'01:10 PM - 03:10 PM'}</div>
                                </div>
                            </div>
                            :
                            menuStep === 'Wed'
                                ?
                                <div>
                                    <div className='p-2 border rounded-2 badge text-dark'>
                                        <div>{'01:10 PM - 03:10 PM'}</div>
                                    </div>
                                </div>

                                :
                                menuStep === 'Thu'
                                    ?
                                    <div>
                                        <div className='p-2 border rounded-2 badge text-dark'>
                                            <div>{'01:10 PM - 03:10 PM'}</div>
                                        </div>
                                    </div>
                                    :
                                    menuStep === 'Fri'
                                        ?
                                        <div>
                                            <div className='p-2 border rounded-2 badge text-dark'>
                                                <div>{'01:10 PM - 03:10 PM'}</div>
                                            </div>
                                        </div>
                                        :
                                        menuStep === 'Sat'
                                            ?
                                            <div>
                                                <div className='p-2 border rounded-2 badge text-dark'>
                                                    <div>{'01:10 PM - 03:10 PM'}</div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className='p-2 border rounded-2 badge text-dark'>
                                                    <div>{'01:10 PM - 03:10 PM'}</div>
                                                </div>
                                            </div>
                                             }

                                        <div className='w-100  py-4'>
                                            <button
                                            onClick={props.onclick}
                                            type="button"
                                            className="btn btn-primary text-center border-0 px-5 py-2"
                                            style={{ backgroundColor: "#E57A4D"  }}>
                                            Book Session
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

export default OnClickSlide
