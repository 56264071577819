import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import "./MentorDetail.Styles.scss";
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from "../../../components/LandingpageComponents/Footer/Footer";
import hollowStar from "../../../assets/startHollow.png";
import noImg from "../../../assets/no-image.png";
import groupOfPeople from "../../../assets/groupOfPeople.png";
import services from "../../../assets/onlieEducation.png";
import education from "../../../assets/university.png";
import nextBtn from "../../../assets/nextBtnCircle.png";
import prevBtn from "../../../assets/prevBtnIcon.png"
import mentorImg from "../../../assets/mentorImg.png";
import StarRatings from "react-star-ratings";
import { HiArrowLongRight } from "react-icons/hi2";
import MyservicesCard from "../../../components/LandingpageComponents/MyServicesCard/MyservicesCard";
import apiservice from "../../../apiservice/ApiService";
import metaTagImg from '../../../assets/test/my-image-name.png'
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function MentorDetails(props) {
  let navigate = useNavigate()
  const params = useParams();
  let [mentorsData, setMentorsData] = useState('')
  let [userData, setUserData] = useState('')
  let [mentorServices, setMentorsServices] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getMentorsData()
    // console.log(mentorsData);
    window.scrollTo(0, 0)
    localStorage.removeItem('isFilter')
  }, [])

  function clickedOnExploreAllServices() {
    navigate('/courses')
  }
  const getMentorsData = async () => {
    setIsLoading(true)
    let mData = await apiservice.getLocalStorage('mentorData')
    // console.log(mData.content_experts_id);
    let id = params.id
    await apiservice.apiCall(`/ContentExpertDetails/${id}`).then(res => {
      if (res.data) {
        setIsLoading(false)
        setIsLoading(false)
        console.log(res.data);
        let mentorData = res.data[0]
        setMentorsData(mentorData)
        getServicesByMentor(mentorData.content_expert_id)
      } else {
        setErrorMsg(res.error)
        setIsLoading(false)
        console.log(res.error)
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }

  async function getServicesByMentor(id) {
    setIsLoading(true)
    // console.log(mData);
    await apiservice.apiCall(`/ContentExpertService/${id}`).then(res => {
      console.log(res.data);
      if (res.data) {
        setIsLoading(false)
        setMentorsServices(res.data)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  function loadServices() {
    let list = [];
    if (mentorServices.length > 0) {

      for (let i = 0; i < mentorServices.length; i++) {
        let item = mentorServices[i]
        if (i < 3) {
          list.push(
            <div key={i} className="col-lg-4 col-sm-12 col-md-6" onClick={() => clickedOnService(item)}>
              <MyservicesCard
                courseTitle={item.Service_Name}
                mentorName={`${mentorsData.name} ${mentorsData.lname}`}
                serviceType={item.MentorshipType}
                ratting={item.averageRating}
                courseImg={item.ThumbnailImage}
                courseDuration={`${item.Hour}h ${item.Minutes}m`}
                timeZone={item.timezone}
                webinarDate={item.start_date}
                webinarTime={item.start_time}
                courseCost={item.Price}
                currencySymbol={item.CurrencySymbol} />

            </div>
          )
        }
      }
    } else {
      list.push(
        <div className=' fw-bold my-5 text-danger'>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
      )
    }
    return list;
  }



  const clickedOnService = async (item) => {
    await apiservice.setLocalStorage('serviceData', item)
    await // console.log(item.Service_id);
      navigate(`/servicedetail/${item.Service_id}`)
  }
  const reviewList = () => {
    let list = []
    let dataArr = (mentorsData && mentorsData.review_list) || [];
    if (dataArr.length > 0) {

    } else {
      list.push(
        <div className="my-3 fw-bold text-danger"> No reviews added yet! </div>
      )
    }
    for (let index = 0; index < dataArr.length; index++) {
      let element = dataArr[index]
      list.push(

        <div class={index === 0 ? "carousel-item active" : "carousel-item "}>
          <div >

            <div className="d-flex align-items-center py-2 mb-3">
              <div className="rounded-circle overflow-hidden" style={{ width: '100px', height: '100px' }}>

                <img src={element.learner.ProfileImage === null ? element.learner.SocialImage === null ? element.learner.profile_photo_url : element.learner.SocialImage : `${apiservice.apiImageShow()}/${element.learner.ProfileImage}`} width={'100%'} height={'100%'} alt="" />
              </div>
              <div className="px-3">
                <span className="reviewerName">{element.learner.name}</span>
                <br />
                <StarRatings
                  rating={Number(element.Ratting)}
                  starDimension='20px'
                  starRatedColor="#FDB022"
                  numberOfStars={5}
                />
              </div>
            </div>
            <p>
              {element.comment}
            </p>
          </div>
        </div>
      )

    }

    return list
  }

  return (
    <Fragment>
      <Header navigate={navigate} />
      <Helmet>
        {/* <meta property="og:title" content={'XperThrivr'} />
        <meta property="og:description" content={mentorsData.AboutSelf} />
        <meta property="og:image" content={mentorsData.ProfileImage === null ? mentorsData.SocialImage === null ? mentorsData.profile_photo_url : mentorsData.SocialImage : `${apiservice.apiImageShow()}/${mentorsData.ProfileImage}`} />

        <meta name="twitter:title" content={'XperThrivr'} />
        <meta name="twitter:description" content={mentorsData.AboutSelf} />
        <meta name="twitter:image" content={mentorsData.ProfileImage === null ? mentorsData.SocialImage === null ? mentorsData.profile_photo_url : mentorsData.SocialImage : `${apiservice.apiImageShow()}/${mentorsData.ProfileImage}`} /> */}
        {/* Add any other meta tags or elements you need */}


        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Meta Tags — Preview, Edit and Generate" />
        <meta property="og:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!" />
        <meta property="og:image" content="https://metatags.io/images/meta-tags.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta property="twitter:title" content="Meta Tags — Preview, Edit and Generate" />
        <meta property="twitter:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!" />
        <meta property="twitter:image" content="https://metatags.io/images/meta-tags.png" />
      </Helmet>
      {isLoading ? <Loader /> :
        <section
          className="mentorsDetailsSection"
          style={{ backgroundColor: "#f1efe7" }}
        >
          {errorMsg === '' ?
            <div>
              {/* -----------Top section--------------- */}
              <div className="container py-3">
                <div className="row">
                  <div className="col-lg-8 col-10">
                    <h3 className="py-lg-2" style={{ fontWeight: 600 }}>
                      Greatest Content Expert Inspire
                    </h3>
                    <p>
                      {mentorServices.Overview}
                    </p>
                  </div>
                  <div className="col-lg-2  d-sm-none d-md-none"></div>
                  <div className="col-lg-2 col-2 d-lg-block d-none">
                    {" "}
                    <img src={hollowStar} className="starsAnimatin" width={104} height={104} alt="" />{" "}
                  </div>
                </div>
              </div>

              <div className="Gmentor">
                <div className="container">
                  <div className="cardBorder p-3">
                    <div className="row">
                      <div className="col-lg-2 text-center my-lg-3">
                        <div className="d-none d-lg-block">
                          <img src={mentorsData.ProfileImage === null ? mentorsData.SocialImage === null ? mentorsData.profile_photo_url : mentorsData.SocialImage : `${apiservice.apiImageShow()}/${mentorsData.ProfileImage}`} width='150px' height='150px' alt="" className="mentorsDetailImg rounded-circle " />
                        </div>
                        <div className="d-lg-none d-block">
                          <div className="row">
                            <div className="col-4">

                              <img src={mentorsData.ProfileImage === null ? mentorsData.SocialImage === null ? mentorsData.profile_photo_url : mentorsData.SocialImage : `${apiservice.apiImageShow()}/${mentorsData.ProfileImage}`} width='100px' height='100px' alt="" className="mentorsDetailImg rounded-circle " />
                            </div>
                            <div className="col-8 text-start">
                              <div className="mb-1 title">{mentorsData.name} {mentorsData.lname} </div>
                              <div className="mb-1  Subtitle">
                                <div>{mentorsData.lastDesignation}</div>
                              </div>
                              <div>
                                <StarRatings
                                  rating={mentorsData.average_rating}
                                  starDimension='20px'
                                  starRatedColor="#FDB022"
                                  numberOfStars={5}
                                />
                                <span className="text-center ms-3 " >{mentorsData.average_rating === 0 ? 0 : Number(mentorsData.average_rating).toFixed(1)}</span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="m-2">
                          <div className="mb-1 title d-none d-lg-block">{mentorsData.name} {mentorsData.lname} </div>
                          <div className="mb-1 d-flex Subtitle d-none d-lg-block">
                            <p>{mentorsData.lastDesignation} @ {mentorsData.company}</p>
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            <div className="col mb-2 d-none d-lg-block">
                              <p>
                                <StarRatings
                                  rating={mentorsData.average_rating}
                                  starDimension='20px'
                                  starRatedColor="#FDB022"
                                  numberOfStars={5}
                                />
                                <span className="text-center ms-3 " >{mentorsData.average_rating === 0 ? 0 : Number(mentorsData.average_rating).toFixed(1)}</span>
                              </p>

                            </div>
                            <div className="col Subtitle"><p>
                              <img
                                alt=""
                                src={groupOfPeople}
                                width={24}
                                height={24}
                                className="me-3 ms-lg-3"
                              />
                              {mentorsData.student_count} {mentorsData.student_count === 1?'Learner':'Learners'}</p>
                            </div>
                            <div className="col  mt-0 mt-lg-0 Subtitle">
                              <p>
                                <img
                                  alt=""
                                  src={services}
                                  width={20}
                                  height={20}
                                  className="me-3 ms-lg-3 "
                                />
                                {mentorsData.services} {mentorsData.services === 1?'Service':'Services'}</p>
                            </div>
                          </div>
                          <div className="mb-1 title mt-lg-3 mt-0 mt-lg-0">Education</div>
                          <div className="mb-1">

                          </div>
                              <div> {mentorsData.qualification} | {mentorsData.latest_certificate} </div>
                          <div className="d-flex mb-1">
                            <div>
                              <img
                                alt=""
                                src={education}
                                className="me-3"
                                width={24}
                                height={24}
                              />
                            </div>

                            <div>
                              <p> {mentorsData.universityName}</p>
                            </div>
                          </div>
                          <div className="mb-1 title  mt-3 mt-lg-0">Experience</div>
                          <div className="mb-1"><p>Total Experience - {mentorsData.overallExp} Years</p></div>
                          <div className="mb-1 title  mt-3 mt-lg-0">About me</div>
                          <div className="mb-1 pe-5 Subtitle ">
                            {/* <p> {mentorsData.AboutSelf}</p> */}
                            <div dangerouslySetInnerHTML={{ __html: mentorsData.AboutSelf}}></div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container py-5">
                <div className="d-flex justify-content-between">
                  <h2 className="testimonialHeading">Reviews</h2>
                  <div>

                    <button class="custom-carousel-control custom-prev btn p-0" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">  <img src={prevBtn} width={40} height={40} alt="" /></button>
                    <button class="custom-carousel-control custom-next btn p-0" type="button" data-bs-target="#carouselExample" data-bs-slide="next"> <img src={nextBtn} width={40} height={40} alt="" /></button>
                  </div>
                </div>


                <div id="carouselExample" class="carousel slide position-relative">
                  <div class="carousel-inner">
                    {reviewList()}
                  </div>
                </div>





                <div className="myServices my-5">
                  <div className="d-flex justify-content-between align-items-center" >
                    <h4 className="testimonialHeading">My Services</h4>
                    <div className=" " style={{ cursor: 'pointer' }} onClick={clickedOnExploreAllServices}>
                      Explore<span className="d-lg-blone d-none">  All Services</span> <HiArrowLongRight />
                    </div>
                  </div>
                </div>

                <div className="row  bg-transparent">
                  {
                    loadServices()
                  }
                </div>

              </div>
            </div>
            :

            <div>
              <div className="text-center fs-2 my-5 text-danger">{errorMsg}</div>
            </div>
          }
          <Footersection navigate={navigate} />
        </section>}
    </Fragment>
  );
}

export default MentorDetails;
