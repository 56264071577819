import React, { useEffect, useState } from 'react'
import apiservice from '../../../apiservice/ApiService'
import { useNavigate, useParams } from 'react-router-dom'


function JoinMeeting(props) {
    let navigate = useNavigate()
    const params = useParams();
    const [meetingData, setMeetingData] = useState([])
    useEffect(() => {
        // let dataObject = JSON.parse(params.id)
        // console.log(dataObject);
        // getMeetingData()
    })
    const getMeetingData = async () => {
        let uData = await apiservice.getLocalStorage('userData')
        let dataObject = JSON.parse(params.id)
        await apiservice.apiCall('/MentorJoinSession', uData.Token, dataObject, 'POST').then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);

                let datas = res.data
                let url = `${apiservice.backendOrigin()}/meeting/index.php?name=${uData.name} ${uData.lname}&meeting_number=${datas.session_code}&meeting_pwd=${datas.session_password}&role=${1}&booking_id=${dataObject.Book_Service_Id}`
                onClickOpenNewTab(url)
                // setMeetingData(res.data)
                // createZoomMeeting(res.data)
            }
            else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }


    const createZoomMeeting = async (data) => {
        console.log(data);
        let uData = await apiservice.getLocalStorage('userData')
        console.log(uData);
        try {
            const { ZoomMtg } = await import('@zoomus/websdk');
            ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
            // loads language files, also passes any error messages to the ui
            ZoomMtg.i18n.load('en-US');
            ZoomMtg.i18n.reload('en-US');
            ZoomMtg.generateSDKSignature({
                meetingNumber: data.session_code,
                role: 1,
                sdkKey: data.sdk_key,
                sdkSecret: data.sdk_secret,
                success: function (signature) {
                    console.log(signature);
                    ZoomMtg.init({
                        leaveUrl: 'http://localhost:3000/contetexpert-home/learnerlistforschedule/3',
                        isSupportAV: true,
                        isShowJoiningErrorDialog: true, // Enable video
                        success: function (sucess) {
                            console.log(sucess);

                            try {
                                ZoomMtg.join({
                                    meetingNumber: data.session_code,
                                    signature: signature.result,
                                    sdkKey: data.sdk_key,
                                    userName: `${uData.name} ${uData.lname}`,
                                    userEmail: `${uData.email}`,
                                    passWord: data.session_password,
                                    tk: '',
                                    success: function () {
                                        // alert('suceess')
                                        console.log('--- Joined ---');
                                    },
                                    error: function (error) {
                                        // alert('error 1')
                                        console.log(error);
                                    }
                                })
                            } catch (error) {
                                // alert('error 2')
                                console.log(error);
                            }
                        },
                        error: function (error) {
                            // alert('error 3')
                            console.log(error);
                        }
                    })
                },
                error: function (error) {
                    // alert('error 4')
                    console.log(error);
                }
            })
        } catch (error) {
            // alert('error 5')
            console.log(error);
        }
    }


    const onClickOpenNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer')
    }

    return (
        <>
            <button className='btn btn-primary' onClick={getMeetingData}>Join</button>
            {/* <div>
                <ZoomMeet data={payload} />
            </div> */}
        </>
    )
}

export default JoinMeeting
