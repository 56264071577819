import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import paypallogo from '../../../assets/paypallogo.png'
import apiservice from '../../../apiservice/ApiService'
import validation from '../../../components/Validations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faGear, faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../components/Loader'
import { useNavigate } from 'react-router-dom'

import totalEarning from '../../../assets/SVG ICON/earning.svg'
import onGoingEarning from '../../../assets/SVG ICON/passive-income.svg'
import completedEarning from '../../../assets/SVG ICON/approved-payment.svg'
import receivedEarning from '../../../assets/SVG ICON/income.svg'

import moment from 'moment'

import './PaymentsPage.scss'

import DataTable from 'datatables.net-dt';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
function PaymentsPage(props) {
    let navigate = useNavigate()
    const initialData = {
        paypalId: '',
        reqAmount: ''
    }
    const [data, setData] = useState(initialData)
    const [connectPopup, setConnectPopup] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setsuccessMessage] = useState('')
    const [dashboardCount, setDashboardCount] = useState('')
    const [userData, setUserData] = useState('')

    let [isLoading, setIsLoading] = useState(false)

    const [reqPopup, setReqPopup] = useState(false)
    const [successReq, setSuccessReq] = useState('')
    const [reqError, setReqError] = useState('')
    const [reqErrorMsg, setReqErrorMsg] = useState('')
    const [paymentHistoryandCount, setPaymentHistoryandCount] = useState([])
    const [paymentHistoryListArr, setPaymentHistoryListArr] = useState([])
    useEffect(() => {
        getDashboardCount()
        getPaymentHistoryandCounts()
        setTimeout(() => {
            new DataTable('#myTable', {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: '<"top"ifrt><"bottom"lp><"clear">',


            });
        }, 1000);
    }, [])
    const onChangeHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        setErrorMessage('')
        setReqErrorMsg('')
    }

    const getDashboardCount = async () => {
        setIsLoading(true)
        let usrData = await apiservice.getLocalStorage('userData')
        setUserData(usrData)
        await apiservice.apiCall('/mentor_dashboard', usrData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setData({ ...data, 'paypalId': dashboardCount.paypal_email })
                setDashboardCount(res.data)
                setsuccessMessage('')
            }
            else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const onClickOpenPopup = () => {
        setConnectPopup(true)
        setErrorMessage('')
        setData({ ...data, 'paypalId': dashboardCount.paypal_email })
    }

    const onClickConnect = async () => {
        console.log(data.paypalId);
        if (data.paypalId === '' || data.paypalId === null) {
            setErrorMessage('Paypal Email is required!')
        } else if (!data.paypalId.match(validation().emailVal)) {
            setErrorMessage('Paypal Email is not valid!')
        } else {
            setIsLoading(true)
            let uData = await apiservice.getLocalStorage('userData')
            let dataObject = {
                paypal_email: data.paypalId
            }
            await apiservice.apiCall('/ProfilePayPalEmailAdd', uData.Token, dataObject, 'POST').then(async res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setsuccessMessage(res.data)

                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
            })
        }
    }

    const onClickOK = () => {
        setConnectPopup(false)
        getDashboardCount()
    }

    const requestPopupOpen = () => {
        setReqPopup(true)
    }
    const requestPopupClose = () => {
        setReqPopup(false)
        setData({ ...data, 'reqAmount': '' })
        setReqErrorMsg('')
        setSuccessReq('')
        setReqError('')
    }

    const paymentRequestSubmit = async () => {
        if (data.reqAmount === '') {
            setReqErrorMsg('Please Enter Amount')
        }
        else if (!data.reqAmount.match(validation().onlyNumberVal)) {
            setReqErrorMsg('Invalid Amount')

        }
        else if (data.reqAmount < 5) {
            setReqErrorMsg(`Please enter minimum ${userData.CurrencySymbol} 5.00`)

        }
        else if (data.reqAmount > 500) {
            setReqErrorMsg(`Please enter maximum ${userData.CurrencySymbol} 500.00`)
        }
        else {

            let uData = await apiservice.getLocalStorage('userData')
            let dataObject = {
                amount: data.reqAmount
            }
            console.log(dataObject);
            await apiservice.apiCall('/mentor_payout_request', uData.Token, dataObject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setReqPopup(false)
                    getPaymentHistoryandCounts()
                    setSuccessReq(res.data)
                } else {
                    setReqError(res.error)
                    console.log(res.error);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getPaymentHistoryandCounts = async () => {
        let uData = await apiservice.getLocalStorage('userData')
        await apiservice.apiCall('/mentor_payout_list', uData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                console.log(res.data);
                setPaymentHistoryandCount(res.data)
                let dataArr = res.data.payout_requests
                setPaymentHistoryListArr(dataArr)
            } else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }



    const paymentHistoryList = () => {
        let array = paymentHistoryListArr
        let list = []
        for (let index = 0; index < array.length; index++) {
            let data = array[index]
            console.log(data);
            list.push(
                <tr >
                    <td>{moment(data.created_at).format('DD-MM-YYYY')}</td>
                    <td>{data.id}</td>
                    <td>{userData.CurrencySymbol}{data.amount}</td>
                    <td>{data.transaction_id === null ? '-' : data.transaction_id}</td>
                    <td>{data.status_date === null ? '-' : data.status_date}</td>
                    <td className={data.status === 'pending' ? 'text-warning' : data.status === 'completed' ? 'text-success' : 'text-danger'}>
                        {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                    </td>
                </tr>
            )

        }
        return list

    }

    return (
        <>
            <Modal show={connectPopup}>
                <ModalBody>

                    <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickOK}><FontAwesomeIcon icon={faTimes} /></div>
                    {successMessage !== '' ?
                        <div className=''>
                            <div className='text-success fw-bold text-center'>Successfully Connected!</div>
                            <div className='mt-3 text-center'>
                                <button className='btn btn-orange' onClick={onClickOK}>OK</button>
                            </div>
                        </div>
                        :
                        <div className=''>
                            <div className='mb-3'>
                                <img src={paypallogo} alt='' width={'100px'} />
                            </div>
                            {errorMessage === '' ? '' : <div className='my-3 text-danger'><small >{errorMessage}</small></div>}
                            <div className='mb-3'>
                                <div className="form-group">
                                    <input type="text" onChange={onChangeHandle}
                                        className="form-control" name="paypalId" value={data.paypalId} placeholder="Enter Your Paypal Email" />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <button className='btn btn-orange' onClick={onClickConnect}>{dashboardCount.paypal_email === null ? 'Submit' : 'Update'}</button>
                            </div>
                        </div>}

                </ModalBody>
            </Modal>
            <Modal show={reqPopup}>
                <ModalBody>
                    {successReq !== '' || reqError !== '' ?
                        <div className='text-center'>
                            {successReq !== '' ? <div className='text-success'>{successReq}</div> : ''}
                            {reqError !== '' ? <div className='text-danger'>{reqError}</div> : ''}
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={requestPopupClose}>OK</button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={requestPopupClose}><FontAwesomeIcon icon={faTimes} /></div>
                            <div className='fw-bold fs-5'>Payment Request</div>
                            <div className='mt-3'>
                                <div class="form-group">
                                    <input type="text" onChange={onChangeHandle}
                                        class="form-control" name='reqAmount' value={data.reqAmount} placeholder={`${userData.CurrencySymbol} 0.00`} />
                                    <div className='text-danger ' >{reqErrorMsg}</div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={paymentRequestSubmit}>Submit</button>
                            </div>
                        </div>}
                </ModalBody>
            </Modal>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='d-md-flex justify-content-between'>
                        <div className='fw-bold Title   '>My Earning</div>
                        <div className='d-flex justify-content-between'>
                            <div className='mt-3 me-3'><button className='btn btn-orange' onClick={requestPopupOpen}>Payment Request</button></div>
                            {dashboardCount.paypal_email === null ?
                                <div className='mt-3'>
                                    <button className='btn btn-orange px-5 btnM' onClick={onClickOpenPopup}>Connect Payout</button>
                                </div> :

                                <div className='p-lg-3 pt-3  d-flex'>
                                    <div className=' '>
                                        <div>
                                            <div className='d-flex'>
                                                <img src={paypallogo} alt='' width={'70px'} />

                                                <button className='btn btn-orange border px-1 py-0 rounded-5 ms-3' style={{ fontSize: '10px' }} onClick={onClickOpenPopup}><FontAwesomeIcon icon={faGear} /></button>
                                            </div>

                                        </div>
                                        <div className=' text-success' style={{ fontSize: '12px' }}> Connected <FontAwesomeIcon icon={faCheckCircle} /></div>
                                        <div className='text-secondary fw-bold' style={{ fontSize: '12px' }}>
                                            {dashboardCount.paypal_email}
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mt-3 col-md-6 col-lg-3 col-12'>
                            <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                                <div className='text-end'>
                                    <img src={totalEarning} alt='' />
                                </div>
                                <div className='align-bottom'>
                                    <div className='fw-bold'> {userData.CurrencySymbol} {Number(paymentHistoryandCount.total_earning).toFixed(2)}</div>
                                    <div className=''><div>Total Earning</div></div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 col-md-6 col-lg-3 col-12'>
                            <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                                <div className='text-end'>
                                    <img src={onGoingEarning} alt='' />
                                </div>
                                <div className='align-bottom'>
                                    <div className='fw-bold'> {userData.CurrencySymbol} {Number(paymentHistoryandCount.total_ongoing).toFixed(2)}</div>
                                    <div className=''><div>Ongoing Payment</div></div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 col-md-6 col-lg-3 col-12'>
                            <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                                <div className='text-end'>
                                    <img src={completedEarning} alt='' />
                                </div>
                                <div className='align-bottom'>
                                    <div className='fw-bold'> {userData.CurrencySymbol} {Number(paymentHistoryandCount.pending_total_payment).toFixed(2)}</div>
                                    <div className=''><div>Pending Payment</div></div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 col-md-6 col-lg-3 col-12'>
                            <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                                <div className='text-end'>
                                    <img src={receivedEarning} alt='' />
                                </div>
                                <div className='align-bottom'>
                                    <div className='fw-bold'> {userData.CurrencySymbol} {Number(paymentHistoryandCount.received_total_payment).toFixed(2)}</div>
                                    <div className=''><div>Received Payment</div></div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div className='mt-3'>
                        <div>
                            {paymentHistoryListArr.length > 0 ? <div className=' overflow-auto'>

                                <table id='myTable' class="table  w-100  ">
                                    <thead>
                                        <tr className=''>
                                            <th>Request Date</th>
                                            <th>Request ID</th>
                                            <th>Amount</th>
                                            <th>Transaction ID</th>
                                            <th>Status Update Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentHistoryList()}
                                    </tbody>
                                </table>
                            </div>

                                :
                                <div className='mt-5 text-center text-danger'> You don't have any payout history yet!</div>
                            }
                        </div>
                    </div>

                </div>}
        </>
    )
}

export default PaymentsPage
