import React from "react";
import { Fragment, useEffect } from "react";
import brandLogo from "../../../assets/LOGO.png";
import { HiArrowLongRight } from "react-icons/hi2";
import sideImageReg from "../../../assets/sideImageReg.png";
import { useState } from "react";
import validation from "../../../components/Validations";
import apiservice from "../../../apiservice/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import './Resetpass.Styles.scss'
import { useNavigate } from "react-router-dom";
function Resetpass(props) {
  let navigate = useNavigate()
  const initialState = {
    newPassword: '',
    confPassword: '',
    showNewPassword: true,
    showConfirmPassword: true
  }


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [data, setdata] = useState(initialState)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')


  const inputHandlechange = (e) => {
    setErrorMsg('')

    setSuccessMsg('')
    let { name, value } = e.target
    setdata({ ...data, [name]: value })
  }

  const clickedOnSubmit = async () => {

    if (data.newPassword === '') {
      setErrorMsg('New password is required!')
    }
    else if (!data.newPassword.match(validation().passwordVal)) {
      setErrorMsg('Your password must be at least 8 characters long and include min 1 lowercase, 1 uppercase, 1 digit, and 1 special character.!')
    }
    else if (data.confPassword === '') {
      setErrorMsg('Confirm Password is required!')
    }
    else if (data.newPassword !== data.confPassword) {
      setErrorMsg('Password is mismatch!')
    }
    else {
      setErrorMsg('')
      const searchParams = new URLSearchParams(window.location.search);
      console.log(searchParams);
      let email = searchParams.get('email')
      let token = searchParams.get('token')
      console.log(data);
      let dataObject = {
        token: token,
        email: email,
        password: data.newPassword,
        password_confirmation: data.confPassword

      }
      console.log(dataObject);
      await apiservice.apiCall('/password/reset', '', dataObject, 'POST').then(res => {
        if (res.data) {
          setSuccessMsg(res.data)
          setTimeout(() => {
            setSuccessMsg('')
            setErrorMsg('')
            navigate('/login')
          }, 3000);
        } else {
          setSuccessMsg('')
          setErrorMsg(res.error)
        }
      }).catch(err => {
        setSuccessMsg('')
        console.log(err);
      })
    }
  }
  console.log(errorMsg);

  const onClickShowNewPassword = () => {
    setdata({ ...data, 'showNewPassword': data.showNewPassword = !data.showNewPassword })
  }
  const onClickShowConfirmPassword = () => {
    setdata({ ...data, 'showConfirmPassword': data.showConfirmPassword = !data.showConfirmPassword })
  }


  return (
    <Fragment>

      <div
        className="container-fluid  p-0"

      >
        <div className="reset_password_container">
          <div className="container-fluid bg-white ">
            <div className="container-lg d-flex justify-content-between py-3">
              <img src={brandLogo} width={170} height={40} alt="" />
            </div>
          </div>
          <div className="container py-4">
            <div
              className="card border-3 rounded-3 border-white"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                backgroundColor: "#EEEFF580",
              }}
            >
              <div className="card-body p-0 m-0 row">
                <div className="col-lg-6 col-12 p-5">
                  <div className="py-2 my-2">
                    <h5 className="regHeadText">Reset your password</h5>
                  </div>

                  <div className='text-center'>
                    {errorMsg !== '' ? <div className='text-danger'>{errorMsg}</div> : ''}
                    {successMsg !== '' ? <div className='text-success'>{successMsg}</div> : ''}
                  </div>

                  <div className='mb-3'>
                    <label htmlFor="">New Password</label>
                    <div className="input-group ">
                      <input type={data.showNewPassword ? "password" : "text"}
                        className="form-control border-end-0" name="newPassword" value={data.newPassword} onChange={inputHandlechange} placeholder="Password" style={{ border: "1px solid #BBBBBB", outline: "none" }} />
                      <span className="input-group-text bg-white " id="basic-addon2" style={{ border: "1px solid #BBBBBB", outline: "none" }}>
                        {data.showNewPassword ?
                          <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowNewPassword} /> :
                          <FontAwesomeIcon icon={faEye} onClick={onClickShowNewPassword} />}
                      </span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor="">Confirm Password</label>
                    <div className="input-group ">
                      <input type={data.showConfirmPassword ? "password" : "text"}
                        className="form-control border-end-0" name="confPassword" value={data.confPassword} onChange={inputHandlechange} placeholder="Re-type Password" style={{ border: "1px solid #BBBBBB", outline: "none" }} />
                      <span className="input-group-text bg-white " id="basic-addon2" style={{ border: "1px solid #BBBBBB", outline: "none" }}>
                        {data.showConfirmPassword ?
                          <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowConfirmPassword} /> :
                          <FontAwesomeIcon icon={faEye} onClick={onClickShowConfirmPassword} />}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-lg-row justify-content-between align-content-between ">
                    <button type="submit" onClick={clickedOnSubmit} className="btn btn-outline-dark my-3 buttonText">
                      Change Password
                    </button>
                  </div>

                  <p className="clickableBlueText p-1" onClick={() => navigate('/login')} >Cancel</p>
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none pt-5 mb-5">
                  <img src={sideImageReg} className="sideImgSignUp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Resetpass;
