import React from 'react'
import { Pagination } from 'react-bootstrap';
import './Notificationpage.scss'
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import person from '../../../assets/person.png'
import apiservice from '../../../apiservice/ApiService';
import { useEffect } from 'react';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';

function NotificationPage(props) {
  let navigate = useNavigate()
  useEffect(() => {
    getNotification()
  }, [])
  const [notificationArr, setNotificationArr] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const getNotification = async () => {
    setIsLoading(true)
    let uData = await apiservice.getLocalStorage('userData')
    await apiservice.apiCall(`/learner_get_all_notifications`, uData.Token).then(res => {
      console.log(res.data);
      if (res.unAuthenticated) {
        navigate('/')
        localStorage.removeItem('userData')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setNotificationArr(res.data)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = notificationArr.slice(startIndex, endIndex);

  const totalPages = Math.ceil(notificationArr.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  var colors = [
    '#4CCF8C',
    '#FD853A',
    '#FD6F8E',
    '#BDB4FE',
    '#53B1FD',
  ]

  const notificationList = () => {
    let array = itemsToDisplay
    let list = []


    for (let index = 0; index < array.length; index++) {
      let data = array[index]
      list.push(
        <div className='p-3 row border-bottom'>
          <div className='col-lg-10 col-12 pt-2 text-break'>
            {data.message}
          </div>
          <div className='col-lg-2 mt-lg-0 mt-3 col-12 pt-2'> {moment(new Date(data.created_at)).startOf('minite').fromNow()} </div>
        </div>
      )
    }
    return list
  }
  return (
    <>
      {isLoading ? <Loader /> :
        <div className='p-3'>
          <div className='container-fluid'>
            <div className='fs-3 fw-bold'>Notification</div>
          </div>
          <div className='mt-3'>
            {notificationArr.length > 0 ?
              <div>

                <div>
                  {notificationList()}
                </div>

                <div className="pagination-container mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination>
                    <Pagination.Prev
                      className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1 ? true : false}
                    >
                      <img src={currentPage === 1 ? leftLight : left} alt='...' />
                    </Pagination.Prev>

                    {[...Array(totalPages).keys()].map((page) => (
                      <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Next
                      className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages ? true : false}
                    >
                      <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                    </Pagination.Next>
                  </Pagination>
                </div>
              </div>
              :
              <div className='my-5 text-center text-danger fw-bold'>We regret to inform you that our database search yielded no results matching your query. Despite a comprehensive check, there were no matches found. Please consider refining your search criteria and trying again.</div>
            }


          </div>
        </div>}

    </>
  );
}

export default NotificationPage
