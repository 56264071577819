import React from 'react'
import card1 from '../../../assets/MultiColorCard/multiColCardICbuizz1.png'
import card2 from '../../../assets/MultiColorCard/multiColCardICbuizz2.png'
import card3 from '../../../assets/MultiColorCard/multiColCardICbuizz3.png'
import card4 from '../../../assets/MultiColorCard/multiColCardICbuizz4.png'
import card5 from '../../../assets/MultiColorCard/multiColCardICbuizz5.png'
import card6 from '../../../assets/MultiColorCard/multiColCardICbuizz6.png'
import card7 from '../../../assets/MultiColorCard/multiColCardICbuizz7.png'
import card8 from '../../../assets/MultiColorCard/multiColCardICbuizz8.png'
import card9 from '../../../assets/MultiColorCard/multiColCardICbuizz9.png'
import './MultiColorCard.scss'
// import ScrollAnimation from 'react-animate-on-scroll'
function MultiColorCard() {
    return (
        <>
            <div className='container multi-color-card'>
                <div className='my-lg-3'>
                    <div className='row'>
                        <div className='col-lg-4 p-lg-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card1">
                                    <img className="card-img-top" src={card1} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Consultants</h4>
                                        <p className="card-text">Provide 1:1 online consultation sessions and set your rates based on your expertise. Use our multi-sessions feature for clients who need ongoing support or need help on a specific project.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card2">
                                    <img className="card-img-top" src={card2} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">E-lawyers</h4>
                                        <p className="card-text">Ideal platform to provide legal consultation, document review and drafting, and representation in legal matters.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card3">
                                    <img className="card-img-top" src={card3} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Software & Technology</h4>
                                        <p className="card-text">Teach your tech know-how (JavaScript, game development, designing, etc) via online sessions. Start a tech consulting business with your tech expertise using our platform.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card4">
                                    <img className="card-img-top" src={card4} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Educational institutions and online tutors</h4>
                                        <p className="card-text">Host live webinars or 1:1 sessions to deliver lectures where students can ask questions and participate in discussions. Upload high-quality, engaging learning materials relevant to your students’ needs.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card5">
                                    <img className="card-img-top" src={card5} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Leadership consultants</h4>
                                        <p className="card-text">Via 1:1 sessions, offer paid coaching and mentoring programs to help clients develop their leadership skills. Host educational webinars on topics such as leadership development and conflict resolution.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card6">
                                    <img className="card-img-top" src={card6} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Health & Fitness</h4>
                                        <p className="card-text">Offer health and fitness coaching via online sessions. Become an online freelance fitness instructor and set your own rates and hours.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card7">
                                    <img className="card-img-top" src={card7} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Spirituality & astrology</h4>
                                        <p className="card-text">Via online sessions, use your healing gifts to make a difference. Help students to connect with their inner selves and discover their spiritual path. Provide group meditation sessions to your students.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card8">
                                    <img className="card-img-top" src={card8} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Sales and marketing</h4>
                                        <p className="card-text">Using our 1:1 and webinar feature, help your clients achieve their marketing objective by scheduling and conducting coaching sessions. Offer marketing consultations to your clients with confidence.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className='col-lg-4 p-3 col-md-6 col-12'>
                            {/* <ScrollAnimation animateIn='animate__zoomIn' > */}
                                <div className="card p-2 border-0 card9">
                                    <img className="card-img-top" src={card9} alt=".." />
                                    <div className="card-body">
                                        <h4 className="card-title">Counselling & Therapy</h4>
                                        <p className="card-text">Build strong relationships with your clients and provide them with personalised and confidential service using our 1:1 online session feature.</p>
                                    </div>
                                </div>
                            {/* </ScrollAnimation> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultiColorCard
