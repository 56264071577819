
import React, { useEffect } from 'react'
import "./LearnerListForSchedule.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';
import left from '../../../assets/ArrowLeftShape.png'
import right from '../../../assets/ArrowRightShape.png'
import leftLight from '../../../assets/ArrowLightLeftShape.png'
import rightLight from '../../../assets/ArrowLightRightShape.png'
import { Pagination } from 'react-bootstrap';
import apiservice from '../../../apiservice/ApiService';
import CardForScheduleLearner from '../../../components/MentorsComponents/CardForScheduleLearner/CardForScheduleLearner';
import AllLearner from './AllLearner';
import ScheduledLearner from './ScheduledLearner';
import HistoryOfLearner from './HistoryOfLearner';
import { useNavigate, useParams } from 'react-router-dom';

function LearnerListForSchedule(props) {
    let navigate = useNavigate()
    const params = useParams();
    let [myLearners, setMyLearners] = useState([])
    const [currentBtn, setCurrentBtn] = useState(1)
    useEffect(() => {
        let value = localStorage.getItem('schedulePageMenu')
        setCurrentBtn(Number(value))
        console.log(Number(value));
    })

    const onClickTopbtn = (id) => {
        localStorage.setItem('schedulePageMenu', id)
        let value = localStorage.getItem('schedulePageMenu')
        setCurrentBtn(value)
    }

    return (
        <>
            <div>
                <div className='p-3'>
                    <div className='fs-3 fw-bold'>List of Learners</div>
                    <hr />
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-lg-3 '>
                                <button className={currentBtn === 1 ? 'btn w-100 active_button rounded-2 border btnM' : 'btn w-100 bg-light rounded-2 border btnM'} onClick={() => onClickTopbtn(1)}>All Booked</button>
                            </div>
                            <div className='col-lg-3 col-6 mt-lg-0 mt-3'>
                                <button className={currentBtn === 2 ? 'btn w-100 active_button rounded-2 border btnM' : 'btn w-100 bg-light rounded-2 border btnM'} onClick={() => onClickTopbtn(2)}>Scheduled</button>
                            </div>
                            <div className='col-lg-3 col-6 mt-lg-0 mt-3'>
                                <button className={currentBtn === 3 ? 'btn w-100 active_button rounded-2 border btnM' : 'btn w-100 bg-light rounded-2 border btnM'} onClick={() => onClickTopbtn(3)}>History</button>
                            </div>
                        </div>

                    </div>

                    <div className='mt-3'>
                        {currentBtn === 1 ?
                            <div>
                                <AllLearner data={myLearners} navigate={navigate} id={params.id} />
                            </div>
                            : currentBtn === 2 ?
                                <div>
                                    <ScheduledLearner data={myLearners} navigate={navigate} id={params.id} />
                                </div>
                                :
                                <div>
                                    <HistoryOfLearner data={myLearners} navigate={navigate} id={params.id} />
                                </div>
                        }
                    </div>


                </div>
            </div>
        </>
    )
}

export default LearnerListForSchedule
