import React, { useEffect, useRef, useState } from 'react'
import './ProfilePage.scss'
import profileimage from '../../../assets/profileImage.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPencilAlt, faPlus, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { countries } from 'country-list-json';
import Select from 'react-select';
import apiservice from './../../../apiservice/ApiService';
import validation from './../../../components/Validations';
import { BiRupee } from 'react-icons/bi';
import GetTimeZone from '../../../components/GetTimeZone/GetTimeZone';
import noImage from "../../../assets/no-image.png"
import ShareOnSocial from 'react-share-on-social';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Cropper from 'react-easy-crop';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment/moment';
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}
function ProfilePage(props) {
    let navigate = useNavigate()
    const initialDataState = {
        profileImage: '',
        selectedImgURL: '',
        profileLink: '',
        firstName: '',
        lastName: '',
        aboutYourself: '',
        shortDescription: '',

        username: '',
        birthDate: '',
        languages: '',

        companyName: '',
        designation: '',
        startDate: '',
        endDate: '',
        rolesResponsibility: '',

        certificateName: '',
        certificateAuth: '',
        certificateId: '',
        certificateUrl: '',

        courseName: '',
        collegeName: '',
        universityName: '',
        startingYear: '',
        completionYear: '',

        emailAddress: '',
        countryCode: '',
        mobileNo: '',

        currentPassword: '',
        newPassword: '',
        reEnterPassword: '',

        timeZone: '',
        Currency: '',
        CurrencySymbol: '',

        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        bankBranch: '',
        accountType: '',
        ifscCode: '',
        IBANNumber: '',
        BICNumber: '',
        SortCode: '',
        SwiftCode: '',
        BankCode: '',
        FIN: '',
        BTN: '',
        BSB: '',

        currExpId: '',
        currCerId: '',
        currEduId: '',

        educationArr: [],
        workExpArr: [],
        certiArr: [],

        isAdminVerified: "0"
    };

    const [data, setData] = useState(initialDataState);
    const [userData, setUserData] = useState('')
    const [menuKey, setMenuKey] = useState('Profile');
    const [errorMsg, setErrorMsg] = useState('')
    const [bankvalidData, setBankvalidData] = useState(false)
    const [isWorkExpEdit, setWorkExpEdit] = useState(false)
    const [isCertiEdit, setCertiEdit] = useState(false)
    const [isEduEdit, setEduEdit] = useState(false)
    let [isVerified, setIsVerified] = useState(false)
    let [successMsg, setSuccessMsg] = useState(false)
    let [isLoading, setIsLoading] = useState(false)
    const [isMobileEditable, setIsMobileEditable] = useState(false)
    const [isCurrencyEditable, setIsCurrencyEditable] = useState(false)
    const [bankError, setBankError] = useState('')
    const [bankSuccess, setBankSuccess] = useState('')
    const inputRef = React.useRef();
    const [profileApprovePopup, setProfileApprovePopup] = useState(false)
    const [profileSubmittedMsg, setProfileSubmittedMsg] = useState('')
    const [successFullyUpdate, setSuccessFullyUpdate] = useState(false)
    const [submitProfileError, setSubmitProfileError] = useState('')
    const [selectedOption, setSelectedOption] = useState({ value: 'US +1', label: 'US +1', code: '+1' });
    // const [crop, setCrop] = useState({ x: 0, y: 0 });
    // const [zoom, setZoom] = useState(1);
    // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropPopUp, setCropPopUp] = useState(false)
    const [currencyList, setCurrencyList] = useState([])

    const [isPresent, setIsPresent] = useState(0)

    const [yourselfValue, setYourselfValue] = useState('')

    useEffect(() => {

        syncMentorsData()
        getWorkExeperience()
        getEducation()
        getCertificates()
        getCurrencyList()

    }, [])




    async function syncMentorsData() {
        setIsLoading(true)
        let allData = await apiservice.getLocalStorage('userData');

        let expdata;
        try {
            expdata = await apiservice.apiCall('/expprofile', allData.Token)
            if (expdata.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (expdata.data) {
                setIsLoading(false)
                console.log(expdata.data);
            }
            else {
                setIsLoading(false)
                console.log(expdata.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }


        let certificateData;
        try {
            certificateData = await apiservice.apiCall('/certiprofile', allData.Token)

            if (certificateData.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (certificateData.data) {
                setIsLoading(false)
                console.log(certificateData.data);
            }
            else {
                setIsLoading(false)
                console.log(certificateData.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }


        let educationData;
        try {
            educationData = await apiservice.apiCall('/eduprofile', allData.Token)
            if (educationData.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (educationData.data) {
                setIsLoading(false)
                console.log(educationData.data);
            }
            else {
                setIsLoading(false)
                console.log(educationData.error);
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }

        console.log(allData);
        console.log(expdata);
        console.log(certificateData);
        console.log(educationData);
        setUserData(allData)
        setSelectedOption((allData.country_code && { value: allData.country_dial_code, label: allData.country_dial_code, code: allData.country_code }) || '')
        setData({
            ...data,
            firstName: allData.name || "",
            lastName: allData.lname || "",
            aboutYourself: allData.AboutSelf || "",
            shortDescription: allData.shortDescription || "",
            profileLink: `${apiservice.webOriginUrl()}/contentexpert/${allData.UserName}`,
            profileImage: allData.ProfileImage === null ? allData.SocialImage === null ? allData.profile_photo_url : allData.SocialImage : `${apiservice.apiImageShow()}/${allData.ProfileImage}`,
            username: allData.UserName || "",
            birthDate: allData.Birthdate || "",
            languages: allData.Language || "",
            Token: allData.Token || "",
            educationArr: educationData.data || [],
            workExpArr: expdata.data || [],
            certiArr: certificateData.data || [],
            emailAddress: allData.email || "",
            countryCode: (allData.country_code && { value: allData.country_dial_code, label: allData.country_dial_code, code: allData.country_code }) || '',
            mobileNo: allData.mobile || "",
            timeZone: allData.TimeZone || "",
            Currency: allData.Currency || "",
            accountHolderName: allData.HolderName || "",
            accountNumber: allData.AccountNumber || "",
            ifscCode: allData.IFSC_code || "",
            bankName: allData.BankName || "",
            bankBranch: allData.Branch || "",
            accountType: allData.AccountType || "",
            IBANNumber: allData.iban_number || "",
            BICNumber: allData.bic_number || "",
            SortCode: allData.sort_code || "",
            SwiftCode: allData.swift_code || "",
            BankCode: allData.bank_code || "",
            FIN: allData.fin || "",
            BTN: allData.btn || "",
            BSB: allData.bsb || "",
            isAdminVerified: allData.is_admin_verified || 0,
        })
        setYourselfValue(allData.AboutSelf || '')
        if (allData.country_code === null || allData.mobile === null || !allData.mobile.match(validation().mobileVal)) {
            setIsMobileEditable(false)
        } else {
            setIsMobileEditable(true)

        }
        if (allData.Currency === null || allData.Currency === null || allData.Currency === undefined) {
            setIsCurrencyEditable(false)
        } else {
            setIsCurrencyEditable(true)
        }
    }

    console.log(data);
    const handleInputChange = event => {

        const { name, value } = event.target;
        if (name === 'accountHolderName' && !value.match(validation().onlyStringVal)) {
            setBankvalidData(true)
            setErrorMsg('Please enter valid input Account Holder Name is not accepted numbers and special character!')
        }
        else if (name === 'accountNumber' && (value.length < 3 || value.length > 30)) {
            setBankvalidData(true)
            setErrorMsg('account no is invalid!')
        }
        else if (name === 'ifscCode' && !value.match(validation().IFSCCodeVal)) {
            setBankvalidData(true)
            setErrorMsg('IFSC code is invalid!')
        }
        else if (name === 'bankName' && !value.match(validation().onlyStringVal)) {
            setBankvalidData(true)
            setErrorMsg('Please enter valid input Bank Name is not accepted numbers and special character!')
        }
        else if (name === 'bankBranch' && !value.match(validation().onlyStringVal)) {
            setBankvalidData(true)
            setErrorMsg('Please enter valid input Bank Branch is not accepted numbers and special character!')
        }
        else if (name === 'accountType' && value === '') {
            setBankvalidData(true)
            setErrorMsg('Type of Account is required!')
        }
        else {
            setBankvalidData(false)
            setErrorMsg('')
            setWorkExperienceError('')
            setCertificationsError('')
            setEducationError('')
            setBankError('')
            setSubmitProfileError('')
        }
        setData({ ...data, [name]: value });
    };

    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [cropString, setCropString] = useState('')


    const onSelectProfileImage = (e) => {
        const file = e.target.files[0];

        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setCropPopUp(true)
                setData({ ...data, profileImage: reader.result })
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }

    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 1));
    }

    async function completedCrop(ele) {
        if (ele?.width && ele?.height && imgRef.current) {
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = ele.width;
            canvas.height = ele.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                imgRef.current,
                ele.x * scaleX,
                ele.y * scaleY,
                ele.width * scaleX,
                ele.height * scaleY,
                0,
                0,
                ele.width,
                ele.height
            );
            const base64String = canvas.toDataURL('image/png');
            setCropString(base64String)
            console.log(base64String);
        }
    }

    const handleCropButtonClick = () => {
        setCropPopUp(false)
        setData({ ...data, profileImage: cropString, selectedImgURL: cropString, })
    }



    const onClickOK = () => {
        setData({ ...data, selectedImgURL: '', })
        setCropPopUp(false)

    }

    const onClickTopMenu = (e) => {
        setMenuKey(e)
        setErrorMsg('')
    }

    const handleChange = (selectedOption) => {
        console.log(selectedOption);
        setData({ ...data, 'countryCode': selectedOption })
        setSelectedOption(selectedOption)
    };



    let [options] = useState([]);
    options = countries.map((item, i) => {
        // console.log(item);
        return { 'value': `${item.code} ${item.dial_code}`, 'label': `${item.code} ${item.dial_code}`, 'code': item.dial_code }
    }).sort((a, b) => a.label.localeCompare(b.label));





    const [workExperience, setWorkExperience] = useState(false)
    const [workExperienceError, setWorkExperienceError] = useState('')

    const onClickAddExperience = () => {
        setWorkExperience(true)
        setWorkExperienceError('')
        setIsPresent(0)
        setData({ ...data, companyName: '', designation: '', startDate: '', endDate: '', rolesResponsibility: '' })
    }
    const onClickCloseExperience = () => {
        setWorkExperience(false)
        setWorkExperienceError('')
        setIsPresent(0)
        setData({ ...data, companyName: '', designation: '', startDate: '', endDate: '', rolesResponsibility: '' })
    }

    const [certifications, setCertifications] = useState(false)
    const [certificationsError, setCertificationsError] = useState('')
    const onClickAddCertifications = () => {
        setCertifications(true)
        setCertificationsError('')
        setData({ ...data, certificateName: '', certificateAuth: '', certificateId: '', certificateUrl: '' })
    }
    const onClickCloseCertifications = () => {
        setCertifications(false)
        setCertificationsError('')
        setData({ ...data, certificateName: '', certificateAuth: '', certificateId: '', certificateUrl: '' })
    }

    const [education, setEducation] = useState(false)
    const [educationError, setEducationError] = useState(false)
    const onClickAddEducation = () => {
        setEducation(true)
        setEducationError('')
        setData({ ...data, courseName: '', universityName: '', startingYear: '', completionYear: '' })
    }
    const onClickCloseEducation = () => {
        setEducation(false)
        setEducationError('')
        setData({ ...data, courseName: '', universityName: '', startingYear: '', completionYear: '' })
    }
    const onClickSaveChange = async () => {
        let trimAboutSelf = yourselfValue.replace(/<.*?>/g, "")
        if (menuKey === 'Profile') {
            if (data.firstName === '') {
                setErrorMsg('First Name is required!')
            }
            else if (data.firstName.trim() === '') {
                setErrorMsg('First Name is required!')
            }
            else if (data.lastName === '') {
                setErrorMsg('Last Name is required!')
            }
            else if (data.lastName.trim() === '') {
                setErrorMsg('Last Name is required!')
            }
            else if (data.shortDescription === '') {
                setErrorMsg('Short Description is required!')
            }
            else if (data.shortDescription.trim() === '') {
                setErrorMsg('Short Description is required!')
            }
            else if (yourselfValue === '') {
                setErrorMsg('About your self is required!')
            }
            else if (trimAboutSelf.trim() === '') {
                setErrorMsg('About your self is required!')
            }
            else {
                updateData()
            }
        }

        if (menuKey === 'Personal') {
            if (data.birthDate === '') {
                setErrorMsg('Birthdate is required!')
            } else if (new Date(data.birthDate) >= new Date()) {
                setErrorMsg('Birthdate is invalid!')
            }
            // else if (data.languages === '') {
            //     setErrorMsg('Language is required!')
            // }
            else {
                updateData()
            }
        }
        if (menuKey === 'Other') {
            if (data.countryCode === '') {
                setErrorMsg('Please enter your country code!')
            }
            else if (data.mobileNo === '') {
                setErrorMsg('Please enter your phone number!')
            } else if (!data.mobileNo.match(validation().mobileVal)) {
                setErrorMsg('Phone number is not valid!')

            }
            else {
                updateData()
            }
        }
    }


    // -----------work Exp------------
    const workExperienceSaveBtn = () => {
        if (data.companyName.trim() === '') {
            setWorkExperienceError('Company name is required!');
        }
        else if (data.designation.trim() === '') {
            setWorkExperienceError('Designation is required!');
        }
        else if (!data.designation.match(validation().stringWithSpecialCharacter)) {
            setWorkExperienceError('Designation is invalid!');
        }
        else if (data.startDate === '') {
            setWorkExperienceError('Start date is required!');
        }
        else if (isPresent === 0 && data.endDate === '') {
            setWorkExperienceError('End date is required!');
        }
        else if (isPresent === 0 && (new Date(data.endDate) < new Date(data.startDate))) {
            setWorkExperienceError('End date should be greater than Start Date!');
        }
        else if (data.rolesResponsibility.trim() === '') {
            setWorkExperienceError('Roles Responsibility is required!');
        }
        else {
            let dataObject = {
                CompanyName: data.companyName,
                Designation: data.designation,
                StartDate: data.startDate,
                EndDate: data.endDate,
                is_working: isPresent,
                RR: data.rolesResponsibility,
            }
            saveWorkExperiance(dataObject)
            console.log(dataObject);
        }
    }
    async function saveWorkExperiance(dataObject) {
        setIsLoading(true)
        try {
            let dataObj = dataObject;
            let response = await apiservice.apiCall('/expprofilestore', data.Token, dataObj, 'POST')
            console.log(response);
            if (response.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (response.data) {
                setIsLoading(false)
                console.log(response.data);
                getWorkExeperience()
                syncMentorsData()
            } else {
                setIsLoading(false)
                console.log(response.error);
                setErrorMsg('Invalid Credentials')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    const workExpList = () => {
        let list = []
        let dataArr = []
        if (data.workExpArr === 'User Doesnot Have Data..') {
            dataArr.length = 0
        }
        else {
            dataArr = data.workExpArr;
        }
        for (let index = 0; index < dataArr.length; index++) {
            const currentdata = dataArr[index];
            if (index <= 3) {

                list.push(
                    <div key={index} className='mt-3 '>
                        <div className='p-3 border rounded-2'>
                            <div></div>
                            <div className='text-end'>
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => clickedOnEditWorkExp(currentdata)} className='me-3 pointer' />
                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteCurrentWorkExp(index)} className='pointer' />
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-12 fw-semibold'>Company name : </div>
                                        <div className='col-lg-7 col-12'>{currentdata.CompanyName}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-12 fw-semibold'>Designation : </div>
                                        <div className='col-lg-7 col-12'>{currentdata.Designation}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-12 fw-semibold'>Start date : </div>
                                        <div className='col-lg-7 col-12'>{moment(currentdata.StartDate).format('DD-MM-YYYY')}</div>
                                    </div>
                                </div>
                                {currentdata.is_working === 1 ? 'I am currently working in this role!' :
                                    <div className='col-lg-6 col-12'>
                                        <div className='row'>
                                            <div className='col-lg-5 col-12 fw-semibold'>End date : </div>
                                            <div className='col-lg-7 col-12'>{moment(currentdata.EndDate).format('DD-MM-YYYY')}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-lg-2 col-12 fw-semibold'>Roles and responsibility : </div>
                                        <div className='col-lg-10 col-12 ps-lg-5 '>{currentdata.RR}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        }
        return list
    }

    const getWorkExeperience = async () => {
        setIsLoading(true)
        let allData = await apiservice.getLocalStorage('userData');
        await apiservice.apiCall('/expprofile', allData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setData({
                    ...data, workExpArr: res.data,
                    companyName: '',
                    designation: '',
                    startDate: '',
                    endDate: '',
                    rolesResponsibility: '',
                })
                setWorkExperience(false)
            } else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }


    async function deleteCurrentWorkExp(index) {
        setIsLoading(true)
        try {
            let allData = await apiservice.getLocalStorage('userData');
            await apiservice.apiCall('/expprofile', allData.Token).then(async res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    console.log(res);
                    let itemToDeleteID = res.data[index].ex_id
                    let itemTodelete = res[index]
                    await apiservice.apiCall(`/expprofiledelete/${itemToDeleteID}`, allData.Token, itemTodelete, 'POST').then(response => {
                        if (response.unAuthenticated) {
                            navigate('/')
                            localStorage.removeItem('userData')
                        } else if (response.data) {
                            setIsLoading(false)
                            console.log(response.data);
                        } else {
                            setIsLoading(false)
                            console.log(response.error);
                        }
                    }).catch(err => {
                        setIsLoading(false)
                        console.log(err);
                    })
                } else {
                    setIsLoading(false)
                    console.log(res.error);
                }
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
            })

            getWorkExeperience()
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    async function clickedOnEditWorkExp(datas) {
        console.log(datas);
        setWorkExperience(true)
        setWorkExpEdit(true)
        setIsPresent(datas.is_working)
        setData({
            ...data,
            companyName: datas.CompanyName,
            designation: datas.Designation,
            startDate: datas.StartDate,
            endDate: datas.EndDate,
            rolesResponsibility: datas.RR,
            currExpId: datas.ex_id,
        })
    }


    async function saveEditedWorkExp() {
        if (data.companyName.trim() === '') {
            setWorkExperienceError('Company name is required!');
        }
        else if (data.designation.trim() === '') {
            setWorkExperienceError('Designation is required!');
        }
        else if (!data.designation.match(validation().stringWithSpecialCharacter)) {
            setWorkExperienceError('Designation is invalid!');
        }
        else if (data.startDate === '') {
            setWorkExperienceError('Start date is required!');
        }
        else if (isPresent === 0 && data.endDate === '') {
            setWorkExperienceError('End date is required!');
        }
        else if (isPresent === 0 && (new Date(data.endDate) < new Date(data.startDate))) {
            setWorkExperienceError('End date should be greater than Start Date!');
        }
        else if (data.rolesResponsibility.trim() === '') {
            setWorkExperienceError('Roles Responsibility is required!');
        }
        else {
            setIsLoading(true)
            let dataObj = {
                CompanyName: data.companyName,
                Designation: data.designation,
                StartDate: data.startDate,
                EndDate: data.endDate,
                is_working: isPresent,
                RR: data.rolesResponsibility,
                ex_id: data.currExpId,
            };
            let itemToEditID = dataObj.ex_id;
            try {
                let response = await apiservice.apiCall(`/expprofileupdate/${itemToEditID}`, data.Token, dataObj, 'POST')
                console.log(response);
                if (response.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (response.data) {
                    setIsLoading(false)
                    console.log(response.data);
                    getWorkExeperience()
                    setWorkExpEdit(false)
                    syncMentorsData()
                    setData({
                        ...data,
                        companyName: dataObj.CompanyName,
                        designation: dataObj.Designation,
                        startDate: dataObj.StartDate,
                        endDate: dataObj.EndDate,
                        rolesResponsibility: dataObj.RR,
                        currEduId: dataObj.ex_id,
                    })
                    setWorkExperience(false)

                } else {
                    setIsLoading(false)
                    console.log(response.error);
                    setErrorMsg('Invalid Credentials')
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
    }
    const onChangeIsPresetCheckBox = (e) => {
        console.log(e.target.checked);
        let checkedValue = e.target.checked

        setIsPresent(checkedValue ? 1 : 0)
    }
    // -----------Certificates------------

    const certificationSaveBtn = () => {

        if (data.certificateName === '') {
            setCertificationsError('Certification name is required!')
        }
        else if (data.certificateName.trim() === '') {
            setCertificationsError('Certification name is required!')
        }
        else if (!data.certificateName.match(validation().stringWithSpecialCharacter)) {
            setCertificationsError('Certification name is invalid!')
        }
        else if (data.certificateAuth === '') {
            setCertificationsError('Certificate Auth is required!')
        }
        else if (data.certificateAuth.trim() === '') {
            setCertificationsError('Certificate Auth is required!')
        }
        else if (data.certificateId === '') {
            setCertificationsError('Certificate ID is required!')
        }
        else if (data.certificateId.trim() === '') {
            setCertificationsError('Certificate ID is required!')
        }
        else {
            let dataObject = {
                Name: data.certificateName,
                Authority: data.certificateAuth,
                Cerid: data.certificateId,
                URL: data.certificateUrl,
            }
            saveCertificates(dataObject)
            console.log(dataObject);
        }
    }
    async function saveCertificates(dataObject) {
        setIsLoading(true)
        try {
            let dataObj = dataObject;
            let response = await apiservice.apiCall('/certiprofilestore', data.Token, dataObj, 'POST')
            console.log(response);
            if (response.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (response.data) {
                setIsLoading(false)
                console.log(response.data);
                getCertificates()
                setCertifications(false)
                syncMentorsData()
            } else {
                setIsLoading(false)
                console.log(response.error);
                setErrorMsg('Invalid Credentials')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    async function deleteCurrentCertificate(element, index) {
        setIsLoading(true)
        let token = data.Token
        let res = await apiservice.apiCall(`/certiprofiledelete/${element.cer_id}`, token, null, 'POST')
        if (res.unAuthenticated) {
            navigate('/')
            localStorage.removeItem('userData')
        } else if (res.data) {
            setIsLoading(false)
            console.log(res);
        } else {
            setIsLoading(false)
            console.log(res);
        }
        getCertificates()
    }


    async function clickedOnEditCerti(datas) {
        setCertifications(true)
        setCertiEdit(true)
        setData({
            ...data,
            certificateName: datas.Name,
            certificateAuth: datas.Authority,
            certificateId: datas.Cerid,
            certificateUrl: datas.URL,
            currCerId: datas.cer_id,

        })
    }

    async function updateCertiDetails() {
        if (data.certificateName === '') {
            setCertificationsError('Certification name is required!')
        }
        else if (data.certificateName.trim() === '') {
            setCertificationsError('Certification name is required!')
        }
        else if (!data.certificateName.match(validation().stringWithSpecialCharacter)) {
            setCertificationsError('Certification name is invalid!')
        }
        else if (data.certificateAuth === '') {
            setCertificationsError('Certificate Auth is required!')
        }
        else if (data.certificateAuth.trim() === '') {
            setCertificationsError('Certificate Auth is required!')
        }
        else if (data.certificateId === '') {
            setCertificationsError('Certificate ID is required!')
        }
        else if (data.certificateId.trim() === '') {
            setCertificationsError('Certificate ID is required!')
        }
        else {
            setIsLoading(true)
            let dataObj = {
                Authority: data.certificateAuth,
                Cerid: data.certificateId,
                URL: data.certificateUrl,
                Name: data.certificateName,
                cer_id: data.currCerId
            };
            let itemToEditID = dataObj.cer_id;
            try {
                let response = await apiservice.apiCall(`/certiprofileupdate/${itemToEditID}`, data.Token, dataObj, 'POST')
                console.log(response);
                if (response.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (response.data) {
                    setIsLoading(false)
                    console.log(response.data);
                    getCertificates()
                    setCertiEdit(false)

                    setData({
                        ...data,
                        certificateName: dataObj.Name,
                        certificateAuth: dataObj.Authority,
                        certificateId: dataObj.Cerid,
                        certificateUrl: dataObj.URL,
                        currCerId: dataObj.cer_id,
                    })
                    setCertifications(false)
                    syncMentorsData()
                } else {
                    setIsLoading(false)
                    console.log(response.error);
                    setErrorMsg('Invalid Credentials')
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
    }


    const getCertificates = async () => {
        setIsLoading(true)
        let allData = await apiservice.getLocalStorage('userData');
        await apiservice.apiCall('/certiprofile', allData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res);
                setData({
                    ...data, certiArr: res.data,
                    certificateName: '',
                    certificateAuth: '',
                    certificateId: '',
                    certificateUrl: '',
                })
                setWorkExperience(false)
            } else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }


    const certArr = () => {
        let list = []
        let dataArr = []
        if (data.certiArr === 'User Doesnot Have Data..') {
            dataArr.length = 0
        }
        else {
            dataArr = data.certiArr;
        }
        for (let index = 0; index < dataArr.length; index++) {
            const currentdata = dataArr[index];
            if (index <= 3) {

                list.push(
                    <div key={index} className='mt-3 '>
                        <div className='p-3 border rounded-2'>
                            <div className='text-end'>
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => { clickedOnEditCerti(currentdata) }} className='me-3 pointer' />
                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => { deleteCurrentCertificate(currentdata, index) }} className='pointer' />
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Certification name :  </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.Name}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Certification authority : </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.Authority} </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Certification ID : </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.Cerid}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Certification URL : </div>
                                        <div className='col-lg-6 col-12 text-break'>{currentdata.URL}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return list
    }
    // -----------Education------------
    const educationSaveBtn = () => {
        if (data.courseName === '') {
            setEducationError('Course name is required!')
        }
        else if (data.courseName.trim() === '') {
            setEducationError('Course name is required!')
        }
        // else if (data.collegeName === '') {
        //     setEducationError('College name is required!')
        // }
        else if (data.universityName === '') {
            setEducationError('University name is required!')
        }
        else if (data.universityName.trim() === '') {
            setEducationError('University name is required!')
        }
        else if (data.startingYear === '') {
            setEducationError('Starting Year is required!')
        }
        else if (data.completionYear === '') {
            setEducationError('Completion Year is required!')
        }
        else if (new Date(data.completionYear) < new Date(data.startingYear)) {
            setEducationError('Completion Year should be greater than Starting Year.!')
        }
        else {
            let dataObject = {
                Name: data.courseName,
                CollegeName: data.collegeName,
                UniversityName: data.universityName,
                Startignyear: data.startingYear,
                EndingYear: data.completionYear,
            }
            saveMentorsEducation(dataObject)
            console.log(dataObject);
        }
    }

    async function deleteCurrentEducation(dataArr) {
        setIsLoading(true)
        try {
            let allData = await apiservice.getLocalStorage('userData');
            let itemToDeleteID = await dataArr.edu_id
            let itemTodelete = await dataArr
            await apiservice.apiCall(`/eduprofiledelete/${itemToDeleteID}`, allData.Token, itemTodelete, 'POST').then(res => {
                if (res.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    getEducation()
                    syncMentorsData()
                } else {
                    setIsLoading(false)
                    console.log(res.error);
                }
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
            })
        }
        catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }
    async function clickedOnEditEducation(datas) {
        setEducation(true)
        setEduEdit(true)
        setData({
            ...data,
            courseName: datas.Name,
            collegeName: datas.CollegeName,
            universityName: datas.UniversityName,
            startingYear: datas.Startignyear,
            completionYear: datas.EndingYear,
            currEduId: datas.edu_id,
        })
    }
    async function updateMentorsEducation() {
        if (data.courseName === '') {
            setEducationError('Course name is required!')
        }
        // else if (data.collegeName === '') {
        //     setEducationError('College name is required!')
        // }
        else if (data.universityName === '') {
            setEducationError('University name is required!')
        }
        else if (data.startingYear === '') {
            setEducationError('Starting Year is required!')
        }
        else if (data.completionYear === '') {
            setEducationError('Completion Year is required!')
        }
        else if (new Date(data.completionYear) < new Date(data.startingYear)) {
            setEducationError('Completion Year should be greater than Starting Year.!')
        }
        else {
            setIsLoading(true)
            let dataObj = {
                Name: data.courseName,
                CollegeName: data.collegeName,
                UniversityName: data.universityName,
                Startignyear: data.startingYear,
                EndingYear: data.completionYear,
                edu_id: data.currEduId,
            };
            let itemToEditID = dataObj.edu_id;
            try {
                let response = await apiservice.apiCall(`/eduprofileupdate/${itemToEditID}`, data.Token, dataObj, 'POST')
                console.log(response);
                if (response.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (response.data) {
                    setIsLoading(false)
                    console.log(response.data);
                    getEducation()
                    setEduEdit(false)
                    setData({
                        ...data,
                        courseName: dataObj.Name,
                        collegeName: dataObj.CollegeName,
                        universityName: dataObj.UniversityName,
                        startingYear: dataObj.Startignyear,
                        completionYear: dataObj.EndingYear,
                    })
                    setEducation(false)
                    syncMentorsData()
                } else {
                    setIsLoading(false)
                    console.log(response.error);
                    setErrorMsg('Invalid Credentials')
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
    }

    async function saveMentorsEducation(dataObject) {
        setIsLoading(true)
        try {
            let dataObj = dataObject;
            let response = await apiservice.apiCall('/eduprofilestore', data.Token, dataObj, 'POST')
            console.log(response);
            if (response.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (response.data) {
                setIsLoading(false)
                console.log(response.data);
                getEducation()
            } else {
                setIsLoading(false)
                console.log(response.error);
                setErrorMsg('Invalid Credentials')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    const educationListView = () => {
        let list = []
        let dataArr = []
        if (data.educationArr === 'User Doesnot Have Data..') {
            dataArr.length = 0
        }
        else {
            dataArr = data.educationArr;
        }
        for (let index = 0; index < dataArr.length; index++) {
            const currentdata = dataArr[index];
            if (index <= 3) {

                list.push(
                    <div key={index} className='mt-3 '>
                        <div className='p-3 border rounded-2'>
                            <div className='text-end'>
                                <FontAwesomeIcon icon={faPencilAlt} className='me-3 pointer' onClick={() => clickedOnEditEducation(currentdata)} />
                                <FontAwesomeIcon icon={faTrashAlt} className='pointer' onClick={() => deleteCurrentEducation(currentdata)} />
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Course name :  </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.Name}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>University name :</div>
                                        <div className='col-lg-6 col-12 '>{currentdata.UniversityName}</div>
                                    </div>
                                </div>
                                {/* <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>College name : </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.CollegeName}</div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='row mt-3'>

                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Starting year :</div>
                                        <div className='col-lg-6 col-12 text-break'>{currentdata.Startignyear}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 fw-semibold'>Completion year : </div>
                                        <div className='col-lg-6 col-12 '>{currentdata.EndingYear}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
        }
        return list
    }

    const getEducation = async () => {
        setIsLoading(true)
        let allData = await apiservice.getLocalStorage('userData');
        await apiservice.apiCall('/eduprofile', allData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setIsLoading(false)
                setData({
                    ...data, educationArr: res.data,
                    courseName: '',
                    collegeName: '',
                    universityName: '',
                    startingYear: '',
                    completionYear: '',
                })
                setEducation(false)
            } else {
                setIsLoading(false)
                console.log(res.error);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }


    async function changePassword() {



        if (data.currentPassword === '') {
            setErrorMsg('Current password is required!')
        }
        else if (data.newPassword === '') {
            setErrorMsg('New password is required!')
        }
        else if (!data.newPassword.match(validation().passwordVal)) {
            setErrorMsg('Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.')
        }
        else if (data.newPassword !== data.reEnterPassword) {
            setErrorMsg('Please write the same password in new password field and Re-enter password field. ')
        }
        else {
            setIsLoading(true)
            let changePassObj = {
                password: data.currentPassword,
                newpass: data.newPassword,
                confirmpass: data.reEnterPassword
            }
            try {
                let response = await apiservice.apiCall('/changepassword', data.Token, changePassObj, 'POST')
                console.log(response);
                if (response.unAuthenticated) {
                    navigate('/')
                    localStorage.removeItem('userData')
                } else if (response.data) {
                    setIsLoading(false)
                    console.log(response.data);
                    setData({
                        ...data,
                        currentPassword: '',
                        newPassword: '',
                        reEnterPassword: '',
                    })
                } else {
                    setIsLoading(false)
                    console.log(response.error);
                    setErrorMsg(response.error)
                }

            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }

    }

    async function saveAccountDetails() {
        if (data.accountHolderName === "" || data.accountHolderName.trim() === "") {
            setBankError('Account Holders name is required')
        }
        else if (!data.accountHolderName.match(validation().onlyStringVal)) {
            setBankError('Account Holders name is Not Valid')
        }
        else if (data.Currency === "") {
            setBankError('Currency name is required')
        }
        else if (data.accountNumber === "") {
            setBankError('Account Number is required')
        }
        // else if (!data.accountNumber.match(validation().onlyNumberVal)) {
        //     setBankError('Account Number is Not Valid')
        // }
        else if (data.bankName === "" || data.bankName.trim() === "") {
            setBankError('Bank name is required')
        }
        else if (!data.bankName.match(validation().onlyStringVal)) {

            setBankError('Bank name is invalid')
        }
        else if (data.bankBranch === "" || data.bankBranch.trim() === "") {
            setBankError('Bank Branch is required')
        }
        else if (!data.bankBranch.match(validation().stringWithSpecialCharacter)) {
            setBankError('Bank Branch is invalid')
        } else if (
            data.ifscCode === '' &&
            data.IBANNumber === '' &&
            data.BICNumber === '' &&
            data.SortCode === '' &&
            data.SwiftCode === '' &&
            data.BankCode === '' &&
            data.FIN === '' &&
            data.BTN === '' &&
            data.BSB === ''
        ) {
            setBankError('Please enter at least one code (IFSC Code, IBAN No., BIC No., Sort Code, Swift Code, Bank Code, FIN, BTN, BSB).');
        }
        else {
            updateData()

        }
    }

    const updateData = async () => {
        console.log(data.countryCode);
        setIsLoading(true)
        let formData = new FormData();

        let dataObject = {
            'HolderName': data.accountHolderName,
            'AccountNumber': data.accountNumber,
            'BankName': data.bankName,
            'Branch': data.bankBranch,
            'AccountType': data.accountType,
            'name': data.firstName,
            'lname': data.lastName,
            'AboutSelf': yourselfValue,
            'shortDescription': data.shortDescription,
            'UserName': data.username,
            'Birthdate': data.birthDate,
            'Language': data.languages,
            'ProfileImage': data.selectedImgURL === '' || data.selectedImgURL === null || data.selectedImgURL === undefined ? '' : data.profileImage,
            'Currency': data.Currency,
            'CurrencySymbol': data.CurrencySymbol,
            'country_code': (data.countryCode && data.countryCode.code) || '',
            'country_dial_code': (data.countryCode && data.countryCode.value) || '',
            'mobile': data.mobileNo,
            'IFSC_code': data.ifscCode,
            'iban_number': data.IBANNumber,
            'bic_number': data.BICNumber,
            'sort_code': data.SortCode,
            'swift_code': data.SwiftCode,
            'bank_code': data.BankCode,
            'fin': data.FIN,
            'btn': data.BTN,
            'bsb': data.BSB,

        }
        console.log(dataObject);
        for (let key in dataObject) {
            formData.append(key, dataObject[key]);
        }

        let token = data.Token
        let res = await apiservice.apiCallFormData('/ProfileDeatilsStore', token, formData, "POST")
        console.log(res.data);
        if (res.unAuthenticated) {
            navigate('/')
            localStorage.removeItem('userData')
        } else if (res.data) {
            setIsLoading(false)
            if (profileSubmittedMsg !== '') {
                setSuccessFullyUpdate(true)
            }
            setSuccessMsg('Successfully Updated!')
            setBankError('')
            setData({ ...data, selectedImgURL: '' })
            setTimeout(() => {
                setSuccessMsg('')
                syncMentorsData()
            }, 3000);
            console.log(res.data);
            await apiservice.setLocalStorage('userData', res.data)
            setData({ ...data })
        } else {
            setIsLoading(false)
            setBankError(res.error)
            console.log(res);
        }
    }

    async function submitYourProfileForApproval() {
        let allData = await apiservice.getLocalStorage('userData');
        console.log(data);
        if (data.firstName === '') {
            setSubmitProfileError('First Name is required!');
        } else if (data.lastName === '') {
            setSubmitProfileError('Last Name is required!');
        } else if (data.shortDescription === '') {
            setSubmitProfileError('Short Description is required!');
        } else if (data.aboutYourself === '') {
            setSubmitProfileError('About yourself is required!');
        } else if (data.birthDate === '') {
            setSubmitProfileError('Birthdate is required!');
        } else if (data.workExpArr.length === 0) {
            setSubmitProfileError('Please write your work experience.');
        } else if (data.certiArr.length === 0) {
            setSubmitProfileError('Please write your certifications details.');
        } else if (data.educationArr.length === 0) {
            setSubmitProfileError('Please write your education details.');
        } else if (data.Currency === '') {
            setSubmitProfileError('Please select the required currency you want to earn in.');
        } else if (data.accountHolderName === '') {
            setSubmitProfileError('Account Holders name is required.');
        } else if (!data.accountHolderName.match(validation().onlyStringVal)) {
            setSubmitProfileError('Account Holders name is not valid.');
        } else if (data.accountNumber === '') {
            setSubmitProfileError('Account Number is invalid!');
        } else if (data.accountNumber.length < 3 || data.accountNumber.length > 30) {
            setSubmitProfileError('Account Number is not valid.');
        } else if (data.bankName === '') {
            setSubmitProfileError('Bank name is required!');
        } else if (!data.bankName.match(validation().onlyStringVal)) {
            setSubmitProfileError('Bank name is invalid.');
        } else if (
            data.ifscCode === '' &&
            data.IBANNumber === '' &&
            data.BICNumber === '' &&
            data.SortCode === '' &&
            data.SwiftCode === '' &&
            data.BankCode === '' &&
            data.FIN === '' &&
            data.BTN === '' &&
            data.BSB === ''
        ) {
            setBankError('Please enter at least one code (IFSC Code, IBAN No., BIC No., Sort Code, Swift Code, Bank Code, FIN, BTN, BSB).');
        }
        else {
            setIsLoading(true)
            let saveProfile = await apiservice.apiCall('/RequestForProfileApprove', data.Token, null, 'POST')
            console.log(saveProfile);

            if (saveProfile.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (saveProfile.data) {
                setIsLoading(false)
                saveAccountDetails()
                setProfileApprovePopup(true)
                setProfileSubmittedMsg(saveProfile.data)
                console.log(saveProfile.data);
                syncMentorsData()
            }
            else {
                setIsLoading(false)
                setErrorMsg(saveProfile.error)
                console.log(saveProfile.error);
            }
        }



    }
    const getCurrencyList = async () => {
        let allData = await apiservice.getLocalStorage('userData');
        await apiservice.apiCall('/currency_list', allData.Token).then(res => {
            if (res.unAuthenticated) {
                navigate('/')
                localStorage.removeItem('userData')
            } else if (res.data) {
                setCurrencyList(res.data)
            } else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const listOfCurrency = () => {
        let list = []
        for (let index = 0; index < currencyList.length; index++) {
            const element = currencyList[index];
            list.push(
                <option value={element.code}> {element.code} - {element.name} </option>
            )

        }

        return list
    }

    const handleCurrencyInputChange = (e) => {
        console.log(e.target.value);
        let currencyObject = currencyList.filter(ele => ele.code === e.target.value)
        let fObj = currencyObject[0]
        console.log(fObj, '----------------------------------- Fobj');
        setData({ ...data, 'Currency': fObj.code, 'CurrencySymbol': fObj.symbol })
    }



    return (
        <>
            <Modal show={profileApprovePopup}>
                <ModalBody >
                    <div className='d-flex justify-content-between'>{profileSubmittedMsg} <button className='btn btn-primary' onClick={() => setProfileApprovePopup(false)}>OK</button></div>
                </ModalBody>
            </Modal>
            <Modal show={successFullyUpdate}>
                <ModalBody >
                    <div className='d-flex justify-content-between'>Successfully Updated! <button className='btn btn-primary' onClick={() => setSuccessFullyUpdate(false)}>OK</button></div>
                </ModalBody>
            </Modal>
            <Modal show={cropPopUp}>

                <ModalBody>
                    <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickOK}><FontAwesomeIcon icon={faTimes} /></div>

                    {data.profileImage && (
                        <div className="crop-container">
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => completedCrop(c)}
                                aspect={false}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={data.profileImage}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-orange' onClick={handleCropButtonClick}>Crop Image</button>
                </ModalFooter>
            </Modal>
            <div className='p-3'>

                <div className='container-fluid'>
                    <div className='fs-3 fw-bold'>Profile</div>
                </div>
                <div className='d-none d-lg-block'>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className={menuKey === 'Profile' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Profile')}>Profile</button>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className={menuKey === 'Personal' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Personal')}>Personal Details</button>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className={menuKey === 'Other' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Other')}>Other Details</button>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className='btn btn-dark w-100' onClick={onClickSaveChange} disabled={bankvalidData ? true : false}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-lg-none d-block'>
                    <div className='row'>
                        <div className='col-6'>
                            <button className={menuKey === 'Profile' ? 'btn btn-light w-100 h-100 menu active-menu px-5' : 'btn btn-light w-100 h-100  menu'} onClick={() => onClickTopMenu('Profile')}>Profile</button>
                        </div>
                        <div className='col-6'>
                            <button className={menuKey === 'Personal' ? 'btn btn-light w-100 h-100 menu active-menu' : 'btn btn-light w-100  h-100 menu'} onClick={() => onClickTopMenu('Personal')}>Personal Details</button>
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <button className={menuKey === 'Other' ? 'btn btn-light w-100 h-100  menu active-menu ' : 'btn btn-light w-100 h-100  menu'} onClick={() => onClickTopMenu('Other')}>Other Details</button>
                        </div>
                        <div className='col-6'>
                            <button className='btn btn-dark w-100 h-100 ' onClick={onClickSaveChange} disabled={bankvalidData ? true : false}>Save Changes</button>
                        </div>
                    </div>
                </div>
                <hr />

                <div className='p-3'>
                    <div className='mb-3 text-success fw-bold text-center'>{successMsg}</div>
                    {menuKey === 'Profile' ?
                        <div className=''>
                            <div className='text-center mb-3'>
                                {errorMsg !== '' ?
                                    <small className='text-danger'>{errorMsg}</small>
                                    : ''}
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex'>
                                    <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                        {/* <img loading='lazy' src={data.selectedImgURL === "" ? data.profileImage : data.selectedImgURL} alt='' width={'100%'} height={'100%'} /> */}
                                        <LazyLoadImage
                                            alt={'...'}
                                            effect="blur"
                                            loading='lazy'
                                            width={'100%'} height={'100%'}
                                            src={data.selectedImgURL === "" ? data.profileImage : data.selectedImgURL} />
                                    </div>
                                    {/* <div className='mt-2'>
                                        <div className='ms-3 fw-bold pt-4 fs-5'>Profile Photo <span style={{ color: '#ff0000' }}>*</span></div>
                                    </div> */}
                                </div>
                                <div className='pt-4'>
                                    <input type="file"
                                        accept=".jpg,.png,.jpeg"
                                        style={{ display: 'none' }}
                                        onChange={onSelectProfileImage}
                                        ref={(instance) => {
                                            inputRef.current = instance; // We also need it to manipulate the elemnent
                                        }} />
                                    <div className='mt-2 border-bottom border-dark pointer' onClick={() => inputRef.current?.click()} >Change Photo</div>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <div className='row'>
                                    <div className='col-lg-10 col-12'>
                                        <div className="form-group">
                                            <label >Your Profile Link</label>
                                            <input type="text"
                                                className="form-control" name="profileLink" value={data.profileLink} onChange={handleInputChange} placeholder="Loremipsum12345/io.com" disabled />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-12'>
                                        <div className='text-end'>
                                            <ShareOnSocial
                                                textToShare={data.aboutYourself}
                                                link={data.profileLink}
                                                linkTitle={`${data.firstName} ${data.lastName}`}
                                                linkMetaDesc={data.shortDescription}
                                                linkFavicon={data.selectedImgURL === "" ? data.profileImage : data.selectedImgURL}
                                                noReferer
                                            >
                                                <button className='btn px-5 mt-4 border rounded-2'>Share</button>
                                            </ShareOnSocial>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='mt-3'>
                                <div className='row'>
                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group mb-2">
                                            <label >First name</label>
                                            <input type="text"
                                                className="form-control" name="firstName" value={data.firstName} onChange={handleInputChange} placeholder="First name" />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group mb-2">
                                            <label >Last name</label>
                                            <input type="text"
                                                className="form-control" name="lastName" value={data.lastName} onChange={handleInputChange} placeholder="Last name" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='mt-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label >Your purpose on XperThrivr (This will be your profile headline!)</label>
                                            {/* <ReactQuill
                                            style={{fontSize:'22px'}} theme="snow" value={yourselfValue} rows={'5'} onChange={setYourselfValue} /> */}

                                            <textarea type="text" maxLength={80} rows={'2'}
                                                className="form-control" name="shortDescription" value={data.shortDescription} onChange={handleInputChange} placeholder="For e.g.: Empowering aspiring barristers | Providing 1:1 client consultation" />
                                            <small className={''}>{80 - data.shortDescription.length}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label >About Yourself</label>
                                            <ReactQuill
                                                style={{ fontSize: '22px' }} theme="snow" value={yourselfValue} rows={'5'} onChange={setYourselfValue} />

                                            {/*    <textarea type="text"
                                                className="form-control" name="aboutYourself" value={data.aboutYourself} rows={'7'} onChange={handleInputChange} placeholder="About Yourself"></textarea>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        menuKey === 'Personal' ?
                            <div>
                                <div className='text-center mb-3'>
                                    {errorMsg !== '' ?
                                        <small className='text-danger'>{errorMsg}</small>
                                        : ''}
                                </div>
                                <div className=''>
                                    <div className=' fw-bold  fs-5'>Personal Details</div>
                                </div>

                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mb-2">
                                                <label >Username</label>
                                                <input type="text"
                                                    className="form-control" name="username" value={data.username} onChange={handleInputChange} placeholder="Username" disabled />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mb-2">
                                                <label >Birthdate</label>
                                                <input type="date"
                                                    className="form-control" name="birthDate" value={data.birthDate} onChange={handleInputChange} placeholder="Birthdate" />
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-4 col-12'>
                                            <div className="form-group">
                                                <label >Languages</label>
                                                <select className="form-select" name="languages" value={data.languages} onChange={handleInputChange} >
                                                    <option value=''>Select</option>
                                                    <option value='English'>English</option>
                                                    <option value='Hindi'>Hindi</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>


                                <div className='mt-3 d-flex justify-content-between'>
                                    <div className=' fw-bold  fs-5'>Work Experience</div>
                                    <div className='text-end'>
                                        {workExperience ? <div className='badge border-bottom border-danger rounded-0 px-0 text-danger pointer' onClick={onClickCloseExperience}><FontAwesomeIcon icon={faTimes} /> Close</div>
                                            : <div className={data.workExpArr.length <= 3 || data.workExpArr === 'User Doesnot Have Data..' ? 'badge border-bottom border-black rounded-0 px-0 text-dark pointer' : 'pointer d-none'} onClick={onClickAddExperience}><FontAwesomeIcon icon={faPlus} /> Add New</div>
                                        }
                                    </div>
                                </div>
                                {workExperience ?
                                    <div>
                                        {workExperienceError !== '' ?
                                            <div className='text-center'>
                                                <small className='text-danger '>{workExperienceError}</small>
                                            </div>
                                            : ''}
                                        <div className='mt-3'>
                                            <div className='row'>
                                                <div className='col-lg-3 col-12'>
                                                    <div className="form-group mb-2">
                                                        <label >Company Name</label>
                                                        <input type="text"
                                                            className="form-control" name="companyName" value={data.companyName} onChange={handleInputChange} placeholder="Company Name" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <div className="form-group mb-2">
                                                        <label >Designation</label>
                                                        <input type="text"
                                                            className="form-control" name="designation" value={data.designation} onChange={handleInputChange} placeholder="Designation" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-12'>
                                                            <div className="form-group mb-2">
                                                                <label >Start date</label>
                                                                <input type="date"
                                                                    className="form-control" name="startDate" value={data.startDate} onChange={handleInputChange} placeholder="Start date" />
                                                            </div>
                                                        </div>
                                                        {isPresent ? '' :
                                                            <div className='col-lg-6 col-12'>
                                                                <div className="form-group">
                                                                    <label >End date</label>
                                                                    <input type="date"
                                                                        className="form-control" name="endDate" value={data.endDate} onChange={handleInputChange} placeholder="End date" />
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <input type="checkbox" onChange={onChangeIsPresetCheckBox}
                                                        value={isPresent} checked={isPresent === 1 ? true : false} aria-label="" /> I am currently working in this role!
                                                </span>
                                                {/* {isPresent} */}
                                            </div>
                                            <div className='mt-3'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className="form-group">
                                                            <label >Roles and Responsibility</label>
                                                            <textarea type="text"
                                                                className="form-control" name="rolesResponsibility" value={data.rolesResponsibility} rows={'5'} onChange={handleInputChange} ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                {
                                                    isWorkExpEdit ?
                                                        <button className='btn btn-orange px-5' onClick={saveEditedWorkExp}>Update</button> :
                                                        <button className='btn btn-orange px-5' onClick={workExperienceSaveBtn}>Save</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {workExpList()}

                                    </div>}
                                <div className='mt-3 d-flex justify-content-between'>
                                    <div className=' fw-bold  fs-5'>Certifications</div>
                                    <div className='text-end'>
                                        {certifications ? <div className='badge border-bottom border-danger rounded-0 px-0 text-danger pointer' onClick={onClickCloseCertifications}><FontAwesomeIcon icon={faTimes} /> Close</div>
                                            : <div className={data.certiArr.length <= 3 || data.certiArr === 'User Doesnot Have Data..' ? 'badge border-bottom border-black rounded-0 px-0 text-dark pointer' : 'pointer d-none'} onClick={onClickAddCertifications}><FontAwesomeIcon icon={faPlus} /> Add New</div>
                                        }
                                    </div>
                                </div>
                                {certifications ?
                                    <div className='mt-3'>
                                        {certificationsError !== '' ?
                                            <div className='text-center mb-3'>
                                                <small className='text-danger '>{certificationsError}</small>
                                            </div>
                                            : ''}
                                        <div className='row'>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Certifications Name</label>
                                                        <input type="text"
                                                            className="form-control" name="certificateName" value={data.certificateName} onChange={handleInputChange} placeholder="Certifications Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Certification Authority</label>
                                                        <input type="text"
                                                            className="form-control" name="certificateAuth" value={data.certificateAuth} onChange={handleInputChange} placeholder="Certification Authority" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Certification ID</label>
                                                        <input type="text"
                                                            className="form-control" name="certificateId" value={data.certificateId} onChange={handleInputChange} placeholder="Certification ID" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Certification URL</label>
                                                        <input type="text"
                                                            className="form-control" name="certificateUrl" value={data.certificateUrl} onChange={handleInputChange} placeholder="Certification URL" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                isCertiEdit ?
                                                    <button className='btn btn-orange px-5' onClick={updateCertiDetails}>Update</button> :
                                                    <button className='btn btn-orange px-5' onClick={certificationSaveBtn}>Save</button>
                                            }
                                        </div>
                                    </div> :

                                    <div>
                                        {
                                            certArr()
                                        }
                                    </div>}

                                <div className='mt-3 d-flex justify-content-between'>
                                    <div className=' fw-bold  fs-5'>Education</div>
                                    <div className='text-end'>
                                        {education ? <div className='badge border-bottom border-danger rounded-0 px-0 text-danger pointer' onClick={onClickCloseEducation}><FontAwesomeIcon icon={faTimes} /> Close</div>
                                            : <div className={data.educationArr.length <= 3 || data.educationArr === 'User Doesnot Have Data..' ? 'badge border-bottom border-black rounded-0 px-0 text-dark pointer' : 'pointer d-none'} onClick={onClickAddEducation}><FontAwesomeIcon icon={faPlus} /> Add New</div>
                                        }
                                    </div>
                                </div>
                                {education ?
                                    <div className='mt-3'>
                                        {educationError !== '' ?
                                            <div className='text-center mb-3'>
                                                <small className='text-danger '>{educationError}</small>
                                            </div>
                                            : ''}
                                        <div className='row'>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Course Name</label>
                                                        <input type="text"
                                                            className="form-control" name="courseName" value={data.courseName} onChange={handleInputChange} placeholder="Course Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >College Name</label>
                                                        <input type="text"
                                                            className="form-control" name="collegeName" value={data.collegeName} onChange={handleInputChange} placeholder="College Name" />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >University Name</label>
                                                        <input type="text"
                                                            className="form-control" name="universityName" value={data.universityName} onChange={handleInputChange} placeholder="University Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Starting Year</label>
                                                        <input type="date"
                                                            className="form-control" name="startingYear" value={data.startingYear} onChange={handleInputChange} placeholder="Starting Year" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className='mb-3'>

                                                    <div className="form-group">
                                                        <label >Completion Year</label>
                                                        <input type="date"
                                                            className="form-control" name="completionYear" value={data.completionYear} onChange={handleInputChange} placeholder="Completion Year" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                isEduEdit ?
                                                    <button className='btn btn-orange px-5' onClick={updateMentorsEducation}>Update</button> :
                                                    <button className='btn btn-orange px-5' onClick={educationSaveBtn}>Save</button>
                                            }
                                        </div>
                                    </div> :
                                    <div>
                                        {educationListView()}

                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <div className='text-center mb-3'>
                                    {errorMsg !== '' ?
                                        <small className='text-danger ' >{errorMsg}</small>
                                        : ''}
                                </div>
                                <div className=''>
                                    <div className=' fw-bold  fs-5'>Account Details</div>
                                </div>
                                <div className='mt-3 row'>
                                    <div className='col-lg-4 col-12'>
                                        <div className='mb-3'>
                                            <div className="form-group">
                                                <label >Email Address</label>
                                                <input type="text"
                                                    className="form-control" name="emailAddress" value={data.emailAddress} onChange={handleInputChange} placeholder="Email Address" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-12'>
                                        <div className='mb-3'>
                                            <div className="">

                                                {/* <div className="input-group mb-3">

                                                    <span className="input-group-text bg-transparent p-0" id="basic-addon1">
                                                        {console.log(selectedOption)}
                                                        <Select onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} isDisabled={isMobileEditable ? true : false} placeholder='+1' />
                                                    </span>

                                                    <input type="text" maxLength={10} disabled={isMobileEditable ? true : false} autoComplete='off'
                                                        className="form-control py-0" name="mobileNo" value={data.mobileNo} onChange={handleInputChange} placeholder="Mobile" />

                                                </div> */}
                                                <div className='d-md-flex'>
                                                    <div className='form-group me-3'>
                                                    <label >Country Code</label>
                                                        <Select className='form-control p-0' onChange={handleChange} value={selectedOption} defaultValue={selectedOption} options={options} isDisabled={isMobileEditable ? true : false} placeholder='+1' />
                                                    </div>
                                                    <div className='form-group'>
                                                    <label >Mobile No.</label>
                                                        <input type="text" maxLength={10} disabled={isMobileEditable ? true : false} autoComplete='off'
                                                            className="form-control " name="mobileNo" value={data.mobileNo} onChange={handleInputChange} placeholder="Mobile" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className=' fw-bold  fs-5'>Change Password</div>
                                </div>

                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mb-2">
                                                <label >Current password</label>
                                                <input type="password"
                                                    className="form-control" name="currentPassword" value={data.currentPassword} onChange={handleInputChange} placeholder="Current password" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mb-2">
                                                <label >New password</label>
                                                <input type="password"
                                                    className="form-control" name="newPassword" value={data.newPassword} onChange={handleInputChange} placeholder="New password" />
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mb-2">
                                                <label>Re-enter new password</label>
                                                <input type="password"
                                                    className="form-control" name="reEnterPassword" value={data.reEnterPassword} onChange={handleInputChange} placeholder="Re-enter new password" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group">
                                                <button className='btn btn-orange px-5 my-2' onClick={changePassword} style={{ width: 'max-content' }} >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className=' fw-bold  fs-5'>Settings</div>
                                </div>

                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-8 col-12'>

                                            <div className="form-group mb-2">
                                                <label >Timezone (Required for timely communications)</label>
                                                <GetTimeZone />
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-4 col-12">
                                            <label htmlFor=''>Select currency </label>
                                            {console.log('currency', isCurrencyEditable)}
                                            <select type="text" disabled={isCurrencyEditable}
                                                className="form-select" name="Currency" value={data.Currency} onChange={handleCurrencyInputChange} placeholder="Select currency">
                                                <option value="">Select currency</option>
                                                {listOfCurrency()}
                                                {/* <option value="INR">INR </option>
                                                <option value="USD">USD </option>
                                                <option value="EUR">EUR </option>
                                                <option value="GBP">GBP </option>
                                                <option value="MUR">MUR </option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className=' fw-bold  fs-5'>Bank Details</div>
                                </div>
                                <div className='text-center text-danger'>{bankError}</div>
                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>

                                                <div className="form-group">
                                                    <label >Account Holder Name</label>
                                                    <input type="text"
                                                        className="form-control" name="accountHolderName" value={data.accountHolderName} onChange={handleInputChange} placeholder="Account Holder Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>

                                                <div className="form-group">
                                                    <label >Account Number</label>
                                                    <input type="text"
                                                        className="form-control" name="accountNumber" value={data.accountNumber} onChange={handleInputChange} placeholder="Account Number" />

                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>

                                                <div className="form-group">
                                                    <label >Bank Name</label>
                                                    <input type="text"
                                                        className="form-control" name="bankName" value={data.bankName} onChange={handleInputChange} placeholder="Bank Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>

                                                <div className="form-group">
                                                    <label >Bank Branch</label>
                                                    <input type="text"
                                                        className="form-control" name="bankBranch" value={data.bankBranch} onChange={handleInputChange} placeholder="Bank Branch" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >Type of Account </label>
                                                    <input type="text"
                                                        className="form-control" name="accountType" value={data.accountType} onChange={handleInputChange} placeholder="Type of Account" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >IFSC Code</label>
                                                    <input type="text"
                                                        className="form-control" name="ifscCode" value={data.ifscCode} onChange={handleInputChange} placeholder="IFSC Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >IBAN Number</label>
                                                    <input type="text"
                                                        className="form-control" name="IBANNumber" value={data.IBANNumber} onChange={handleInputChange} placeholder="IBAN Number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >BIC Number</label>
                                                    <input type="text"
                                                        className="form-control" name="BICNumber" value={data.BICNumber} onChange={handleInputChange} placeholder="BIC Number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >Sort Code</label>
                                                    <input type="text"
                                                        className="form-control" name="SortCode" value={data.SortCode} onChange={handleInputChange} placeholder="Sort Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >Swift Code</label>
                                                    <input type="text"
                                                        className="form-control" name="SwiftCode" value={data.SwiftCode} onChange={handleInputChange} placeholder="Swift Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >Bank Code</label>
                                                    <input type="text"
                                                        className="form-control" name="BankCode" value={data.BankCode} onChange={handleInputChange} placeholder="Bank Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >FIN</label>
                                                    <input type="text"
                                                        className="form-control" name="FIN" value={data.FIN} onChange={handleInputChange} placeholder="FIN" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >BTN</label>
                                                    <input type="text"
                                                        className="form-control" name="BTN" value={data.BTN} onChange={handleInputChange} placeholder="BTN" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className='mb-3'>
                                                <div className="form-group">
                                                    <label >BSB</label>
                                                    <input type="text"
                                                        className="form-control" name="BSB" value={data.BSB} onChange={handleInputChange} placeholder="BSB" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-orange px-5' onClick={saveAccountDetails} style={{ width: 'max-content' }} >Save</button>
                                </div>
                                {console.log(data.isAdminVerified)}
                                {data.isAdminVerified === 3 ?
                                    <div className='text-center' >
                                        {submitProfileError === '' ? '' : <div className='text-danger'>{submitProfileError}</div>}
                                        <button className='btn btn-orange px-5 mt-2' onClick={submitYourProfileForApproval} style={{ width: 'max-content' }} disabled={isLoading ? true : false} >{isLoading ? 'Loading...' : 'Submit Your Profile'} <FontAwesomeIcon className='ms-2' icon={faArrowRight} /> </button>
                                    </div>
                                    :
                                    ""
                                }

                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ProfilePage
