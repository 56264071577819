import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../components/LandingpageComponents/Header/Header'
import Footersection from '../../../components/LandingpageComponents/Footer/Footer'
import hollowStar from "../../../assets/startHollow.png"
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import noImg from "../../../assets/no-image.png"
import MentorCard from './../../../components/LandingpageComponents/MentorsCard/MentorCard';
import left from '../../../assets/Left.png'
import right from '../../../assets/rightArrow.png'
import leftLight from '../../../assets/leftLight.png'
import rightLight from '../../../assets/rightLight.png'
import { Pagination } from 'react-bootstrap';
import "./ExploreAllMentors.scss"
import apiservice from '../../../apiservice/ApiService';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';



function ExploreAllMentors(props) {
  let navigate = useNavigate()
  let [myData, setMydata] = useState([]);


  async function getAllData() {
    setIsLoading(true)
    try {
      let mentorsdata = await apiservice.apiCall('/ContentExpertList')
      console.log(mentorsdata);
      if (mentorsdata.data) {
        setIsLoading(false)
        console.log(mentorsdata.data);
        setMydata(mentorsdata.data)
      }
      else {
        setIsLoading(false)
        console.log(mentorsdata.error);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getAllData()
    localStorage.removeItem('isFilter')

  }, [])

  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(myData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = myData.slice(startIndex, endIndex);

  const [isLoading, setIsLoading] = useState(false)

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const clickedOnMentorsCard = (items) => {
    console.log(items);
    let mentorsdata = apiservice.setLocalStorage('mentorData', items)
    // navigate('/contentexpert')
    navigate(`/contentexpert/${items.content_experts_id}`)
  }


  const pageRangeStart = Math.max(1, currentPage - 2);
  const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

  const getMentorsList = () => {
    let array = itemsToDisplay
    let list = []
    let count = 0
    for (let index = 0; index < array.length; index++) {
      let data = array[index]
      list.push(
        <MentorCard
          key={index}
          onClick={() => clickedOnMentorsCard(data)}
          mentorsName={`${data.name} ${data.lname}`}
          sideBgColor={`cardBg${count}`}
          mentorsImage={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`}
          mentorsDescription={data.shortDescription}
          mentorDesignation = {`${data.lastDesignation} @ ${data.company}`}
        />
      )
      if (count === 4) {
        count = 0;
      } else {
        count++;
      }
    }
    return list
  }




  return (
    <Fragment>
      <Header navigate={navigate} />
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: "#f1efe7" }}>
          <article className='container py-3'>
            <div className="row">
              <div className="col-lg-9 col-12">
                <h1 className='Title'>Greatest Content Expert Inspire</h1>
                <span className='descText'>Our team of Content Experts comprises highly qualified industry professionals dedicated to advancing your career and refining your skills. Discover the ideal Content Expert tailored to your needs, and unlock new heights in your professional journey.</span>
              </div>
              <div className="col-lg-3 d-lg-block d-none">
                <img src={hollowStar} width={84} height={84} alt="" />
              </div>
            </div>
          </article>

          <article className='container'>
            <div className="row">
              {getMentorsList()}
            </div>
          </article>


          <article className='d-flex justify-content-center'>
            <Pagination>
              <Pagination.Prev
                className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                onClick={handlePreviousPage}
                disabled={currentPage === 1 ? true : false}
              >
                <img src={currentPage === 1 ? leftLight : left} width={7} height={12} alt='...' />
              </Pagination.Prev>
              {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                <Pagination.Item
                  key={pageRangeStart + offset}
                  active={pageRangeStart + offset === currentPage}
                  onClick={() => handlePageChange(pageRangeStart + offset)}
                >
                  {pageRangeStart + offset}
                </Pagination.Item>
              ))}
              {pageRangeEnd < totalPages && (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Next
                className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                onClick={handleNextPage}
                disabled={currentPage === totalPages ? true : false}
              >
                <img src={currentPage === totalPages ? rightLight : right} width={7} height={12} alt='...' />
              </Pagination.Next>
            </Pagination>
          </article>


          <Footersection navigate={navigate} />
        </section>
      }
    </Fragment>
  )
}

export default ExploreAllMentors
